import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { webpTry } from '@src/utils/webp';
import Module from '../components/Module';
import Text from './../components/Text';
  
const useStyles = makeStyles((theme) => ({
  photoGrid: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  container: {
    display: 'block',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridGap: 0,
      gridRowGap: theme.spacing(1 / 2)
    },
  },
  background: {
    height: '100%',
    width: "100%",
    minHeight: 550,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down('md')]: {
      minHeight: 350,
    },
  }
}));

const Gallery = ({ photos }) => {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      {(photos || []).map((p, i) => (
        <div key={`photo_${i}`} className={clsx(classes.background, 'lazyload')} data-bg={webpTry(p)} style={{ gridArea: p.gridArea }}></div>
      ))}
    </div>
  );
}

const PhotosGridModule = ({ title, desktopItems, mobileItems }) => {
  const classes = useStyles();

  const itemFilter = f => f.photo && f.photo.src;
  const itemMap = (f, mobile) => ({ ...f.photo, gridArea: mobile ? `auto / 1 / span ${f.larger ? 2 : 1} / auto` : `auto / auto / auto / span ${f.larger ? 2 : 1}` });

  const filteredDesktopItems = (desktopItems || []).filter(itemFilter).map((f) => itemMap(f, false));
  const filteredMobileItems = (mobileItems || []).filter(itemFilter).map((f) => itemMap(f, true));

  return (
    <Module className={classes.photoGrid} variant='full'>
      <Text className={classes.title} variant='titleXXXL' weight='bold' align='center' overline>{title}</Text>
      <Box className={classes.container}>
        <Hidden smDown>
          <Gallery photos={filteredDesktopItems}></Gallery>
        </Hidden>
        <Hidden mdUp>
          <Gallery photos={filteredMobileItems}></Gallery>
        </Hidden>
      </Box>
    </Module>
  );
};

PhotosGridModule.defaultProps = {
  desktopItems: [],
  mobileItems: []
};

export default PhotosGridModule;
