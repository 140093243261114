import React, { useState, Fragment, useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import Module from "../components/Module"
import Text from "../components/Text"
import Picture from "../components/Picture"
import CtaButton from "../components/CtaButton"
import { detectAlpha2Country, getLocaleByAlpha2 } from "../utils/country"
import { GTMEvent } from "../utils/gtm"
import "../style/LeadFormModule.css"

const useStyles = makeStyles(theme => ({
  leadFormContainer: {
    paddingLeft: "48px",
    paddingRight: "48px",
    marginTop: "80px !important",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  leadFormContainerConfirm: {
    marginTop: "80px !important",
  },
  contentContainerConfirm: {
    maxWidth: 922,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    maxWidth: "922px",
    backgroundColor: "#f2f2f2",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      float: "initial !important",
    },
  },
  leadForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "58%",
    backgroundColor: "#f2f2f2",
    minHeight: "415px",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "24px",
    paddingBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imgContainer: {
    width: "42%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mdtitle: {
    fontSize: "30px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.07",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#662482",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdsubTitle: {
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.11",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#3b4154",
    marginTop: "16px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  xstitle: {
    fontSize: "30px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.07",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#662482",
    paddingTop: "40px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  xssubTitle: {
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.11",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#3b4154",
    marginTop: "16px",
    marginBottom: "24px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  inputForm: {
    marginTop: "8px",
    width: "100%",
    padding: "20.9px 10px",
    border: "solid 1px #f2f2f2",
    backgroundColor: "#fbfbfb",
    color: "#3b4154",
    textAlign: "center",
    fontSize: "16px",
  },
  bannerCtaButton: {
    width: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  privacyInfo: {
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#808080",
    marginTop: "8px",
  },
  btnCta: {
    border: "none",
    backgroundColor: "transparent",
  },
  check_icon: {
    width: "80px",
    height: "80px",
    position: "relative",
    borderRadius: "50%",
    boxSizing: "content-box",
    zIndex: "1",
  },
  icon_line: {
    height: "5px",
    backgroundColor: "#662482",
    display: "block",
    borderRadius: "2px",
    position: "absolute",
    zIndex: "10",
  },
  line_tip: {
    top: "47px",
    left: "18px",
    width: "20px",
    transform: "rotate(45deg)",
  },
  line_long: {
    top: "27px",
    left: "21px",
    width: "80px",
    transform: "rotate(-45deg)",
  },
  icon_circle: {
    top: "-4px",
    left: "-4px",
    zIndex: "10",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    position: "absolute",
    boxSizing: "content-box",
    border: "4px solid #662482",
    clipPath: "polygon(74% 0, 0% 0%, 0% 100%, 100% 100%, 100% 43%, 60% 39%)",
  },
  icon_fix: {
    top: "8px",
    width: "5px",
    left: "26px",
    zIndex: "1",
    height: "85px",
    position: "absolute",
    transform: "rotate(-45deg)",
    clipPath: "polygon(74% 0, 0% 0%, 0% 100%, 100% 100%, 100% 43%, 60% 39%)",
  },
  container: {
    maxWidth: "922px",
    paddingLeft: "32px !important",
    paddingRight: "32px !important",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  col24: {
    flexBasis: "100%",
    maxWidth: "100%",
  },
  pxMd2: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  leadContainer: {
    display: "flex",
    minHeight: "415px",
    flexDirection: "column",
    backgroundColor: "#fff",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
    alignItems: "center !important",
    justifyContent: "center",
  },
  containerCheckedIcon: {
    borderRadius: "5px",
    padding: "24px",
    marginTop: "24px",
    backgroundColor: "#fff",
  },
  confirmationMessageTitle: {
    fontSize: "40px",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "0.94",
    letterSpacing: "normal",
    margin: "0",
    color: "#662482",
    textAlign: "center",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    paddingBottom: "15px",
  },
  descriptionMessage: {
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    paddingBottom: "24px",
  },
  img: {
    objectFit: "cover",
    height: "100%",
    objectPosition: "center",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontSize: "12px",
    marginTop: "16px",
    color: "#ff6671",
  },
  containerDate: {
    width: "100%",
    position: "relative",
    height: "100%",
    marginBottom: "0px",
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  placeHolderDate: {
    cursor: "pointer",
    zIndex: "9",
    color: "grey",
    textAlign: "center",
    backgroundColor: "#fbfbfb",
    position: "absolute",
    width: "99%",
    top: "24px",
    height: "auto",
    paddingTop: "0px",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      top: "10px",
      paddingTop: "16px",
      height: "100%",
    },
  },
}))

const LeadFormModule = props => {
  const {
    title,
    subtitle,
    privacy_info,
    button_text,
    input_mail_placeholder,
    image,
    surname_label,
    surname_is_visible,
    telephone_number_is_visible,
    telephone_number_label,
    name_is_visible,
    name_label,
    birth_date_is_visible,
    birth_date_label,
    confirmation_message_text,
    confirmation_message_title,
    audience_campaign,
    audience_group,
    audience_segment,
    audience_source_name,
    error_text,
    message_confirmation_cta,
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const country = detectAlpha2Country() ? detectAlpha2Country() : "uk"
  const [isComplete, setComplete] = useState(false)
  const [isError, setIsError] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    let birthDate = e?.target?.birthdate?.value.split("-")
    var data = {
      email: e?.target?.email?.value,
      firstname: e?.target?.name?.value,
      lastname: e?.target?.surname?.value,
      profile: {
        birthDay: birthDate ? birthDate[2] : null,
        birthMonth: birthDate ? birthDate[1] : null,
        birthYear: birthDate ? birthDate[0] : null,
        phones: {
          type: "default",
          number: e?.target?.phone?.value,
        },
      },
      site: `FH${country.toUpperCase()}`,
      locale: getLocaleByAlpha2(country),
      apikey: process.env.GATSBY_GPROXY_NEWSLETTER_URL,
      subscriptions: {
        marketing: true,
      },
      audience: {
        campaign: audience_campaign,
        group: audience_group,
        segment: audience_segment,
        source: audience_source_name,
      },
      callback: function callback(result) {
        console.log("res lead form", result)
        if (result?.errorCode === 0) {
          GTMEvent("form_lead", "success", "undefined")
          setComplete(true)
        } else {
          GTMEvent("form_lead", "error", result.errorDetails)
          setIsError(true)
        }
      },
    }
    window.gproxy.subscriptions.upsert(data)
  }

  useEffect(() => {
    if (document.getElementById("birthdateDate")) {
      var today = new Date()
      var dd = today.getDate()
      var mm = today.getMonth() + 1 //January is 0!
      var yyyy = today.getFullYear()

      if (dd < 10) {
        dd = "0" + dd
      }

      if (mm < 10) {
        mm = "0" + mm
      }

      today = yyyy + "-" + mm + "-" + dd
      document.getElementById("birthdateDate").setAttribute("max", today)
    }
  }, [])

  return isComplete ? (
    <Module
      className={classes.leadFormContainerConfirm}
      classes={{ container: classes.contentContainerConfirm }}
      variant="left"
    >
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.col24}>
            <div className={classes.pxMd2}>
              <div className={classes.leadContainer}>
                <div className={classes.containerCheckedIcon}>
                  <div className={classes.check_icon}>
                    <span
                      className={`${classes.icon_line} ${classes.line_tip}`}
                    ></span>
                    <span
                      className={`${classes.icon_line} ${classes.line_long}`}
                    ></span>
                    <div className={classes.icon_circle}></div>
                    <div className={classes.icon_fix}></div>
                  </div>
                </div>
                <Text className={classes.confirmationMessageTitle}>
                  {confirmation_message_title}
                </Text>
                <Text tag="p" className={classes.descriptionMessage}>
                  {confirmation_message_text}
                </Text>

                <button
                  className={classes.btnCta}
                  onClick={() => setComplete(false)}
                >
                  <CtaButton size="large" className={classes.bannerCtaButton}>
                    {message_confirmation_cta}
                  </CtaButton>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Module>
  ) : (
    <Module
      className={classes.leadFormContainer}
      classes={{ container: classes.contentContainer }}
      variant="left"
    >
      <Fragment>
        <Text className={classes.xstitle} component="p">
          {title}
        </Text>
        <Text className={classes.xssubTitle} component="p">
          {subtitle}
        </Text>
      </Fragment>

      <div className={classes.imgContainer}>
        <Picture
          className={classes.foreground}
          imgClassName={classes.img}
          {...image}
        ></Picture>
      </div>
      <div className={classes.leadForm}>
        <Fragment>
          <Text className={classes.mdtitle} component="p">
            {title}
          </Text>
          <Text className={classes.mdsubTitle} component="p">
            {subtitle}
          </Text>
          <form onSubmit={onSubmit} className={classes.formFields}>
            <input
              type="email"
              name="email"
              id="lead_form_email"
              className={classes.inputForm}
              placeholder={input_mail_placeholder}
              required
              onClick={() =>
                GTMEvent("form_lead", "click_field", "email_clicked")
              }
            ></input>
            {name_is_visible && (
              <input
                type="text"
                name="name"
                className={classes.inputForm}
                placeholder={name_label}
                required
                onClick={() =>
                  GTMEvent("form_lead", "click_field", "name_clicked")
                }
              ></input>
            )}
            {surname_is_visible && (
              <input
                type="text"
                name="surname"
                className={classes.inputForm}
                placeholder={surname_label}
                required
                onClick={() =>
                  GTMEvent("form_lead", "click_field", "surname_clicked")
                }
              ></input>
            )}
            {birth_date_is_visible && (
              <div className={classes.containerDate} id="idContainerDate">
                <label
                  className={classes.placeHolderDate}
                  htmlFor="birthdateDate"
                  id="birthdateDateLabel"
                >
                  {birth_date_label}
                </label>
                <input
                  type="date"
                  name="birthdate"
                  className={classes.inputForm}
                  placeholder={birth_date_label}
                  required
                  id="birthdateDate"
                  onClick={() => {
                    document.getElementById("birthdateDate").showPicker()
                    document.getElementById(
                      "birthdateDateLabel"
                    ).style.display = "none"
                    document.getElementById(
                      "idContainerDate"
                    ).style.marginBottom = "0px"
                    GTMEvent("form_lead", "click_field", "birthdate_clicked")
                  }}
                  onBlur={() =>
                    document.getElementById("birthdateDate").value == ""
                      ? (document.getElementById(
                          "birthdateDateLabel"
                        ).style.display = "block")
                      : null
                  }
                ></input>
              </div>
            )}
            {telephone_number_is_visible && (
              <input
                type="tel"
                name="phone"
                pattern="\d*"
                className={classes.inputForm}
                placeholder={telephone_number_label}
                required
                onClick={() =>
                  GTMEvent("form_lead", "click_field", "phone_clicked")
                }
              ></input>
            )}

            {isError && (
              <div className={classes.errorMessage}>{error_text}</div>
            )}

            {privacy_info && (
              <Text tag="div" className={classes.privacyInfo}>
                {privacy_info}
              </Text>
            )}
            <button className={classes.btnCta}>
              <CtaButton
                size="large"
                className={classes.bannerCtaButton}
                onClick={() => {
                  GTMEvent("form_lead", "click_button", "send")
                  document.getElementById("lead_form_email").value === ""
                    ? GTMEvent("form_lead", "error", "email_missing")
                    : null
                }}
              >
                {button_text}
              </CtaButton>
            </button>
          </form>
        </Fragment>
      </div>
    </Module>
  )
}

export default LeadFormModule
