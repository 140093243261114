import React from "react"
import PropTypes from "prop-types"
import Icon from "../components/Icon"
import Text from '../components/Text';
import Module from '../components/Module';
import { Container, Grid, Box, Hidden, IconButton, Dialog, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from "../components/Button"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import FluidImage from '../components/FluidImage';

const parallaxHeight = '35vh'
const useStyles = makeStyles(theme => ({
  heroBannerContainer: {
    position: "relative",
    minHeight: "35vh",
    display: "flex",
    alignItems: "center",
  },
  parallax: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    marginTop: 65, // Header
    flexDirection: "column", 
    justifyContent: "center",
    padding: theme.spacing(0, 30, 0, 30),
    textAlign: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .title + .subtitle': {
      marginTop: theme.spacing(4),
    },
    '& .subtitle': theme.text.bodyM,
    '& .logo img': {
      maxWidth: 400
    }
  },
}))

const GenericTopBannerModule = props => {

  const { logo, title, title_color, subtitle, image, image_mobile } = props; 
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const moduleRef = React.useRef(null);

  return (
    <Module 
    ref={moduleRef}
    alwaysVisible 
    variant="full" 
    noMargin 
    style={{ 
      backgroundImage: !isMobile ? `url(${image.field_value?.src})` : `url(${image_mobile.field_value?.src})`, backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover' 
    }}>
      <Box className={classes.heroBannerContainer}>
        <Parallax
          key={`GenericTopBannerModule`}
          strength={250}
          amount={0.75}
          className={classes.parallax}
          parallaxStyle={{
            position: "absolute",
            height: parallaxHeight,
          }}
        />
        <div className={classes.contentContainer}>
          <ParallaxItem disabled={isMobile} y={["20", "-20"]}>
            <Module>

              { logo.field_value && (
                <ParallaxItem styleInner={{ height: '100%' }} y={['2', '-2']}>
                  <div className="logo"><FluidImage {...logo.field_value} /></div>
                </ParallaxItem>
              ) }

              { title.field_value && (
                <Text
                  className="title"
                  align="center"
                  variant="specialTitleXXL"
                  weight="bold"
                  color={ title_color.field_value === 'WHITE' ? 'textTertiary' : 'primary' }
                >
                  {title.field_value}
                </Text>
              )}

              { subtitle.field_value && (
                <Text
                  className="subtitle"
                  align="center"
                  variant="specialTitleL"
                  weight="bold"
                  color={ title_color.field_value === 'WHITE' ? 'textTertiary' : 'primary' }
                  dangerouslySetInnerHTML={{ __html: subtitle.field_value }}
                />
              )}

            </Module>
          </ParallaxItem>
        </div>
      </Box>
    </Module>
  )

};

export default GenericTopBannerModule;

GenericTopBannerModule.defaultProps = {
  logo: {
    field_type: "image",
    field_value: null
  },
  title: {
    field_type: "text",
    field_value: ""
  },
  title_color: {
    field_type: "select",
    field_value: ""
  },  
  subtitle: {
    field_type: "wysiwyg",
    field_value: ""
  },
  image: {
    field_type: "image",
    field_value: null
  },
  image_mobile: {
    field_type: "image",
    field_value: null
  },    
};

GenericTopBannerModule.propTypes = {
  title: PropTypes.object,
  logo: PropTypes.object,
  title: PropTypes.object,
  title_color: PropTypes.object,
  subtitle: PropTypes.object,
  image: PropTypes.object,
  image_mobile: PropTypes.object,
};