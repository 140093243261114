import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import Text from "../components/Text"
import FluidImage from "../components/FluidImage"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

const useStyles = makeStyles(theme => ({
  securePaymentsTitle: {
    marginBottom: "40px",
  },
  picture: {
    display: "flex",
    justifyContent: "center",
    maxHeight: 98,
    [theme.breakpoints.down("xl")]: {
      maxHeight: 88,
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: 78,
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: 60,
    },
  },
  securePaymentsContainer: {
    margin: "80px 80px 0",
    [theme.breakpoints.down("md")]: {
      margin: "40px 24px 0",
    },
  },
  clickable: {
    cursor: "pointer",
  },
  securePaymentCardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "32px",
    gridRowGap: "40px",
    paddingTop: "32px",
    "& .secure-payment-card-item": {
      width: "100%",
      "& .secure-payment-card-item-container": {
        backgroundColor: "#f6f6f6",
        color: "#662482",
        padding: 40,
        display: "flex",
        flexFlow: "column",
        height: "100%",
      },
      "& .secure-payment-card-item-title": {
        fontWeight: 800,
        fontSize: 32,
        lineHeight: "48px",
        [theme.breakpoints.down("md")]: {
          fontSize: 24,
          lineHeight: "32px",
        },
      },
      "& .secure-payment-card-item-text": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        "& p": {
          margin: "24px 0px 0px 0px",
        },
      },
    },
  },
  sliderDotsContainer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    width: "100%",
    height: "8px",
    position: "relative",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .slider-dot": {
      height: "8px",
      width: "8px",
      display: "inline-block",
      borderRadius: "50%",
      backgroundColor: "#005c69",
      marginLeft: "8px",
      cursor: "pointer",
    },
    "& .slider-dot-inactive": {
      opacity: 0.4,
    },
  },
}))

const SecurePaymentsModule = ({ title, item, secondary }) => {
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    initial: 0,
    loop: true,
    breakpoints: {
      "(max-width: 900px)": {
        drag: true,
        slides: {
          perView: 2,
        },
      },
    },
    slides: {
      perView: 4,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <section className="secure-payments-module">
      <div className={classes.securePaymentsContainer}>
        <Text
          variant="titleXXXL"
          align="center"
          weight="bold"
          overline
          gutterBottom
          className={classes.securePaymentsTitle}
        >
          {title?.field_value}
        </Text>
        <div
          ref={sliderRef}
          className="keen-slider secure-payment-item-container"
        >
          {item?.field_value.map((el, i) => (
            <div
              key={i}
              className="keen-slider__slide secure-payment-item-slide"
            >
              {/* --- if link  --- */}
              {el?.field_value?.cta?.field_value?.href ? (
                <a
                  className={classes.clickable}
                  {...el?.field_value?.cta?.field_value}
                >
                  <FluidImage
                    className={classes.picture}
                    {...el?.field_value?.logo_image?.field_value}
                  />
                </a>
              ) : (
                <FluidImage
                  className={classes.picture}
                  {...el?.field_value?.logo_image?.field_value}
                />
              )}
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && (
          <div className={classes.sliderDotsContainer}>
            {Array.from(
              Array(Math.floor(item?.field_value.length / 2)).keys()
            ).map(idx => (
              <span
                key={idx}
                className={`slider-dot ${
                  currentSlide !== idx * 2 ? "slider-dot-inactive" : ""
                }`}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx * 2)
                }}
              />
            ))}
          </div>
        )}
        <div className={classes.securePaymentCardContainer}>
          {secondary?.field_value.map((el, i) => {
            return (
              <div key={i} style={{ display: "flex" }}>
                <div className="secure-payment-card-item">
                  <div className="secure-payment-card-item-container">
                    <div className="secure-payment-card-item-title">
                      {el?.field_value?.title?.field_value}
                    </div>
                    <div
                      className="secure-payment-card-item-text"
                      dangerouslySetInnerHTML={{
                        __html: el?.field_value?.text?.field_value,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SecurePaymentsModule
