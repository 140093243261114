import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Container, Box, Hidden } from '@material-ui/core';
import capitalize from '@material-ui/core/utils/capitalize';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"

import Button from '@src/components/Button';
import Text from '@src/components/Text';
import FluidImage from '@src/components/FluidImage';
import Stamp from '@src/components/Stamp';
import Module from '@src/components/Module';

const useStyles = makeStyles((theme) => ({
    promoContainer: {
        position: 'relative',        
        width: '84%',
        maxWidth: '84%',
        margin: '0 auto',
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '95%',
        }
    },
    offerMainTitle: {
        marginTop: '60px',
        marginBottom: '30px',
        textAlign: 'center',
    },
    promo: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        height: '37vw',
        minHeight: 350,
        maxHeight: 500,
        [theme.breakpoints.only('xs')]: {
            backgroundSize: 'cover',
            height: '88vh',
        }
    },
    promoVariantWide: {
        [theme.breakpoints.only('xs')]: {
            minHeight: 450,
            maxHeight: 500,
        }
    },
    promoVariantTall: {
        [theme.breakpoints.only('xs')]: {
            minHeight: 550,
            maxHeight: 600,
        }
    },
    promoLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'last baseline',
        width: '55%', 
        padding: '3%', 
        float: 'left', 
        height: '100%',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            padding: '5%', 
        },
    },
    promoLeftInner: {
        alignSelf: 'flex-end',
        width: '100%',
        marginTop: 'auto',
        zIndex: 100,
        
    },
    promoRight: { 
        width: '45%', 
        height: '100%', 
        float: 'right' 
    },
    promoLogo: {        
        width: '70%',
        [theme.breakpoints.only('xs')]: {
            width: '50%',
        }
    },
    promoImagePlacer: {
        position: 'absolute',
        width: '100%',
        left: '-2%',
        width: '70%',
    },
    promoImagePlacerVariantTall: {
        height: '50%',
    },
    promoImagePlacerVariantWide: {
        height: '50%',
    },
    promoImage: {
        [theme.breakpoints.only('xs')]: {
            width: '120%',
            maxWidth: 300,
            transform: 'translate3d(50%, 50%, 0)',
            zIndex: 0,
            position: 'absolute',
            right: 0,
            bottom: 0,
        }
    },
    promoImageWrapper: {
        display: 'table',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '125%',
        height: '125%',
        marginTop: '-5%',
        right: '20%',
        position: 'relative',
        zIndex: 100,
    },
    offerTitle: {
        position: 'flex',
        width: '100%',
        color: 'white',
        paddingBottom: '3%',
        //fontSize: '2.6vw',
        [theme.breakpoints.only('xs')]: {
            marginTop: '3%',
            width: '100%',
            //fontSize: '2.9vh',
        }
    },
    offerDesc: {
        position: 'flex',
        width: '90%',
        color: 'white',
        //fontSize: '1.4vw',
        paddingBottom: '3%',
        //lineHeight: '1.45vw',
        //height: '25%',
        [theme.breakpoints.only('xs')]: {
            //fontSize: '3.3vw',
            //lineHeight: '3.35vw',
            width: '100%',
            paddingTop: '3%',
            marginBottom: 'top',
            zIndex: 100,
            height: 'auto',
        }
    },
    ctaButton: {
        position: 'flex',
        alignSelf: 'flex-end',
        width: '80%',
        //fontSize: '1.2rem',
        fontWeight: '500',
        height: '50px',
        padding: '0',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '40px',
            //fontSize: '1.2rem',
        },
    },
    stamp: {
        position: 'absolute',
        width: 'fit-content',
        padding: '5px !important',
        top: 80,
        right: 40,
        //marginTop: 100,
        zIndex: 101,
        [theme.breakpoints.only('xs')]: {
            width: 'fit-content',
            left: '15px',
            padding: 'initial !important',
            position: 'absolute',
            top: 'unset',
            bottom: '45%',
        }
    },
    stampTitle: {
        width: '80%',
        margin: '0 auto',
        //paddingBottom: 5,
        textTransform: 'uppercase',
        ...theme.fonts.faces.bodyXS,
        ...theme.fonts.weights.bold,
        [theme.breakpoints.down('sm')]: {
            ...theme.fonts.weights.bold,
        },
    },
    stamphigHightedText: {
        ...theme.fonts.faces.titleXXXL,
        ...theme.fonts.weights.bold,
        [theme.breakpoints.down('sm')]: {
            ...theme.fonts.faces.titleXXXL,
            ...theme.fonts.weights.bold,
        },
    }
}));

const HeroStamp = ({ text, highlightedText }) => {
    const classes = useStyles();
    return (
        <Stamp className={classes.stamp}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                <Text className={classes.stampTitle}>{text}</Text>
                <Text className={classes.stamphigHightedText}>{highlightedText}</Text>
            </Box>
        </Stamp>);

};

const PrelaunchOfferModule = ({ site, promo, variant }) => {
    const classes = useStyles()

    const { t, i18n } = useTranslation()

    const stamp = {
        text: t('prelaunch.offer.stamp.text'),
        highlightedText: t('prelaunch.offer.stamp.highlightedText'),
    }

    const variantClass = classes[`promoVariant${capitalize(variant)}`];
    const placerVariantClass = classes[`promoImagePlacerVariant${capitalize(variant)}`];

    return (
        <Module>
            <Container className={classes.promoContainer}>
                <Box className={classes.offerMainTitle}>
                    <Text variant="titleXXL" weight="bold" className={clsx(classes.moduleTitleOverride, "moduleTitle")}>{t('prelaunch.offer.title')}</Text>
                </Box>
                <Hidden smUp>
                    <Box className={clsx(classes.promo, variantClass)} style={{ backgroundImage: `url(/img/prelaunch/${site}/offer-bg-xs.jpg)` }}>
                        <Box className={classes.promoLeft}>
                            <FluidImage className={classes.promoLogo} src={`/img/prelaunch/${site}/offer-logo.png`} />
                            <Text className={classes.offerTitle} variant="specialTitleXL">{t('prelaunch.offer.product')}</Text>
                            <div className={clsx(classes.promoImagePlacer, placerVariantClass)}>
                                <img className={classes.promoImage} src={`/img/prelaunch/${site}/offer-detail-smup.png`} />
                            </div>
                            <HeroStamp {...stamp} />
                            <Box className={classes.promoLeftInner}>
                                <Text className={classes.offerDesc} variant="bodyS">{t('prelaunch.offer.description')}</Text>
                                <a href={t('prelaunch.offer.link')}>
                                    <Button className={classes.ctaButton} next>{t(`prelaunch.offer.cta`)}</Button>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Hidden>
                <Hidden xsDown>
                    <Box className={clsx(classes.promo, variantClass)} style={{ backgroundImage: `url(/img/prelaunch/${site}/offer-bg-smup.jpg)` }}>
                        <Box className={classes.promoLeft}>
                            <FluidImage className={classes.promoLogo} src={`/img/prelaunch/${site}/offer-logo.png`} />
                            <Box className={classes.promoLeftInner}>
                                <Text className={classes.offerTitle} variant="specialTitleXL">{t('prelaunch.offer.product')}</Text>
                                <Text className={classes.offerDesc} variant="bodyS">{t('prelaunch.offer.description')}</Text>
                                <a href={t('prelaunch.offer.link')}>
                                    <Button className={classes.ctaButton} next>{t(`prelaunch.offer.cta`)}</Button>
                                </a>
                            </Box>
                        </Box>
                        <Box className={classes.promoRight}>
                            <div className={clsx(classes.promoImageWrapper, classes[promo])} style={{ backgroundImage: `url(/img/prelaunch/${site}/offer-detail-smup.png)` }} />
                            <HeroStamp {...stamp} />
                        </Box>
                    </Box>
                </Hidden>
            </Container>
        </Module>
    );
};

const defaultProps = {
    variant: 'tall'
};

PrelaunchOfferModule.propTypes = {
    /**
     * the module type
     */
    variant: PropTypes.oneOf(['tall', 'wide']),
};

PrelaunchOfferModule.defaultProps = defaultProps;

export default PrelaunchOfferModule;
