import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Box, IconButton, Dialog, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import shortid from 'shortid';
import Slider from './Slider';

import Text from './Text';
import Icon from './Icon';
import FluidImage from './FluidImage';

const useStyles = makeStyles((theme) => ({
    giftOverlay: {
    },
    appBar: {
        position: 'relative',
        padding: "0 8px 0 8px !important",
        background: 'white',
        borderBottom: `1px solid ${theme.customPalette.greyClear}`,
        [theme.breakpoints.down('sm')]: {
            borderTop: `27px solid ${theme.customPalette.greyClear}`,
        },
    },
    appBarSticky: {
        top: 0,
        left: 'auto',
        right: 0,
        width: '100%',
        zIndex: 1100,
        position: 'sticky',
        padding: "0 8px 0 8px !important",
        background: 'white',
        borderBottom: `1px solid ${theme.customPalette.greyClear}`,
        [theme.breakpoints.down('sm')]: {
            borderTop: `27px solid ${theme.customPalette.greyClear}`,
        },
    },
    body: {
        padding: theme.spacing(0, 4, 4, 4)
    },
    icon: {
        height: 24,
        width: 24
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    image: {
        height: 380,
        width: 400,
        display: 'block',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 340
        },
    },
    name: {

    },
    descriptio: {}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Gift = ({ name, description, image }) => {
    const classes = useStyles();
    return (
        <Grid alignContent="center" spacing={2} container>
            <Grid xs={12} item>
                <FluidImage className={classes.image} {...image} />
            </Grid>
            <Grid xs={12} item>
                <Text className={classes.name} component="div" align="center" variant='titleXXL'>
                    {name}
                </Text>
            </Grid>
            <Grid xs={12} item>
                <Text className={classes.description} align="center" variant='bodyM' weight='medium'>
                    {description}
                </Text>
            </Grid>
        </Grid>
    );
};

const GiftOverlay = ({ className, title, gifts, open, onClose, initialPos, stickyHeader, tracking }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sliderSettings = {
        tracking: tracking,
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: classes.slider,
    };


    return (
        <Dialog
            className={clsx(className, classes.giftOverlay)}
            fullScreen={isMobile}
            fullWidth
            keepMounted
            maxWidth='lg'
            PaperProps={{
                square: true,
            }}
            open={open}
            TransitionComponent={Transition}>
            <AppBar className={!!stickyHeader ? classes.appBarSticky : classes.appBar} elevation={0}>
                <Toolbar disableGutters>
                    <Text variant="title" color="textPrimary" className={classes.title}>
                        {title}
                    </Text>
                    <IconButton edge="start" size="small" color="inherit" onClick={onClose} aria-label="close">
                        <Icon className={classes.icon} name="close" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box className={classes.body}>
                {
                    gifts && gifts.length > 0 && (gifts.length > 1 ?
                        <Slider
                            initialPos={initialPos}
                            {...sliderSettings}
                        >
                            {
                                gifts.map(gift => (
                                    <Gift key={shortid.generate()} {...gift}> </Gift>
                                ))
                            }
                        </Slider>
                        :
                        <Gift {...gifts[0]}></Gift>
                    )
                }
            </Box>
        </Dialog>
    );
}

export default GiftOverlay;