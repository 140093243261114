function findAncestor(target, className) {
    let ref = target.parentElement, limit = 10, loop = 0;
    while (true) {
        loop++;
        if (ref.classList.contains(className)) {
            return ref;
        }
        if (loop >= limit) {
            break;
        }
        ref = ref.parentElement;
    }
    return false;
}

const dom = {
    findAncestor: findAncestor,
}

export default dom;