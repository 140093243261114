import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';

import Text from './Text';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        height: 64,
        padding: "0 8px 0 8px !important",
        background: 'white'
    },
    appBarSticky: {
        top: 0,
        left: 'auto',
        right: 0,
        width: '100%',
        zIndex: 1100,
        position: 'sticky',
        height: 64,
        padding: "0 8px 0 8px !important",
        background: 'white'
    },
    icon: {
        height: 24,
        width: 24
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Overlay = ({ title, open, children, fullScreen, onClose, stickyHeader }) => {
    const classes = useStyles();

    return (
        <Dialog fullScreen={fullScreen} open={open} TransitionComponent={Transition}>
            <AppBar className={!!stickyHeader ? classes.appBarSticky : classes.appBar} elevation={0}>
                <Toolbar disableGutters>
                    <Text variant="title" color="textPrimary" className={classes.title}>
                        {title}
                    </Text>
                    <IconButton edge="start" size="small" color="inherit" onClick={onClose} aria-label="close">
                        <Icon className={classes.icon} name="close" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box>
                {children}
            </Box>
        </Dialog>
    );
}

export default Overlay;