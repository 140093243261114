import React, { useEffect, useContext, useState } from "react"
import clsx from "clsx"
import txt from "@src/utils/txt"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid, Hidden } from "@material-ui/core"
import moment from "moment"

import Module from "../components/Module"
import FluidImage from "../components/FluidImage"
import Text from "../components/Text"
import Stamp from "../components/Stamp"
import Countdown from "../components/Countdown"
import HighlightTextContainer from "../animations/HighlightTextContainer"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { showGuides } from "@src/utils/debug"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"

const useStyles = makeStyles(theme => ({
  earlybirdOffer: {
    // FIX ** Do not manage margnins on single module! This breaks re-positioning
    // Move logics on root Module container and theme instead...
    //marginTop: theme.spacing(1 / 2),
    //marginBottom: theme.spacing(1 / 2)
    //paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(4),
    /*[theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 0,
        }*/
  },
  container: {
    /* FIX **
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        */
  },
  imageWrapper: {
    position: "absolute",
    display: "block",
    top: "-5%",
    left: theme.spacing(4),
    //marginLeft: -theme.spacing(4),
    //maxWidth: 640,
    width: "40%",
    height: "110%",
    border: showGuides("2px solid black"), // FIX ** guides
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "relative",
      width: "100%",
      justifyContent: "center",
      margin: "auto",
      top: "unset",
      left: "unset",
    },
  },
  imageContainer: {
    border: showGuides("3px solid green"), // FIX ** guides
    //display: 'flex',
    position: "relative",
    // top: '-10%', // FIX ** give image the possibility to overflow and keep centered
    //left: '55%',
    //transform: 'translate3d(-50%,0,0)',
    marginLeft: "auto",
    width: "fit-content",
    height: "100%", // FIX ** give image the possibility to overflow and keep centered
  },
  image: {
    border: showGuides("3px solid blue"), // FIX ** guides
    //margin: 'auto',
    height: "100%",
    marginRight: "-10%",
    [theme.breakpoints.down("sm")]: {
      marginRight: "unset",
    },
  },
  stamp: {
    top: "50%",
    right: -15,
    maxWidth: 150,
    padding: 30,
    transform: "translate3d(0, -50%, 0)", // align to middle
    [theme.breakpoints.down("sm")]: {
      padding: 20,
      top: "50%",
      left: "50%",
      right: "unset",
      transform: "translate3d(-50%, -50%, 0)", // center
      marginLeft: -120, // FIX ** translate left with negative margin
      marginTop: 20, // FIX ** translate bottom with negative margin
    },
  },
  contentContainer: {
    border: showGuides("3px solid green"),
    // position: 'absolute', // ? to check --> better work absolute on overlay image than content contanier
    width: "100%",
    height: "fit-content",
    //top: 0,
    //bottom: 0,
    //right: 0,
    //left: 0,
    //margin: 'auto',
    //zIndex: -1,
    //padding: theme.spacing(3), // ? to check
    [theme.breakpoints.down("sm")]: {
      //padding: theme.spacing(4, 0, 0, 0),
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 0,
    },
  },
  gridContainer: {
    padding: theme.spacing(4),
    background: theme.customPalette.greyClear,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: theme.spacing(4, 4, 4, 4),
    },
  },
  highlightedContainer: {
    //marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  title: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  subtitle: {
    //fontSize: 24,
    marginTop: theme.spacing(2),
    //marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {
    lineHeight: 1.5,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingRight: "unset",
    },
  },
  countdown: {
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      margin: "auto",
    },
  },
  hidden: {
    display: "none",
  },
  parallaxWrapper: {
    position: "absolute",
    right: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
    /*bottom: 0,
    left: theme.spacing(8),
    right: '-25vw',
    margin: 'auto',
    height: '100%',
    zIndex: -1,*/
  },
  parallaxItem: {
    height: "100%",
    maxWidth: 500,
  },
  parallax: {
    //height: `${parallaxHeight}px !important`,
    zIndex: -1,
  },
}))

const Image = ({ image, stampText }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden smDown>
        <div className={classes.imageWrapper}>
          <div className={classes.imageContainer}>
            <Box className={classes.parallaxWrapper}>
              <Parallax
                className={classes.parallax}
                /*blur={0}*/
                amount={0.35}
              ></Parallax>
            </Box>
            <ParallaxItem className={classes.parallaxItem} y={["0", "-6"]}>
              <FluidImage className={classes.image} {...image} />
            </ParallaxItem>
            {stampText && (
              <Stamp className={classes.stamp}>
                {txt.lastWord(stampText, 1.5)}
              </Stamp>
            )}
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.imageWrapper}>
          <FluidImage className={classes.image} {...image} />
          {stampText && (
            <Stamp className={classes.stamp}>
              {txt.lastWord(stampText, 1.5)}
            </Stamp>
          )}
        </div>
      </Hidden>
    </>
  )
}

const EarlybirdOfferModule = ({
  title,
  subtitle,
  description,
  stampText,
  image,
  expirationDate,
  expireText,
  lastVisibilityDate,
}) => {
  const classes = useStyles()
  const dispatch = useContext(GlobalDispatchContext)
  const parsedExpirationDate = expirationDate && moment(expirationDate).toDate()
  const parsedLastVisibilityDate =
    lastVisibilityDate && moment(lastVisibilityDate).toDate()

  const calculateTimeLeft = endDate => {
    if (!endDate) {
      return 0
    } else {
      return Date.parse(endDate) - Date.parse(new Date())
    }
  }

  const [visible, setVisible] = useState(
    calculateTimeLeft(parsedLastVisibilityDate) >= 0
  )

  useEffect(() => {
    // TODO: uniform names
    if (calculateTimeLeft(parsedExpirationDate) > 0) {
      dispatch({
        type: "showEarlybirdOffer",
        payload: { expirationDate, expirationText: expireText, show: true },
      })
    }
  }, [expirationDate, expireText])

  useEffect(() => {
    const tid = setInterval(() => {
      const hideOffer = calculateTimeLeft(parsedExpirationDate) < 0
      const hideModule = calculateTimeLeft(parsedLastVisibilityDate) < 0
      if (hideOffer) {
        dispatch({ type: "showEarlybirdOffer", payload: { show: false } })
      }
      if (hideModule) {
        setVisible(false)
      }
      if (hideModule && hideOffer) {
        clearInterval(tid)
      }
    }, 1000)
    return () => {
      clearInterval(tid)
    }
  }, [])

  return (
    <Module
      className={clsx(classes.earlybirdOffer, { [classes.hidden]: !visible })}
      classes={{ container: classes.container }}
      imageOverflowModule
    >
      {/* Moved to grid
            <Hidden smDown>
                <Image stampText={stampText} image={image}></Image>
            </Hidden>
            */}
      <Box className={classes.contentContainer}>
        <Grid className={classes.gridContainer} alignItems="center" container>
          <Hidden smDown>
            <Grid xs={6} item>
              <Image stampText={stampText} image={image}></Image>
            </Grid>
          </Hidden>
          <Grid xs={12} md={6} item>
            <Grid container>
              <Grid xs={12} item>
                <HighlightTextContainer
                  className={classes.highlightedContainer}
                >
                  <Text
                    className={classes.title}
                    variant="titleXXXL"
                    component="p"
                    overline
                    weight="bold"
                  >
                    {title}
                  </Text>
                </HighlightTextContainer>
              </Grid>
              <Hidden mdUp>
                <Grid xs={12} item>
                  <Image stampText={stampText} image={image}></Image>
                </Grid>
              </Hidden>
              <Grid xs={12} item>
                <Text
                  className={classes.subtitle}
                  component="h3"
                  variant="titleXL"
                >
                  {subtitle}
                </Text>
              </Grid>
              <Grid xs={12} item>
                <Text
                  className={classes.description}
                  component="p"
                  variant="bodyM"
                >
                  {description}
                </Text>
              </Grid>
              <Grid xs={12} item>
                {expirationDate && (
                  <Countdown
                    className={classes.countdown}
                    date={expirationDate}
                  ></Countdown>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Module>
  )
}

export default EarlybirdOfferModule
