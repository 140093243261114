import React from "react"
import Module from "../components/Module"
import Box from "@material-ui/core/Box"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TrustBox from "../components/TrustBox"
import VisibilitySensor from "../animations/VisibilitySensor"

const useStyles = makeStyles(theme => ({
  TrustPilotSlider: {},
}))

const TrustpilotSlider = ({ data_template_id, repo }) => {
  const classes = useStyles()

  return (
    <VisibilitySensor>
      <Module className={classes.TrustPilotSlider}>
        <Container>
          <Box textAlign="center">
            <TrustBox
              type="slider"
              data_template_id={data_template_id}
              country={repo?.seo?.country}
            ></TrustBox>
          </Box>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default TrustpilotSlider
