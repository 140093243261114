import React, { useRef, useEffect, useState } from "react"
import clsx from "clsx"
import Portal from "@material-ui/core/Portal"
import withStyles from "@material-ui/core/styles/withStyles"
import SlickSlider from "react-slick"
import Icon from "./Icon"
import { makeStyles, useTheme } from "@material-ui/core"

// import { GTMEvent } from "../utils/gtm"

import { Container, Grid, useMediaQuery } from "@material-ui/core"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const styles = theme => ({
  root: {
    position: "relative",
    height: "100%",
    "& .slick-disabled": {
      border: `4px solid transparent`,
      opacity: 0.25,
      [theme.breakpoints.up("sm")]: {
        opacity: 1,
      },
    },
    "& .slick-list": { height: "100%" },
    "& .slick-track": {
      height: "100%",
    },
  },
  slider: { height: "100%" },
  slider2: {
    "& .slick-track": { padding: "0 20% 0 0" },
  },
  equalHeight: {
    "& .slick-track": { display: "flex !important" },
    "& .slick-slide": { height: "inherit !important" },
    "& .slick-slide > div": { height: "100%" },
  },
  arrows: {
    display: "flex",
    justifyContent: "none",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  icon: {
    display: "block",
    height: 32,
    width: 32,
    "@media (max-width: 360px)": {
      width: 24,
      height: 24,
    },
    [theme.breakpoints.up("md")]: {
      width: 40,
      height: 40,
    },
  },
  arrow: {
    cursor: "pointer",
  },
  back: {
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    boxSizing: "border-box",
  },
  next: {
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    boxSizing: "border-box",
  },
})

const NextArrow = ({
  className,
  classes,
  dark,
  onClick,
  container,
  arrowBorder,
}) => {
  const filteredClasses = className.replace("slick-arrow slick-next", "")
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.next)}
      onClick={onClick}
      style={{ borderWidth: arrowBorder }}
    >
      <Icon className={classes.icon} name={dark ? "right" : "right_white"} />
    </div>
  )

  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  )
}

const PrevArrow = ({
  className,
  classes,
  dark,
  onClick,
  container,
  arrowBorder,
}) => {
  const filteredClasses = className.replace("slick-arrow slick-prev", "")
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.back)}
      onClick={onClick}
      style={{ borderWidth: arrowBorder }}
    >
      <Icon className={classes.icon} name={dark ? "left" : "left_white"} />
    </div>
  )
  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  )
}

const Slider = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    classes,
    showExtraSlides,
    prevIcon = "left",
    nextIcon = "right",
    disableEqualHeight = false,
    dark = false,
    initialPos,
    arrowBorder,
    tracking,
    ...settings
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const slider = useRef(null)
  const arrowContainer = useRef(null)
  const [sliderSettings, setSliderSettings] = useState(settings)

  //console.log(" USE MEDIA QUERY", isMobile)

  // configure slider
  useEffect(() => {
    setSliderSettings({
      ...settings,
      // beforeChange: (current, next) => {
      //   if (tracking) {
      //     if (current < next) {
      //       GTMEvent(tracking, "slider", "swiper-slider")
      //     } else if (current > next) {
      //       GTMEvent(tracking, "slider", "swiper-slider")
      //     }
      //   }
      // },
      nextArrow: (
        <NextArrow
          classes={classes}
          container={arrowContainer}
          dark={isMobile ? true : false}
          arrowBorder={arrowBorder}
        />
      ),
      prevArrow: (
        <PrevArrow
          classes={classes}
          container={arrowContainer}
          dark={isMobile ? true : false}
          arrowBorder={arrowBorder}
        />
      ),
    })
  }, [isMobile])

  // set initial position
  useEffect(() => {
    const firstSlide = initialPos || 0
    if (slider && slider.current) {
      slider.current.slickGoTo(firstSlide, true)
    }
  }, [initialPos, slider.current])

  return (
    <div className={classes.root} ref={ref}>
      <SlickSlider
        ref={slider}
        className={clsx(
          showExtraSlides ? classes.slider2 : classes.slider,
          className,
          { [classes.equalHeight]: !disableEqualHeight }
        )}
        {...sliderSettings}
      >
        {children}
      </SlickSlider>
      {/* <Hidden smDown> */}
      <Container>
        <Grid container>
          <Grid xs={12} md={12} style={{ position: "relative" }}>
            <div
              container
              ref={arrowContainer}
              className={classes.arrows}
            ></div>
          </Grid>
        </Grid>
      </Container>
      {/* </Hidden> */}
    </div>
  )
})

export default withStyles(styles, { name: "FHSlider" })(Slider)
