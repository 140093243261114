import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid } from "@material-ui/core"

import Module from "../components/Module"
import Text from "../components/Text"
import LinkButton from "../components/LinkButton"
import Icon from "../components/Icon"
import Link from "../components/Link"
import { GTMEvent } from "../utils/gtm"

const iconWidth = 56
const iconSpacing = 4

const useStyles = makeStyles(theme => ({
  issueLink: {},
  container: {
    padding: theme.spacing(4, 5),
    backgroundColor: theme.customPalette.greyClear,
  },
  text: {},
  textWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "start",
    },
  },
  linkWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      marginLeft: iconWidth + theme.spacing(iconSpacing),
    },
  },
  link: {},
  icon: {
    height: iconWidth,
    width: iconWidth,
    marginRight: theme.spacing(iconSpacing),
    flexShrink: 0,
  },
}))

const IssueLinkModule = ({ text, link, repo }) => {
  const classes = useStyles()
  return (
    <Module className={classes.issueLink}>
      <Grid
        className={classes.container}
        alignItems="center"
        spacing={2}
        container
      >
        <Grid xs={12} sm={8} item>
          <Box className={classes.textWrapper}>
            <Icon className={classes.icon} name="download_magazine" />
            <Text
              className={classes.text}
              component="h2"
              variant="titleXL"
              weight="bold"
            >
              {text}
            </Text>
          </Box>
        </Grid>
        <Grid xs={12} sm={4} item>
          <Box className={classes.linkWrapper}>
            {link && (
              <Link
                {...link}
                className={classes.link}
                variant="bodyS"
                iconName="down"
                repo={repo}
              >
                <LinkButton
                  className={classes.link}
                  variant="bodyS"
                  iconName="down"
                  onClick={() =>
                    GTMEvent("product", "issuelink", "click_DOWNLOAD")
                  }
                  {...link}
                />
              </Link>
            )}
          </Box>
        </Grid>
      </Grid>
    </Module>
  )
}

export default IssueLinkModule
