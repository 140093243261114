import React from 'react';
import txt from '@src/utils/txt';

import Box from '@material-ui/core/Box';
import { Grid, Hidden, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import Text from '../components/Text';
import FluidImage from '../components/FluidImage';
import CtaButton from '../components/CtaButton';
import Module from '../components/Module';
import Stamp from '../components/Stamp';
import Icon from '../components/Icon';

const useStyles = makeStyles((theme) => ({
  package: {
  },
  moduleTitle: {
    marginBottom: 130,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  packageItemContainer: {
    position: 'relative',
    background: theme.customPalette.greyClear,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingTop: 90,
      height: '100%',
    },
    marginTop: 130,
  },
  packageItem: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: 'center',
    justifyContent:"space-between",
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      position: 'relative',
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%'
    },
  },
  textContent: {
    maxWidth: 500,
    textAlign: 'left',
    position: 'relative',
    paddingTop: 30,
    [theme.breakpoints.down('md')]: {
      maxWidth: "unset",
      paddingTop: 24,
      width: "100%"
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: "center"
    },
  },
  subtitle: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2)
    },
  },
  figureContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -130,
  },
  figure: {
    width: '100%',
    height: 360,
    [theme.breakpoints.down('md')]: {
      //height: 152,
      //marginTop: -40
      height: "unset",
      position: "inherit"
    }
  },
  chipText: {
    fontSize: 16,
    fontWeight: 500
  },
  stamp: {
    width: 136,
    height: 136,
    //fontSize: 18,
    top: 0,
    ...theme.fonts.faces.bodyS,
    ...theme.fonts.weights.bold,
    lineHeight: '1rem !important',
    [theme.breakpoints.down('md')]: {
      width: 84,
      height: 84,
      left: 0,
      top: 0,
      lineHeight: '1rem !important',
    },
  },
  stampWrapper: {
    position: 'absolute',
    width: 136,
    height: '45%',
    bottom: "30px",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: 136,
      height: 136,
      left: 12,
      bottom: 105,
      marginBottom: "40px"
    }
  },
  chip: {
    display: "flex",
    alignItems: "center",
    minHeight: "30px",
    padding: "4px 16px",
    [theme.breakpoints.down('md')]: {
      padding: "8px",
      minHeight: "34px",
      marginBottom: 20
    }
  },
  bigStampTitle: {
    fontSize: 36,
    lineHeight: "36px",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      fontSize: 29,
      lineHeight: "29px",
    }
  },
  smallerStampTitle: {
    fontSize: 19,
    marginTop: 8,
    lineHeight: "19px",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: "14px",
    }
  },
  figureContainer: {
    //marginTop: -110,
    position: "absolute",
    top: "-40px",
    [theme.breakpoints.down('md')]: {
      position: "relative",
      top: "unset",
      backgroundColor: theme.customPalette.greyClear,
      display: 'flex',
      marginTop: 0,
      justifyContent: 'center'
    },
  },
  ctaButtonWrapper: {
    display: 'flex',
    justifyContent: 'initial',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(1),
    //marginTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginBottom: theme.spacing(0),
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  },
  ctaButton: {
    height: 56,
    width: 300,
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  imageContainer: {
    display: "flex"
  }, 
  banner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(7),
    padding: theme.spacing(3, 0),
    background: `${theme.customPalette.greyClear}`,
  },
  bannerIcon: {
    height: 57,
    paddingRight: theme.spacing(6),
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: "16px 10px"
    }
  },
  purpleBanner: {
    backgroundColor: theme.customPalette.darkishPurple,
    color: "#fff",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  padding24: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2)
    }
  },
  noMarginBottom: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: "0px!important"
    }
  },
  font24mobile: {
    [theme.breakpoints.down('md')]: {
      fontSize: "24px!important"
    }
  },
  noPaddingMobile: {
    [theme.breakpoints.down('md')]: {
      padding: "0px!important"
    }
  }
}));

const OffersV2Module = ({ image, imageMobile, badge, chip, title, subtitle, text, cta, shippingText, shippingTextMobile, ...others }) => {
  /*if(true){
    image={"sources":[
      {"type":"image\/png",
      "srcset":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.png"},
      {"type":"image\/webp","srcset":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.webp"}],
      "alt":null,"title":null,
      "src":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.png"};
      imageMobile={"sources":[{"type":"image\/png","srcset":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.png"},
      {"type":"image\/webp","srcset":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.webp"}],
      "alt":null,"title":null,"src":"https:\/\/s3.eu-west-1.amazonaws.com\/deatech.snake.assets\/fh\/test\/media\/group-7-copy-at-3x\/group-7-copy-at-3x_optmzd_80.png"};
      badge="<p>\u00a3 0.99<br \/>\r\n1st assembly stage<\/p>";
      chip="<p>Cancel anytime<\/p>";
      title="Risk-free trial offer";
      subtitle="Your 1st package";
      text="<p>In your 1st package you will receive two assembly stages (parts + magazines) to start building your incredible model.<\/p>";
      cta={"text":"Start building!","highlightedText":null,"productCode":"9XF203000000"};
      shippingText="<p>Free post and packaging on first delivery<\/p>";
      shippingTextMobile="<p>FREE SHIPPING<\/p>";
  }*/
  const classes = useStyles();

  const stampContent = (badge) => {
    let text = "";
    let strings = [];
    const firstSplit = badge ? txt.sanifyHtml(badge).split("<br>")[0].split("<br/>")[0].split("<br />") : [];
    const regex = /[(\d\.\,)]+/g;
    if (firstSplit.length > 1){
      const spaced = firstSplit ? firstSplit[0].split(" ") : [];
      strings = (spaced.length !== 2) ? firstSplit[0].split(/([\d\.\,]+)/g) : spaced;
      text = firstSplit[1];
    } else {
      text = badge;
    }
    return (<>
      <div className={classes.bigStampTitle}>
        {(strings || []).filter(el=>!!el).map((el, i) => <div key={`strings_${i}`}>          
          <div className={!regex.test(el) ? classes.smallerStampTitle : ""}>
            <Text>{txt.sanifyHtml(el).replace("&amp;", "&")}</Text>
          </div>
        </div>)}
      </div>
      <Hidden mdDown>
        <Text variant="bodyM" weight="bold">{txt.sanifyHtml(txt.brakeBeforeParentheses(text))}</Text>
      </Hidden>
      <Hidden lgUp>
        <Text variant="bodyS" weight="bold">{txt.sanifyHtml(txt.brakeBeforeParentheses(text))}</Text>
      </Hidden>
      </>
    );
  }

  return (
    <Module className={classes.package}>
      <Box textAlign="center">
        {/*<Text className={classes.moduleTitle} variant='titleXXXL' align='center' overline weight='bold'>{title}</Text>*/}
            <Box style={{ position: 'relative' }}>
              <Hidden lgUp>
                <div className={classes.purpleBanner}>
                  <Text variant="bodyM" weight="bold">{shippingTextMobile}</Text>
                </div>
              </Hidden>
              <Box className={clsx(classes.container, classes.packageItemContainer)}>
                <div className={classes.packageItem}>
                  <Hidden lgUp>
                    <Box className={classes.padding24}>
                      <Text className={classes.title} variant='titleXXXL' component="div" weight='bold'>{title}</Text>
                    </Box>
                      {(chip) && (
                      <Box py={1} pb={3} className={classes.noMarginBottom} display="flex" alignItems="center">
                        <Chip
                          className={classes.chip}
                          label={<Text component="div" variant="bodyS" className={classes.chipText}>
                            {chip}
                          </Text>}
                        />
                      </Box>
                    )}
                  </Hidden>
                  <div className={classes.imageContainer}>
                    <div className={classes.stampWrapper}>
                      <Stamp className={classes.stamp}>
                        {stampContent(badge)}
                      </Stamp>
                    </div>
                    <Box className={classes.figureContainer} position="relative">
                  <Box px={3} className={classes.noPaddingMobile}>
                        <Hidden mdDown>
                          <FluidImage {...image} className={classes.figure} />
                        </Hidden>
                        <Hidden lgUp>
                          <FluidImage {...imageMobile} className={classes.figure} />
                        </Hidden>
                      </Box>
                    </Box>
                  </div>
                  <div className={classes.textContent}>
                    <Hidden mdDown>
                    <Box>
                      <Text className={classes.title} variant='titleXXXL' component="div" weight='bold'>{title}</Text>
                    </Box>
                    {(chip) && (
                      <Box py={1} display="flex" alignItems="center">
                        <Chip 
                          className={classes.chip}
                          label={<Text component="div" variant="bodyS" className={classes.chipText}>
                            {chip}
                          </Text>} 
                        />
                      </Box>
                    )}
                    </Hidden>
                    <Box py={1}>
                      <Text className={clsx(classes.font24mobile, classes.title)} variant='titleXL' component="div" weight='medium'>{subtitle}</Text>
                    </Box>
                    <Box>
                      <Text className={classes.title} variant='bodyM'>{text}</Text>
                    </Box>
                    {cta && <Box pt={3} className={classes.ctaButtonWrapper}>
                      <CtaButton
                        className={classes.ctaButton}
                        next
                        size='large'
                        {...cta}
                        trackingObj={others.repo}
                        trackingAction="click_link"
                        trackingLabel={"OffersV2Module - "+(cta?.text || "")}
                      >
                        {cta.text}
                      </CtaButton>
                    </Box>
                    }
                  </div>
                </div>
              </Box>
            </Box>
          <Hidden mdDown>
          <div className={classes.banner}>
            <Icon className={classes.bannerIcon} name="fastShipping"></Icon>
            <Text variant="titleXXL" component="div" weight="bold">{shippingText}</Text>
          </div>
        </Hidden>
      </Box>
    </Module>
  );
};

export default OffersV2Module;
