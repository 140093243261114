import React, { useRef, useState } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Module from "../components/Module"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core"
import { showGuides } from "@src/utils/debug"
import Text from "../components/Text"
import Icon from "../components/Icon"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import FluidImage from "../components/FluidImage"
import { GTMEvent } from "../utils/gtm"

const parallaxHeight = 450

const useStyles = makeStyles(theme => ({
  module360: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
      marginBottom: "initial",
    },
  },
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  content: {
    border: showGuides("4px solid cyan"), // FIX ** guides
    position: "relative",
    display: "flex",
    width: "100%",
    padding: theme.spacing(0, 0, 0, 5), // FIX ** to check later
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      padding: theme.spacing(0, 2, 0, 2),
      textAlign: "center",
      flexDirection: "column",
      minHeight: "auto !important",
      background: "none",
    },
  },
  textContainer: {
    width: "fit-content",
    maxWidth: "40%",
    height: "100%",
    padding: theme.spacing(4, 0, 4, 0),
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: "auto !important",
      padding: theme.spacing(4, 0, 0, 0),
    },
  },
  textContainerImg: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(4, 0, 4, 0),
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: "auto !important",
      padding: theme.spacing(4, 0, 0, 0),
    },
  },
  title: {
    color: "#5D338B",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  titleCenter: {
    color: "#5D338B",
    textAlign: "center",
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  info: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  infoItem: {
    padding: theme.spacing(0.5, 3, 0.5, 0),
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  imgWrapper: {
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
    textAlign: "right", // FIX ** push right for safari
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
    },
  },
  w_100: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
    },
  },
  parallaxWrapper: {
    position: "absolute",
    right: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
  },
  parallax: {
    zIndex: -1,
  },
  imgInner: {
    maxHeight: 500,
    minHeight: 400, // FIX ** we need an anchor point somewhere
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
  },
  img: {
    height: 420,
    width: 700,
    display: "block",
    marginBottom: "80px",
    marginRight: "50px",
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 200,
    },
  },
  module360ChipSelector: {
    padding: "24px 80px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  module360ChipNotSelected: {
    color: "#5D338B",
  },
  module360ChipSelected: {
    color: "white",
    background: "#5D338B",
  },

  module360Chip: {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "600",
    padding: "8px 16px",
    margin: "12px 12px 0px 0px",
    borderRadius: "24px",
    border: "2px solid #5D338B",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "5D338B",
  },
  selectorClass: {
    border: "1.5px solid #5D338B",
    borderRadius: "25px",
    padding: "8px 16px",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: "#5D338B",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    minHeight: "45px",
    position: "relative",
    zIndex: "2",
    display: "flex",
  },
  selectorTriangle: {
    width: "0px",
    height: "0px",
    borderStyle: "solid",
    borderWidth: "11px 7px 0px",
    borderColor: "rgb(93,51,139) transparent transparent",
  },
  selectorWindowContainer: {
    position: "relative",
    top: "10px",
    border: "1.5px solid #5D338B",
    borderRadius: "6px",
    width: "100%",
    zIndex: "100",
    color: "#5D338B",
    fontFamily: "Poppins",
    cursor: "pointer",
    maxHeight: "140px",
    overflowY: "auto",
  },
  selectorItemContainer: {
    fontSize: "16px",
    padding: "0px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
  },
  module360Selector: {
    margin: "24px",
    flexBasis: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  h_220: {
    [theme.breakpoints.down("md")]: {
      height: "220px",
    },
  },
  h_700: {
    minHeight: "450px",
  },
}))

const Module360Module = props => {
  const {
    title,
    primary,
    secondary,
    repo,
    background_color,
    text_color_for_points,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const [specsMinHeight, setSpecsMinHeight] = useState("auto")
  const ref = useRef(null)
  const handleMinHeight = () => {
    if (ref?.current) {
      const { height } = ref?.current?.getBoundingClientRect()
      setSpecsMinHeight(`${height}px`)
    }
  }
  const [selectedChip, setSelectedChip] = useState(
    secondary.field_value[0].field_value?.button_text?.field_value
  )
  const [currentImage, setCurrentImage] = useState(
    secondary.field_value[0].field_value.image.field_value
  )
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const handleChangeImage = img => {
    setCurrentImage(img)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  return primary ? (
    <Module
      className={
        primary ? clsx(classes.module360, classes.h_700) : classes.module360
      }
      classes={{ container: classes.container }}
    >
      {/*Start Box*/}

      <Box
        className={classes.content}
        style={{
          minHeight: "100%",
          justifyContent: "space-between",
          backgroundColor: background_color.field_value,
        }}
      >
        <>
          <div className={classes.textContainer} ref={ref}>
            <Text
              className={classes.title}
              variant="titleXXXL"
              color="textTertiary"
              align="left"
              weight="bold"
              overline
              gutterBottom
            >
              {title.field_value}
            </Text>
            <List className={classes?.info}>
              {(primary && (primary.field_value || [])).map((el, i) => (
                <ListItem key={i} className={classes.infoItem}>
                  <ListItemIcon>
                    <Icon
                      className={classes.bulletIcon}
                      name="bullet"
                      onLoad={handleMinHeight}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.infoText}
                    style={{ color: text_color_for_points?.field_value }}
                    primary={el.field_value.point.field_value}
                  />
                </ListItem>
              ))}
            </List>
          </div>

          <div className={classes.module360Selector}>
            <div
              className={classes.selectorClass}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>{selectedChip}</span>
              <span
                className={classes.selectorTriangle}
                style={isOpen ? { transform: "rotate(180deg)" } : {}}
              />
            </div>
            {isOpen && (
              <div className={classes.selectorWindowContainer}>
                {secondary.field_value.map((el, i) => (
                  <div
                    key={i}
                    className={classes.selectorItemContainer}
                    onClick={() => {
                      GTMEvent(
                        "360_module",
                        "click_button",
                        `${
                          repo.product_info.product_universal_title +
                          "_" +
                          el.field_value?.button_text?.field_value
                        }`
                      )
                      setSelectedChip(el?.field_value?.button_text?.field_value)
                      handleChangeImage(el?.field_value?.image?.field_value)
                      setIsOpen(false)
                    }}
                  >
                    {el?.field_value?.button_text?.field_value}
                  </div>
                ))}
              </div>
            )}
          </div>

          <Box className={clsx(classes.sizeImgContainer)}>
            <Box className={clsx(classes.imgContainer)}>
              <Box className={classes.imgWrapper}>
                <Box className={classes.parallaxWrapper}>
                  <Parallax className={classes.parallax} amount={0.35}>
                    <div
                      style={{ height: parallaxHeight, width: "100%" }}
                    ></div>
                  </Parallax>
                </Box>
                <ParallaxItem disabled={isMobile} y={["8", "-2"]}>
                  <FluidImage
                    className={clsx(classes.img)}
                    innerClassName={classes.imgInner}
                    {...currentImage}
                  ></FluidImage>
                </ParallaxItem>
              </Box>
              <div className={classes.module360ChipSelector}>
                {secondary.field_value.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className={clsx(
                        classes.module360Chip,
                        selectedChip ===
                          el.field_value?.button_text?.field_value
                          ? classes.module360ChipSelected
                          : classes.module360ChipNotSelected
                      )}
                      onClick={() => {
                        if (
                          selectedChip ===
                          el.field_value?.button_text?.field_value
                        ) {
                          setSelectedChip(null)
                          GTMEvent(
                            "360_module",
                            "click_button",
                            `${
                              repo.product_info.product_universal_title +
                              "_" +
                              el.field_value?.button_text?.field_value
                            }`
                          )
                        } else {
                          GTMEvent(
                            "360_module",
                            "click_button",
                            `${
                              repo.product_info.product_universal_title +
                              "_" +
                              el.field_value?.button_text?.field_value
                            }`
                          )
                          setSelectedChip(
                            el.field_value?.button_text?.field_value
                          )
                          handleChangeImage(el.field_value?.image?.field_value)
                        }
                      }}
                    >
                      {el.field_value?.button_text?.field_value}
                    </div>
                  )
                })}
              </div>
            </Box>
          </Box>
        </>
      </Box>

      {/*End Box */}
    </Module>
  ) : (
    <>
      <Module className={classes.module360}>
        <Box
          className={classes.content}
          style={{
            minHeight: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.textContainerImg} ref={ref}>
            <Text
              className={classes.titleCenter}
              variant="titleXXXL"
              color="textTertiary"
              align="left"
              weight="bold"
              overline
              gutterBottom
            >
              {title.field_value}
            </Text>
            <div className={classes.module360Selector}>
              <div
                className={classes.selectorClass}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span>{selectedChip}</span>
                <span
                  className={classes.selectorTriangle}
                  style={isOpen ? { transform: "rotate(180deg)" } : {}}
                />
              </div>
              {isOpen && (
                <div className={classes.selectorWindowContainer}>
                  {secondary.field_value.map((el, i) => (
                    <div
                      key={i}
                      className={classes.selectorItemContainer}
                      onClick={() => {
                        GTMEvent(
                          "360_module",
                          "click_button",
                          `${
                            repo.product_info.product_universal_title +
                            "_" +
                            el.field_value?.button_text?.field_value
                          }`
                        )
                        setSelectedChip(
                          el?.field_value?.button_text?.field_value
                        )
                        handleChangeImage(el?.field_value?.image?.field_value)
                        setIsOpen(false)
                      }}
                    >
                      {el?.field_value?.button_text?.field_value}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Box className={clsx(classes.h_220)}>
              <Box className={clsx(classes.imgContainer)}>
                <Box className={classes.w_100}>
                  <Box className={classes.parallaxWrapper}>
                    <Parallax
                      className={classes.parallax}
                      /*blur={0}*/
                      amount={0.35}
                    >
                      <div
                        style={{ height: parallaxHeight, width: "100%" }}
                      ></div>
                    </Parallax>
                  </Box>
                  <ParallaxItem disabled={isMobile} y={["8", "-2"]}>
                    <FluidImage
                      className={clsx(classes.img)}
                      innerClassName={classes.imgInner}
                      {...currentImage}
                    ></FluidImage>
                  </ParallaxItem>
                </Box>
                <div className={classes.module360ChipSelector}>
                  {secondary.field_value.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className={clsx(
                          classes.module360Chip,
                          selectedChip ===
                            el.field_value?.button_text?.field_value
                            ? classes.module360ChipSelected
                            : classes.module360ChipNotSelected
                        )}
                        onClick={() => {
                          if (
                            selectedChip ===
                            el.field_value?.button_text?.field_value
                          ) {
                            setSelectedChip(null)
                            GTMEvent(
                              "360_module",
                              "click_button",
                              `${
                                repo.product_info.product_universal_title +
                                "_" +
                                el.field_value?.button_text?.field_value
                              }`
                            )
                          } else {
                            GTMEvent(
                              "360_module",
                              "click_button",
                              `${
                                repo.product_info.product_universal_title +
                                "_" +
                                el.field_value?.button_text?.field_value
                              }`
                            )
                            setSelectedChip(
                              el.field_value?.button_text?.field_value
                            )
                            handleChangeImage(
                              el.field_value?.image?.field_value
                            )
                          }
                        }}
                      >
                        {el.field_value?.button_text?.field_value}
                      </div>
                    )
                  })}
                </div>
              </Box>
            </Box>
          </div>
        </Box>
      </Module>
    </>
  )
}

export default Module360Module
