import React from 'react';
import DevelopLazy from './DevelopLazy';

class DevelopLazyError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            message: 'Error message'
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            message: error.message
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <DevelopLazy message={this.state.message}/>
            )
        }
        return this.props.children;
    }
}

export default DevelopLazyError;