import React from "react"
import PropTypes from "prop-types"
import Icon from "../components/Icon"
import Text from '../components/Text';
import Module from '../components/Module';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box"
import { Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  WarrantyList: {
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(5),
    },    
  },
  container: {
    width: '100%',
    height: '100%',
  },
  grid: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  },  
  icon: {
    width:16,
    height:16,
    marginRight: 10,
    verticalAlign: 'sub'
  },
  card: {
    padding:'5px 10px',
    [theme.breakpoints.down('lg')]: {
      padding:'5px 16px',
    }    
  },
  text: {
    color:'#000'
  }  
}));

const WarrantyListModule = props => {

  const { title, primary } = props;
  const classes = useStyles();

  return (
    <Module className={classes.WarrantyList}>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Text
              className={classes.title}
              align="center"
              component="h3"
              variant="titleXXXL"
              weight="bold"
              overline
            >
              {title?.field_value || ""}
            </Text>      
            <Grid container>
              {(primary?.field_value || []).map((element, index) => (
                <Grid xs={12} md={6} item container>
                  <Grid className={classes.card} direction="row" alignItems="center" wrap="nowrap" container>
                    <Grid item><Icon name="checked_tangerine" className={classes.icon} /></Grid>
                    <Grid item><Text className={classes.text} variant="bodyM" align='left'>{ element?.field_value?.text?.field_value }</Text></Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
    </Module>
  );

};

WarrantyListModule.propTypes = {
  title: PropTypes.string,
  primary: PropTypes.array,
}

export default WarrantyListModule;