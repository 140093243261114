import React, { useState } from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
//import { InView } from 'react-intersection-observer';
import VisibilitySensor from '@src/animations/VisibilitySensor';
import { useSpring, animated } from "react-spring";
import * as easings from 'd3-ease';

const useStyles = makeStyles((theme) => ({
    highlightText: {
        width: 'fit-content',
    },
    textWrapper: {
        width: 'fit-content',
        zIndex: 99,
    },
    highlighter: {
        zIndex: 1,
        marginTop: -10,
        backgroundColor: theme.customPalette.palePurple
    }
}));

const HighlightText = ({ className, inView, duration, height, children }) => {

    const classes = useStyles();
    const props = useSpring({
        config: { duration: duration, easing: easings.easeExpOut },
        width: inView ? '100%' : '0%',
        height: height,
        from: { width: '0%' }
    });
    return (
        <div className={clsx(classes.highlightText, className)}>
            <div className={classes.textWrapper}>
                {children}
            </div>
            <animated.div className={classes.highlighter} style={props}></animated.div>
        </div>
    );
};

const HighlightTextContainer = (props) => {
    const classes = useStyles();
    const [inView, setInView] = useState();

    const handleInViewChange = val => {
        setInView(val)
    };

    const {
        duration = 1500,
        className,
        height = 15,
        children
    } = props;

    return (
        <VisibilitySensor partialVisibility active={true} onChange={handleInViewChange}>
            <HighlightText inView={inView} className={clsx(classes.highlightText, className)} height={height} duration={duration}>{children}</HighlightText>
        </VisibilitySensor>
    );
};


export default HighlightTextContainer;