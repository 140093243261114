import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid, Chip, Hidden, useMediaQuery } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Module from "@src/components/Module"

import Text from "@src/components/Text"
import Icon from "@src/components/Icon"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles(theme => ({
  mainTitle: {
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(3),
  },
  category: {
    minHeight: "80px",
    backgroundColor: theme.customPalette.greyClear,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    textDecoration: "none",
  },
  categoryIcon: {
    width: "80px",
    height: "100%",
    backgroundColor: theme.customPalette.tangerine,
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    color: theme.customPalette.primaryGrey05,
  },
  link: {
    color: theme.customPalette.black,
  },
}))

const InlineMenuFormModule = props => {
  const { inlineMenuItemForm, title } = props
  const classes = useStyles()

  return (
    <Module>
      <Hidden lgUp>
        <Text
          className={classes.mainTitle}
          align="center"
          variant="titleXXXL"
          weight="bold"
          overline
        >
          {title}
        </Text>
        <Container fluid>
          <Grid className="" md={12}>
            {inlineMenuItemForm
              ? inlineMenuItemForm.map((link, index) => {
                  return (
                    <Link className={classes.category} {...link.link}>
                      <div>
                        <Text
                          align="left"
                          variant="titleL"
                          weight="bold"
                          className={classes.link}
                        >
                          {link.link.title}
                        </Text>
                      </div>
                      <div className={classes.categoryIcon}>
                        <Icon className={classes.icon} name="right_white" />
                      </div>
                    </Link>
                  )
                })
              : null}
          </Grid>
        </Container>
      </Hidden>
    </Module>
  )
}

InlineMenuFormModule.defaultProps = {}

export default InlineMenuFormModule
