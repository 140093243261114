import React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Module from "../components/Module"
import Text from "../components/Text"
import Icon from "../components/Icon"
import Video from "../components/Video"
import InfoBox from "../components/InfoBox"
import FluidImage from "../components/FluidImage"

const useStyles = makeStyles(theme => ({
  help: {},
  title: {
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },
  grid: {},
  gridElement: {},
  helpBox: {
    height: "100%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.greyClear,
    padding: theme.spacing(4, 3),
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
  helpHeader: {
    marginBottom: theme.spacing(2),
  },
  helpTitle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  helpDescription: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  helpHeaderIcon: {
    height: 30,
    width: 30,
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  helpContent: {
    // padding: theme.spacing(3, 4),
    flexGrow: 1,
  },
  list: {
    padding: theme.spacing(3, 0),
  },
  listImage: {
    height: 300,
    float: "right",
    marginRight: theme.spacing(-3),
  },
  ctaContent: {
    display: "flex",
    flexDirection: "column",
  },
  video: {
    height: 260,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
    },
  },
  videoContent: {
    backgroundColor: "#EFEFEF",
  },
  ctaDescription: {
    flexGrow: 1,
  },
  ctaButton: {
    width: "100%",
    padding: theme.spacing(1, 2),
  },
  ctaIcon: {
    height: 20,
    width: "auto",
    marginRight: theme.spacing(2),
  },
  info: {
    marginTop: theme.spacing(2),
  },
}))

const HelpBox = ({ item, tracking }) => {
  const classes = useStyles()

  return (
    <Box className={classes.helpBox}>
      <Box className={classes.helpHeader}>
        <Text className={classes.helpTitle} variant="titleXXL" weight="medium">
          {item.title}
        </Text>
      </Box>
      <Box
        className={clsx(
          classes.helpContent,
          item.type === "CTA" && classes.ctaContent,
          item.type === "VIDEO" && classes.videoContent
        )}
      >
        <>
          <Grid container>
            <Grid item xs={12} sm={item.type === "LIST" ? 8 : 12}>
              {item.subtitle && item.subtitle.length > 0 && (
                <Box>
                  <Text variant="bodyM" weight="bold">
                    {item.subtitle}
                  </Text>
                </Box>
              )}
              {item.description && item.description.length > 0 && (
                <Box>
                  <Text className={classes.helpDescription}>
                    {item.description}
                  </Text>
                </Box>
              )}
              {item.type === "LIST" && (
                <List dense disablePadding className={classes.list}>
                  {item.list.map((text, i) => (
                    <ListItem key={i} disableGutters>
                      <ListItemIcon>
                        <Icon className={classes.bulletIcon} name="bullet" />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.infoText}
                        primary={text}
                      />
                      {/* <ListItemText primary={`- ${text}`}></ListItemText> */}
                    </ListItem>
                  ))}
                </List>
              )}
              {item.type === "VIDEO" && (
                <Video
                  className={classes.video}
                  {...item.video}
                  fullscreen={false}
                  tracking={tracking}
                ></Video>
              )}
            </Grid>
            {item.type === "LIST" && (
              <Hidden xsDown>
                <Grid item xs={4}>
                  <FluidImage
                    avoidExtraAnimation
                    className={classes.listImage}
                    {...item.image}
                  />
                </Grid>
              </Hidden>
            )}
          </Grid>
          {item.type === "CTA" && (
            <Button
              variant="outlined"
              className={classes.ctaButton}
              href={item.ctaLink.href}
              trackingObj={tracking}
              trackingAction="click_link"
              trackingLabel={"HelpBox - "+(item?.ctaLink?.label || item?.ctaLink?.title || "")}
            >
              <FluidImage
                className={classes.ctaIcon}
                {...item.ctaIcon}
              ></FluidImage>
              {item.ctaText}
            </Button>
          )}
        </>
      </Box>
    </Box>
  )
}

const HelpModule = ({ title, items, info, ...others }) => {
  const classes = useStyles()

  return (
    <Module className={classes.help}>
      <Box className={classes.title}>
        <Text variant="titleXXXL" weight="bold" className="moduleTitle">
          {title}
        </Text>
      </Box>

      <Grid
        className={classes.grid}
        container
        justify="space-around"
        spacing={2}
      >
        {(items || []).map((el, i) => (
          <Grid key={i} xs={12} md item className={classes.gridElement}>
            <HelpBox item={el} tracking={others.repo || null} />
          </Grid>
        ))}
      </Grid>
      <InfoBox className={classes.info} {...info} tracking={others.repo || null}></InfoBox>
    </Module>
  )
}

const defaultProps = {
  title: "",
  items: [],
}

HelpModule.defaultProps = defaultProps

export default HelpModule
