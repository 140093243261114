import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import stampBackground from "../assets/img/stamp.svg"
import { showGuides } from "@src/utils/debug"

const useStyles = makeStyles(theme => ({
  stamp: {
    color: "#fff",
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.bold,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    lineHeight: 1,
    fontSize: 14,
    position: "absolute",
    minWidth: "50px",
    minHeight: "50px",
    border: showGuides("1px solid green"),
    [theme.breakpoints.down("md")]: {
      left: "auto",
    },
  },
  background: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "0 !important",
    border: showGuides("1px solid yellow"),
  },
  backgroundWrapper: {
    //padding: theme.spacing(1),
    border: showGuides("1px solid red"),
    position: "absolute",
    width: "100%",
    minWidth: "110px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "20px",
    },
  },
  content: {
    //padding: theme.spacing(1),
    //padding: '10%',
    display: "flex",
    flexDirection: "column",
  },
}))

const Stamp = ({ className, children }) => {
  const classes = useStyles()
  return (
    <Box
      className={clsx(
        className,
        classes.stamp,
        classes.background
      )} /*style={{ backgroundImage: `url(${stampBackground})` }}*/
    >
      <div className={clsx(classes.backgroundWrapper)}>
        <img className={classes.background} src={stampBackground} />
      </div>
      <div className={classes.content} style={{ zIndex: 2 }}>
        {children}
      </div>
    </Box>
  )
}

export default Stamp
