import React, { useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Hidden, Grid } from "@material-ui/core"

import Module from "../components/Module"
import FigureCard from "../components/FigureCard"
import Text from "../components/Text"
import GiftOverlay from "../components/GiftOverlay"

import { GTMEvent } from "../utils/gtm"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Lazy } from "swiper"

// install Swiper modules
SwiperCore.use([Lazy])

const useStyles = makeStyles(theme => ({
  gifts: {
    textAlign: "center",
    overflow: "hidden",

    '& .swiper-container': {
      width: '100%',      
      listStyle: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 0,
      position: 'relative',
      zIndex: 1,      
    },
    '& .swiper-wrapper': {
      boxSizing: 'border-box',
      display: 'flex',
      height: '100%',
      position: 'relative',
      transitionProperty: '-webkit-transform',
      transitionProperty: 'transform',
      transitionProperty: 'transform,-webkit-transform',
      width: '100%',
      zIndex: 1,      
    },
    '& .swiper-slide': {
      flexShrink: 0,
      height: 'auto',
      position: 'relative',
      transitionProperty: '-webkit-transform',
      transitionProperty: 'transform',
      transitionProperty: 'transform,-webkit-transform',
      width: '100%',
    }

  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  slider: {    
  },
  gift: {
    height: "100%",
    padding: "2px",
    overflow: 'hidden',
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 350,
    },
  },
}))

const Overlay = React.forwardRef((props, ref) => {

    React.useImperativeHandle(ref, () => ({
      maybeShow() {
        setShowOverlay( !showOverlay );
      },
      initPosition( position ) {
        setInitialPos( position );
      }      
    }))

  const [showOverlay, setShowOverlay] = useState( false )
  const [initialPos, setInitialPos] = useState( 0 )

  return (
    <GiftOverlay
      title={props.title}
      open={showOverlay}
      stickyHeader
      initialPos={initialPos}
      gifts={(props?.gifts || []).map(g => ({
        name: g.name,
        description: g.summary,
        image: g.picture,
      }))}
      onClose={()=>setShowOverlay(false)}
      tracking={props.repo}
    />    
  )

});

const GiftsModule = ({ title, subtitle, gifts, ...others }) => {
  const classes = useStyles()
  const sliderRef = useRef(null)
  const OverlayRef = useRef(null)

  const handleGiftClick = (gift, index) => {
    OverlayRef.current.maybeShow();
    OverlayRef.current.initPosition( index );
    GTMEvent(others?.repo, "gifts", gift?.name || "");
  }    

  const GiftSlider = ({ gifts, onClick }) => {
    return (
      <Swiper
      ref={sliderRef}
      loop={false}
      allowTouchMove
      loopFillGroupWithBlank={false}
      breakpoints={{
        320: {
          slidesPerView: 1.5,
          spaceBetween: 8
        },
        960: {
          slidesPerView: 2.5,
          spaceBetween: 8
        },
        1100: {
          slidesPerView: 3.8,
          spaceBetween: 8
        },        
      }}
    >
      {(gifts || []).map((gift, index) => {
        return (
          <SwiperSlide key={index}>
            <FigureCard
              key={index}
              className={classes.gift}
              title={gift.name}
              picture={gift.picture}
              promoText={gift.promoText}
              hover
              onClick={() => onClick(gift, index)}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
    )
  }

  return (
    <Module
      className={classes.gifts}
      container={{ container: classes.container }}
    >
      <Text
        className={classes.title}
        variant="titleXXXL"
        component="h3"
        align="center"
        weight="bold"
        overline
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          className={classes.subtitle}
          component={title ? "h4" : "h3"}
          align="center"
          variant="bodyM"
          weight="medium"
        >
          {subtitle}
        </Text>
      )}
      <Box className={classes.sliderContainer}>
        <Hidden smDown>
          {gifts.length < 5 ? (
            <Grid container justify="center">
              {(gifts || []).map((gift, index) => (
                <Grid item xs={3} key={index}>
                  <FigureCard
                    className={classes.gift}
                    title={gift.name}
                    hover
                    picture={gift.picture}
                    promoText={gift.promoText}
                    onClick={() => handleGiftClick(gift, index)}
                  />
                  {/* <GiftCard className={classes.sliderItem} /> */}
                </Grid>
              ))}
            </Grid>
          ) : (
            <GiftSlider
              gifts={gifts}
              onClick={handleGiftClick}
            />
          )}
        </Hidden>
        <Hidden mdUp>
          <GiftSlider
            gifts={gifts}
            onClick={handleGiftClick}
          />
        </Hidden>
      </Box>
      <Overlay
        ref={OverlayRef}
        title={title} 
        subtitle={subtitle} 
        gifts={gifts}
        {...others}
      />
    </Module>
  )
}

GiftsModule.defaultProps = {
  title: "",
  gifts: [],
}

export default GiftsModule
