import React, { useEffect } from "react"
import Text from "../components/Text"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Box, Chip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Module from "../components/Module"
import CtaButton from "../components/CtaButton"
import Link from "../components/Link"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { window } from "browser-monads"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import FluidImage from "../components/FluidImage"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  sectionBackground: {
    backgroundColor: "#f6f6f6",
    width: "100%",
    height: "calc(100% - 200px)",
    position: "absolute",
    zIndex: -1,
    marginTop: 200,
    left: 0,
    right: 0,
    bottom: 0,
  },
  sectionTitle: {
    marginBottom: 30,
    "& .title": {
      textAlign: "center",
    },
    "& .title:before": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  backgroundParallax: {
    position: "absolute !important",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  gridCardWrapper: {
    padding: theme.spacing(0, 1 / 2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1 / 2, 11, 1 / 2),
      paddingBottom: theme.spacing(1),
      "&:not(:last-child)": {
        padding: theme.spacing(0, 1 / 2, 17, 1 / 2),
        paddingBottom: theme.spacing(1),
      },
    },
  },
  badgeTitleWrapper: {
    padding: 40,
    height: "100%",
  },
  badgeTitle: {
    position: "absolute",
    top: 24,
    right: 0,
    minHeight: 37,
    background: "#ffbd08",
    minWidth: 97,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    padding: 10,
    "& p": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 14,
      color: "#1d1d1b",
    },
  },
  logoForeground: {
    maxHeight: "280px !important",
    maxWidth: "100% !important",
    width: "auto !important",
    "& img": {
      maxHeight: "280px !important",
      maxWidth: "100% !important",
      width: "auto !important",
    },
  },
  contentTextWrapper: {
    position: "relative",
    zIndex: 1,
  },
  ctaButtonWidth: {
    minWidth: "240px",
  },
  buttonCard: {
    zIndex: "99",
    width: "100%",
    height: "100%",
    background: "transparent",
    WebkitTextFillColor: "transparent",
    position: "absolute",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& img": {
      display: "none",
    },
  },
  card: {
    border: 0,
    borderRadius: 0,
    position: "relative",
    marginBottom: 40,
    minHeight: 420,
    overflow: "visible",
    "& .logoNarrow": {
      position: "absolute",
      left: 20,
      right: 20,
      top: 20,
      zIndex: 2,
      "& .parallax-inner > div": {
        textAlign: "left",
        maxWidth: "50%",
      },
      "& picture, & img": {
        height: "140px !important",
        width: "auto !important",
      },
      "&.logoNarrowDesktop, &.logoNarrowDesktop img, &.logoNarrowDesktop picture":
        {
          [theme.breakpoints.up("sm")]: {
            maxHeight: "125px !important",
          },
        },
      "&.logoNarrowMobile, &.logoNarrowMobile img, &.logoNarrowMobile picture":
        {
          [theme.breakpoints.down("sm")]: {
            maxHeight: "125px !important",
          },
        },
      "&.logoNarrowCenter, &.logoNarrowCenter img, &.logoNarrowCenter picture":
        {
          "& .parallax-inner": {
            display: "block !important",
          },
          "& .parallax-inner > div": {
            textAlign: "center !important",
            maxWidth: "100%",
          },
        },
    },
    "& .cardMedia": {
      display: "block",
    },
    "& .parallaxFix .parallax-inner > div ": {
      inset: "-50% 0px !important",
    },
    "&.big .cardForeground": {
      width: "50%",
      bottom: "40px",
    },
    "& .cardForeground": {
      width: "50%",
      float: "right",
      "& .layer": {
        position: "absolute",
        top: "50%",
        right: 0,
        height: "124%",
        width: "74%",
        maxHeight: 620,
        transform: "translate(0, -50%)",
        [theme.breakpoints.down("sm")]: {
          width: "87%",
          height: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        "& .image": {
          height: "100%",
        },
      },
    },
    "& .cardForeground33": {
      width: "50%",
      float: "right",
      "& .layer": {
        position: "absolute",
        top: "50%",
        right: 0,
        height: "124%",
        width: "74%",
        maxHeight: 620,
        transform: "translate(0, -50%)",
        [theme.breakpoints.down("sm")]: {
          width: "87%",
          height: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        "& .image": {
          height: "100%",
        },
      },
    },
    "&.big .cardContent": {
      width: "33%",
    },
    "& .cardContent": {
      position: "relative",
      padding: 20,
      minHeight: 400,
      width: "50%",
      float: "left",
      display: "flex",
      alignContent: "end",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    "& .cardContent50": {
      position: "absolute",
      width: "50%",
      float: "left",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    "& .textWrapper": {
      width: "100%",
    },
    "& .textWrapper .info": {
      color: "#b2b2b2",
    },
    "& .textWrapper .info > *": {
      display: "inline-block",
      height: "auto !important",
    },
    "& .textWrapper .info div + span": {
      marginLeft: 10,
    },
    "& .textWrapper .title": {
      marginTop: 20,
      color: "#b2b2b2",
    },
    "& .textWrapper .links": {
      marginTop: 20,
      color: "#b2b2b2",
      "& .button": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
    "& .position_foreground": {
      position: "relative",
      right: "10px",
      [theme.breakpoints.up("md")]: {
        right: "10%",
      },
    },
    "& .position_foreground50": {
      position: "relative",
      width: "100%",
    },
  },
  center: {
    margin: "0 auto",
  },
}))

const handleNarrowLogoClasses = product => {
  let classes = []
  classes.push("logoNarrow")
  if (product?.narrow_logo_desktop) {
    classes.push("logoNarrowDesktop")
  } else if (product?.narrow_logo_mobile) {
    classes.push("logoNarrowMobile")
  }
  if (product?.position_center_logo) {
    classes.push("logoNarrowCenter")
  }
  return classes.join(" ")
}

const fallbackProperty = product => {
  let fallbackProperty = new Object()
  fallbackProperty.box_size = product?.box_size
    ? product?.box_size
    : product?.list_box_v2_box_size
  fallbackProperty.cta =
    product?.cta != "" ? product?.cta : product?.list_box_v2_cta
  fallbackProperty.image_foreground = product?.image_foreground
    ? product?.image_foreground
    : product?.list_box_v2_image_foreground
  fallbackProperty.image_full_width = product?.image_full_width
    ? product?.image_full_width
    : product?.list_box_v2_image_full_width
  fallbackProperty.image_half_width = product?.image_half_width
    ? product?.image_half_width
    : product?.list_box_v2_image_half_width
  fallbackProperty.image_mobile_width = product?.image_mobile
    ? product?.image_mobile
    : product?.list_box_v2_image_mobile
  fallbackProperty.narrow_logo_desktop = product?.narrow_logo_desktop
    ? product?.narrow_logo_desktop
    : product?.list_box_v2_narrow_logo_desktop
  fallbackProperty.narrow_logo_mobile = product?.narrow_logo_mobile
    ? product?.narrow_logo_mobile
    : product?.list_box_v2_narrow_logo_mobile
  fallbackProperty.position_center_logo = product?.position_center_logo
    ? product?.position_center_logo
    : product?.list_box_v2_position_center_logo
  fallbackProperty.pre_order = product?.pre_order
    ? product?.pre_order
    : product?.list_box_v2_pre_order
  fallbackProperty.up_title = product?.up_title
    ? product?.up_title
    : product?.list_box_v2_up_title
  fallbackProperty.discover_now = product?.discover_now
    ? product?.discover_now
    : ""
  fallbackProperty.title = product?.title ? product?.title : ""
  fallbackProperty.pre_title = product?.pre_title ? product?.pre_title : ""
  fallbackProperty.logo = product?.logo ? product?.logo : ""
  // -- foreground default (33%)
  fallbackProperty.image_foreground_33 = product?.image_foreground_33
    ? product?.image_foreground_33
    : product?.list_box_v2_image_foreground_33

  // -- foreground default (50%)
  fallbackProperty.image_foreground_50 = product?.image_foreground_50
    ? product?.image_foreground_50
    : product?.list_box_v2_image_foreground_50

  // -- foreground default (100%)
  fallbackProperty.image_foreground_100 = product?.image_foreground_100
    ? product?.image_foreground_100
    : product?.list_box_v2_image_foreground_100

  // -- preOrder
  fallbackProperty.productPreOrder = product?.cta?.isPreorder ? true : false

  return fallbackProperty
}

const CardProduct = props => {
  const { product, repo, centered } = props
  const productFallbackProperty = fallbackProperty(product)
  const classes = useStyles()
  const { i18n, t } = useTranslation()

  const boxSize =
    productFallbackProperty?.box_size == 100
      ? 12
      : productFallbackProperty?.box_size == 50
      ? 6
      : productFallbackProperty?.box_size == 33
      ? 4
      : 12

  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })
  const isMobile = windowSize.width <= "480"
  const { ref, inView, entry } = useInView()
  const [animate, setAnimate] = React.useState(true)

  // window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  // show on scroll
  const IsObserverSupported = () => {
    return (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    )
  }
  useEffect(() => {
    if (animate && inView && ref && IsObserverSupported()) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: parseInt(i / 5) * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, parseInt(i / 5) * 50 + 500)
      })
    }
  }, [ref, inView, entry])
  return (
    <Grid
      ref={ref}
      xs={12}
      md={boxSize}
      item
      className={clsx(classes.gridCardWrapper, centered && classes.center)}
    >
      {boxSize === 12 && !isMobile ? (
        <Card
          className={clsx(
            classes.card,
            boxSize === 12 && isMobile === false ? "big" : null
          )}
        >
          {productFallbackProperty?.up_title ? (
            <Box className={classes.badgeTitle}>
              <Text
                component="div"
                variant="title"
                weight="bold"
                align="left"
                verticalAlign="middle"
              >
                {productFallbackProperty?.up_title}
              </Text>
            </Box>
          ) : null}

          {productFallbackProperty.logo ? (
            <div className={handleNarrowLogoClasses(productFallbackProperty)}>
              <Box>
                <ParallaxItem
                  disabled={isMobile}
                  styleInner={{
                    position: "relative",
                    display: "flex",
                    alignItems: "start",
                    height: "100%",
                    width: "100%",
                  }}
                  y={["8", "-8"]}
                >
                  <FluidImage {...productFallbackProperty.logo} />
                </ParallaxItem>
              </Box>
            </div>
          ) : null}

          <div className="cardMedia parallaxFix">
            <CtaButton
              className={clsx(classes.buttonCard, "button")}
              {...productFallbackProperty?.cta}
              next
              size="large"
              trackingAction={productFallbackProperty?.cta?.outbound}
              trackingLabel={
                "CategoriesProductListV2ManualModule - " +
                (productFallbackProperty?.cta?.label ||
                  productFallbackProperty?.cta?.title)
              }
            >
              {productFallbackProperty?.cta?.label ||
                productFallbackProperty?.cta?.title}
            </CtaButton>
            <Parallax
              className={classes.backgroundParallax}
              bgImage={
                boxSize === 12 && !isMobile
                  ? productFallbackProperty?.image_full_width
                  : productFallbackProperty?.image_half_width
              }
            ></Parallax>
          </div>

          {productFallbackProperty.image_foreground ? (
            <Box align="center" className="cardForeground">
              <ParallaxItem
                className="layer"
                disabled={isMobile}
                styleInner={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
                y={["8", "-8"]}
              >
                <FluidImage {...productFallbackProperty.image_foreground} />
              </ParallaxItem>
            </Box>
          ) : null}
          {productFallbackProperty?.image_foreground_100 ? (
            <Box align="center" className="cardForeground position_foreground">
              <FluidImage {...productFallbackProperty.image_foreground_100} />
            </Box>
          ) : null}
          <CardContent className="cardContent">
            <div className="textWrapper">
              <Box className="info">
                {productFallbackProperty.productPreOrder ? (
                  <Chip label={t("pre_order_label")} />
                ) : (
                  productFallbackProperty.pre_title && (
                    <Chip
                      label={productFallbackProperty.pre_title.replace(
                        /<\/?[^>]+(>|$)|&nbsp;/g,
                        ""
                      )}
                    />
                  )
                )}
              </Box>

              {productFallbackProperty.title ? (
                <Box className="title">
                  <Link
                    {...product.list_box_v2_cta}
                    type={product.list_box_v2_cta?.type}
                    product_code={product.list_box_v2_cta?.product_code}
                    repo={repo}
                  >
                    <Text className="specialTitleXL" color="textTertiary">
                      {productFallbackProperty.title}
                    </Text>
                  </Link>
                </Box>
              ) : null}

              <Box className="links">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  {productFallbackProperty.discover_now ? (
                    <Grid xs={12} item>
                      <Text
                        variant="bodyM"
                        weight="bold"
                        align="left"
                        verticalAlign="middle"
                      >
                        {productFallbackProperty.discover_now}
                      </Text>
                    </Grid>
                  ) : null}
                  {productFallbackProperty?.cta?.href ||
                  product?.cta?.productCode ? (
                    <Grid xs={12} item>
                      <CtaButton
                        className={clsx(classes.ctaButtonWidth, "button")}
                        {...productFallbackProperty?.cta}
                        next
                        size="large"
                        trackingAction={productFallbackProperty?.cta?.outbound}
                        trackingLabel={
                          "CategoriesProductListV2ManualModule - " +
                          (productFallbackProperty?.cta?.label ||
                            productFallbackProperty?.cta?.title)
                        }
                      >
                        {productFallbackProperty?.cta?.label ||
                          productFallbackProperty?.cta?.title}
                      </CtaButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card}>
          {productFallbackProperty?.up_title && (
            <Box className={classes.badgeTitle}>
              <Text
                component="div"
                variant="title"
                weight="bold"
                align="left"
                verticalAlign="middle"
              >
                {productFallbackProperty?.up_title}
              </Text>
            </Box>
          )}

          <div className="flexCard">
            <div className="cardMedia parallaxFix">
              <CtaButton
                className={clsx(classes.buttonCard, "button")}
                {...productFallbackProperty?.cta}
                next
                size="large"
                trackingAction={productFallbackProperty?.cta?.outbound}
                trackingLabel={
                  "CategoriesProductListV2ManualModule - " +
                  (productFallbackProperty?.cta?.label ||
                    productFallbackProperty?.cta?.title)
                }
              >
                {productFallbackProperty?.cta?.label ||
                  productFallbackProperty?.cta?.title}
              </CtaButton>
              <Parallax
                className={classes.backgroundParallax}
                bgImage={productFallbackProperty?.image_half_width}
                bgMobileImage={productFallbackProperty?.image_mobile_width}
              ></Parallax>
            </div>
            {boxSize === 6 && !isMobile ? (
              productFallbackProperty?.image_foreground_50 ? (
                <Box
                  align="center"
                  className="cardForeground position_foreground50"
                >
                  <FluidImage
                    {...productFallbackProperty.image_foreground_50}
                  />
                </Box>
              ) : null
            ) : productFallbackProperty?.image_foreground_33 ? (
              <Box
                align="center"
                className="cardForeground33 position_foreground50"
              >
                <FluidImage {...productFallbackProperty.image_foreground_33} />
              </Box>
            ) : null}

            <CardContent className="cardContent50">
              <div className="textWrapper">
                <Box className="info">
                  {productFallbackProperty.productPreOrder ? (
                    <Chip label={t("pre_order_label")} />
                  ) : (
                    productFallbackProperty.pre_title && (
                      <Chip
                        label={productFallbackProperty.pre_title.replace(
                          /<\/?[^>]+(>|$)|&nbsp;/g,
                          ""
                        )}
                      />
                    )
                  )}
                </Box>

                {productFallbackProperty.title ? (
                  <Box className="title">
                    <Link
                      {...product.list_box_v2_cta}
                      type={product.list_box_v2_cta?.type}
                      product_code={product.list_box_v2_cta?.product_code}
                      repo={repo}
                    >
                      <Text className="specialTitleXL" color="textTertiary">
                        {productFallbackProperty.title}
                      </Text>
                    </Link>
                  </Box>
                ) : null}

                <Box className="links">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    {productFallbackProperty.discover_now &&
                    !productFallbackProperty?.cta?.href ? (
                      <Grid xs={12} item>
                        <Text
                          variant="bodyM"
                          weight="bold"
                          align="left"
                          verticalAlign="middle"
                        >
                          {productFallbackProperty.discover_now}
                        </Text>
                      </Grid>
                    ) : null}
                    {productFallbackProperty?.cta?.href ||
                    product?.cta?.productCode ? (
                      <Grid xs={12} item>
                        <CtaButton
                          className={clsx(classes.ctaButtonWidth, "button")}
                          {...productFallbackProperty?.cta}
                          next
                          size="large"
                          trackingAction={
                            productFallbackProperty?.cta?.outbound
                          }
                          trackingLabel={
                            "CategoriesProductListV2ManualModule - " +
                            (productFallbackProperty?.cta?.label ||
                              productFallbackProperty?.cta?.title)
                          }
                        >
                          {productFallbackProperty?.cta?.label ||
                            productFallbackProperty?.cta?.title}
                        </CtaButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </div>
            </CardContent>
          </div>
        </Card>
      )}
    </Grid>
  )
}

CardProduct.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
}

const CategoriesProductListV2ManualModule = props => {
  const { title, products, repo } = props
  const classes = useStyles()

  return (
    <Module>
      <div className={classes.sectionBackground}></div>
      <Grid container>
        {title ? (
          <Grid xs={12} item className={classes.sectionTitle}>
            <Text
              className="title"
              variant="titleXXXL"
              overline
              weight="bold"
              component="h2"
            >
              {title}
            </Text>
          </Grid>
        ) : null}
        <Grid xs={12} item container>
          {products?.map((product, index) => {
            const centered =
              products.length % 2 !== 0 && products.length - 1 === index

            return (
              <CardProduct
                key={index}
                product={product}
                index={index}
                repo={repo}
                centered={centered}
              />
            )
          })}
        </Grid>
      </Grid>
    </Module>
  )
}

CategoriesProductListV2ManualModule.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
}

export default CategoriesProductListV2ManualModule
