import React, { useRef, useState, useEffect } from "react"
import shortid from "shortid"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Hidden, Grid } from "@material-ui/core"
import Slider from "../components/Slider"
import PropTypes from "prop-types"
import Module from "../components/Module"
import Text from "../components/Text"
import ImageGalleryOverlay from "../components/ImageGalleryOverlay"
import FluidImage from "../components/FluidImage"
import Icon from "../components/Icon"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import clsx from "clsx"
import "swiper/swiper-bundle.css"

const useStyles = makeStyles(theme => ({
  gallery: {
    textAlign: "center",
    overflow: "hidden",
    "& .swiper-pagination-bullet": {
      width: "16px",
      height: "16px",
      textAlign: "center",
      lineHeight: "20px",
      fontSize: "12px",
      color: "#000",
      opacity: "1",
      background: "#d2d3d6",
      borderRadius: "50%",
      cursor: "pointer",
    },
    "& .swiper-pagination-bullet-active": {
      color: "#fff",
      background: "#ffbd08",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  slider: {},
  image: {
    height: "345px",
    border: "4px solid #fff",
    position: "relative",
    overflow: "hidden",
    maxWidth: "100%",
    "&:hover": {
      cursor: "pointer",
      border: "4px solid #f29100",
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
      padding: 0,
      margin: 0,
    },
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    cursor: "pointer",
  },
  icon: {
    width: 24,
    height: 24,
  },
  w100: {
    width: "100%",
  },
  py4: {
    paddingBottom: 32,
    paddingTop: 32,
  },
  modalSwiper: {
    height: "100%",
  },
  prelative: {
    position: "relative !important",
  },
}))

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const swiperResponsive = {
  425: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 14,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 14,
  },
}

const ImageGalleryModule = props => {
  const classes = useStyles()
  const { title, description, primary } = props
  const sliderRef = useRef(null)
  const dialogRef = useRef(null)
  const [index, setIndex] = useState(0)
  const [isEnd, setIsEnd] = useState(false)
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    variableWidth: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    className: classes.slider,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const pagination = {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>"
    },
  }

  const handleClick = index => {
    dialogRef.current?.open(index)
  }

  return (
    <Module
      className={classes.gallery}
      container={{ container: classes.container }}
    >
      <ImageGalleryOverlay
        ref={dialogRef}
        stickyHeader
        items={primary?.field_value || []}
      />

      <Text
        className={classes.title}
        variant="titleXXXL"
        component="h3"
        align="center"
        weight="bold"
        overline
      >
        {title?.field_value}
      </Text>
      {description?.field_value && (
        <Text
          className={classes.subtitle}
          component={description?.field_value ? "h4" : "h3"}
          align="center"
          variant="bodyM"
          weight="medium"
        >
          {description?.field_value}
        </Text>
      )}

      <Box className={classes.sliderContainer}>
        <div className={classes.flex}>
          <button className={clsx(classes.arrow, "image-gallery__custom_prev")}>
            <Icon className={classes.icon} name="dropdown-left-yellow"></Icon>
          </button>
          <div className={classes.w100}>
            <Swiper
              loop={true}
              pagination={pagination}
              allowTouchMove
              breakpoints={swiperResponsive}
              className={classes.modalSwiper}
              navigation={{
                nextEl: ".image-gallery__custom_next",
                prevEl: ".image-gallery__custom_prev",
              }}
              initialSlide={parseInt(primary?.field_value?.length / 2)}
              modules={[Navigation, Pagination]}
            >
              {(primary?.field_value || []).map((image, index) => {
                return (
                  <SwiperSlide>
                    <div onClick={() => handleClick(index)}>
                      <Hidden mdUp>
                        <FluidImage
                          animationDepth={0}
                          className={classes.image}
                          key={shortid.generate()}
                          sources={
                            image?.field_value?.image_mobile?.field_value
                              ?.sources
                          }
                        />
                      </Hidden>
                      <Hidden smDown>
                        <FluidImage
                          animationDepth={0}
                          className={classes.image}
                          key={shortid.generate()}
                          sources={
                            image?.field_value?.image?.field_value?.sources
                          }
                        />
                      </Hidden>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <button className={clsx(classes.arrow, "image-gallery__custom_next")}>
            <Icon className={classes.icon} name="dropdown-right-yellow"></Icon>
          </button>
        </div>

        <div className="col-24">
          <div
            className={clsx(
              classes.flex,
              classes.py4,
              classes.prelative,
              "swiper-pagination"
            )}
            style={{ gap: "8px" }}
          ></div>
        </div>
      </Box>
    </Module>
  )
}

ImageGalleryModule.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  primary: PropTypes.array,
}

export default ImageGalleryModule
