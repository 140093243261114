import React from 'react';
import clsx from 'clsx';
import { Container, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Module from '../components/Module';
import Text from '../components/Text';
import Image from '../components/Image';
import Video from '../components/Video';

const VIDEO_HEIGHT = 35;

const useStyles = makeStyles((theme) => ({
    aboutUsHeader: {
        position: 'relative',
        padding: theme.spacing(12),
        paddingBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        }
    },
    bottomVideoMargin: {
        marginBottom: `25vw`,
    },
    background: {
        top: 0,
        left: 0,
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        width: '100%',
        height: '100%',
        zIndex: -1
    },
    container: {
        display: 'grid',
        gridTemplate: 'auto 1fr auto auto / 1fr 1fr 1fr 1fr',
        gridGap: theme.spacing(4),
        //paddingTop: 77,
        [theme.breakpoints.down('sm')]: {
            gridTemplate: '1fr / 1fr',
            gridGap: 20,
            //paddingTop: 30,
        },
    },
    logo: {
        height: 74,
        cursor: 'pointer',
        marginBottom: 150,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 100,
            height: 32
        },
    },
    headerWrapper: {
        marginTop: 160,
        gridColumn: '1 / 4',
        [theme.breakpoints.down('sm')]: {
            gridColumn: 'auto / auto',
            marginTop: 100,
        },
    },
    firstBoxWrapper: {
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: 'auto / auto',
        },
    },
    secondBoxWrapper: {
        gridColumn: '3 / 5',
        [theme.breakpoints.down('sm')]: {
            gridColumn: 'auto / auto',
        },
    },
    videoWrapper: {
        gridColumn: '1 / 5',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            gridColumn: 'auto / auto',
        },
    },
    kicker: {
        fontFamily: 'SpeziaExtended',
        fontWeight: 'bold',
        fontSize: 21.3,
        lineHeight: 2.84,
        letterSpacing: -0.04,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10.5,
            letterSpacing: -0.02,
        },
    },
    title: {
        marginBottom: theme.spacing(4),
        fontWeight: 500,
        fontSize: 57.7,
        lineHeight: 1.05,
        letterSpacing: -0.06,
        width: '50vw',
        ['@media (min-device-width: 1900px) and (max-device-width: 2150px)']: {
            width: '40vw'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
            fontSize: 28.4,
            letterSpacing: -0.03,
            width: '100%',
        },
    },
    boxTitle: {
        marginBottom: theme.spacing(1)
    },
    boxText: {
        fontFamily: 'SpeziaExtended',
        fontWeight: 600,
        fontSize: 27,
        letterSpacing: -0.04,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15.9,
            letterSpacing: -0.02,
        },
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: `${VIDEO_HEIGHT}vw`,
        top: 0,
        '& .react-player__preview': {
            borderRadius: 7,
        }
    },
    box: {
        backgroundImage:
            "linear-gradient(to left, rgba(107, 26, 123, 0.25), #390b45)",
        borderRadius: 7,
        padding: theme.spacing(5),
        width: '100%',
        height: '100%'
    },
}));

const LiftOut = ({ title, text }) => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Text className={classes.boxTitle} variant='bodyExtS' color='secondary' weight='bold'>{title}</Text>
            <Text className={classes.boxText} component="h2" variant='titleExtXL' color='textTertiary'>{text}</Text>
        </Box>
    );
}

const AboutUsHeader = ({
    kicker, title, firstBox, secondBox, video, backgroundImage, mobileBackgroundImage, ...others
}) => {
    const classes = useStyles();

    return (
        <Module className={clsx(classes.aboutUsHeader, { [classes.bottomVideoMargin]: video && video.src })} variant="full" noMargin>
            <Hidden smDown>
                <div className={classes.background} style={{ backgroundImage: `url(${backgroundImage ? backgroundImage.src : ''})` }} />
            </Hidden>
            <Hidden mdUp>
                <div className={classes.background} style={{ backgroundImage: `url(${mobileBackgroundImage ? mobileBackgroundImage.src : ''})` }} />
            </Hidden>
            <Container className={classes.container}>
                <Box className={classes.headerWrapper}>
                    <Box display='flex' flexDirection='column'>
                        <Text className={classes.kicker} variant='bodyExtL' color='secondary' weight='bold'>{kicker}</Text>
                        <Text className={classes.title} variant='specialTitleXXL' color='textTertiary'>{title}</Text>
                    </Box>
                </Box>
                <Box className={classes.firstBoxWrapper}>
                    <LiftOut {...firstBox} />
                </Box>
                <Box className={classes.secondBoxWrapper}>
                    <LiftOut {...secondBox} />
                </Box>
                {video && video.src &&
                    <Box className={classes.videoWrapper}>
                        <div style={{ height: `${VIDEO_HEIGHT / 2}vw` }}></div>
                        <Video className={classes.video} {...video} tracking={others.repo || null}></Video>
                    </Box>
                }
            </Container>
        </Module >
    );
};


export default AboutUsHeader;
