import React, { useEffect, useRef, useContext } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box, Hidden, Container } from "@material-ui/core"
import { window } from "browser-monads"
import moment from "moment"

import Push from "./Push"
import Countdown from "./Countdown"
import Text from "./Text"
import CtaButton from "./CtaButton"
import Button from "./Button"

import { GlobalStateContext } from "../context/GlobalContextProvider"
const threshold = 100
const pushId = "push_promo"

const useStyles = makeStyles(theme => ({
  promoFooter: {
    position: "fixed",
    left: 0,
    transform: `translate3d(0,${threshold}px,0)`,
    width: "100%",
    zIndex: theme.zIndex.modal - 15,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07), 0px -2px 4px rgba(0,0,0,0.05)",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",

    [theme.breakpoints.down("lg")]: {},
  },
  title: {
    fontSize: "min(max(15px, 1.2vw), 36px)",
  },
  text: {
    fontSize: "min(max(11px, 0.9vw), 28px)",
  },
  promoExpireDateContainer: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    // borderLeft: `1px solid ${theme.customPalette.greyClear}`,
  },
  promoExpireTextWrapper: {
    maxWidth: "min(max(160px, 10vw), 200px)",
  },
  promoExpireText: {
    fontSize: "min(max(11px, 0.9vw), 28px)",
  },
  ctaButton: {
    height: "fit-content",
    fontSize: "min(max(16px, 1.0vw), 36px)",
    width: "100%",
    maxWidth: 350,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  expirationTextMobile: {
    fontSize: 12,
    fontFamily: "Spezia",
    letterSpacing: "normal",
    color: theme.customPalette.darkishPurple,
  },
}))

const PromoFooter = props => {
  const { title, text, picture, cta, tracking } = props
  const classes = useStyles()
  const theme = useTheme()
  const { earlybirdOffer, cookieBar } = useContext(GlobalStateContext)
  const footerRef = useRef(null)

  const handleScroll = e => {
    const friction = 0.3
    if (footerRef.current) {
      //let sticky = e.target.documentElement.scrollTop > threshold;
      let sticky =
        window.scrollY > threshold /** FIX - support old browser iOS <= 12.1 */
      if (sticky) {
        //let pos = Math.max(threshold - (e.target.documentElement.scrollTop - threshold) * friction, 0);
        let pos = Math.max(
          threshold - (window.scrollY - threshold) * friction,
          0
        )
        footerRef.current.style.transform = `translate3d(0,${pos}px,0)`
        if (pos === 0) {
          footerRef.current.style.zIndex = theme.zIndex.modal - 1
        } else {
          footerRef.current.style.zIndex = theme.zIndex.modal - 3
        }
      } else {
        footerRef.current.style.transform = `translate3d(0,${threshold}px,0)`
      }
    }
  }

  useEffect(() => {
    // Remove first to avoid unhandled renders
    window.removeEventListener("scroll", handleScroll)
    window.addEventListener("scroll", handleScroll)
  })

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    cta &&
    cta.href &&
    cta.href.includes("/subscribe") &&
    ((cta.href.includes("checkout.fanhome.com/") &&
      !cta.href.includes("testVersion")) ||
      cta.href.includes("p1597070c1tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <Box
      ref={footerRef}
      className={classes.promoFooter}
      style={{ bottom: (cookieBar && cookieBar.height) || 0 }}
    >
      <Container className={classes.container}>
        {/* Desktop */}
        <Hidden mdDown>
          <Box display="flex" alignItems="center">
            <Box
              style={{
                backgroundImage: picture ? `url(${picture.src})` : "",
                width: "min(max(130px, 10vw), 100px)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100%",
              }}
            ></Box>

            <Box py={1} px={2}>
              <Text
                className={classes.title}
                variant="titleL"
                component="div"
                weight="bold"
              >
                {title}
              </Text>
              <Text className={classes.text} variant="bodyS" weight="bold">
                {text}
              </Text>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={clsx({ [classes.divider]: !!earlybirdOffer.show })}
          >
            {earlybirdOffer.show && (
              <>
                <Box className={classes.promoExpireTextWrapper} py={1} px={2}>
                  <Text
                    className={classes.promoExpireText}
                    variant="bodyS"
                    weight="bold"
                  >
                    {earlybirdOffer.expirationText}
                  </Text>
                </Box>

                <Box px={2} display="flex" alignItems="center">
                  <Countdown
                    stickyBanner={true}
                    date={earlybirdOffer.expirationDate}
                  ></Countdown>
                  {/* <DiscountDateBox expireDate={expireDate} text={promoExpireText} /> */}
                </Box>
              </>
            )}
          </Box>

          {/* CTAs */}
          <Box maxWidth={"375px"} width={"100%"}>
            {cta && (cta?.productCode || cta?.href) && (
              <Box
                p={1}
                display="flex"
                flexGrow="2"
                alignItems="center"
                justifyContent="flex-end"
              >
                <CtaButton
                  className={classes.ctaButton}
                  variant="contained"
                  size="large"
                  {...cta}
                  next
                  trackingObj={tracking}
                  trackingAction="cta sticky"
                  trackingLabel={
                    cta?.productCode || cta?.label || cta?.title || ""
                  }
                >
                  {cta.text || cta.label}
                </CtaButton>
              </Box>
            )}
          </Box>
        </Hidden>

        {/* Mobile */}
        <Hidden lgUp>
          <Box display="flex" width="100%" py={1} px={2} alignItems="center">
            {earlybirdOffer.show && (
              <Box
                width="50%"
                display="flex"
                alignItems="center"
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Text className={classes.expirationTextMobile} weight="bold">
                  {earlybirdOffer.expirationText}
                </Text>
                {/* <Text variant="bodyM" weight="bold" color="textSecondary">
                    {moment(earlybirdOffer.expirationDate).format("DD.MM.YYYY")}
                  </Text> */}
                <Box display="flex" alignItems="center">
                  <Countdown
                    date={earlybirdOffer.expirationDate}
                    stickyBanner={true}
                  ></Countdown>
                  {/* <DiscountDateBox expireDate={expireDate} text={promoExpireText} /> */}
                </Box>
              </Box>
            )}
            <Box
              width={earlybirdOffer.show ? "50%" : "100%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              pl={earlybirdOffer.show && 2}
            >
              {cta && cta.productCode && (
                <CtaButton
                  size="large"
                  className={classes.ctaButton}
                  productCode={cta.productCode}
                  trackingObj={tracking}
                  trackingAction="cta sticky"
                  trackingLabel={cta.productCode}
                >
                  {cta?.text || cta?.label}
                </CtaButton>
              )}
              {cta && cta.label && cta.href && TEMPORARY_FIX && (
                <CtaButton
                  className={classes.ctaButton}
                  size="large"
                  forceOverrideLinkTEMPORARY={cta.href}
                  {...cta}
                  trackingObj={tracking}
                  trackingAction="cta sticky"
                  trackingLabel={cta?.label || cta?.title || ""}
                >
                  {cta.label}
                </CtaButton>
              )}
              {cta && cta.label && cta.href && !TEMPORARY_FIX && (
                <Button
                  className={classes.ctaButton}
                  size="large"
                  {...cta}
                  trackingObj={tracking}
                  trackingAction="cta sticky"
                  trackingLabel={cta?.label || cta?.title || ""}
                >
                  {cta.label}
                </Button>
              )}
            </Box>
          </Box>
        </Hidden>
      </Container>
      <Push pushId={pushId} floatingElRef={footerRef} />
    </Box>
  )
}

export default PromoFooter
