import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ArticleCard from '../components/ArticleCard';
import MobileSlider from '../components/MobileSlider';
import Module from '../components/Module';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(0, 13),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  gridElement: {
    padding: theme.spacing(1, 1 / 2),
  },
  slider: {
    paddingLeft: theme.spacing(2),
    '& .slick-list': {
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
}));

const NewsModule = ({ title, subtitle, news, ...others }) => {
  const classes = useStyles();

  return (
    <Module className={classes.container}>
      <Typography align="center" className="titleXXXL bold moduleTitle" gutterBottom>
        {title}
      </Typography>
      <Typography align="center" className="bodyM medium" gutterBottom>
        {subtitle}
      </Typography>
      <Hidden smDown>
        <Grid container className={classes.grid}>
          {(news || []).map((el, i) => (
            <Grid key={i} item sm={i === 0 ? 8 : 4} className={classes.gridElement}>
              <ArticleCard {...el} isBig={i === 0} />
            </Grid>
          ))}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <MobileSlider className={classes.slider} tracking={others.repo}>
          {(news || []).map((el, i) => (
            <Box pr={2} key={i}>
              <ArticleCard {...el} />
            </Box>
          ))}
        </MobileSlider>
      </Hidden>
    </Module>
  );
};

NewsModule.defaultProps = {
  title: '',
  subtitle: '',
  news: [],
};

export default NewsModule;
