
const shortid = require('shortid');

const schema = 'pubsub___id___';
const default_publisher = `${schema}default`; 
var clients = 0;
var registry = {};

function newid() {
    return schema + clients++;
}

function oneoff() {
    return default_publisher + '___TOPIC___' + shortid.generate();
}

function subscribe(topic, handler, publisher) {
    if (!publisher) {
        publisher = default_publisher;
    }
    //console.debug('New Subscriber', topic, publisher);
    if (!registry[publisher]) {
        registry[publisher] = {};
    }
    if (!registry[publisher][topic]) {
        registry[publisher][topic] = [];
    }
    registry[publisher][topic].push(handler);
}

function publish(topic, event, publisher) {
    if (!publisher) {
        publisher = default_publisher;
    }
    //console.debug('Pulish Event', topic, event, publisher);
    if (!registry[publisher]) {
        return;
    }
    if (!registry[publisher][topic]) {
        return;
    }
    registry[publisher][topic].map((handler, k) => {
        //console.debug("Notify Event", event, k, handler);
        handler.apply(handler, [event]);
        return true;
    });
}

module.exports.pubsub = {
    newid,
    oneoff,
    subscribe,
    publish
};