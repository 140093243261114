import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Hidden } from '@material-ui/core';
import Text from './Text';
import Icon from './Icon';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(3),
        backgroundColor: theme.customPalette.greyClear,
    },
    headerWrapper: {
        marginTop: 'auto', // FIX ** align vertical
        marginBottom: 'auto'
    },
    header: {
        display: 'table', // FIX ** necessary to vertical align text with icon
        flexDirection: 'row',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            paddingBottom: theme.spacing(2)
        },
    },
    title: {
        //fontSize: 20 // FIX ** font sizing in theme... local override only if strictly necessary
        display: 'table-cell', // FIX ** necessary to vertical align text with icon
        verticalAlign: 'middle',
        transform: 'translate3d(0, -7px, 0)'
    },
    textWrapper: {
        paddingLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        },
    },
    linkButton: {
        width: 'fit-content',
        marginTop: theme.spacing(2)
    },
    icon: {
        height: 56,
        width: 56,
        flexShrink: 0,
        marginRight: theme.spacing(3)
    }
}));


const InfoBox = ({ className, title, text, link, tracking }) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.root, className)}>
            <Grid className={classes.container} container>
                <Grid sm={12} md={4} item className={classes.headerWrapper}>
                    <Box className={classes.header}>
                        <Icon className={classes.icon} name='help' />
                        <Text className={classes.title} component="div" variant='titleL' weight='bold'>{title}</Text>
                    </Box>
                </Grid>
                {text &&
                    <>
                        <Hidden smDown>
                            <Divider orientation='vertical' flexItem />
                        </Hidden>
                        <Grid sm={12} md={7} item>
                            <Box className={classes.textWrapper} display='flex' flexDirection='column'>
                                <Text variant='bodyS'>{text}</Text>
                                {link && link.title && <Button
                                    className={classes.linkButton}
                                    size='large'
                                    {...link}
                                    trackingObj={tracking}
                                    trackingAction="click_link"
                                    trackingLabel={"InfoBox - "+(link?.title || "")}
                                >
                                    {link.title}
                                </Button>
                                }
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    );
};

export default InfoBox;
