import React from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core"

import Module from "../components/Module"
import Icon from "../components/Icon"
import Text from "../components/Text"
import FadeInContainer from "../animations/FadeInContainer"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import GrowthContainer from "../animations/GrowthContainer"
import FluidImage from "../components/FluidImage"
import { showGuides } from "@src/utils/debug"
const parallaxHeight = 450
const useStyles = makeStyles(theme => ({
  figureSpecs: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
      marginBottom: "initial",
    },
  },
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  content: {
    border: showGuides("4px solid cyan"), // FIX ** guides
    position: "absolute",
    top: "50%" /* position the top  edge of the element at the middle of the parent */,
    transform: "translate3d(0, -50%, 0)",
    display: "flex",
    backgroundColor: "black",
    width: "100%",
    padding: theme.spacing(0, 0, 0, 5), // FIX ** to check later
    //boxShadow: theme.shadows[2],
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      padding: theme.spacing(0, 2, 0, 2),
      textAlign: "center",
      flexDirection: "column",
      transform: "initial",
      minHeight: "auto !important",
    },
  },
  contentShadow: {
    opacity: 0,
    border: showGuides("4px solid cyan"), // FIX ** guides
    position: "relative", // FIX ** shadow
    transform: "translate3d(0, 0, 0)",
    display: "flex",
    backgroundColor: "black",
    width: "100%",
    padding: theme.spacing(4, 0, 4, 0), // FIX ** shadowing also padding to push up and down
    //boxShadow: theme.shadows[2],
  },
  textContainer: {
    width: "fit-content",
    maxWidth: "40%",
    height: "100%",
    padding: theme.spacing(4, 0, 4, 0),
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: "auto !important",
    },
  },
  title: {
    //paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  info: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  infoItem: {
    padding: theme.spacing(0.5, 3, 0.5, 0),
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    color: "white",
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  specs: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    padding: theme.spacing(0, 4, 0, 4),
    marginLeft: -theme.spacing(4),
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      alignSelf: "initial",
      flexDirection: "column",
      paddingBottom: theme.spacing(4),
      margin: "unset",
    },
  },
  spec: {
    display: "flex",
    flexDirection: "column",
    borderColor: theme.customPalette.charcoal,
    borderStyle: "solid",
    borderWidth: "0 0 0 0",
    paddingRight: theme.spacing(2),
    paddingBottom: 15,
    marginTop: "auto", // FIX ** push to bottom
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
      borderWidth: "1px 0 1px 0",
      position: "relative",
      flexDirection: "row",
    },
    "&:nth-child(odd)": {
      [theme.breakpoints.down("sm")]: {
        marginTop: -1,
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 0,
        paddingRight: theme.spacing(2),
        borderWidth: "0 1px 0 0",
      },
    },
    "&:nth-child(even)": {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(2),
      },
    },
  },
  noBorder: {
    borderWidth: "0 !important",
  },
  specLabel: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
    },
  },
  specValue: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 20,
    },
  },
  sizeImgContainer: {
    border: showGuides("2px solid yellow"), // FIX ** guides
    display: "flex",
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    position: "absolute", // FIX ** this need to be absoute otherwise there's no way to put an horizontal image (!!)
    maxWidth: "70%",
    maxHeight: 800, // FIX ** we need a fixed point
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "fit-content",
      margin: "auto",
      transform: "translate3d(0, 0, 0)",
      top: "unset",
      maxWidth: "100%",
      paddingBottom: theme.spacing(4),
    },
  },
  sizeImgContainerShadow: {
    top: 0,
    position: "relative",
    transform: "translate3d(0, 0, 0)",
  },
  contentTall: {
    paddingTop: theme.spacing(0), // FIX ** reset padding push up and down for tall image
    paddingBottom: theme.spacing(0), // FIX ** reset padding push up and down for tall image
  },
  sizeTall: {
    right: "5%",
    [theme.breakpoints.down("sm")]: {
      right: "unset",
    },
  },
  sizeWide: {
    right: "-5%",
    marginTop: "-3%",
    [theme.breakpoints.down("sm")]: {
      right: "0%",
    },
  },
  imgContainer: {
    border: showGuides("2px solid magenta"),
    display: "flex",
    maxWidth: "100%",
    //transform: 'translate3d(0,-50%,0)',
    //marginRight: imgContainerMarginRight,
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
  },
  imgWrapper: {
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
    textAlign: "right", // FIX ** push right for safari
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
    },
  },

  imgContainerShadow: {
    position: "relative",
  },
  img: {
    border: showGuides("3px solid blue"), // FIX ** guides
    minWidth: 200,
    maxWidth: 800,
    width: "fit-content",
    height: "100%",
  },
  imgInner: {
    maxHeight: 800,
    minHeight: 400, // FIX ** we need an anchor point somewhere
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
  },
  imgShadow: {},
  dimensionContainer: {
    backgroundColor: theme.customPalette.mediumGrey,
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.bold,
    color: "white",
    fontWeight: 700,
    padding: theme.spacing(0, 1),
    //width: 'fit-content',
    borderRadius: 3,
    zIndex: 1,
    position: "absolute",
    whiteSpace: "nowrap",
  },
  dimensionVerticalLine: {
    opacity: 0,
    height: "0%",
    position: "relative",
    borderRight: `2px solid ${theme.customPalette.mediumGrey}`,
    "&:before": {
      top: "-10px",
      display: "inline-block",
      left: "-4px",
      width: "0",
      height: "0",
      content: "''",
      position: "absolute",
      borderLeft: "5px solid transparent",
      borderTop: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: `12px solid ${theme.customPalette.mediumGrey}`,
    },
    "&:after": {
      bottom: "-10px",
      display: "inline-block",
      left: "-4px",
      width: "0",
      height: "0",
      content: "''",
      position: "absolute",
      borderLeft: "5px solid transparent",
      borderTop: `12px solid ${theme.customPalette.mediumGrey}`,
      borderRight: "5px solid transparent",
      borderBottom: "5px solid transparent",
    },
  },
  dimensionHorizontalLine: {
    opacity: 0,
    width: "0%",
    position: "relative",
    borderBottom: `2px solid ${theme.customPalette.mediumGrey}`,
    "&:before": {
      left: "-10px",
      display: "inline-block",
      top: "-4px",
      width: "0",
      height: "0",
      content: "''",
      position: "absolute",
      borderLeft: "5px solid transparent",
      borderTop: "5px solid transparent",
      borderBottom: "5px solid transparent",
      borderRight: `12px solid ${theme.customPalette.mediumGrey}`,
    },
    "&:after": {
      right: "-10px",
      display: "inline-block",
      top: "-4px",
      width: "0",
      height: "0",
      content: "''",
      position: "absolute",
      borderTop: "5px solid transparent",
      borderLeft: `12px solid ${theme.customPalette.mediumGrey}`,
      borderRight: "5px solid transparent",
      borderBottom: "5px solid transparent",
    },
  },
  rotate: {
    // transform: "rotate3d(0,0,1,-90deg)",
  },
  height: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingTop: "2%",
    paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5%",
      paddingBottom: "5%",
    },
  },
  widthTranslate: {
    transform: "translate(0, -50%)",
  },
  parallaxWrapper: {
    position: "absolute",
    right: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
    /*bottom: 0,
        left: theme.spacing(8),
        right: '-25vw',
        margin: 'auto',
        height: '100%',
        zIndex: -1,*/
  },
  parallax: {
    //height: `${parallaxHeight}px !important`,
    zIndex: -1,
  },
  width: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    bottom: theme.spacing(7),
    height: 30,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(0),
      marginTop: "10px",
    },
  },
}))

const Spec = ({ className, label, value }) => {
  const classes = useStyles()

  return (
    <Box className={clsx(className, classes.spec)}>
      <Text
        className={classes.specLabel}
        variant="bodyM"
        color="textTertiary"
        weight="bold"
      >
        {label}
      </Text>
      <Text className={classes.specValue} variant="bodyM" color="textTertiary">
        {value}
      </Text>
    </Box>
  )
}

const ImageContainer = ({ picture, height, width, shadow }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box
      className={clsx(
        classes.sizeImgContainer,
        { [classes.sizeImgContainerShadow]: shadow },
        { [classes.sizeTall]: height },
        { [classes.sizeWide]: !height }
      )}
    >
      <Box
        className={clsx(classes.imgContainer, {
          [classes.imgContainerShadow]: shadow,
        })}
      >
        <Box className={classes.imgWrapper}>
          <Box className={classes.parallaxWrapper}>
            <Parallax
              className={classes.parallax}
              /*blur={0}*/
              amount={0.35}
            >
              <div style={{ height: parallaxHeight, width: "100%" }}></div>
            </Parallax>
          </Box>
          <ParallaxItem disabled={isMobile} y={["8", "-2"]}>
            <FluidImage
              className={clsx(classes.img, { [classes.imgShadow]: shadow })}
              innerClassName={classes.imgInner}
              {...picture}
            ></FluidImage>
          </ParallaxItem>
          {width && (
            <Box>
              <Box className={classes.width}>
                <Box
                  className={clsx(
                    classes.dimensionContainer,
                    classes.widthTranslate
                  )}
                >
                  {width}
                </Box>
                <GrowthContainer fromH={30} toH={30} fromW={"0%"} toW={"100%"}>
                  <Box className={classes.dimensionHorizontalLine}></Box>
                </GrowthContainer>
              </Box>
            </Box>
          )}
        </Box>
        {height && (
          <Box className={classes.height}>
            <Box className={clsx(classes.dimensionContainer, classes.rotate)}>
              {height}
            </Box>
            <GrowthContainer fromH={"0%"} toH={"100%"}>
              <Box className={classes.dimensionVerticalLine}></Box>
            </GrowthContainer>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const FigureSpecsModule = props => {
  const {
    title,
    subtitle,
    description,
    specs,
    picture,
    pictureMobile,
    firstSpec,
    secondSpec,
    height,
    width,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const empty = spec => !spec || !spec.label || !spec.value

  // Handle height specs in desktop
  const [specsMinHeight, setSpecsMinHeight] = React.useState("auto")
  const ref = React.useRef(null)
  const handleMinHeight = () => {
    if (ref?.current) {
      const { height } = ref?.current?.getBoundingClientRect()
      setSpecsMinHeight(`${height}px`)
    }
  }
  React.useLayoutEffect(() => {
    window.addEventListener("resize", handleMinHeight)
    return () => window.removeEventListener("resize", handleMinHeight)
  }, [])
  // END Handle height specs in desktop

  const Specs = () => (
    <Box className={classes.specs}>
      {!empty(firstSpec) && (
        <Spec
          className={clsx({ [classes.noBorder]: empty(secondSpec) })}
          label={firstSpec.label}
          value={firstSpec.value}
        />
      )}
      {!empty(secondSpec) && (
        <Spec label={secondSpec.label} value={secondSpec.value} />
      )}
    </Box>
  )

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const Content = ({ shadow }) => (
    <Box
      className={clsx(
        classes.content,
        { [classes.contentShadow]: shadow },
        { [classes.contentTall]: height }
      )}
      style={{ minHeight: specsMinHeight }}
    >
      {/* Desktop content */}
      <Hidden smDown>
        {!shadow && (
          <>
            <div className={classes.textContainer} ref={ref}>
              <Text
                className={classes.title}
                variant="titleXXXL"
                color="textTertiary"
                align="left"
                weight="bold"
                overline
                gutterBottom
              >
                {title}
              </Text>
              <Text
                className={classes.subtitle}
                variant="bodyLhM"
                color="textTertiary"
                align="left"
                weight="bold"
              >
                {subtitle}
              </Text>
              {description && (
                <Text
                  className={classes.description}
                  variant="bodyLhM"
                  color="textTertiary"
                  align="left"
                >
                  {description}
                </Text>
              )}
              <List className={classes.info}>
                {(specs || []).map((el, i) => (
                  <ListItem key={i} className={classes.infoItem}>
                    <ListItemIcon>
                      <Icon
                        className={classes.bulletIcon}
                        name="bullet"
                        onLoad={handleMinHeight}
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.infoText} primary={el} />
                  </ListItem>
                ))}
              </List>
            </div>
            <Specs />
          </>
        )}

        {!isMobile && picture && (
          <ImageContainer
            picture={picture}
            height={height}
            width={width}
            shadow={shadow}
          />
        )}
      </Hidden>

      {/* Mobile content */}
      <Hidden mdUp>
        {!shadow && (
          <>
            <div className={classes.textContainer}>
              <Text
                className={classes.title}
                variant="titleXXXL"
                color="textTertiary"
                align="left"
                weight="bold"
                overline
                gutterBottom
              >
                {title}
              </Text>
              <Text
                className={classes.subtitle}
                variant="bodyLhM"
                color="textTertiary"
                align="left"
                weight="bold"
              >
                {subtitle}
              </Text>
              {description && (
                <Text
                  className={classes.description}
                  variant="bodyLhM"
                  color="textTertiary"
                  align="left"
                >
                  {description}
                </Text>
              )}
            </div>
          </>
        )}
      </Hidden>

      <Hidden mdUp>
        {!shadow && (
          <>
            <div className={classes.textContainer}>
              <List className={classes.info}>
                {(specs || []).map((el, i) => (
                  <ListItem key={i} className={classes.infoItem}>
                    <ListItemIcon>
                      <Icon className={classes.bulletIcon} name="bullet" />
                    </ListItemIcon>
                    <ListItemText className={classes.infoText} primary={el} />
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        )}
      </Hidden>
      {isMobile && pictureMobile && (
        <ImageContainer
          picture={pictureMobile}
          height={height}
          width={width}
          shadow={shadow}
        />
      )}
    </Box>
  )

  return (
    <Module
      className={classes.figureSpecs}
      classes={{ container: classes.container }}
    >
      {/* Shadow */}
      <Hidden smDown>
        <Content shadow />
      </Hidden>
      <Content />
    </Module>
  )
}

FigureSpecsModule.defaultProps = {
  title: "",
  description: "",
  height: "",
  width: "",
}

export default FigureSpecsModule
