import React, { useRef, useEffect } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Grid,
  Hidden,
  Box,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import shortid from "shortid"
import Slider from "./Slider"

import Text from "./Text"
import Icon from "./Icon"
import FluidImage from "./FluidImage"

const useStyles = makeStyles(theme => ({
  giftOverlay: {
    backgroundColor: "rgba(59,65,84,.8)",
    zIndex: "999999 !important",
    "& .MuiDialog-paper": {
      backgroundColor: "transparent",
      boxShadow: "unset",
    },
    "& .slick-list": {
      padding: "0 20px",
    },
    "& .slick-list, & .slick-track": {
      height: "auto !important",
      minHeight: "calc(100vh - 200px) !important",
    },
    "& .slick-slide": {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
      "& > div": {
        height: "auto !important",
      },
    },
  },
  appBar: {
    position: "relative",
    padding: "0 8px 0 8px !important",
    background: "white",
    borderBottom: `1px solid ${theme.customPalette.greyClear}`,
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  appBarSticky: {
    top: 0,
    left: "auto",
    right: 0,
    width: "100%",
    zIndex: 1100,
    position: "sticky",
    padding: "0 30px 0 30px !important",
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  body: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  icon: {
    height: 24,
    width: 24,
  },
  image: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "99%",
      marginLeft: "-19px",
    },
    display: "block",
    margin: "auto",
  },
  slider: {
    backgroundColor: "transparent",
  },
  header: {
    justifyContent: "end",
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Image = ({ image }) => {
  const classes = useStyles()
  return (
    <Grid alignContent="center" spacing={2} container>
      <Grid xs={12} item>
        <FluidImage className={classes.image} {...image} />
      </Grid>
    </Grid>
  )
}

const ImageGalleryOverlay = React.forwardRef(function ImageGalleryOverlay(
  props,
  ref
) {
  const { className, items, stickyHeader, tracking, ...other } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = React.useState(false)
  const [initialPos, setInitialPos] = React.useState(false)

  const sliderSettings = {
    tracking: tracking,
    dots: false,
    infinite: false,
    arrows: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.slider,
  }

  React.useImperativeHandle(ref, () => ({
    open(index) {
      setInitialPos(index)
      setOpen(true)
    },
  }))

  return (
    <Dialog
      className={clsx(className, classes.giftOverlay)}
      fullScreen={isMobile}
      fullWidth
      keepMounted
      maxWidth="lg"
      PaperProps={{
        square: true,
      }}
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar
        className={!!stickyHeader ? classes.appBarSticky : classes.appBar}
        elevation={0}
      >
        <Toolbar className={classes.header} disableGutters>
          <Text
            variant="title"
            color="textPrimary"
            className={classes.title}
          ></Text>
          <IconButton
            edge="start"
            size="small"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <Icon className={classes.icon} name="close_white" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box>
        {items && items.length > 0 ? (
          <Slider initialPos={initialPos} {...sliderSettings}>
            {items.map(item => (
              <div>
                <Hidden mdUp>
                  <Image
                    key={shortid.generate()}
                    image={{
                      title:
                        item?.field_value?.image_mobile?.field_value?.title,
                      alt: item?.field_value?.image_mobile?.field_value?.alt,
                      sources:
                        item?.field_value?.image_mobile?.field_value?.sources,
                    }}
                  />
                </Hidden>
                <Hidden smDown>
                  <Image
                    key={shortid.generate()}
                    image={{
                      title: item?.field_value?.image?.field_value?.title,
                      alt: item?.field_value?.image?.field_value?.alt,
                      sources: item?.field_value?.image?.field_value?.sources,
                    }}
                  />
                </Hidden>
              </div>
            ))}
          </Slider>
        ) : null}
      </Box>
    </Dialog>
  )
})

export default ImageGalleryOverlay
