import React from 'react';
import { useRef } from 'react';
import clsx from 'clsx';
import txt from '@src/utils/txt';
import dom from '@src/utils/dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box, Hidden } from '@material-ui/core';
import Module from '../components/Module';
import Parallax from '../components/Parallax';
import Text from '../components/Text';
import Stamp from '../components/Stamp';
import FluidImage from '../components/FluidImage';
import { Parallax as ParallaxItem } from 'react-scroll-parallax';

const useStyles = makeStyles((theme) => ({
    premiumSubscription: {},
    container: {
        position: 'relative',
        overflow: 'hidden',
        //minHeight: '400px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: 'auto',
            minHeight: 'unset',
            maxHeight: 'unset',
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0
        },
    },
    innerContainer: {
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            paddingLeft: 0,
            paddingRight: 0
        },
    },
    wrappedContent: {
        position: 'absolute',
        top: '50%',
        transform: 'translate3d(0,-50%,0)'
    },
    shadowContent: {
        visibility: 'hidden',
        opacity: 0,
    },
    shadow: {
        position: 'relative !important',
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        width: '50%',
        marginLeft: 'auto',
        float: 'right',
        padding: theme.spacing(4, 4),
        [theme.breakpoints.down('sm')]: {
            float: 'none',
            height: 'auto',
            width: '100%',
            margin: 0,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(4, 2),
        },
    },
    parallaxWrapper: {
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        /*display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",*/
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            //position: "relative", // FIX **
            //maxHeight: '100%'
        },
    },
    overlayEffectWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        minHeight: 1000,
        //background: 'rgba(255,255,255,0.2)',
        top: 0,
    },
    overlayEffect: {
        position: 'absolute',
        height: '100px',
        width: '100%',
        background: 'white',
        bottom: 0
    },
    parallax: {
        //height: '100%',
        '& .parallax-inner': {
            zIndex: -1
        },
        [theme.breakpoints.down('sm')]: {
            //position: 'absolute',
            width: '100%',
            //height: '100% !important',
            zIndex: -1,
            //maxHeight: '1085px',
        }
    },
    parallaxItemWrapper: {
        position: 'absolute',
        top: '50%',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            top: 'unset',
            width: '90%',
            margin: '0 auto',
            paddingBottom: theme.spacing(4)
        }
        //top: '50%',
        //transform: 'translate3d(0,-50%,0)'
    },
    imageWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        width: 'fit-content',
        margin: '0 auto',
        maxHeight: 652,
        transform: 'translate3d(0,-50%,0)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            transform: 'translate3d(0,0,0)',
            //maxHeight: 530,
        },
    },
    stamp: {
        width: 115,
        height: 115,
        //fontSize: 18,
        left: 45,
        bottom: 0,
        ...theme.fonts.faces.bodyS,
        ...theme.fonts.weights.bold,
        lineHeight: '1rem !important',
        [theme.breakpoints.down('sm')]: {
            width: 95,
            height: 95,
            left: '0%',
            lineHeight: '1rem !important',
        },
    },
    stampWrapper: {
        position: 'absolute',
        width: '100%',
        height: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            maxHeight: 360,
        }
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            //transform: 'scale(1.1)',
        }
    },
    gridImageContainer: {
        maxHeight: 510,
    },
    gridTitle: {
        padding: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto',
        }
    },
    grid: {
        padding: theme.spacing(0, 0, 1, 0),
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        }
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        '&:before': {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },
    subtitle: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    description: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
}));

const PremimuSubscriptionModule = ({
    title,
    subtitle,
    description,
    stampText,
    backgroundImage,
    mobileBackgroundImage,
    mobileForegroundImage,
    foregroundImage
}) => {
    const classes = useStyles();
    const moduleRef = useRef(null);

    const Image = ({ image, stampText, mobileImage }) => {
        const classes = useStyles();

        const handleOnLoad = (imageRef) => {
            let target = dom.findAncestor(imageRef.current, 'parallax-outer');
            if (target) {
                if (imageRef.current.clientHeight > target.previousSibling.clientHeight) {
                    let css = `padding-top: 0 !important; padding-bottom: 0 !important;`;
                    moduleRef.current.style.cssText = css;
                }
            }
        }

        return (<div className={classes.imageWrapper}>
            <Hidden smDown>
                <FluidImage onLoad={handleOnLoad} className={classes.image} {...image} />
            </Hidden>
            <Hidden mdUp>
                {(!!mobileImage) ?
                <FluidImage onLoad={handleOnLoad} className={classes.image} {...mobileImage} />
                :
                <FluidImage onLoad={handleOnLoad} className={classes.image} {...image} />
                }
            </Hidden>
            {stampText &&
                <div className={classes.stampWrapper}>
                    <Stamp className={classes.stamp}>{txt.priceUplift(stampText, 2)}</Stamp>
                </div>
            }
        </div>);
    }

    const TextContent = ({ className }) => (
        <Box className={clsx(className, classes.body)}>
            <Grid container>
                <Grid item className={classes.gridTitle}>
                    <Text className={classes.title} component="h2" variant='titleXXXL' overline weight='bold'>
                        {title}
                    </Text>
                </Grid>
                <Grid item className={classes.grid}>
                    <Text className={classes.subtitle} component="h3" variant='titleXL'>
                        {subtitle}
                    </Text>
                </Grid>
                <Grid item className={classes.grid}>
                    <Text component="div" className={classes.description} variant='bodyM'>
                        {description}
                    </Text>
                </Grid>
            </Grid>
        </Box>
    );

    const Content = ({ shadow }) => {
        return (
            <div className={shadow ? classes.shadowContent : classes.wrappedContent}>
                <div className={classes.parallaxWrapper}>
                    <Parallax
                        className={classes.parallax}
                        bgImage={backgroundImage}
                        bgMobileImage={mobileBackgroundImage}
                        amount={0.2}
                    >
                        <Hidden mdUp>
                            <div className={classes.overlayEffectWrapper}>
                                <div className={classes.overlayEffect}></div>
                            </div>
                        </Hidden>
                    </Parallax>
                </div>

                <Container className={classes.innerContainer}>
                    <TextContent />
                    <ParallaxItem className={clsx(classes.parallaxItemWrapper, { [classes.shadow]: shadow })} styleInner={{ height: '100%' }} y={['2', '-2']}>
                        <Image stampText={stampText} mobileImage={mobileForegroundImage} image={foregroundImage}></Image>
                    </ParallaxItem>
                    <Hidden smDown>
                        <div style={{ clear: 'both' }}></div>
                    </Hidden>
                </Container>
            </div>
        )
    }

    return (
        <Module ref={moduleRef} className={classes.premiumSubscription} classes={{ container: classes.container }}>
            <Content shadow />
            <Content />
        </Module>

    );
};

export default PremimuSubscriptionModule;
