import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { useSpring, animated } from "react-spring"
import * as easings from "d3-ease"
import { pubsub } from "@src/utils/pubsub"

const useStyles = makeStyles(theme => ({
  fadeInDirection: {
    height: "100%",
    width: "auto",
  },
  hidden: {
    visibility: "hidden",
  },
}))

const FadeInDirection = ({
  className,
  shouldStartTopic,
  opacity,
  isVisible,
  delay,
  duration,
  fromX,
  toX,
  fromY,
  toY,
  children,
}) => {
  const props = useSpring({
    delay: delay,
    config: { duration: duration, easing: easings.easeExpOut },
    opacity: opacity,
    transform: `translate3d(${toX}, ${toY}, 0)`,
    from: {
      visibility: "visible",
      opacity: 0,
      transform: `translate3d(${fromX}, ${fromY}, 0)`,
    },
    reset: !isVisible,
  })

  const [shouldStart, setShouldStart] = useState(false)

  pubsub.subscribe(shouldStartTopic, event => {
    setShouldStart(true)
  })

  return (
    <>
      {shouldStart ? (
        <animated.div className={className} style={props}>
          {children}
        </animated.div>
      ) : (
        <animated.div className={className}>{children}</animated.div>
      )}
    </>
  )
}

const FadeInContainer = props => {
  const classes = useStyles()
  const {
    delay = 600,
    duration = 1500,
    toX = 0,
    fromX = 0,
    fromY = 0,
    toY = 0,
    opacity = 1,
    className,
    children,
    shouldStartTopic,
  } = props

  /*return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
                <FadeInDirection
                    className={clsx(classes.fadeInDirection, className)}
                    isVisible={isVisible}
                    duration={duration}
                    delay={delay}
                    opacity={opacity}
                    fromX={fromX}
                    toX={toX}
                    fromY={fromY}
                    toY={toY}>
                    {children}
                </FadeInDirection>
            )}
        </VisibilitySensor>
    );*/

  return (
    <FadeInDirection
      className={clsx(classes.fadeInDirection, className, classes.hidden)}
      shouldStartTopic={shouldStartTopic}
      isVisible={true}
      opacity={opacity}
      duration={duration}
      delay={delay}
      fromX={fromX}
      toX={toX}
      fromY={fromY}
      toY={toY}
    >
      {children}
    </FadeInDirection>
  )
}

export default FadeInContainer
