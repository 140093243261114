import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Hidden } from '@material-ui/core';

import Module from '../components/Module';
import Text from '../components/Text';
import GridGallery from '../components/GridGallery';

const useStyles = makeStyles((theme) => ({
    collectorsWall: {
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.customPalette.greyClear,
        padding: '80px 10vw !important',
    },
    headerWrapper: {
        width: '100%',
        marginBottom: theme.spacing(5)
    },
    galleryWrapper: {
        width: '100%',
        marginBottom: theme.spacing(1)
    },
    socialWrapper: {
        width: '100%',
        background: 'white',
        padding: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    subtitle: {
    },
    gallery: {
        width: '100%',
        gridGap: theme.spacing(1 / 2),
        gridTemplateColumns: 'repeat( 4, 1fr )',
        gridTemplateRows: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat( 2, 1fr ) !important',
        },
        '& div::before': {
            content: '""',
            paddingBottom: "100%",
            display: "inline-block",
            verticalAlign: "top"
        },
    },
    socialTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        },
    },
    background: {
        objectFit: 'cover',
        objectPosition: 'center center',
        minHeight: 'initial',
    },
    highlighted: {
    }
}));

const CollectorsWallModule = ({ title, subtitle, socialTitle, text, photos }) => {
    const classes = useStyles();

    const galleryPhoto = (photos || []).map((p) => ({ ...p.image }));
    const mobileGalleryPhoto = (photos || []).map((p) => ({
        ...p.image,
        className: p.highlighted ? classes.highlighted : '',
        gridArea: p.highlighted ? `auto / auto / span 2 / span 2 ` : 'auto  / auto / auto / auto'
    }));

    return (
        <Module className={classes.collectorsWall} variant='full' classes={{ container: classes.container }}>
            <Box className={classes.headerWrapper}>
                <Text className={classes.title} variant='titleXXXL' weight='bold' align='center' overline>{title}</Text>
                <Text className={classes.subtitle} variant='titleL' weight='medium' align='center'>{subtitle}</Text>
            </Box>
            <Box className={classes.galleryWrapper}>
                <Hidden smDown>
                    <GridGallery className={classes.gallery} photos={galleryPhoto} classes={{ background: classes.background }} />
                </Hidden>
                <Hidden mdUp>
                    <GridGallery className={classes.gallery} photos={mobileGalleryPhoto} classes={{ background: classes.background }} />
                </Hidden>
            </Box>
            <Box className={classes.socialWrapper}>
                <Text className={classes.socialTitle} variant='titleXXXL' weight='bold' align='center' color='primary' gutterBottom>{socialTitle}</Text>
                <Text className={classes.text} variant='bodyM' weight='medium' align='center'>{text}</Text>
            </Box>
        </Module>
    );
};

export default CollectorsWallModule;
