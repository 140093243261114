import React, { useState, useRef, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { window, document } from 'browser-monads';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

import Module from '../components/Module';
import Icon from '../components/Icon';
import Text from '../components/Text';
import Push from '../components/Push';


const useStyles = makeStyles((theme) => ({
    cookiePolicy: {
        display: 'none',
        outline: "3px solid rgba(0, 0, 0, 0.07)",
        //boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07) inset",
        backgroundColor: 'white',
    },
    cookieBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
        color: '#000',
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(3, 2, 3, 2),
        },
    },
    container: {
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100%'
        }
    },
    sticky: {
        position: "fixed",
        zIndex: theme.zIndex.modal - 2,
        width: '100%',
        bottom: 0
    },
    shown: {
        display: 'block'
    },
    content: {
        display: 'grid',
        placeContent: 'center',
        gridAutoFlow: 'column',
        gridGap: '16px'
    },
    text: {
        margin: 'auto'
    },
    cookieIcon: {
        width: 40,
        height: 40
    },
    closeButton: {
        margin: 'auto',
        width: 30,
        height: 30
    },
    closeIcon: {
        margin: 'auto',
        width: 24,
        height: 24
    }
}));

const CookieBar = ({ title, text, onClose }) => {
    const classes = useStyles();
    return (
        <Box className={classes.cookieBar}>
            <Box className={classes.content}>
                <Icon className={classes.cookieIcon} name="cookie"></Icon>
                <Text className={classes.text} component="div" variant="titleL" weight="bold">{title}</Text>
                <Text className={classes.text} variant="bodyS" weight="medium">{text}</Text>
            </Box>
            <Box display="flex">
                <IconButton className={classes.closeButton} size="small" onClick={onClose}>
                    <Icon className={classes.closeIcon} name="close_purple"></Icon>
                </IconButton>
            </Box>
        </Box>
    );
}

const cookieName = 'cookie_policy_flag';
const pushId = 'push_cookie';

const hasAcceptedCookies = () => {
    let value = window.localStorage.getItem(cookieName);
    return typeof value !== "undefined" && value !== null;
};

const createCookie = () => {
    window.localStorage.setItem(cookieName, new Date());
}

const CookiePolicyModule = ({ title, text }) => {
    const dispatch = useContext(GlobalDispatchContext);
    const classes = useStyles();
    const cookieRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [pushContent, setPushContent] = useState(false);

    const removePush = () => {
        setPushContent(false);
        dispatch({ type: "updateCookieBar", payload: { show: false, height: 0 } });
    };

    const handleOnClose = () => {
        setOpen(false);
        createCookie();
        removePush();
    }

    const handleResize = () => {
        if (cookieRef.current) {
            const height = cookieRef.current.clientHeight;
            dispatch({ type: "updateCookieBar", payload: { height } });
        }
    };

    useEffect(() => {
        const notAccepted = !hasAcceptedCookies();
        setOpen(notAccepted);
        if (notAccepted) {
            setPushContent(true);
            setTimeout(() => handleResize());

        }

    }, []);

    useEffect(() => {
        if (!hasAcceptedCookies()) {
            // Remove first to avoid unhandled renders
            window.removeEventListener('resize', handleResize);
            window.addEventListener('resize', handleResize);
        }
    }, []);

    return (
        <>
            <Module ref={cookieRef} className={clsx(classes.cookiePolicy, classes.sticky, { [classes.shown]: open })} classes={{ container: classes.container }} noMargin>
                <CookieBar title={title} text={text} onClose={handleOnClose}></CookieBar>
            </Module >
            <Push show={pushContent} pushId={pushId} floatingElRef={cookieRef}></Push>
        </>
    );
};


export default CookiePolicyModule;
