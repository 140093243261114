import React from "react"
import Text from "../components/Text"
import Box from "@material-ui/core/Box"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import Module from "@src/components/Module"

const useStyles = makeStyles(theme => ({
  bannerAlertModule: {
    marginTop: "-24px !important",
    padding: `${theme.spacing(2, 0)} !important`,
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(1, 0)} !important`,
    },
  },
  title: {
    color: "#fff",
    "& + $subtitle": {
      marginTop: 5,
    },
  },
  subtitle: {
    color: "#fff",
    display: "inline-block",
  },
}))

const BannerAlertModule = ({
  title,
  subtitle,
  linecolor,
  "background-color": backgroundColor,
}) => {
  const classes = useStyles()
  const Line = () => (
    <div
      style={{ border: `1px solid ${linecolor.field_value || "transparent"}` }}
    />
  )
  return (
    <VisibilitySensor>
      <Module
        className={classes.bannerAlertModule}
        style={{
          backgroundColor: backgroundColor.field_value || "transparent",
        }}
        noMargin
      >
        <Container>
          <Line />
          <Box textAlign="center">
            {title.field_value ? (
              <Text
                className={classes.title}
                align="center"
                component="h3"
                variant="titleXXXL"
                weight="bold"
              >
                {title.field_value || ""}
              </Text>
            ) : null}
            {subtitle.field_value ? (
              <Text className={classes.subtitle} tag="div">
                {subtitle.field_value}
              </Text>
            ) : null}
          </Box>
          <Line />
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

const defaultProps = {
  title: "",
  subtitle: "",
  linecolor: "",
  "background-color": "",
}

BannerAlertModule.defaultProps = defaultProps

export default BannerAlertModule
