import React from 'react';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';

import Icon from '../components/Icon';
import Text from '../components/Text';

export const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    playButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: "49px",
        height: "49px",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.38)",
        backgroundColor: theme.customPalette.tangerine,
        borderRadius: '50%'
    },
    playText: {
        marginTop: theme.spacing(2),
        fontWeight: 500,
    },
    playIcon: {
        width: 27,
        height: 27
    },
});


const PlayButton = React.forwardRef(function PlayButton(props, ref) {

    const {
        classes,
        className,
        children,
        showPlayButtonText = false,
        onClick = () => { },
    } = props;

    const { t } = useTranslation();

    return (
        <div role="button" className={clsx(className, classes.root)} onClick={onClick}>
            <div className={classes.playButton}>
                <Icon className={classes.playIcon} name='play'></Icon>
            </div>
            {showPlayButtonText ? <Text className={classes.playText} variant='bodyM' color='textTertiary'>{t('playVideo')}</Text> : ''}
        </div>
    );

});

export default withStyles(styles, { name: 'FHPlayButton' })(PlayButton);