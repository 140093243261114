import React, { useEffect } from "react"
import Module from "../components/Module"
import { Box, Container, Grid, MenuItem } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import CtaButton from "../components/CtaButton"
import Text from "../components/Text"
import Select from "../components/Select"
import clsx from "clsx"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
//import "../style/EcommerceMonthlyItemModule.css"
import FluidImage from "../components/FluidImage"

const useStyles = makeStyles(theme => ({
  EcommerceMonthlyItemModule: {},
  title: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "#662482",
  },
  subtitleNext: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  colorAviable: {
    color: "#662482",
  },
  imageSlider: {
    height: "300px",
  },
  infoBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  infoImage: {
    width: "100px",
    height: "100px",
    backgroundColor: "#ccc",
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    color: "white",
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  price: {
    marginBottom: "24px",
  },
  boxOffer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%",
    backgroundColor: "#ccc",
    display: "flex",
    height: "10%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonAddToCart: {
    background: "white",
    color: "black",
    border: "2px solid #f29100",
    "&:disabled": {
      border: "1px solid #999999",
      backgroundColor: "#cccccc",
      color: "#000",
      pointerEvents: "none",
      backgroundImage: "none",
    },
  },
  buttonDisabled: {
    border: "1px solid #999999",
    backgroundColor: "#cccccc",
    color: "#000",
    pointerEvents: "none",
    backgroundImage: "none",
  },
  iconNext: {
    width: "168px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
  },
  w50: {
    width: "50%",
  },
  w100: {
    width: "100%",
  },
}))

const EcommerceMonthlyItemModule = props => {
  const {
    title,
    product,
    commercial_message_price,
    commercial_message_text,
    distributionType,
    indexPicture,
  } = props
  console.log("++++++++++props", props)
  const classes = useStyles()
  const [currentSelect, setCurrentSelect] = React.useState(0)
  const nextPack = currentSelect + 1
  const [figuresItem, setFiguresItem] = React.useState([])
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  const [currentSlide2, setCurrentSlide2] = React.useState(0)
  const [loaded2, setLoaded2] = React.useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    initial: 0,
    loop: true,
    breakpoints: {
      "(max-width: 900px)": {
        drag: true,
        slides: {
          perView: 2,
        },
      },
    },
    slides: {
      perView: 1,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  const [sliderRef2, instanceRef2] = useKeenSlider({
    drag: true,
    initial: 0,
    loop: true,
    breakpoints: {
      "(max-width: 900px)": {
        drag: true,
        slides: {
          perView: 2,
        },
      },
    },
    slides: {
      perView: 4,
      spaceBetween: 2,
    },
    slideChanged(slider) {
      setCurrentSlide2(slider.track.details.rel)
    },
    created() {
      setLoaded2(true)
    },
  })

  useEffect(() => {
    let array = [product?.field_value[currentSelect]?.figure_image?.field_value]
    product?.field_value[currentSelect]?.figure_gallery?.field_value.forEach(
      figure =>
        array.push(figure?.field_value?.figure_gallery_image?.field_value)
    )
    setFiguresItem(array)
  }, [])

  return (
    <VisibilitySensor>
      <Module
        className={clsx(
          classes.EcommerceMonthlyItemModule,
          "EcommerceMonthlyItem"
        )}
      >
        <Container maxWidth="md">
          <Text
            variant="titleXL"
            component="h2"
            overline
            weight="bold"
            align="center"
            className={classes.title}
          >
            {title?.field_value}
          </Text>
          {/* START BOX PREVIEW */}
          {process.env.NODE_ENV === "development" &&
            product?.field_value[currentSelect]?.figure_ecommerce_date
              ?.field_value && (
              <Box px={2} my={5} mx={"auto"} className={classes.select}>
                <Select
                  value={currentSelect}
                  onChange={select => setCurrentSelect(select?.target?.value)}
                >
                  {product.field_value.map((item, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {item?.figure_ecommerce_title?.field_value +
                          " - " +
                          item?.figure_ecommerce_date?.field_value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            )}

          {/* END BOX PREVIEW */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  ref={sliderRef}
                  className="keen-slider secure-payment-item-container"
                  style={{ marginBottom: "16px", height: "100%" }}
                >
                  {
                    //item?.field_value.map((el, i) => (
                    <>
                      {console.log("+++++++array", figuresItem)}
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        {
                          <FluidImage
                            className={classes.w100}
                            {...(product?.field_value[currentSelect]
                              ?.figure_image?.field_value ||
                              product?.field_value[indexPicture]?.field_value
                                ?.figure_image?.field_value)}
                          ></FluidImage>
                        }
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "100%" }}
                          src={"https://placehold.co/600x600?text=img2"}
                        ></img>
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "100%" }}
                          src={"https://placehold.co/600x600?text=img3"}
                        ></img>
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "100%" }}
                          src={"https://placehold.co/600x600?text=img4"}
                        ></img>
                      </div>
                    </>
                    /*))*/
                  }
                </div>

                <div
                  ref={sliderRef2}
                  className="keen-slider secure-payment-item-container"
                >
                  {
                    //item?.field_value.map((el, i) => (
                    <>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "90%" }}
                          src={"https://placehold.co/600x400?text=img1"}
                        ></img>
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "90%" }}
                          src={"https://placehold.co/600x400?text=img2"}
                        ></img>
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "90%" }}
                          src={"https://placehold.co/600x400?text=img3"}
                        ></img>
                      </div>
                      <div
                        //key={i}
                        className="keen-slider__slide secure-payment-item-slide"
                      >
                        <img
                          style={{ width: "90%" }}
                          src={"https://placehold.co/600x400?text=img4"}
                        ></img>
                      </div>
                    </>
                    /*))*/
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text
                variant="titleXXL"
                className={classes.subtitle}
                component="h2"
                weight="bold"
              >
                {product?.field_value[currentSelect]?.figure_ecommerce_title
                  ?.field_value ||
                  product?.field_value[indexPicture]?.field_value?.figure_title
                    ?.field_value}
              </Text>
              <Text
                variant="titleL"
                weight="bold"
                tag="span"
                className={classes.price}
              >
                {product?.field_value[currentSelect]?.figure_ecommerce_price
                  ?.field_value ||
                  product?.field_value[indexPicture]?.field_value[
                    `figure_${distributionType}_price`
                  ]?.field_value}
              </Text>
              <Text
                className="boxDescription"
                dangerouslySetInnerHTML={{
                  __html:
                    product?.field_value[currentSelect]
                      ?.figure_ecommerce_description?.field_value ||
                    product?.field_value[indexPicture]?.field_value
                      ?.figure_description?.field_value,
                }}
              ></Text>
              <Box className={classes.boxOffer}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: commercial_message_text?.field_value,
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: commercial_message_price?.field_value,
                  }}
                />
              </Box>
              <Box className={classes.containerButton}>
                <CtaButton
                  size="large"
                  data-sku={
                    product?.field_value[currentSelect]?.figure_ecommerce_code
                      ?.field_value ||
                    product?.field_value[indexPicture]?.field_value[
                      `figure_${distributionType}_code`
                    ]?.field_value
                  }
                  className={clsx(
                    classes.buttonAddToCart,
                    "add-cart-item-trigger",
                    classes.w50
                  )}
                >
                  {product?.field_value[currentSelect]?.figure_cta_addtocart
                    ?.field_value ||
                    product?.field_value[indexPicture]?.field_value
                      ?.figure_cta_addtocart?.field_value}
                </CtaButton>
                <CtaButton
                  size="large"
                  className={clsx(classes.w50, classes.buttonDisabled)}
                >
                  <Text>Subscribe now</Text>
                </CtaButton>
              </Box>
              <Box className={classes.infoBlock}>
                <Box>
                  <Text>Coming Next month:</Text>
                  <Text
                    variant="titleL"
                    weight="bold"
                    tag="span"
                    className={classes.subtitleNext}
                  >
                    {product?.field_value[nextPack]?.figure_ecommerce_title
                      ?.field_value ||
                      product?.field_value[indexPicture + 1]?.field_value
                        ?.figure_title?.field_value}
                  </Text>
                  <Text className={classes.colorAviable}>
                    {"Aviable " +
                      product?.field_value[nextPack]?.figure_ecommerce_date
                        ?.field_value}
                  </Text>
                </Box>
                <img
                  className={classes.iconNext}
                  src={
                    product?.field_value[nextPack]?.figure_image?.field_value
                      ?.src ||
                    product?.field_value[indexPicture + 1]?.field_value
                      ?.figure_image?.field_value?.src
                  }
                ></img>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default EcommerceMonthlyItemModule
