import React from 'react';
import clsx from 'clsx';
import { webpSort } from '@src/utils/webp';
import shortid from 'shortid';

const PictureSchema = {
  title: 'image title',
  alt: 'image alt',
  src: '/assets/img/placeholder.jpg',
  webp: '/assets/img/placeholder.webp',
};

const Picture = ({ title, alt, src, sources, className, style, imgClassName }) => {

  return (
    <picture style={style} className={className}>
      {(webpSort(sources) || []).map(({ srcset, media, type }) => (
        <source key={shortid.generate()} data-srcset={srcset} media={media} type={type} />
      ))}
      <img width="100%" data-src={src} alt={alt} title={alt} className={clsx(imgClassName, 'lazyload')} />
    </picture>
  );
};

export default Picture;
export { PictureSchema };
