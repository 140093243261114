import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Module from '@src/components/Module';

const useStyles = makeStyles((theme) => ({
    prelaunchPrefooter: {
        width: '100%',
        backgroundColor: 'white',
        //paddingTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            //transform: `translate3d(0, ${theme.spacing(4)}px, 0)`
        },
        [theme.breakpoints.only('xs')]: {
            overflow: 'hidden'
        }
    },
    image: {
        width: '100%',
        position: 'relative',
        transform: 'translate3d(0,10px,0)',
        [theme.breakpoints.only('xs')]: {
            width: '200%',
            marginLeft: '-50%'
        }
    },
}));

const PrelaunchPrefooterModule = () => {
    const classes = useStyles();

    return (
        <>
        <Module className={classes.prelaunchPrefooter} variant="full" noMargin>
            <img className={classes.image} src={`/img/prelaunch/prefooter.png`} alt="" />
        </Module>
        </>
    );
};

export default PrelaunchPrefooterModule;
