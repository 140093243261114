import React from 'react';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';

export const styles = (theme) => ({
    gridGallery: {
        display: 'grid',
        gridGap: theme.spacing(1),
        gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
        gridTemplateRows: 'repeat( auto-fit, 22vw )',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr !important',
            gridGap: 'initial',
            gridRowGap: theme.spacing(1),
            gridTemplateRows: 'repeat( auto-fit, 40vw )',
        },
    },
    background: {
        height: '100%',
        width: "100%",
        backgroundSize: 'cover',
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        minHeight: '22vw',
        [theme.breakpoints.down('sm')]: {
            minHeight: '40vw',
        },
    }
});

const GridGallery = React.forwardRef(function GridGallery(props, ref) {
    const {
        classes,
        className,
        photos
    } = props;

    return (
        <div className={clsx(className, classes.gridGallery)}>
            {(photos || []).map(({ className, gridArea, src, ...others }, i) => (
                <div key={`photo_${i}`}
                    className={clsx(classes.background, className)} {...others}
                    style={{
                        backgroundImage: `url(${src ? src : ''})`,
                        gridArea: gridArea ? gridArea : 'initial'
                    }}
                />
            ))}
        </div>
    );
});

export default withStyles(styles, { name: 'FHGridGallery' })(GridGallery);