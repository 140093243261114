import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Module from '../components/Module';
import FluidImage from '../components/FluidImage';
import Text from '../components/Text';

const useStyles = makeStyles((theme) => ({
  benefits: {},
  benefitContainer: {
    //marginTop: theme.spacing(5), // FIX ** no margin if possibile inside modules --> use module global margin
    padding: theme.spacing(2, 4, 2, 4),
    backgroundColor: theme.customPalette.greyClear,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {},
  },
  benefitGrid: {
    [theme.breakpoints.only('xs')]: {
      // width: '100%',
      // backgroundColor: '#fff',
      // border: '1px solid',
      // borderColor: theme.palette.grey[200],
    },
  },
  benefitElement: {
    [theme.breakpoints.only('xs')]: {
      // border: '1px solid',
      padding: theme.spacing(2),
      // flexDirection: 'column',
    },
  },
  benefitIcon: {
    width: 57,
    height: 57,
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.only('xs')]: {

    }
  },
  title: {
    marginBottom: theme.spacing(4), // FIX ** 8 was too much
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

const BenefitsModule = ({ title, items }) => {
  const classes = useStyles();

  return (
    <Module className={classes.benefits}>
      <Box className={classes.benefitContainer}>
        <Text className={classes.title} overline align='center' component="h2" variant='titleXXXL' weight='bold'>
          {title}
        </Text>
        <Grid container className={classes.benefitGrid}>
          {(items || []).map((el, i) => (
            <Grid key={i} item xs={6} sm className={classes.benefitElement}>
              <Box display='flex' justifyContent='center'>
                <FluidImage className={classes.benefitIcon} {...el.icon} />
              </Box>
              <Text variant="titleL" component="div" color="primary" weight="bold">{el.text}</Text>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Module>
  );
};

const defaultProps = {
  title: '',
  items: [],
};

BenefitsModule.defaultProps = defaultProps;

export default BenefitsModule;
