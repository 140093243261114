import React from "react"
import Icon from "../components/Icon"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import Module from "../components/Module"
import Text from "../components/Text"

const useStyles = makeStyles(theme => ({
  quote: {},
  containerBox: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
    margin: "0 auto",
  },
  imgQuotation: {
    paddingTop: "8px",
    paddingBottom: "8px",
    [theme.breakpoints.down("md")]: {
      width: "31px",
    },
  },
  textName: {
    color: "#662482",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21",
  },
  textTitle: {
    fontSize: "30px",
    lineHeight: "34px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "40px",
      lineHeight: "44px",
    },
    color: "#000000",
    fontWeight: 500,
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
  },
  fontWeight400: {
    fontWeight: 400,
  },
}))

const QuoteModule = ({ quote, name_person_quote, subname_quote, repo }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Module className={classes.quote}>
      <Box className={classes.containerBox}>
        <div style={{ textAlign: "center" }}>
          <Icon className={classes.imgQuotation} name="quotation-up" />
        </div>
        <Text
          className={classes.textTitle}
          component="p"
          align="center"
          dangerouslySetInnerHTML={{ __html: quote.field_value }}
        />
        <div style={{ textAlign: "center" }}>
          <Icon className={classes.imgQuotation} name="quotation-down" />
        </div>
      </Box>
      <Box style={{ paddingTop: "32px" }}>
        <Text
          className={classes.textName}
          component="p"
          align="center"
          variant="titleXL"
          dangerouslySetInnerHTML={{ __html: name_person_quote.field_value }}
        />
      </Box>
      <Box style={{ paddingTop: "8px" }}>
        <Text
          className={classes.fontWeight400}
          component="p"
          align="center"
          variant="titleL"
          dangerouslySetInnerHTML={{ __html: subname_quote.field_value }}
        />
      </Box>
    </Module>
  )
}

export default QuoteModule
