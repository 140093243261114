import React from 'react';
import clsx from 'clsx';
import { Container, Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"

import Text from '@src/components/Text';
import Stamp from '@src/components/Stamp';
import Module from '@src/components/Module';

const useStyles = makeStyles((theme) => ({
    hilights: {
        position: 'relative',        
        width: '84%',
        maxWidth: '84%',
        margin: '0 auto',
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '95%',
        }
    },
    titleWrapper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
        textAlign: 'center',
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(8),
        }
    },
    caption: {
        fontFamily: 'SpeziaExtended',
        textTransform: 'uppercase',
        color: '#f19110',
        fontSize: '1.05rem !important',
        fontWeight: 'bold',
        //letterSpacing: '0.1rem',
        [theme.breakpoints.only('xs')]: {
            fontSize: '3.8vw',
        }
    },
    title: {
        fontFamily: 'SpeziaExtended',
        color: '#662482',
        width: '80%',
        margin: '1rem auto',
        fontSize: '2.4rem',
        fontWeight: 'bold',
        //lineHeight: '3.2vw',
        [theme.breakpoints.only('xs')]: {
            width: '90%',
            //fontSize: '5.6vw',
            //lineHeight: '6.1vw',
        }
    },
    subtitle: {
        textAlign: 'center',
        fontFamily: 'SpeziaExtended',
        color: '#662482',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        margin: '0 auto',
        [theme.breakpoints.only('xs')]: {
            width: '90%',
            //fontSize: '3.8vw',
        }
    },
    grid: {
    },
    gridElement: {
    },
    helpBox: {
        height: '100%',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.customPalette.greyClear,
        padding: theme.spacing(4, 3),
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2, 2, 0, 2),
        },
    },
    helpHeader: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    helpHeaderText: {
        fontFamily: 'SpeziaExtended',
        textTransform: 'uppercase',
        color: '#f19110',
        fontSize: '0.7rem !important',
        fontWeight: 'bold',
        [theme.breakpoints.only('xs')]: {
            //fontSize: '3.4vw',
        }
    },
    helpContent: {
        // padding: theme.spacing(3, 4),
        flexGrow: 1,
        textAlign: 'center',
        margin: '0 auto',
        width: '80%',        
    },
    helpContentText: {
        fontFamily: 'SpeziaExtended',
        fontSize: '1.3rem !important',
        fontWeight: 'bold',
        color: '#662482',
        [theme.breakpoints.only('xs')]: {
            //fontSize: '4.2vw',
            marginBottom: theme.spacing(4),
        }
    }
}));

const HelpBox = ({ title, text }) => {
    const classes = useStyles();

    return (
        <Box className={classes.helpBox}>
            <Box className={classes.helpHeader}>
                <Text className={classes.helpHeaderText} variant="titleXL">{title}</Text>
            </Box>
            <Box
                className={clsx(
                    classes.helpContent
                )}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <Text className={classes.helpContentText} variant="bodyM" weight="bold">{text}</Text>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const PrelaunchHilightsModule = () => {
    const classes = useStyles()

    const { t, i18n } = useTranslation()

    const stamp = {
        text: t('prelaunch.offer.stamp.text'),
        highlightedText: t('prelaunch.offer.stamp.highlightedText'),
    }

    return (
        <Module>
            <Container className={classes.hilights}>
                <Box className={classes.titleWrapper}>
                    <Text className={classes.caption} variant="titleXXL">{t('prelaunch.caption')}</Text>
                    <Text className={classes.title} variant="titleXXL">{t('prelaunch.title')}</Text>
                    <Text className={classes.subtitle} variant="bodyM">{t('prelaunch.subtitle')}</Text>
                </Box>
                <Grid className={classes.grid} container justify='space-around' spacing={2}>
                    <Grid xs={12} md item className={classes.gridElement}>
                        <HelpBox title={t('prelaunch.hilights.title1')} text={t('prelaunch.hilights.text1')} />
                    </Grid>
                    <Grid xs={12} md item className={classes.gridElement}>
                        <HelpBox title={t('prelaunch.hilights.title2')} text={t('prelaunch.hilights.text2')} />
                    </Grid>
                </Grid>
            </Container>
        </Module>
    );
};

const defaultProps = {
    
};

PrelaunchHilightsModule.defaultProps = defaultProps;

export default PrelaunchHilightsModule;
