import React, { useState } from 'react';
import { Container, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import clsx from 'clsx';
import Module from '@src/components/Module';
import Text from '@src/components/Text';
import FluidImage from '@src/components/FluidImage';
import SubscribeForm from '@src/components/SubscribeForm';
import Countdown from '@src/components/Countdown';

const useStyles = makeStyles((theme) => ({
    prelaunchHero: {
        width: '100%',
        background: 'linear-gradient(0deg, #390b45 0%, #662482 50%, rgba(102, 36, 130, 1) 85%)',
        padding: '0',
        position: 'relative',
        top: '0',
    },
    heroBannerContainer: {
        width: '100%',
        //maxWidth: '100%',
        alignItems: 'center',
        padding: '0',
        position: 'relative'
    },
    logo: {
        display: 'block',
        position: 'relative',
        zIndex: 10,
        width: 300,
        paddingTop: 200,
        margin: '0 auto',
        padding: theme.spacing(1, 9, 1, 9),
        [theme.breakpoints.down('sm')]: {
            paddingTop: 100,
            width: 250,
        }
    },
    fluidBackgroundFull: {
        backgroundSize: 'cover',
    },

    fluidBackground: {
        display: 'table',
        position: 'absolute',
        width: '100%',
        height: '400px',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        top: '0',
        right: '0',
        left: '0',
        WebkitMaskImage: "-webkit-linear-gradient(rgba(0,0,0,1) 35%, rgba(0,0,0,0) 70%)",
        maskImage: "linear-gradient(rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)",
        [theme.breakpoints.down('sm')]: {
            backgroundSize: '135%',
            height: '56vw',
        },
    },
    picture: {
        maxHeight: '100%',
        maxWidth: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        zIndex: 2
    },
    gradientFix: {
        position: 'absolute',
        zIndex: 0,
        top: 0,
        width: '100%',
        height: '60vh',
        backgroundImage: 'linear-gradient(0deg, rgba(92, 34, 117, 0.5) 0%, rgba(99, 33, 127, 1) 30%, rgba(102, 36, 130, 0.9) 80%, rgba(102, 36, 130, 0) 100%)'
    },
    h1: {
        color: 'white',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '4vw',
        maxWidth: '65%',
        margin: '0 auto 20px auto',
        fontWeight: '400',
        lineHeight: '4vw',
        paddingTop: '25px',
        position: 'relative',
        zIndex: 10,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '90%',
            fontSize: '7.7vw',
            lineHeight: '8vw',
            paddingTop: '15px',
        }
    },
    intro: {
        width: '50%',
        color: 'white',
        textAlign: 'center',
        margin: '20px auto',
        padding: '0 10px',
        fontSize: '1.6vw',
        [theme.breakpoints.only('xs')]: {
            fontSize: '4vw',
            width: '80%',
        }
    },
    contentWrapper: {
        width: '50%',
        margin: '0 auto',
        paddingTop: '1vw',
        position: 'relative',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    logoboxContainer: {
        height: 140,
        width: '84%',
        display: 'block',
        margin: '0 auto',
        background: 'white',
        borderRadius: '5px',
        transform: 'translate3d(0, 50%, 0)',
        position: 'relative',
        filter: 'drop-shadow(0 0 0.65rem rgba(0,0,0,0.3))',
        [theme.breakpoints.only('xs')]: {
            width: '95%',
            height: '15vw',
        }
    },
    logobox: {
        width: '90%',
        margin: 'auto auto',
        position: 'absolute',
        top: '53%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
    }
}));

const PrelaunchHeroModule = ({ site }) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation()



    return (
        <Module className={classes.prelaunchHero} variant="full" noMargin>
            <div className={clsx(classes.fluidBackground, classes.fluidBackgroundFull)} style={{
                backgroundImage: `url(/img/prelaunch/${site}/hero-bkg.jpg)`
            }}></div>
            <Container className={classes.heroBannerContainer}>
                <div className={classes.fluidBackground} style={{
                    backgroundImage: `url(/img/prelaunch/${site}/hero.png)`
                }}></div>
                <img className={classes.logo} src='/img/prelaunch/logo.png' />
                <div>
                    <Text className={classes.h1} variant="specialTitleXXL">
                        {t('prelaunch.h1')}
                    </Text>
                    <Countdown variant="filled" date={t('prelaunch.launchDate')} />
                    <Text className={classes.intro} variant="titleXL">{t('prelaunch.intro')}</Text>
                </div>
                <div className={classes.contentWrapper}>
                    <SubscribeForm
                        audience='FH_pre-launch'
                        flags={{
                            marketing: true,
                            newsletter: true
                        }}
                    />
                </div>
                <div className={classes.logoboxContainer}>
                    <FluidImage className={classes.logobox} src={`/img/prelaunch/logobox.png`} />
                </div>
            </Container>
        </Module>
    );
};

export default PrelaunchHeroModule;
