import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { window } from 'browser-monads';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Module from '../components/Module';
import FluidImage from '../components/FluidImage';
import Text from '../components/Text';

const useStyles = makeStyles((theme) => ({
  moreProduct: {
    backgroundColor: theme.customPalette.greyClear,
    padding: theme.spacing(10, 0),
  },
  productsGrid: {
  },
  title: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  productsItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  product: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    cursor: 'pointer',
    height: 340,
    width: "100%",
    maxWidth: 692,
    padding: theme.spacing(3),
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down('sm')]: {
      height: 400,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  productImage: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxHeight: 340,
    maxWidth: 524,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      maxWidth: 228,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    width: '100%'
  },
  small: {},
  big: {
    "&$product": {
      height: 400,
      [theme.breakpoints.down('sm')]: {
        height: 400,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'row',
      },
      [theme.breakpoints.down('xs')]: {
        height: 400,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    "& $productImage": {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        maxWidth: 524,
      },
    }
  }
}));

const Product = (props) => {
  const classes = useStyles();

  const {
    variant = 'small',
    title,
    subtitle,
    link,
    backgroundImage,
    mobileBackgroundImage,
    foregroundImage,
    mobileForegroundImage
  } = props;

  const handleClick = () => {
    window.open(link.href, "_blank");
  }

  const Component = ({ bgImage, fgImage }) => (
    <Box className={clsx(classes.product, { [classes[variant]]: variant !== 'small' })} style={{ backgroundImage: `url(${bgImage ? bgImage.src : ''})` }} onClick={handleClick}>
      <FluidImage className={classes.productImage} {...fgImage} ></FluidImage>
      <Box className={classes.textWrapper} style={{ zIndex: 2 }}>
        <Text variant="specialTitleXXL" weight="bold" color="textTertiary" gutterBottom>{title}</Text>
        <Text variant="bodyM" weight="bold" color="textTertiary">{subtitle}</Text>
      </Box>
    </Box>);

  return (<>
    <Hidden smDown>
      <Component bgImage={backgroundImage} fgImage={foregroundImage}></Component>
    </Hidden>
    <Hidden mdUp>
      <Component bgImage={mobileBackgroundImage} fgImage={mobileForegroundImage}></Component>
    </Hidden>
  </>);
};

const MoreProductsModule = ({ title, products }) => {
  const classes = useStyles();

  return (
    <Module className={classes.moreProduct} noMargin>
      <Text className={classes.title} align="center" overline variant="titleXXXL">
        {title}
      </Text>
      <Grid container className={classes.productsGrid} spacing={1}>
        {(products || []).map((p, i) => (
          <Grid key={i} className={classes.productsItem} item xs={12} md={true}>
            <Product variant={'small'} {...p}></Product>
          </Grid>
        ))}
      </Grid>
    </Module>
  );
};

export default MoreProductsModule;
