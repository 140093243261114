import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import FluidImage from "../components/FluidImage"
import Text from "../components/Text"
import { GTMEvent } from "../utils/gtm"
import VisibilitySensor from "../animations/VisibilitySensor"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Lazy, Autoplay } from "swiper"
import "../style/brandModule.css"

const useStyles = makeStyles(theme => ({
  picture: {
    display: "flex",
    justifyContent: "center",
    maxHeight: 98,
    [theme.breakpoints.down("xl")]: {
      maxHeight: 88,
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: 78,
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: 60,
    },
  },
  clickable: {
    cursor: "pointer",
  },
}))

// install Swiper modules
SwiperCore.use([Lazy, Autoplay])

const swiperResponsive = {
  768: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
  1280: {
    slidesPerView: 5,
    spaceBetween: 24,
  },
  1440: {
    slidesPerView: 6,
    spaceBetween: 24,
  },
  1600: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  1980: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  2000: {
    slidesPerView: 8,
    spaceBetween: 30,
  },
}

const BrandsModule = ({ title, brands, disable_movement, ...others }) => {
  const classes = useStyles()

  return (
    <section className="BrandsModule">
      <VisibilitySensor>
        <div className="brands-module-space">
          <Text
            variant="titleXXXL"
            align="center"
            weight="bold"
            overline
            gutterBottom
            className="brands-title"
          >
            {title}
          </Text>
          <Swiper
            centerInsufficientSlides
            breakpoints={swiperResponsive}
            slidesPerView={2.8}
            spaceBetween={16}
            loop={brands.length >= 6 ? true : false}
            allowTouchMove
            autoplay={!disable_movement}
          >
            {(brands || []).map((brand, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="brand-container">
                    {brand.link.href ? (
                      <a
                        className={classes.clickable}
                        {...brand.link}
                        onClick={() =>
                          GTMEvent(
                            others.repo,
                            title || "Brands",
                            "BrandsModule - " +
                              (brand.link?.label ||
                                brand.link?.title ||
                                brand.link?.href)
                          )
                        }
                      >
                        <FluidImage
                          animationDepth={50}
                          animationDelay={index}
                          className={classes.picture}
                          {...brand.picture}
                        />
                      </a>
                    ) : (
                      <FluidImage
                        animationDepth={50}
                        animationDelay={index}
                        className={classes.picture}
                        {...brand.picture}
                      />
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </VisibilitySensor>
    </section>
  )
}

const defaultProps = {
  title: "",
  brands: [],
}

BrandsModule.defaultProps = defaultProps

export default BrandsModule
