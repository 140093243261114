import React, { useState } from "react"
import txt from "@src/utils/txt"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid, Hidden } from "@material-ui/core"

import Module from "../components/Module"
import FluidImage from "../components/FluidImage"
import Text from "../components/Text"
import Stamp from "../components/Stamp"
import { showGuides } from "@src/utils/debug"

const useStyles = makeStyles(theme => ({
  specialGift: {
    // FIX ** Do not manage margnins on single module! This breaks re-positioning
    // Move logics on root Module container and theme instead...
    //marginTop: theme.spacing(1 / 2),
    //marginBottom: theme.spacing(1 / 2)
    //paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(19),
    paddingBottom: theme.spacing(18),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
  },
  container: {
    /* FIX **
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        */
  },
  imageWrapper: {
    position: "absolute",
    display: "block",
    top: 0,
    transform: "translateY(-50%)",
    width: "auto",
    maxWidth: 640,
    height: "auto",
    border: showGuides("2px solid black"), // FIX ** guides
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "relative",
      width: "100%",
      justifyContent: "center",
      margin: "auto",
      maxWidth: 640,
      height: "100%",
      transform: "translateY(0)",
    },
  },
  imageContainer: {
    border: showGuides("3px solid green"), // FIX ** guides
    display: "flex",
    position: "absolute",
    top: "-10%", // FIX ** give image the possibility to overflow and keep centered
    left: "50%",
    transform: "translate3d(-50%,0,0)",
    width: 210,
    height: "120%", // FIX ** give image the possibility to overflow and keep centered
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      top: "auto",
      left: "auto",
      transform: "translate3d(0,0,0)",
      width: 439,
      height: "auto",
    },
  },
  image: {
    margin: "auto",
    border: showGuides("3px solid blue"), // FIX ** guides
  },
  stamp: {
    top: "55%",
    left: -10,
    maxWidth: 150,
    padding: 30,
    transform: "translate3d(0, -50%, 0)", // align to middle
    [theme.breakpoints.down("sm")]: {
      padding: 40,
      top: "50%",
      left: "50%",
      transform: "translate3d(-50%, -50%, 0)", // center
      marginLeft: -120, // FIX ** translate left with negative margin
      marginTop: 100, // FIX ** translate bottom with negative margin
    },
  },
  contentContainer: {
    position: "relative",
    "&:hover $hoverIcon": {
      display: "block",
    },
    //border: '3px solid green',
    // position: 'absolute', // ? to check --> better work absolute on overlay image than content contanier
    width: "100%",
    height: "fit-content",
    //top: 0,
    //bottom: 0,
    //right: 0,
    //left: 0,
    //margin: 'auto',
    //zIndex: -1,
    //padding: theme.spacing(3), // ? to check
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 0,
    },
  },
  gridContainer: {
    padding: theme.spacing(4),
    background: theme.customPalette.greyClear,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: theme.spacing(4, 4, 4, 4),
    },
  },
  highlightedContainer: {
    //marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  title: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  subtitle: {
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 26,
    },
  },
  description: {
    lineHeight: 1.5,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingRight: "unset",
    },
  },
  countdown: {
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      margin: "auto",
    },
  },
  hidden: {
    display: "none",
  },
  desktopImageContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

const Image = ({ image, stampText, onClick }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden smDown>
        <div onClick={onClick} className={classes.desktopImageContainer}>
          <div className={classes.imageWrapper}>
            <FluidImage className={classes.imageContainer} {...image} />
            {stampText && (
              <Stamp className={classes.stamp}>
                {txt.lastWord(stampText, 1.5)}
              </Stamp>
            )}
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.imageWrapper} onClick={onClick}>
          <FluidImage className={classes.image} {...image} />
          {stampText && (
            <Stamp className={classes.stamp}>
              {txt.lastWord(stampText, 1.5)}
            </Stamp>
          )}
        </div>
      </Hidden>
    </>
  )
}

const SpecialGiftModule = ({ title, subtitle, description, stamp, image }) => {
  const classes = useStyles()
  const [showOverlay, setShowOverlay] = useState(false)

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay)
  }

  return (
    <Module classes={{ container: classes.container }}>
      <Box className={classes.contentContainer}>
        <Grid className={classes.gridContainer} alignItems="center" container>
          <Hidden smDown>
            <Grid xs={6} item>
              <Image stampText={stamp} image={image}></Image>
            </Grid>
          </Hidden>
          <Grid xs={12} md={6} item>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <Text
                  className={classes.title}
                  component="h2"
                  variant="titleXXXL"
                  overline
                  weight="bold"
                >
                  {title}
                </Text>
              </Grid>
              <Hidden mdUp>
                <Grid xs={12} item>
                  <Image stampText={stamp} image={image}></Image>
                </Grid>
              </Hidden>
              <Grid xs={12} item>
                <Text
                  className={classes.subtitle}
                  component="h3"
                  variant="titleXL"
                >
                  {subtitle}
                </Text>
              </Grid>
              <Grid xs={12} item>
                <Text className={classes.description} variant="bodyM">
                  {description}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Module>
  )
}

export default SpecialGiftModule
