import React, { useEffect } from "react";
import Text from "../components/Text"

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Box, Chip } from "@material-ui/core"

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Module from '../components/Module';
import CtaButton from "../components/CtaButton"
import Link from "../components/Link"
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer"
import { window } from "browser-monads"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import FluidImage from "../components/FluidImage"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  sectionBackground: {
    backgroundColor: '#f6f6f6',
    width: '100%',
    height: 'calc(100% - 200px)',
    position: 'absolute',
    zIndex: -1,
    marginTop: 200,
    left: 0,
    right: 0,
    bottom: 0,
  },  
  sectionTitle: {
    marginBottom: 30,
    "& .title": {
      textAlign: 'center'
    },
    "& .title:before": {
      marginLeft: 'auto',
      marginRight: 'auto'
    }    
  },
  backgroundParallax: {
    position: 'absolute !important',
    left:0,
    right:0,
    bottom:0,
    top:0
  },
  gridCardWrapper: {
    padding: theme.spacing(0, 1 / 2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1 / 2, 11, 1 / 2),
      paddingBottom: theme.spacing(1),
      "&:not(:last-child)": {
        padding: theme.spacing(0, 1 / 2, 17, 1 / 2),
        paddingBottom: theme.spacing(1),
      },
    },
  },
  badgeTitleWrapper: {
    padding:40, 
    height:'100%'
  },
  badgeTitle: {
    position: 'absolute',
    top: 24,
    right: 0,
    minHeight: 37,
    background: '#ffbd08',
    minWidth: 97,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3,
    padding: 10,
    '& p': {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      color: '#1d1d1b'
    }
  },
  card: {
    border:0,
    borderRadius:0,
    position:'relative', 
    marginBottom: 40,
    minHeight:450,
    overflow: 'visible',
    '& .logoNarrow': {
      position:'absolute',
      left: 20,
      right: 20,
      top: 20,
      zIndex:2,
      "& .parallax-inner > div": {
        textAlign: 'left',
        maxWidth: '50%',
      },
      "& picture, & img": {
        height: '140px !important',
        width: 'auto !important',  
      },
      "&.logoNarrowDesktop, &.logoNarrowDesktop img, &.logoNarrowDesktop picture": {
        [theme.breakpoints.up("sm")]: {
          maxHeight: '125px !important', 
        }   
      },
      "&.logoNarrowMobile, &.logoNarrowMobile img, &.logoNarrowMobile picture": {
        [theme.breakpoints.down("sm")]: {
          maxHeight: '125px !important',    
        }
      },
      "&.logoNarrowCenter, &.logoNarrowCenter img, &.logoNarrowCenter picture": {
        "& .parallax-inner": {
          display: 'block !important'
        },
        "& .parallax-inner > div": {
          textAlign: 'center !important',
          maxWidth: '100%',
        }
      },      
    },    
    '& .cardMedia': {
      display:'block',
      position:'absolute',
      top:0,
      right:0,
      bottom:0,
      left:0,
    },
    '& .cardForeground': {
      minHeight:450,
      width: '50%',
      float: 'right',
      '& .layer': {
        position: "absolute",
        top: "50%",
        right: 0,
        height: "124%",
        width: "74%",
        maxHeight: 620,
        transform: "translate(0, -50%)",
        [theme.breakpoints.down("sm")]: {
          width: "87%",
          height: '100%',
        },        
      },
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        height: '100%',
        '& .image': {
          height: '100%',
        }
      },
    },    
    '& .cardContent': {
      position:'relative', 
      padding: 20,
      minHeight: 450,
      width: '50%',
      float: 'left',
      display: 'flex',
      alignContent: 'end',
      flexWrap: 'wrap',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },      
    },
    '& .textWrapper': {
      width: '100%'
    },
    '& .textWrapper .info': {
      color:'#b2b2b2',
    },
    '& .textWrapper .info > *': {
      display:'inline-block',
      height:'auto !important'
    },
    '& .textWrapper .info div + span': {
      marginLeft: 10
    },
    '& .textWrapper .title': {
      marginTop:20,
      color:'#b2b2b2'
    },    
    '& .textWrapper .links': {
      marginTop:20,
      color:'#b2b2b2',
      '& .button': {
        [theme.breakpoints.down("sm")]: {
          width: '100%'
        }
      }      
    },
  },
}))

const handleNarrowLogoClasses = ( product ) => {
  let classes = [];    
  classes.push('logoNarrow');
  if ( product?.list_box_v2_narrow_logo_desktop ) {
    classes.push('logoNarrowDesktop');
  } else if ( product?.list_box_v2_narrow_logo_mobile ) {
    classes.push('logoNarrowMobile');
  }
  if ( product?.list_box_v2_position_center_logo ) {
    classes.push('logoNarrowCenter');
  }
  return classes.join(' ');
}

const CardProduct = props => {

  const { product, index, repo } = props;  
  const classes = useStyles();
  const boxSize = product?.list_box_v2_box_size == 100 ? 6 : 6;

  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  const isMobile = windowSize.width <= '960';
  const { ref, inView, entry } = useInView();
  const [animate, setAnimate] = React.useState(true);

  

  // window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [])
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }  

  // show on scroll
  const IsObserverSupported = () => {
    return (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    )
  }    
  useEffect(() => {
    if (animate && inView && ref && IsObserverSupported()) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: parseInt(i / 5) * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, parseInt(i / 5) * 50 + 500)
      })
    }
  }, [ref, inView, entry])

  return (

      <Grid ref={ref} xs={12} md={boxSize} item className={classes.gridCardWrapper}>
        <Card className={classes.card}>

            { product?.list_box_v2_up_title ? 
              <Box className={classes.badgeTitle}>
                <Text component='div' variant='title' weight='bold' align='left' verticalAlign='middle'>{product?.list_box_v2_up_title}</Text>
              </Box> 
              : null
            }

            { 
              product.logo ?  
              <div className={ handleNarrowLogoClasses( product ) }>
                <Box>
                  <ParallaxItem
                  disabled={isMobile}
                  styleInner={{
                    position: "relative",
                    display: "flex",
                    alignItems: "start",
                    height: "100%",
                    width: "100%",
                  }}
                  y={["8", "-8"]}
                  >
                    <FluidImage {...product.logo} />
                  </ParallaxItem>
                </Box>
                </div>
              : null
            }

            { isMobile ? 
              <CardMedia
              className="cardMedia"
              image={ product?.list_box_v2_image_mobile_width?.src ? product?.list_box_v2_image_mobile_width?.src : product?.list_box_v2_image_half_width?.src ? product?.list_box_v2_image_half_width?.src : product?.list_box_v2_image_full_width?.src }
              />
              :                    
              <div className="cardMedia">
                <Parallax
                  className={classes.backgroundParallax}
                  bgImage={product?.list_box_v2_image_half_width}
                  bgMobileImage={product?.list_box_v2_image_full_width}
                ></Parallax>
              </div>
            }

            { 
              product.list_box_v2_image_foreground ? 
              <Box align="center" className="cardForeground">
                  <ParallaxItem
                    className="layer"
                    disabled={isMobile}
                    styleInner={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                  y={["8", "-8"]}
                  >
                    <FluidImage className="image" {...product.list_box_v2_image_foreground} />
                  </ParallaxItem>
              </Box>
              : null
            }

          <CardContent className="cardContent">

            <div className="textWrapper">
            {
              product.list_box_v2_pre_title || product.list_box_v2_pre_order ?
                <Box className="info">
                  <Chip label={product.list_box_v2_pre_title.replace(/<\/?[^>]+(>|$)/g,"")} />
                  <Text variant='bodyXS' variantMapping="span" weight='bold' align='left' verticalAlign='middle'>{product.list_box_v2_pre_order.replace(/<\/?[^>]+(>|$)/g,"")}</Text>
                </Box>
              : null
            }

            {
              product.title ?
                <Box className="title">
                  <Link
                  {...product.list_box_v2_cta}
                  type={product.list_box_v2_cta?.type}
                  product_code={product.list_box_v2_cta?.product_code}
                  repo={repo}
                  >              
                    <Text className="specialTitleXL" color="textTertiary">{product.title}</Text>
                  </Link>
                </Box>
              : null
            }                

            <Box className="links">
              <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
                { product.list_box_v2_discover_now ?
                  <Grid xs={12} item>
                    <Text variant='bodyM' weight='bold' align='left' verticalAlign='middle'>{ product.list_box_v2_discover_now }</Text>
                  </Grid> : null
                }                            
                { product?.list_box_v2_cta?.href ? 
                  <Grid xs={12} item>

                    <CtaButton
                    className="button"
                    {...product.list_box_v2_cta}
                    next
                    size="large"
                    trackingAction={product.list_box_v2_cta?.outbound}
                    trackingLabel={"CategoriesProductListV2AutomaticModule - " + (product.list_box_v2_cta?.label || product.list_box_v2_cta?.title)}
                    >
                    {product.list_box_v2_cta?.label || product.list_box_v2_cta?.title}
                    </CtaButton>

                  </Grid>
                : null }
              </Grid>
            </Box>
            </div>

          </CardContent>
        </Card>
      </Grid>

  )
}

CardProduct.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
};

const CategoriesProductListV2Module = props => {
  const { title, products, repo } = props;
  const classes = useStyles();

  return (      
      <Module>
        <div className={classes.sectionBackground}></div>    
        <Grid container>

          { title ?  
            <Grid xs={12} item className={classes.sectionTitle}>
              <Text className="title" variant="titleXXXL" overline weight="bold" component="h2">{title}</Text>
            </Grid> 
            : null
          }
          <Grid xs={12} item container>
            {products?.map((product, index) => {
              return <CardProduct key={index} product={product} index={index} repo={repo} />
            })}
          </Grid>
        </Grid>
      </Module>
    );
};

CategoriesProductListV2Module.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
};

export default CategoriesProductListV2Module;