import React from "react"
import Text from "../components/Text"
import Icon from "../components/Icon"
//import "../style/HeroBannerTwoLayerFormModule.css"
import CtaButton from "../components/CtaButton"
import { Chip } from "@material-ui/core"
const HeroBannerTwoLayerFormModule = props => {
  /* const [sliderIndex, setSliderIndex] = React.useState(0)
  const dotsContainer = React.useRef(null)

  const { slides, cta, tracking } = props

  return (
    <section className="HeroBannerModule">
      <div className="hero-banner-module-container">
        <div className="h-100 w-100">
          <div className="h-100 w-100">
            <div className="h-100 w-100">
              <div className="h-100 w-100">
                <div className="slide-container">
                  <div className="slide-description-container">
                    <div class="slide-logo"></div>
                    <a href={cta?.href}>
                      {props?.new_arrivals ? (
                        <Chip className="chip" label={props.new_arrivals} />
                      ) : null}
                      <Text
                        variant="specialTitleXXL"
                        component="h2"
                        color="textTertiary"
                        className="slide-title"
                      >
                        {props.title}
                      </Text>
                      <Text
                        variant="bodyM"
                        color="textTertiary"
                        className="slide-subtitle"
                      >
                        {props.subtitle}
                      </Text>
                      <CtaButton
                        className="bannerCtaButton"
                        {...cta}
                        next
                        size="large"
                        trackingObj={tracking}
                        trackingAction="click_link"
                        trackingLabel={"HeroBannerModule - " + cta.productCode}
                      >
                        {cta.title}
                      </CtaButton>
                    </a>
                  </div>
                  <div className="carousel-img-container">
                    <img
                      src={props?.background_image?.src}
                      alt={props?.background_image?.alt}
                      title={props?.background_image?.title}
                      className="slide-background"
                    />
                    <img
                      src={props?.background_image_mobile?.src}
                      alt={props?.background_image_mobile?.alt}
                      title={props?.background_image_mobile?.title}
                      className="slide-mobile-background"
                    />
                  </div>
                  <div className="slide-foreground-container">
                    <img
                      src={props?.product_image?.src}
                      alt={props?.product_image?.alt}
                      title={props?.product_image?.title}
                      className="slide-foreground slide-left"
                    />
                    <img
                      src={props?.product_image_mobile?.src}
                      alt={props?.product_image_mobile?.alt}
                      title={props?.product_image_mobile?.title}
                      className="slide-mobileForegroundImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )*/
}

export default HeroBannerTwoLayerFormModule
