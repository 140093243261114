import React from 'react';
import Box from '@material-ui/core/Box';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Text from './Text';
import InfoCard from './InfoCard';
import Slider from './Slider';

const useStyles = makeStyles((theme) => ({
    infoCards: {
        marginBottom: theme.spacing(5),
    },
    title: {},
    subtitle: {},
    cards: {
        marginTop: theme.spacing(7),
    },
    sliderElement: {
        maxWidth: 500,
        height: '100%',
        paddingRight: theme.spacing(1)
    }
}));

const InfoCards = ({ title, subtitle, items, tracking }) => {
    const classes = useStyles();

    const sliderSettings = {
        tracking: tracking,
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        adaptiveHeight: true,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        showExtraSlides: true
    };

    return (
        <Box className={classes.infoCards}>
            {title &&
                <Text align="center" variant="titleXXXL" component="h3" weight='bold' overline gutterBottom>
                    {title}
                </Text>
            }
            {subtitle &&
                <Text align="center" variant="titleL" component={title ? "h4" : "h3"} weight='medium' gutterBottom>
                    {subtitle}
                </Text>
            }
            {
                items && <Box className={classes.cards}>
                    <Hidden smDown>
                        <Grid container spacing={1}>
                            {items.map((el, i) => (
                                <Grid xs={true} item>
                                    <InfoCard key={`infoCard_${i}`} {...el} />
                                </Grid>
                            ))}
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Slider {...sliderSettings}>
                            {items.map((el, i) => (
                                <Box className={classes.sliderElement} >
                                    <InfoCard key={`infoCard_${i}`} {...el} />
                                </Box>
                            ))}
                        </Slider>
                    </Hidden>
                </Box>
            }
        </Box>
    );
};

export default InfoCards;
