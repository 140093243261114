import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slider from 'react-slick';

import Module from '../components/Module';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery, Box } from '@material-ui/core';

import { GTMEvent } from "../utils/gtm"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(8, 2),
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(8, 1),
    },
  },
  preTitle: {
    fontSize: 12,
  },
  title: {
    paddingBottom: theme.spacing(6),
  },
  slider: {
    '& .slick-list': {
      overflow: 'visible',
      [theme.breakpoints.only('xs')]: {
        width: '90%',
      },
    },
  },
  sliderItem: {
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  card: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  cardNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 700,
    fontSize: 25,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 3,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(3),
    },
  },
  cardTitle: {
    fontWeight: 700,
    lineHeight: 1.1,
    fontSize: 18,
  },

  giftCardPicture: {
    '& img': {
      padding: theme.spacing(2, 2, 2, 2),
      margin: 'auto',
      width: 'auto',
      height: 250,
    },
  },
  giftCardSummary: {
    padding: theme.spacing(2, 2, 4, 2),
  },
}));

const CuriousThingBox = ({ title, description, number }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      <Paper elevation={1} className={classes.card}>
        <Box className={classes.cardHeader}>
          <Box className={classes.cardNumber}>{number}</Box>
          <Typography className={classes.cardTitle}>{title}</Typography>
        </Box>
        <Box className={classes.cardDescription}>{description}</Box>
      </Paper>
    </Box>
  );
};

const CuriousThingsModule = ({ preTitle, title, items, ...others }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const sliderSettings = {
    onSwipe: (direction) => {
      GTMEvent(others.repo, "slider", "swiper-slider")
    },
    dots: true,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: isMobile ? 1 : Math.min(items.length, 3),
    slidesToScroll: isMobile ? 1 : Math.min(items.length, 3),
    className: classes.slider,
  };

  return (
    <Module className={classes.root}>
      <Typography className={classes.preTitle}>{preTitle}</Typography>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      <Box className={classes.sliderContainer}>
        <Slider {...sliderSettings}>
          {(items || []).map((item, index) => (
            <CuriousThingBox key={`gift_${index}`} number={index + 1} {...item} />
          ))}
        </Slider>
      </Box>
    </Module>
  );
};

CuriousThingsModule.defaultProps = {
  preTitle: '',
  title: '',
  items: [],
};

export default CuriousThingsModule;
