import React, { useState, useEffect, useRef } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import { useInView } from "react-intersection-observer"
import Module from "../components/Module"
import Text from "../components/Text"
import CtaButton from "../components/CtaButton"
import Overlay from "../components/Overlay"
import { GTMEvent } from "../utils/gtm"
import Picture from "../components/Picture"
import EcommerceMonthlyItemModule from "./EcommerceMonthlyItemModule"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  FiguresGridAutomatic: {
    textAlign: "center",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 25%)",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
    },
  },
  highlighted: {
    [theme.breakpoints.down("sm")]: {
      gridColumn: "span 2",
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  ctaButton: {
    marginBottom: theme.spacing(5),
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  biggerGap: {
    marginTop: "80px!important",
    margin: "0 8px!important",
    [theme.breakpoints.down("md")]: {
      margin: "0 4px!important",
      marginTop: "40px!important",
    },
  },
  bigGap: {
    marginTop: "50px!important",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px!important",
    },
  },
  bottomGap: {
    marginBottom: "30px!important",
  },
  figureContainer: {
    padding: theme.spacing(1 / 2),
  },
  curiosities: {
    marginBottom: theme.spacing(7),
  },
  primaryGallery: {
    margin: "0 8px",
    [theme.breakpoints.down("md")]: {
      margin: "0 4px",
    },
  },
  animatedBox: {
    opacity: 0,
  },
  notAnimatedBox: {
    opacity: 1,
  },
  fullHeightBox: {
    // height: "100%",
    padding: "0 2.5px",
  },
  firstSlide: {
    width: "100%",
    height: "375px",
    [theme.breakpoints.up("sm")]: {
      width: 426,
      height: 518,
    },
  },
  otherSlides: {
    width: "100%",
    height: "375px",
    [theme.breakpoints.up("sm")]: {
      width: 341,
      height: 426,
    },
  },
  secondaryGallery: {
    margin: "0 8px 8px 8px",
    gridTemplateAreas: '"a0 a0 a1 a2"\n"a0 a0 a3 aOthers"',
    [theme.breakpoints.down("md")]: {
      margin: "0 4px 8px 4px",
      gridTemplateAreas: '"a0 a0"\n"a0 a0"\n"a1 a2"\n"a3 aOthers"',
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )",
      gridTemplateRows: "repeat( auto-fit, minmax(50px, 372px) )",
    },
  },
  slider: {
    "& .slick-list": {
      overflow: "visible",
    },
    "& .slick-track": {
      alignItems: "center",
    },
  },
  select: {
    width: "100%",
    maxWidth: "427px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  figure: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingLeft: 16,
    paddingRight: 16,
  },
  flexImage: {
    flex: "1 1 0",
    objectFit: "contain",
    width: "100%",
  },
  figuresImg: {
    "& img": {
      margin: "0 auto",
      objectFit: "contain",
      height: "300px",
      width: "100%",
    },
  },
  buttonCart: {
    width: "100%",
    marginTop: "16px",
  },
  buttonDisabled: {
    border: "1px solid #999999",
    backgroundColor: "#cccccc",
    color: "#000",
    pointerEvents: "none",
    backgroundImage: "none",
  },
  pb32: {
    paddingBottom: "32px",
  },
  colorFH: {
    color: "#662482",
  },
  none: {
    display: "none",
  },
  outOfStock: {
    fontSize: "1rem",
    color: "white",
    backgroundColor: "#ff2765",
    width: "50%",
    textAlign: "left",
    paddingLeft: "8px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}))

const FiguresGridAutomatic = props => {
  console.log("+++++props", props)
  const classes = useStyles()
  const {
    title,
    details,
    is_slider,
    popup_title,
    distribution_type,
    ...others
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { ref, inView, entry } = useInView()
  const [animate, setAnimate] = React.useState(true)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [itemsWithPosition, setItemsWithPositions] = useState([])
  const [currentFigure, setCurrentFigure] = useState()
  const [indexSlider, setIndexSlider] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const distributionType = distribution_type.field_value
  const { t } = useTranslation()

  const sliderSettingsNew = {
    tracking: others.repo,
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    variableWidth: true,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          centerMode: false,
          infinite: false,
          variableWidth: false,
        },
      },
    ],
  }

  useEffect(() => {}, [])

  const handleFigureSelect = figure => {
    setCurrentFigure(figure)
  }

  const handleFigureClick = (figure, index) => {
    setCurrentIndex(index)
    setOpenOverlay(true)
    setCurrentFigure(figure)

    if (figure.name && others.repo) {
      GTMEvent(others.repo, "click_figure", figure.name)
    }
  }
  const handleOverlayClose = () => {
    setOpenOverlay(false)
  }

  /*useEffect(() => {
    setItemsWithPositions(
      (items || []).map((item, index) => ({
        ...item,
        position: index,
      }))
    )
  }, [items])*/

  useEffect(() => {
    if (animate && inView && ref) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: parseInt(i / 5) * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, parseInt(i / 5) * 50 + 500)
      })
    }
  }, [ref, inView, entry])

  const IsObserverSupported = () => {
    return (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    )
  }

  // TODO questa logica DEVE arrivare da CMS
  //   const TEMPORARY_FIX =
  //     cta &&
  //     cta.href &&
  //     cta.href.includes("/subscribe") &&
  //     ((cta.href.includes("checkout.fanhome.com/") &&
  //       !cta.href.includes("testVersion")) ||
  //       cta.href.includes("p1597070c1tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <Module className={classes.FiguresGridAutomatic} variant="full">
      <Text
        align="center"
        component="h2"
        variant="titleXXXL"
        weight="bold"
        overline
        gutterBottom
      >
        {title.field_value}
      </Text>
      {/*{subtitle && (
        <Text
          className={classes.subtitle}
          align="center"
          variant="bodyM"
          weight="medium"
        >
          {subtitle}
        </Text>
      )}

      {is_slider && (
        <Box px={2} my={5} mx={"auto"} className={classes.select}>
          <Select
            value={indexSlider}
            onChange={event => {
              setIndexSlider(event.target.value)
            }}
          >
            {itemsWithPosition.map((item, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {item.mainContent?.title}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      )}

      {is_slider ? (
        <Slider
          afterChange={index => setIndexSlider(index)}
          indexSlider={indexSlider}
          {...sliderSettingsNew}
        >
          {itemsWithPosition.map((item, index) => (
            <FigureCard
              onClick={() => {
                setIndexSlider(index)
                handleFigureClick(item)
              }}
              className={clsx(
                classes.notAnimatedBox,
                classes.fullHeightBox,
                {
                  [classes.highlighted]: item.highlighted,
                },
                indexSlider === index ? classes.firstSlide : classes.otherSlides
              )}
              flexImage={classes.flexImage}
              classesFigure={classes.figure}
              hover
              highlighted={item.highlighted}
              key={index}
              index={index}
              title={item.name}
              text={item.text}
              picture={item.picture}
            />
          ))}
        </Slider>
      ) : IsObserverSupported() ? (
        <Box
          ref={ref}
          className={classes.container}
          style={
            items?.length < 5 && !isMobile
              ? {
                  gridTemplateColumns: `repeat(${items.length}, 20%)`,
                }
              : {}
          }
        >
          {itemsWithPosition.map((item, index) => (
            <FigureCard
              onClick={() => {
                handleFigureClick(item)
              }}
              className={clsx(classes.animatedBox, {
                [classes.highlighted]: item.highlighted,
              })}
              hover
              highlighted={item.highlighted}
              key={index}
              title={item.name}
              picture={item.picture}
            />
          ))}
        </Box>
      ) : (*/}
      <Box className={classes.container}>
        {details.field_value.map((item, index) => (
          <div className="lg-6" style={{ paddingTop: "24px" }}>
            <div
              className="m-1 p-1 background-color-grey"
              style={{
                backgroundColor: "#fbfbfb",
                padding: "8px",
                margin: "8px",
              }}
            >
              <div className="img-container">
                <Picture
                  className={classes.figuresImg}
                  {...item?.field_value?.figure_image?.field_value}
                />
              </div>
              <div style={{ height: "30px", paddingLeft: "8px" }}>
                <p
                  className={clsx(
                    classes.outOfStock,
                    classes.none,
                    "out-of-stock-message"
                  )}
                  data-sku={
                    item.field_value[`figure_${distributionType}_code`]
                      ?.field_value
                  }
                >
                  {t("ecommerce.outOfStock")}
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  component="span"
                  variant="titleXL"
                  weight="bold"
                  className={classes.pb32}
                >
                  {item?.field_value?.figure_title?.field_value}
                </Text>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    component="span"
                    variant="titleL"
                    weight="bold"
                    className={classes.colorFH}
                  >
                    {
                      item.field_value[`figure_${distributionType}_price`]
                        ?.field_value
                    }
                  </Text>
                  <Text
                    component="span"
                    variant="titleL"
                    onClick={() => {
                      handleFigureClick(item, index)
                    }}
                    className={classes.cursorPointer}
                  >
                    {t("ecommerce.moreInfo")}
                  </Text>
                </div>
              </div>
              <CtaButton
                size="large"
                data-sku={
                  item.field_value[`figure_${distributionType}_code`]
                    ?.field_value
                }
                className={clsx(
                  classes.buttonCart,
                  classes.buttonDisabled,
                  "add-cart-item-trigger"
                )}
              >
                {item?.field_value?.figure_cta_addtocart?.field_value}
              </CtaButton>
            </div>
          </div>
        ))}
      </Box>
      {/*)}

      <Text
        className={classes.description}
        component="div"
        variant="titleL"
        weight="bold"
      >
        {description}
      </Text>
      {cta && (cta.productCode || cta.product_code) && (
        <CtaButton
          className={classes.ctaButton}
          next
          size="large"
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + cta.productCode}
        >
          {cta.text}
        </CtaButton>
      )}
      {cta && cta.label && cta.href && TEMPORARY_FIX && (
        <CtaButton
          className={classes.ctaButton}
          next
          size="large"
          forceOverrideLinkTEMPORARY={cta.href}
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + (cta?.label || cta?.title)}
        >
          {cta?.label || cta?.title}
        </CtaButton>
      )}
      {cta && cta.label && cta.href && !TEMPORARY_FIX && (
        <Button
          className={classes.ctaButton}
          next
          size="large"
          {...cta}
          trackingObj={others.repo}
          trackingAction="click_link"
          trackingLabel={"FiguresGridModule - " + (cta?.label || cta?.title)}
        >
          {cta.label}
        </Button>
      )}*/}
      <Overlay
        stickyHeader
        title={popup_title?.field_value}
        fullScreen
        open={openOverlay}
        onClose={handleOverlayClose}
      >
        <EcommerceMonthlyItemModule
          title={title}
          product={details}
          distributionType={distributionType}
          indexPicture={currentIndex}
        ></EcommerceMonthlyItemModule>
      </Overlay>
    </Module>
  )
}

export default FiguresGridAutomatic
