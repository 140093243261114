import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Module from '../components/Module';
import VideoDescription from '../components/VideoDescription';

const useStyles = makeStyles((theme) => ({
    videoDescription: {
        margin: '120px 0'
    },
}));

const VideoDescriptionModule = (props) => {
    const classes = useStyles();

    return (
        <Module className={classes.videoDescription}>
            <Container>
                <VideoDescription hideDescOnMobile={true} {...props} tracking={props.repo || null} ></VideoDescription>
            </Container>
        </Module>
    );
};

export default VideoDescriptionModule;
