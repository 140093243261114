import React from 'react';
import { window } from 'browser-monads';

const OverrideUrlModule = ({ url }) => {

    window.history.replaceState("", "", url);

    return null;
};

export default OverrideUrlModule;