import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';

import Box from '@material-ui/core/Box';
import Text from './Text';

export const styles = (theme) => ({
    /* Styles applied to the root element. */
    root: {
        position: 'absolute',
        overflow: 'hidden',
        height: '200px',
        width: '150px',
        background: 'transparent',
        zIndex: '1'
    },
    ribbon: {
        background: theme.customPalette.darkishPurple,
        transform: 'rotate3d(0,0,1,-45deg)',
        position: 'absolute',
        textAlign: 'center',
        top: 26,
        left: -35,
        width: 150,
    },
    text: {
        display: 'inline-block',
        height: '100%',
        verticalAlign: 'middle',
    },
});

const Ribbon = React.forwardRef(function Ribbon(props, ref) {
    const {
        classes,
        className,
        children
    } = props;

    return (
        <div className={clsx(className, classes.root)}>
            <Box className={classes.ribbon}>
                <Text className={classes.text} component='div' variant='bodyXXS' color='textTertiary' weight='bold' align='center' verticalAlign='middle'>{children}</Text>
            </Box>
        </div>
    );
});

Ribbon.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: 'FHRibbon' })(Ribbon);