import React from "react"
import Text from "../components/Text"
import CtaButton from "../components/CtaButton"
import { Container, useMediaQuery, Box, Chip } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Module from "../components/Module"
/*const useStyles = makeStyles(theme => ({
  HeroBannerOneLayerForm: {
    position: "relative",
    zIndex: "20",
    height: "642px",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    backgroundPosition: "center center !important",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginBottom: "4px",
  },
  bannerCtaButton: {
    width: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentContainer: {
    //border: showGuides("2px solid yellow"), // FIX ** guides
    position: "absolute",
    width: "40%",
    zIndex: 99,
    left: theme.spacing(4), // FIX ** apply padding internally
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: 0,
      padding: theme.spacing(2), // FIX ** apply padding internally
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "center",
    },
  },
  heroBannerContainer: {
    paddingTop: "10% !important",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(3),
      display: "block",
    },
  },
}))*/
const HeroBannerOneLayerFormModule = props => {
  /*const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()
  const { pageFamily, tracking, cta, i } = props
  return (
    <Module
      className={classes.HeroBannerOneLayerForm}
      style={
        !isMobile
          ? {
              background: "url(" + props?.single_image?.src + ")",
            }
          : {
              background: "url(" + props?.single_image_mobile?.src + ")",
            }
      }
    >
      {" "}
      <Container
        className={classes.heroBannerContainer}
        style={{ position: "relative" }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.fadeInText}>
            {props?.new_arrivals ? (
              <Chip className={classes.chip} label={props.new_arrivals} />
            ) : null}
            <Text
              variant="specialTitleXXL"
              component={pageFamily === "home" ? "h2" : i === 0 ? "h1" : "h2"}
              color="textTertiary"
              className={classes.title}
            >
              {props.title}
            </Text>
            <Text
              variant="bodyM"
              color="textTertiary"
              className={classes.subtitle}
            >
              {props.subtitle}
            </Text>
            <Box className={classes.ctaContainer}>
              <CtaButton
                className={classes.bannerCtaButton}
                {...cta}
                next
                size="large"
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={"HeroBannerModule - " + cta.productCode}
              >
                {cta.title}
              </CtaButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Module>
  )*/
}

export default HeroBannerOneLayerFormModule
