import React from "react"
import clsx from "clsx"
// import Box from "@material-ui/core/Box"
import { Box, Grid, Chip, Hidden, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import txt from "@src/utils/txt"

import Module from "../components/Module"
import Text from "../components/Text"
import Button from "../components/Button"
import Parallax from "../components/Parallax"
import FluidImage from "../components/FluidImage"
import Stamp from "../components/Stamp"

const useStyles = makeStyles(theme => ({
  collectionList: {
    "& $collectionWrapper:first-child ": {
      marginTop: 0,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
  topSpacing: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
    },
  },
  elementContainer: {
    height: "100%",
    position: "relative",
    minHeight: 450,
  },
  /* BACKGROUND */
  background: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  backgroundLayer0: {
    position: "absolute !important",
    zIndex: 0,
  },
  backgroundLayer1: {
    position: "absolute",
    top: "50%",
    right: 0,
    height: "124%",
    width: "74%",
    maxHeight: 620,
    transform: "translate(0, -50%)",
    [theme.breakpoints.down("sm")]: {
      width: "87%",
    },
  },
  item: {
    maxHeight: 620,
  },
  /* FOREGROUND */
  foreground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    maxWidth: "55%",
    height: "100%",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  logo: {
    maxWidth: "95%",
    maxHeight: 110,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "56%",
      marginBottom: "30%",
    },
  },
  chipContainer: {
    marginBottom: theme.spacing(1),
  },
  chip: {},
  textContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stampBig: {
    position: "absolute",
    fontFamily: "SpeziaExtended",
    fontSize: 18,
    lineHeight: 1,
    fontWeight: "bold",
    bottom: theme.spacing(20),
    transform: "translate(-50%, -50%)",
    left: theme.spacing(14),
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      bottom: "45%",
      right: 0,
      left: "auto",
    },
  },
  stamp: {
    position: "absolute",
    fontFamily: "SpeziaExtended",
    fontSize: 18,
    lineHeight: 1,
    fontWeight: "bold",
    bottom: theme.spacing(12),
    transform: "translate(-50%, -50%)",
    right: 0,
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      bottom: "45%",
      right: 0,
    },
  },
  big: {
    "& $backgroundLayer1": {
      [theme.breakpoints.up("md")]: {
        width: "70%",
      },
    },
    "& $foreground": {
      [theme.breakpoints.up("md")]: {
        maxWidth: "33%",
      },
    },
    "& $logo": {
      maxHeight: 147,
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    "& $button": {
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: 56,
      },
    },
  },
  small: {
    "& $backgroundLayer1": {
      [theme.breakpoints.up("md")]: {
        width: "88%",
      },
    },
    "& $logo": {
      maxHeight: 110,
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    "& $item": {
      alignItems: "initial",
    },
  },
  collectionWrapper: {
    padding: theme.spacing(0, 1 / 2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1 / 2, 11, 1 / 2),
      paddingBottom: theme.spacing(1),
      "&:not(:last-child)": {
        padding: theme.spacing(0, 1 / 2, 17, 1 / 2),
        paddingBottom: theme.spacing(1),
      },
    },
  },
  position_center_logo: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  narrow_logo_desktop: {
    [theme.breakpoints.up("sm")]: {
      maxHeight: "150px !important",
    },
  },
  narrow_logo_mobile: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "56%",
      marginBottom: "30%",
      maxHeight: "125px",
    },
  },
}))

const CategoryListElement = ({
  collection,
  variant = "normal",
  moduleTitle,
  tracking,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div
      className={clsx(classes.elementContainer, {
        [classes[variant]]: variant !== "normal",
      })}
    >
      <Box className={classes.background}>
        <Parallax
          className={classes.backgroundLayer0}
          bgImage={collection.backgroundImage}
          bgMobileImage={collection.mobileBackgroundImage}
        ></Parallax>
        <ParallaxItem
          disabled={isMobile}
          className={classes.backgroundLayer1}
          styleInner={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
          y={["8", "-8"]}
        >
          <Hidden mdUp>
            <FluidImage
              className={classes.item}
              {...collection.mobileForegroundImage}
            />
          </Hidden>
          <Hidden smDown>
            <FluidImage
              className={classes.item}
              {...collection.foregroundImage}
            />
          </Hidden>
          {collection.stampText && (
            <Stamp
              className={variant === "big" ? classes.stampBig : classes.stamp}
            >
              {txt.lastWord(collection.stampText, 1.5)}
            </Stamp>
          )}
        </ParallaxItem>
      </Box>
      <Box className={classes.foreground}>
        {collection.logo && (
          <FluidImage
            className={clsx(
              classes.logo,
              collection.position_center_logo && classes.position_center_logo,
              collection.narrow_logo_desktop && classes.narrow_logo_desktop,
              collection.narrow_logo_mobile && classes.narrow_logo_mobile
            )}
            {...collection.logo}
          />
        )}
        <Box width="100%">
          {collection.chipText && (
            <Box className={classes.chipContainer}>
              <Chip className={classes.chip} label={collection.chipText} />
            </Box>
          )}
          <Box className={classes.textContainer}>
            <Text className="specialTitleXL" color="textTertiary">
              {collection.title}
            </Text>
            {/* <Typography>{collection.description}</Typography> */}
          </Box>
          {collection.link && collection.link.title && (
            <Box>
              <Button
                className={classes.button}
                {...collection.link}
                variant="contained"
                next
                size="large"
                trackingObj={tracking}
                trackingAction={moduleTitle}
                trackingLabel={
                  "CategoryListModule - " +
                  (collection.link?.label ||
                    collection.link?.title ||
                    collection.link?.href)
                }
              >
                {collection.link.title}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

const CategoryListModule = props => {
  const classes = useStyles()

  const { title, products } = props

  var variant = "medium"
  if (products) {
    if (products.length === 1) {
      variant = "big"
    } else if (products.length > 2) {
      variant = "small"
    }
  }

  return (
    <Module className={classes.collectionList}>
      {title && (
        <Hidden mdUp>
          <Text
            className={classes.title}
            align="center"
            variant="titleXXXL"
            weight="bold"
            overline
          >
            {title}
          </Text>
        </Hidden>
      )}

      <Grid container className={classes.topSpacing}>
        {(products || []).map((collection, i) => {
          return (
            <Grid
              key={`collection_${i}`}
              className={classes.collectionWrapper}
              item
              xs={12}
              md={products.length === 1 ? 12 : 6}
            >
              <CategoryListElement
                variant={variant}
                collection={collection}
                moduleTitle={title || ""}
                tracking={props.repo || null}
              />
            </Grid>
          )
        })}
      </Grid>
    </Module>
  )
}

CategoryListModule.defaultProps = {
  products: [],
}

export default CategoryListModule
