import React from "react"
import { useRef } from "react"
import clsx from "clsx"
import txt from "@src/utils/txt"
import dom from "@src/utils/dom"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid, Chip, Box, Hidden } from "@material-ui/core"
import Module from "../components/Module"
import Parallax from "../components/Parallax"
import Text from "../components/Text"
import Stamp from "../components/Stamp"
import FluidImage from "../components/FluidImage"
import Icon from "../components/Icon"
import { EqualHeight, EqualHeightElement } from "../components/EqualHeight"
import HighlightTextContainer from "../animations/HighlightTextContainer"
import { Parallax as ParallaxItem } from "react-scroll-parallax"

const useStyles = makeStyles(theme => ({
  standardContentWrapper: {
    background: `${theme.customPalette.greyClear}`,
    padding: "48px 56px 24px 56px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 24px 16px",
    },
  },
  moduleTitle: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  smallFooterFont: {
    color: `${theme.customPalette.greenishGrey}`,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  packageItemContainer: {
    position: "relative",
    background: theme.customPalette.greyClear,
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      paddingTop: 90,
      height: "100%",
    },
    marginTop: 130,
  },
  premiumSubscription: {
    overflow: "visible",
    marginTop: "40px!important",
  },
  packageItem: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingLeft: 0,
      paddingRight: 0,
      height: "100%",
    },
  },
  textContent: {
    textAlign: "left",
    position: "relative",
    paddingTop: 30,
  },
  figurePromo: {
    color: "#fff",
    backgroundColor: theme.customPalette.darkishPurple,
    ...theme.fonts.faces.bodyM,
    width: 68,
    height: 68,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1,
    fontSize: 14,
    bottom: "13%",
    left: "10%",
    position: "absolute",
  },
  specialOfferChip: {
    background: theme.customPalette.darkishPurple,
    transform: "rotate3d(0,0,1,-45deg)",
    position: "absolute",
    textAlign: "center",
    top: 26,
    left: -35,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      color: "#fff",
      backgroundColor: theme.customPalette.darkishPurple,
      ...theme.fonts.faces.bodyM,
      width: 80,
      height: 80,
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      fontSize: 14,
      top: "auto",
      bottom: "-12%",
      left: "-5%",
      position: "absolute",
      zIndex: 1,
      transform: "none",
    },
  },
  hideOnMd: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  specialOfferText: {
    lineHeight: "25px !important",
  },
  originalPrice: {
    color: theme.customPalette.darkishPurple,
    textDecoration: "line-through",
  },
  figure: {
    width: "100%",
  },
  toBottom: {
    position: "absolute",
    bottom: 0,
    margin: "0 56px 24px 56px",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      margin: "0 0 20px 0",
      width: "100%",
    },
  },
  chipImage: {
    width: 20,
    height: 20,
  },
  chipImageWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: 14,
  },
  standardTitleWrapper: {
    margin: "0px 0px 24px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 8px 0px",
    },
  },
  chipText: {
    fontSize: 18,
    fontWeight: 500,
  },
  stamp: {
    width: 133,
    height: 133,
    //fontSize: 18,
    bottom: 0,
    right: 0,
    ...theme.fonts.faces.bodyS,
    ...theme.fonts.weights.bold,
    lineHeight: "1rem !important",
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
      width: 84,
      height: 84,
      lineHeight: "1rem !important",
    },
  },
  divider: {
    height: 1,
    background: `${theme.customPalette.silver}`,
    margin: "16px 0",
  },
  flxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "60%",
  },
  banner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 0),
    background: `${theme.customPalette.greyClear}`,
  },
  bannerIcon: {
    height: 57,
    paddingRight: theme.spacing(6),
  },
  stampHeight: {
    marginTop: 20,
    height: 133,
    [theme.breakpoints.down("sm")]: {
      height: 84,
    },
  },
  stampWrapper: {
    position: "absolute",
    width: "100%",
    height: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      maxHeight: 360,
    },
  },
  chipMobileContainer: {
    display: "flex",
    justifyContent: "center",
  },
  chip: {
    display: "flex",
    alignItems: "center",
    minHeight: "30px",
    padding: "4px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      marginBottom: 20,
    },
  },
  bigStampTitle: {
    fontSize: 36,
    lineHeight: "36px",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "24px",
    },
  },
  preBigStampTitle: {
    fontSize: 24,
    lineHeight: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "14px",
    },
  },
  postBigStampTitle: {
    fontSize: 24,
    alignSelf: "flex-end",
    marginBottom: 6,
    lineHeight: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginBottom: 5,
      lineHeight: "14px",
    },
  },
  figureContainer: {
    marginTop: -110,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.customPalette.greyClear,
      display: "flex",
      justifyContent: "center",
      height: 250,
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  space: {
    margin: "0 8px",
  },
  contentContainer: {
    position: "relative",
    height: "100%",
  },
  container: {
    position: "relative",
    overflow: "visible",
    maxWidth: "1392px",
    //minHeight: '400px',
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      height: "auto",
      minHeight: "unset",
      maxHeight: "unset",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
  innerContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  wrappedContent: {
    position: "absolute",
    top: "50%",
    height: "100%",
    width: "100%",
    transform: "translate3d(0,-50%,0)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  shadowContent: {
    visibility: "hidden",
    opacity: 0,
    height: "100%",
  },
  shadow: {
    position: "relative !important",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    width: "50%",
    marginLeft: "auto",
    float: "right",
    padding: theme.spacing(6, 4, 4, 0),
    [theme.breakpoints.down("sm")]: {
      float: "none",
      height: "auto",
      width: "100%",
      margin: 0,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4, 2),
    },
  },
  parallaxWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    /*display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",*/
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      //maxHeight: '100%'
    },
  },
  overlayEffectWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    minHeight: 1300,
    //background: 'rgba(255,255,255,0.2)',
    top: 0,
  },
  overlayEffect: {
    position: "absolute",
    height: "100px",
    width: "100%",
    background: "white",
    bottom: 0,
  },
  parallax: {
    height: "100%",
    "& .parallax-inner": {
      zIndex: -1,
    },
    [theme.breakpoints.down("sm")]: {
      //position: 'absolute',
      width: "100%",
      //height: '100% !important',
      zIndex: -1,
      //maxHeight: '1085px',
    },
  },
  parallaxItemWrapper: {
    position: "absolute",
    top: "50%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      top: "unset",
      width: "90%",
      margin: "0 auto",
      paddingBottom: theme.spacing(1),
    },
    //top: '50%',
    //transform: 'translate3d(0,-50%,0)'
  },
  parallaxItemWrapperHorizontal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: 0,
      left: 0,
      transform: "translate(0,0)",
    },
  },
  imageWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    width: "fit-content",
    margin: "0 auto",
    maxHeight: 652,
    transform: "translate3d(0,-60%,0)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      transform: "translate3d(0,0,0)",
      //maxHeight: 530,
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      //transform: 'scale(1.1)',
    },
  },
  gridImageContainer: {
    maxHeight: 510,
  },
  gridTitle: {
    wordBreak: "break-word",
    padding: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto",
    },
  },
  grid: {
    padding: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {
    fontWeight: "500",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontWeight: "700",
      fontSize: "16px",
    },
  },
  flexTitleCentered: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  flexTitleJustified: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  purpleBanner: {
    backgroundColor: theme.customPalette.darkishPurple,
    color: "#fff",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100%",
    color: "white",
    padding: theme.spacing(6, 0, 6, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
    },
  },
  horizontalContent: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100%",
    padding: "0 56px 24px 56px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}))

const OffersModule = ({
  standard,
  premium,
  shippingText,
  shippingTextMobile,
}) => {
  const classes = useStyles()
  const moduleRef = useRef(null)

  const Image = ({ image, stampText }) => {
    const classes = useStyles()

    const handleOnLoad = imageRef => {
      /*let target = dom.findAncestor(imageRef.current, 'parallax-outer');
            if (target) {
                if (imageRef.current.clientHeight > target.previousSibling.clientHeight) {
                    let css = `padding-top: 0 !important; padding-bottom: 0 !important;`;
                    moduleRef.current.style.cssText = css;
                }
            }*/
    }

    return (
      <div className={premium.horizontal ? "" : classes.imageWrapper}>
        <FluidImage
          onLoad={handleOnLoad}
          className={classes.image}
          {...image}
        />
        {/*stampText &&
                <div className={classes.stampWrapper}>
                    <Stamp className={classes.stamp}>{txt.priceUplift(stampText, 2)}</Stamp>
                </div>
            */}
      </div>
    )
  }

  const TextContent = ({ className }) => (
    <Box className={clsx(className, classes.body)}>
      <Grid container>
        <Grid item className={classes.gridTitle}>
          <Text
            className={classes.title}
            component="h2"
            variant="specialTitleXXL"
            weight="bold"
          >
            {premium.title}
          </Text>
        </Grid>
        {/*<Grid item className={classes.grid}>
                    <Text className={classes.subtitle} component="h3" variant='titleXL'>
                        {premium.text}
                    </Text>
                </Grid>*/}
        <Grid item className={classes.grid}>
          <Text component="div" className={classes.description} variant="bodyM">
            {premium.text}
          </Text>
        </Grid>
      </Grid>
    </Box>
  )
  const Content = ({ shadow }) => {
    return (
      <div className={shadow ? classes.shadowContent : classes.wrappedContent}>
        {/*  BG */}
        <div className={classes.parallaxWrapper}>
          <Parallax
            className={classes.parallax}
            bgImage={premium.background}
            bgMobileImage={premium.background}
            //TODO: get bigger images to do parallax
            amount={0}
          >
            <Hidden mdUp>
              <div className={classes.overlayEffectWrapper}>
                <div className={classes.overlayEffect}></div>
              </div>
            </Hidden>
          </Parallax>
        </div>

        {/* Switch between vertical and horizontal */}
        {!premium.horizontal ? (
          //  Vertical
          <Container className={classes.innerContainer}>
            <TextContent />
            <ParallaxItem
              className={clsx(classes.parallaxItemWrapper, {
                [classes.shadow]: shadow,
              })}
              styleInner={{ height: "100%" }}
              y={["0", "0"]}
            >
              <Image image={premium.image}></Image>
            </ParallaxItem>
            <div className={classes.toBottom}>
              <Text variant="bodyS" className={classes.smallFooterFont}>
                {premium.closureText}
              </Text>
            </div>
            <Hidden smDown>
              <div style={{ clear: "both" }}></div>
            </Hidden>
          </Container>
        ) : (
          // Horizontal
          <Container
            style={{ height: "100%" }}
            className={classes.innerContainer}
          >
            <div className={classes.horizontalContainer}>
              <div className={classes.horizontalContent}>
                <div className={classes.gridTitle} style={{ zIndex: 3 }}>
                  <Text
                    className={classes.title}
                    component="h2"
                    variant="specialTitleXXL"
                    weight="bold"
                    align="center"
                  >
                    {premium.title}
                  </Text>
                </div>
                <Hidden smDown>
                  <ParallaxItem
                    className={classes.parallaxItemWrapperHorizontal}
                    y={["0", "0"]}
                    styleInner={{ height: "100%", zIndex: 1 }}
                  >
                    <Image image={premium.image}></Image>
                  </ParallaxItem>
                </Hidden>

                <div className={classes.grid} style={{ zIndex: 3 }}>
                  <Text
                    component="div"
                    className={classes.description}
                    variant="bodyM"
                  >
                    {premium.text}
                  </Text>
                </div>

                <Hidden mdUp>
                  <div>
                    <ParallaxItem
                      className={classes.parallaxItemWrapperHorizontal}
                      y={["0", "0"]}
                      styleInner={{ height: "100%" }}
                    >
                      <Image image={premium.image}></Image>
                    </ParallaxItem>
                  </div>
                </Hidden>
              </div>

              {premium.closureText ? (
                <div className={classes.toBottom}>
                  <Text variant="bodyS" className={classes.smallFooterFont}>
                    {premium.closureText}
                  </Text>
                </div>
              ) : null}
            </div>
          </Container>
        )}
      </div>
    )
  }

  const stampContent = content => {
    if (!content) return ""
    const array = content.split(/(%)/g)
    if (array.length === 1) return txt.sanifyHtml(content)
    return (
      <>
        <div className={classes.bigStampTitle}>
          {txt.sanifyHtml([array[0]].join(""))}
          <div className={classes.postBigStampTitle}>
            {txt.sanifyHtml(array[1])}
          </div>
        </div>
        <div>{txt.sanifyHtml(array[2])}</div>
      </>
    )
  }

  const StandardOfferContent = () => {
    return (
      <div>
        <div
          className={clsx(
            classes.standardTitleWrapper,
            standard.chip
              ? classes.flexTitleJustified
              : classes.flexTitleCentered
          )}
        >
          <Text
            className={classes.moduleTitle}
            variant="titleXXXL"
            align="center"
            weight="bold"
          >
            {standard.title}
          </Text>
          {standard.chip && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Chip
                className={classes.chip}
                label={
                  <div className={classes.chipText}>
                    {txt.sanifyHtml(standard.chip)}
                  </div>
                }
              />
            </Box>
          )}
        </div>

        <Box style={{ position: "relative" }}>
          <Box className={classes.packageItemContainer}>
            <div className={classes.packageItem}>
              <Box className={classes.figureContainer} position="relative">
                <FluidImage {...standard.image} className={classes.figure} />
              </Box>
              {(standard.issues || []).map((figure, i) => (
                <div key={`line_price_${i}`}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text variant="bodyM" weight="bold">
                      {figure.title}
                    </Text>
                    <Text
                      variant="bodyM"
                      className={classes.space}
                      weight="medium"
                    >
                      {figure.priceDiscounted}
                    </Text>
                    {figure.priceOriginal && (
                      <Text variant="bodyXXS" className={classes.originalPrice}>
                        {figure.priceOriginal}
                      </Text>
                    )}
                  </Box>
                </div>
              ))}
            </div>
          </Box>
          <Box
            className={classes.stampHeight}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className={classes.flxColumn}>
              <Text variant="titleXL" component="div" weight="bold">
                {standard.text}
              </Text>
              <div style={{ marginTop: 8 }} className={classes.flex}>
                <div>
                  <HighlightTextContainer
                    className={classes.highlightedContainer}
                  >
                    <Text
                      className={classes.title}
                      variant="titleXXXL"
                      component="div"
                      weight="bold"
                    >
                      {standard.priceDiscounted}
                    </Text>
                  </HighlightTextContainer>
                </div>
                <div>
                  <Text
                    variant="bodyXS"
                    className={clsx(classes.space, classes.originalPrice)}
                  >
                    {standard.priceOriginal}
                  </Text>
                </div>
              </div>
            </div>
            {standard.badge && (
              <Stamp className={classes.stamp}>
                {stampContent(standard.badge)}
              </Stamp>
            )}
          </Box>
        </Box>
        <div className={classes.divider}></div>
        <Text variant="bodyS" className={classes.smallFooterFont}>
          {standard.closureText}
        </Text>
      </div>
    )
  }

  const Banner = () => {
    return (
      <Hidden smDown>
        <div className={classes.banner}>
          <Icon className={classes.bannerIcon} name="fastShipping"></Icon>
          <Text variant="titleXXL" component="div" weight="bold">
            {shippingText}
          </Text>
        </div>
      </Hidden>
    )
  }

  const BannerMobile = ({ text }) => {
    return (
      <Hidden mdUp>
        <div className={classes.purpleBanner}>
          <Text variant="bodyM">{text}</Text>
        </div>
      </Hidden>
    )
  }

  return (
    <Module
      ref={moduleRef}
      className={classes.premiumSubscription}
      classes={{ container: classes.container }}
    >
      <Grid container>
        <Grid item xs={12} className={classes.hideOnMd}>
          <BannerMobile text={shippingTextMobile} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.standardContentWrapper}>
          <StandardOfferContent />
        </Grid>
        <Grid item xs={12} className={classes.hideOnMd}>
          <BannerMobile text={shippingTextMobile} />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.contentContainer}>
            <Content shadow />
            <Content />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Banner />
        </Grid>
      </Grid>
    </Module>
  )
}

export default OffersModule
