import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Text from './Text';

const useStyles = makeStyles((theme) => ({
    infoCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: theme.spacing(5, 7),
        width: '100%',
        height: '100%',
        backgroundColor: theme.customPalette.greyClear,
        borderBottom: `3px solid ${theme.customPalette.darkishPurple}`
    },
    number: {
        marginBottom: theme.spacing(3)
    },
    description: {
        lineHeight: 1.5
    }
}));

const InfoCard = ({ number, title, description }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.infoCard}>
            <Grid item xs={12}>
                <Text className={classes.number} variant="specialTitleXL" color="primary">
                    {number}
                </Text>
            </Grid>
            {title &&
                <Grid item xs={12}>
                    <Text variant="titleXL" color="primary" weight="bold" className={classes.title}>{title}</Text>
                </Grid>
            }
            {description &&
                <Grid item xs={12}>
                    <Text variant="bodyM" className={classes.description}>{description}</Text>
                </Grid>
            }
        </Grid>
    );
};

export default InfoCard;
