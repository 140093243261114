import * as React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Box } from "@material-ui/core"
import txt from "@src/utils/txt"

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    width: "100%",
  },
  countdownElement: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
    ...theme.fonts.faces.specialTitleXL,
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    "&:first-child": {
      paddingLeft: theme.spacing(0),
    },
    "&:last-child": {
      paddingRight: theme.spacing(0),
    },
  },
  countdownElementSticky: {
    textAlign: "center",
    ...theme.fonts.faces.specialTitleXL,
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    paddingRight: "1.0471204188481675vh",
    paddingLeft: "1.0471204188481675vh",
    "&:first-child": {
      paddingLeft: theme.spacing(0),
    },
    "&:last-child": {
      paddingRight: theme.spacing(0),
    },
  },
  countdownElementSticky: {
    textAlign: "center",
    ...theme.fonts.faces.specialTitleXL,
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    paddingRight: "1.0471204188481675vh",
    paddingLeft: "1.0471204188481675vh",
    "&:first-child": {
      paddingLeft: theme.spacing(0),
    },
    "&:last-child": {
      paddingRight: theme.spacing(0),
    },
  },
  number: {},
  numberSticky: {
    fontSize: "min(max(8px, 4vw), 1.5rem)",
    lineHeight: "1.25",
    // wordBreak: "break-word",
    // [theme.breakpoints.down("sm")]: {
    //   lineHeight: 1,
    // },
    // "@media (max-width: 320px)": {
    //   fontSize: 8,
    // },
    // "@media (max-width: 360px)": {
    //   fontSize: 10,
    // },
    // "@media (max-width: 320px)": {
    //   fontSize: 8,
    // },
  },
  label: {
    fontSize: "8px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "7px !important",
    },
  },
  labelSticky: {
    lineHeight: "1.25",
    wordBreak: "break-word",
    fontSize: "min(max(6px, 1vw), 22px)",
    // fontSize: "14px",
    // [theme.breakpoints.down("sm")]: {
    //   lineHeight: 1,
    //   fontSize: "7px",
    // },
  },
  filled: {
    justifyContent: "center",
    "& $countdownElement": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      margin: theme.spacing(0, 1),
      borderRadius: "5.2px",
      border: "solid 1.2px white",
      backgroundColor: "white",
      width: theme.spacing(10),
      height: theme.spacing(10),
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 1 / 2),
      },
      "&::before": {
        content: '""',
      },
    },
    "& $number": {
      fontFeatureSettings: '"tnum"',
      fontVariantNumeric: "tabular-nums",
      ...theme.fonts.faces.titleXXXL,
      ...theme.fonts.weights.medium,
      lineHeight: "44px",
    },
    "& $label": {
      ...theme.fonts.faces.bodyXXS,
      ...theme.fonts.weights.bold,
    },
  },
  divider: {
    textAlign: "center",
    ...theme.fonts.faces.specialTitleXL,
    color: theme.palette.primary.main,
    fontSize: "min(max(14px, 1vw), 22px)",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
  },
})

const defaultVariantConfig = {
  standard: {
    divider: true,
  },
  filled: {
    divider: false,
  },
}

const Countdown = React.forwardRef(function Countdown(props, ref) {
  const {
    classes,
    className,
    variant = "standard",
    variantConfig = defaultVariantConfig,
    date,
    stickyBanner = false,
    ...other
  } = props

  const { t } = useTranslation()
  const config = variantConfig[variant] || defaultVariantConfig[variant]
  const endTime = moment(date).toDate()

  const calculateTimeLeft = () => {
    const total = Date.parse(endTime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      days,
      hours,
      minutes,
      seconds,
      total,
    }
  }

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft())

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  })

  const Divider = () =>
    config.divider ? (
      <Box className={classes.divider}>
        <Box>:</Box>
      </Box>
    ) : null

  const Number = ({ num, label }) => {
    const cutString = str => {
      const string = str

      if (string.length > 5) {
        // Cut string and put a "." at the end of the string
        return string.slice(0, 3) + "."
      } else {
        return string
      }
    }

    return (
      <Box
        className={
          !stickyBanner
            ? classes.countdownElement
            : classes.countdownElementSticky
        }
      >
        <Box className={!stickyBanner ? classes.number : classes.numberSticky}>
          {txt.pad(num, 2)}
        </Box>

        {!stickyBanner ? (
          <Box className={classes.label}>
            {(num >= 0 &&
              num != 1 &&
              label &&
              t("countdown." + label + ".plural").toUpperCase()) ||
              ""}
            {(num == 1 &&
              label &&
              t("countdown." + label + ".singular").toUpperCase()) ||
              ""}
          </Box>
        ) : (
          <Box className={classes.labelSticky}>
            {(num >= 0 &&
              num != 1 &&
              label &&
              cutString(t("countdown." + label + ".plural").toUpperCase())) ||
              ""}
            {(num == 1 &&
              label &&
              cutString(t("countdown." + label + ".singular").toUpperCase())) ||
              ""}
          </Box>
        )}
      </Box>
    )
  }

  return timeLeft.total > 0 ? (
    <Box
      ref={ref}
      display="flex"
      alignItems="flex-start"
      className={clsx(className, classes.root, {
        [classes[variant]]: variant !== "standard",
      })}
      {...other}
    >
      <Number num={timeLeft.days} label={"days"} />
      <Divider />

      <Number num={timeLeft.hours} label={"hours"} />
      <Divider />
      <Number num={timeLeft.minutes} label={"minutes"} />
      <Divider />
      <Number num={timeLeft.seconds} label={"seconds"} />
    </Box>
  ) : null
})

Countdown.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * ISO Date
   */
  date: PropTypes.string,
  /**
   * Applies the theme countdown styles.
   */
  variant: PropTypes.oneOf(["standard", "filled"]),
  /**
   * variant configurations
   */
  variantConfig: PropTypes.object,
}

export default withStyles(styles, { name: "FHCountdown" })(Countdown)
