import React, { useState, useEffect, useMemo, Fragment } from "react"
import Module from "../components/Module"
import PropTypes from "prop-types"
import PromoFooter from "../components/PromoFooter"
import { makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  StickyBarModule: {
    "& > div": {
      padding: "0 !important",
    },
  },
}))

const StickyBarModule = props => {
  const classes = useStyles()
  const { promo_image, promo_title, promo_subtitle, promo_link, repo } = props
  const promoFooterProps = {
    title: promo_title?.field_value,
    text: promo_subtitle?.field_value,
    picture: promo_image?.field_value,
    cta: {
      productCode: promo_link?.field_value?.product_code,
      label: promo_link?.field_value?.label,
      href: promo_link?.field_value?.href,
      hreflang: promo_link?.field_value?.hreflang,
    },
  }

  return (
    <section className={classes.StickyBarModule}>
      <Module alwaysVisible variant="full">
        {(promo_link?.field_value?.product_code ||
          promo_link?.field_value?.href) && (
          <PromoFooter {...promoFooterProps} tracking={repo} />
        )}
      </Module>
    </section>
  )
}

StickyBarModule.propTypes = {
  promo_image: PropTypes.string,
  promo_title: PropTypes.string,
  promo_subtitle: PropTypes.string,
  promo_link: PropTypes.object,
}

export default StickyBarModule
