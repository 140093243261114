import React from 'react';
import Error from './Error';
import Page500 from '@src/pages/500'

const Error500 = ({ message }) => {

  return (
    <Error code="500" head="Application Error" message={message}>
      <Page500 />
    </Error>
  );
};

export default Error500;
