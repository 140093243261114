import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Module from '../components/Module';
import Button from '../components/Button';

const useStyles = makeStyles((theme) => ({
    ctaButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}));

const CtaButtonModule = ({ link, ...others }) => {
    const {
        onClick,
        ...extra
    } = link;
    const classes = useStyles();

    /*
    let actualOnClick = undefined;
    if (typeof onClick === "string"){
        actualOnClick = eval(onClick);
    } else if (typeof onClick === "function") {
        actualOnClick = onClick;
    }
    */

    return (
        <Module>
            <Box className={classes.ctaButton}>
                {link && <Button
                    size='large'
                    //onClick={actualOnClick}
                    {...extra}
                    trackingObj={others.repo}
                    trackingAction="click_link"
                    trackingLabel={"CtaButtonModule - "+(link?.label || link?.title || link?.href)}
                >
                    {(link?.label || link?.title)}
                </Button>}
            </Box>
        </Module >
    );
};

export default CtaButtonModule;
