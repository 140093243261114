import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',    
    borderRadius: '10px',
    border: '4px solid rgba(0,0,0,0)',
    backgroundColor: '#f3f3f3',
    padding: theme.spacing(2),
    margin: '10px auto'
  }
}));

const Error = ({ code, head, message, children }) => {
  
  const classes = useStyles();

  console.error(`Error ${code}`, message);
  
  if (children) {
    return (children);
  } else {
    return (
      <div className={classes.container}>
        <Typography align="center" variant="h6">{code} | {head}</Typography>
      </div>
    );
  }
};

export default Error;
