import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer'
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from "react-spring";
import VisibilitySensor from './VisibilitySensor';
import * as easings from 'd3-ease';

const useStyles = makeStyles((theme) => ({
    growthContainer: {
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: '100%',
    }
}));

const GrowthContainer = (props) => {
    const classes = useStyles();
    const {
        delay = 500,
        duration = 2000,
        fromH = '100%',
        toH = '100%',
        fromW = '100%',
        toW = '100%',
        className,
        children
    } = props;

  const {ref, inView, entry} = useInView()
  const [animate, setAnimate] = React.useState(true);

  useEffect(() => {
    if(animate && inView && ref){
      setAnimate(false);
      Array.from(entry.target.children).forEach((sub,i)=>{
        //sub.style.opacity=0;
        sub.animate([
            { height: fromH, width: fromW, marginTop: toH  },
            { height: toH, width: toW, marginTop: toH }
          ], {
            // timing options
            duration: duration,
            delay: delay
          }
        )
        setTimeout(()=>{
            sub.style.opacity=1;
            sub.style.height=toH;
            sub.style.width=toW;
        },delay);
      });
    }
  }, [ref, inView, entry]);

    /*const config = useSpring({
        delay: delay,
        config: { duration: duration, easing: easings.easeExpOut },
        width: toW,
        margin: 'auto',
        onStart: ()=>console.log("animation starting!!!"),
        height: toH,
        from: { width: fromW, height: fromH, marginTop: toH }
    });*/

    return (
        <div ref={ref} className={clsx(classes.growthContainer, className)}>
            {children}
            {/*<VisibilitySensor>
                {({ isVisible }) => {console.log("resetting",!isVisible);
                return(
                    <animated.div style={visible?{ ...config, reset: !isVisible, opacity: 1 }: {}}>{children}</animated.div>
                )}}
            </VisibilitySensor>*/}
        </div>
    );
};


export default GrowthContainer;