import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { Box } from '@material-ui/core';
import Text from './Text';

export const styles = (theme) => ({
    /* Styles applied to the root element. */
    root: {
    },
    anchor: {
        marginTop: theme.spacing(4),
        width: 'fit-content',
        ...theme.fonts.faces.bodyXS,
        ...theme.fonts.weights.bold,
        color: 'black !important',
        textTransform: 'uppercase',
        cursor: 'pointer',
        borderBottom: `3px solid ${theme.customPalette.tangerine}`,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    text: {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        fallbacks: {
            display: "inline-block"
        }
    },
});

const ShowMoreText = React.forwardRef(function ShowMoreText(props, ref) {
    const {
        classes,
        className,
        readMoreText,
        readLessText,
        children,
        lines = 7,
        onChange,
        onEllipsisInit,
        ...others
    } = props;

    const text = React.useRef(null);
    const [expand, setExpand] = React.useState(false);
    const [ellipse, setEllipse] = React.useState(false);

    var offsetTop = 0;
    var bounds = false;
    const isEllipsisActive = (e) => {
        onEllipsisInit(e);
        return e.current.offsetHeight < e.current.scrollHeight;
    }

    const handleToggle = () => {
        setExpand(!expand);
        if (typeof onChange === 'function') {
            onChange(!expand);
        }
    };

    React.useEffect(() => {
        if (text) {
            setEllipse(isEllipsisActive(text));
        }
    }, [text]);

    return (

        <Box ref={ref} className={clsx(classes.root, className)}>
            <Text ref={text} component='p' className={classes.text} style={{ WebkitLineClamp: expand ? 'initial' : lines }} {...others}>
                {children}
            </Text>
            {ellipse && <Box>
                {expand ?
                    <Box className={classes.anchor} onClick={handleToggle}>{readLessText}</Box> : <Box className={classes.anchor} onClick={handleToggle}>{readMoreText}</Box>
                }
            </Box>
            }
        </Box>);
});

ShowMoreText.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: 'FHShowMoreText' })(ShowMoreText);