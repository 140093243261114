import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Text from "../components/Text"
import Module from "../components/Module"

const useStyles = makeStyles(theme => ({
  SubscriptionOfferContainer: {
    backgroundColor: "#fbfbfb",
    paddingTop: 40,
    paddingBottom: 80,
    marginTop: "80px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 32,
    },
  },
  container: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  row: {
    width: "100%",
  },
  offerTitle: {
    fontSize: 40,
    fontWeight: "600",
    color: "#662482",
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
  },
  px16: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  offerContent: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  offerRows: {
    borderBottom: "1px solid #ffbd08",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
  },
  offerRowsCurrent: {
    backgroundColor: "#fbfbfb",
    color: "#ffbd08",
    border: "1px solid #ffbd08",
    borderBottomColor: "transparent",
    paddingBottom: 24,
    paddingTop: 24,
    flexBasis: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#ffd759",
      border: "1px solid #ffbd08",
      borderBottomColor: "transparent",
      color: "#fbfbfb",
      transition: "all .2s ease-out",
    },
  },
  offerRowsField: {
    backgroundColor: "transparent",
    color: "#d2d3d6",
    border: "1px solid #ffbd08",
    borderBottomColor: "transparent",
    paddingBottom: 24,
    paddingTop: 24,
    flexBasis: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      border: "1px solid #d2d3d6",
      borderBottomColor: "transparent",
      color: "#d2d3d6",
      transition: "all .2s ease-out",
    },
  },
  offerTab: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "1.14",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontStyle: "normal",
  },
  offerContentFields: {
    border: "1px solid #ffbd08",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      borderTopColor: "transparent",
    },
  },
  containerFieldsOffer: {
    paddingBottom: 72,
    paddingTop: 72,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "48px",
      paddingBottom: "0px",
    },
  },
  rowFields: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  colDescription: {
    flexBasis: "33.3333333333%",
    maxWidth: "33.3333333333%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  },
  px64: {
    paddingLeft: 64,
    paddingRight: 64,
    marginBottom: 32,
  },
  offerBottomText: {
    lineHeight: "1.11",
    textAlign: "center",
    fontWeight: "600",
    paddingBottom: 48,
    fontSize: 18,
  },
}))

const SubscriptionOfferModule = props => {
  const { title, primary, bottom_text } = props
  const [fieldCategory, setFieldCategory] = useState(0)
  const changeCategory = (category, title) => {
    if (fieldCategory === category) return
    setFieldCategory(category)
  }
  const classes = useStyles()
  return (
    <Module className={classes.SubscriptionOfferContainer}>
      <div>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className="col-24">
              <h2 className={classes.offerTitle}>{title.field_value}</h2>
            </div>
            <div className="col-24">
              <div className={classes.px16}>
                <div className={classes.offerContent}>
                  <div className={classes.offerRows}>
                    {primary.field_value.map((row, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            changeCategory(
                              index,
                              row.field_value.title_tab.field_value
                            )
                          }
                          className={
                            index === fieldCategory
                              ? classes.offerRowsCurrent
                              : classes.offerRowsField
                          }
                        >
                          <Text className={classes.offerTab}>
                            {row.field_value.title_tab.field_value}
                          </Text>
                        </div>
                      )
                    })}
                  </div>
                  <div className={classes.offerContentFields}>
                    <div className={classes.containerFieldsOffer}>
                      {primary.field_value.map((item, index) => {
                        if (index === fieldCategory) {
                          return (
                            <div className={classes.rowFields}>
                              <div className={classes.colDescription}>
                                <div className={classes.px64}>
                                  <Text>
                                    {
                                      item.field_value?.subtitle_first
                                        ?.field_value
                                    }
                                  </Text>
                                  <Text
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.field_value?.description_first
                                          ?.field_value,
                                    }}
                                  ></Text>
                                </div>
                              </div>
                              <div className={classes.colDescription}>
                                <div className={classes.px64}>
                                  <Text>
                                    {
                                      item.field_value.subtitle_second
                                        .field_value
                                    }
                                  </Text>
                                  <Text
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.field_value.description_second
                                          .field_value,
                                    }}
                                  ></Text>
                                </div>
                              </div>
                              <div className={classes.colDescription}>
                                <div className={classes.px64}>
                                  <Text>
                                    {
                                      item.field_value.subtitle_third
                                        .field_value
                                    }
                                  </Text>
                                  <Text
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.field_value.description_third
                                          .field_value,
                                    }}
                                  ></Text>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                    {bottom_text.field_value && (
                      <div className={classes.offerBottomText}>
                        {bottom_text.field_value}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Module>
  )
}

export default SubscriptionOfferModule
