import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Slider from "react-slick";

import LeftArrowIcon from '../assets/img/left-arrow.svg';
import RightArrowIcon from '../assets/img/right-arrow.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { GTMEvent } from "../utils/gtm"

const figureHeight = 56;
const xsFigureHeight = 36;
const useStyles = makeStyles((theme) => ({
  figureSelector: {
    backgroundColor: theme.customPalette.greyClear,
    height: 68,
    margin: "0 8px",
    padding: "11px 125px 0 125px",
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: "11px 32px 0 32px",
    },
    [theme.breakpoints.only('xs')]: {
      margin: 0,
      padding: theme.spacing(0, 4),
      height: xsFigureHeight,
    },
  },
  figureIcon: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    cursor: 'pointer',
    maxHeight: figureHeight,
    [theme.breakpoints.only('xs')]: {
      height: xsFigureHeight
    }
  },
  selected: {
    filter: 'invert(51%) sepia(41%) saturate(2098%) hue-rotate(10deg) brightness(106%) contrast(101%)',
    borderBottom: `2px solid ${theme.customPalette.tangerine}`
  },
  divButton: {
    outline: "none"
  },
  slider: {
    backgroundColor: theme.customPalette.greyClear,
    '& .slick-list, .slick-track': {
      height: figureHeight,
      [theme.breakpoints.only('xs')]: {
        height: xsFigureHeight,
      }
    },
    '& .slick-prev::before': {
      content: `url(${LeftArrowIcon})`
    },
    '& .slick-next::before': {
      content: `url(${RightArrowIcon})`
    },
  }
}));

const FigureSelector = ({ figures, selected, onSelect, tracking }) => {
  const classes = useStyles();

  const slider = useRef();

  const sliderSettings = {
    beforeChange: (current, next) => {
      if(tracking){
        if(current < next){          
          GTMEvent(tracking, "slider", "swiper-slider")
        }else if(current > next){
          GTMEvent(tracking, "slider", "swiper-slider")
        }
      }
    },
    arrows: true,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 10,
    slidesToScroll: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ]
  };

  useEffect(() => {
    if (slider && figures) {
      slider.current.slickGoTo(figures.findIndex((figure) => figure.position === selected.position));
    }
  }, [figures, selected.position]);

  return (
    <div className={classes.figureSelector}>
      <Slider
        ref={slider}
        className={classes.slider}
        {...sliderSettings}
      >
        {(figures || []).map((figure, index) => (
          <div className={classes.divButton} role='button' key={`figure_${index}`} onClick={() => { onSelect(figure) }}>
            <img className={clsx(classes.figureIcon, { [classes.selected]: figure.position === (selected ? selected.position : '') })} alt='' {...figure.icon}></img>
          </div>
        ))}
      </Slider>
    </div >
  );
};

FigureSelector.defaultProps = {
  figures: [],
};

export default FigureSelector;
