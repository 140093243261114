import React, { useRef } from "react"
import clsx from "clsx"
import { Container, Box, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import Module from "../components/Module"
import Video from "../components/Video"
import Parallax from "../components/Parallax"
import PlayButton from "../components/PlayButton"
const parallaxHeight = 642
const useStyles = makeStyles(theme => ({
  heroBannerContainer: {
    position: "relative",
    height: "90vh",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      height: 694,
    },
  },
  parallax: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 30, 0, 30),
    textAlign: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: -80,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      ...theme.fonts.faces.specialTitleXL,
    },
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {},
  },
  video: {
    width: "100%",
    height: "100%",
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  playButton: {
    marginTop: theme.spacing(3),
  },
  smallHeroBanner: {
    position: "fixed",
    top: -120,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.modal,
    backgroundColor: "#fff",
    transition: "top 500ms",
  },
  smallHeroBannerShowed: {
    top: 0,
  },
  heroBannerPromoTwo: {
    width: "80%",
    position: "relative",
    background: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    margin: "auto",
    marginTop: `-${theme.spacing(3)}px`,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  heroBannerPromoTwoPicture: {
    width: "100%",
    height: "auto",
    marginTop: `-${theme.spacing(8)}px`,
  },
  heroBannerPromoTwoExpiration: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: theme.spacing(3),
    },
  },
}))

const HeroBannerVideoModule = ({ title, subtitle, video, ...others }) => {
  const classes = useStyles()
  const videoRef = useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const handleClick = () => {
    videoRef.current.play()
  }

  return (
    <Module alwaysVisible variant="full" noMargin>
      <Box className={classes.heroBannerContainer}>
        <Parallax
          key={`hero_video_banner`}
          strength={250}
          amount={0.75}
          className={classes.parallax}
          parallaxStyle={{
            position: "absolute",
            height: parallaxHeight,
          }}
        />
        <div className={classes.contentContainer}>
          <ParallaxItem disabled={isMobile} y={["20", "-20"]}>
            <Module>
              <h1 className={clsx(classes.title, "specialTitleXXL white")}>
                {title}
              </h1>
              <h2 className={clsx(classes.subtitle, "bodyM white bold")}>
                {subtitle}
              </h2>
              {video?.url && (
                <PlayButton
                  className={classes.playButton}
                  showPlayButtonText
                  onClick={handleClick}
                ></PlayButton>
              )}
            </Module>
          </ParallaxItem>
        </div>
        <Box className={classes.videoWrapper}>
          <Video
            ref={videoRef}
            className={classes.video}
            hidePlayButton
            fullscreen
            {...video}
            tracking={others.repo || null}
          />
        </Box>
      </Box>
    </Module>
  )
}

const defaultProps = {
  title: "",
  subtitle: "",
}

HeroBannerVideoModule.defaultProps = defaultProps

export default HeroBannerVideoModule
