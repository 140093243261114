import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Hidden } from "@material-ui/core"

import AddCircleIcon from "@material-ui/icons/AddCircle"
import Text from "./Text"
import Picture from "./Picture"
import Icon from "./Icon"

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1 / 4),
  },
  figure: {
    position: "relative",
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
    display: "block",
    flexWrap: "wrap",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      minHeight: 235,
      padding: theme.spacing(1),
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      boxShadow: `inset 0 0 0 4px ${theme.customPalette.tangerine}`,
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
      },
    },
    "&:hover .hoverIcon": {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  hoverIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    color: theme.customPalette.tangerine,
    fontSize: 40,
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: "0.67",
    backgroundImage:
      "radial-gradient(circle at 52% 60%, #ffffff, #cecece 157%)",
    zIndex: -1,
  },
  imageContainer: {
    height: 350,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexGrow: 0,
    width: "100%",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      height: 194,
      paddingBottom: theme.spacing(1),
    },
  },
  image: {
    height: "100%",
    width: "auto",
    maxHeight: "100%",
  },
  img: {
    height: "100%",
    width: "auto",
  },
  title: {
    width: "100%",
    textAlign: "left",
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  promoText: {
    background: theme.customPalette.darkishPurple,
    padding: "8px 0px",
    width: "200px",
    position: "absolute",
    left: "-61px",
    transform: "rotate3d(0,0,1,-45deg)",
    fontSize: "11px",
    [theme.breakpoints.down("sm")]: {
      top: 24,
    },
  },
  addIcon: {
    height: 16,
  },
  highlighted: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      height: 445,
    },
    "& $figure": {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        bottom: 0,
        height: 392,
        overflow: "visible",
      },
    },
    "& $imageContainer": {
      [theme.breakpoints.down("sm")]: {
        height: 400,
        width: "fit-content",
        margin: "auto",
        marginTop: "-49px",
      },
    },
    "& $image": {
      [theme.breakpoints.down("sm")]: {
        height: 445,
      },
    },
  },
}))

const FigureCard = ({
  className,
  title,
  subtitle,
  picture,
  imageUrl,
  highlighted,
  promoText,
  onClick,
  classesFigure,
  flexImage,
  index,
  hover,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.container, className, {
        [classes.highlighted]: highlighted,
        [classes.hover]: hover,
      })}
      onClick={onClick}
    >
      <Box className={clsx(classes.figure, classesFigure)}>
        {promoText && (
          <Text
            className={classes.promoText}
            variant="bodyXXS"
            color="textTertiary"
            weight="bold"
          >
            {promoText}
          </Text>
        )}
        <Box className={clsx(classes.hoverIcon, "hoverIcon")}>
          <AddCircleIcon fontSize="inherit" />
        </Box>
        <Box className={classes.background} />
        <Box className={clsx(classes.imageContainer, flexImage)}>
          <Picture
            className={classes.image}
            {...picture}
            imgClassName={classes.img}
          />
          {/* <img className={classes.image} src={imageUrl} /> */}
        </Box>
        <Box>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="space-between"
            alignItems={"center"}
            style={{ gap: "4px" }}
          >
            <Text className={classes.title} component="h3" variant="titleL">
              {title}
            </Text>
            {hover && (
              <Hidden mdUp>
                <Icon className={classes.addIcon} name="add" />
              </Hidden>
            )}
          </Box>
          {subtitle && (
            <Text
              className={classes.subtitle}
              component="p"
              align="left"
              variant="bodyS"
            >
              {subtitle}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default FigureCard
