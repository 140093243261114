import React from 'react';

function lastWord(text, size) {
    let words = text.split(' ');
    let last = words.splice(-1)[0];
    let others = words.join(' ');
    let adjust = (size - (last.length - 6) * 0.1);
    return (
        <>{others} <span style={{ fontSize: `${adjust * 100}%` }}>{last}</span></>
    )
}

function priceUplift(text, size) {
    let matches = text.match(/ ((\$|€|£).+?) | (.+?(?!\$|€|£)) /gm);
    if (matches && matches.length > 0) {
        let price = matches[0].trim();
        let chunks = text.split(price);
        let before = chunks.splice(0, 1);
        let after = chunks.join(price).trim(); // just in case there's more than 1 price - first only
        return (
            <>{before} <span style={{ fontSize: `${size}rem`, padding: `${size / 5}rem` }}>{price}</span> {after}</>
        )
    }
    return text;
}

function sanifyHtml(text) { // TO BE DONE better!
    if (text) {
        const tags = [
            {
                tag: 'p',
                replace: false,
            }
        ];
        var result = text;
        for (let i = 0; i < tags.length; i++) {
            let filter = tags[i];
            let replace = filter.replace ? '<' + filter.replace + '/>' : '';
            result = result.split('<' + filter.tag + '>').join('');
            result = result.split('</' + filter.tag + '>').join(replace);
            // Use regex for complez(temp fix generic content issue)
            result = result.replace(new RegExp('<' + filter.tag + '.+?>', 'g'), '');
        }
        return result;
    } else {
        return '';
    }
}

function newLineBeforeParentheses(text) {
    if (text) {
        return text.replace('(', '<br>(');
    }
    return text;
}

function brakeBeforeParentheses(text) {
    if (text) {
        return text.replace('(', '<wbr>(').replace('+', '<wbr>+');
    }
    return text;
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const txt = {
    lastWord: lastWord,
    priceUplift: priceUplift,
    sanifyHtml: sanifyHtml,
    newLineBeforeParentheses: newLineBeforeParentheses,
    brakeBeforeParentheses: brakeBeforeParentheses,
    pad: pad
}

export default txt;