import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Text from "../components/Text"
import Module from "../components/Module"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  useMediaQuery,
} from "@material-ui/core"
import Icon from "../components/Icon"
import FluidImage from "../components/FluidImage"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import clsx from "clsx"
import { showGuides } from "@src/utils/debug"
const parallaxHeight = 450
const useStyles = makeStyles(theme => ({
  DoubleTabSpecificationsContainer: {
    paddingTop: 40,
    paddingBottom: 80,
    marginTop: "80px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 32,
    },
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#000000",
    },
  },
  container: {
    padding: 16,
  },
  sizeImgContainer: {
    border: showGuides("2px solid yellow"), // FIX ** guides
    display: "flex",
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    position: "absolute", // FIX ** this need to be absoute otherwise there's no way to put an horizontal image (!!)
    maxWidth: "70%",
    maxHeight: 800, // FIX ** we need a fixed point
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "fit-content",
      margin: "auto",
      transform: "translate3d(0, 0, 0)",
      top: "unset",
      maxWidth: "100%",
      paddingBottom: theme.spacing(4),
    },
  },
  sizeWide: {
    right: "5%",
    marginTop: "-3%",
    [theme.breakpoints.down("sm")]: {
      right: "0%",
    },
    [theme.breakpoints.up("md")]: {
      right: "15%",
    },
  },
  row: {
    width: "100%",
  },
  containerContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "32px 18px 32px 18px",
  },
  titleItem: {
    fontWeight: "600",
    color: "#FFFFFF",
    fontSize: "30px",
    lineHeight: "32px",
    textAlign: "center",
  },
  offerContent: {
    display: "flex",
    flexDirection: "column",
  },
  offerRows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    backgroundColor: "#000000",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  offerRowsCurrent: {
    backgroundColor: "#662482",
    color: "#FFFFFF",
    border: "1px solid #662482",
    borderBottomColor: "transparent",
    paddingBottom: 24,
    paddingTop: 24,
    flexBasis: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#662482",
      border: "1px solid #662482",
      borderBottomColor: "transparent",
      color: "#fbfbfb",
      transition: "all .2s ease-out",
    },
  },
  offerRowsField: {
    backgroundColor: "transparent",
    color: "#FFFFFF",
    border: "1px solid #662482",
    borderBottomColor: "transparent",
    paddingBottom: 24,
    paddingTop: 24,
    flexBasis: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      border: "1px solid #d2d3d6",
      borderBottomColor: "transparent",
      color: "#FFFFFF",
      transition: "all .2s ease-out",
    },
  },
  offerTab: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "1.14",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontStyle: "normal",
  },
  offerContentFields: {
    border: "1px solid #662482",
    wordWrap: "break-word",
    backgroundColor: "#000000",
  },

  infoItem: {
    padding: theme.spacing(0.5, 3, 0.5, 0),
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    color: "white",
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  img: {
    border: showGuides("3px solid blue"), // FIX ** guides
    minWidth: 200,
    maxWidth: 800,
    width: "fit-content",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  imgInner: {
    maxHeight: 800,
    minHeight: 400, // FIX ** we need an anchor point somewhere
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  parallaxWrapper: {
    position: "absolute",
    right: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
  },
  parallax: {
    zIndex: -1,
  },
}))

const DoubleTabSpecificationsModule = props => {
  const { primary, repo } = props

  const [fieldCategory, setFieldCategory] = useState(0)
  const changeCategory = (category, title) => {
    if (fieldCategory === category) return
    setFieldCategory(category)
  }
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Module className={classes.DoubleTabSpecificationsContainer}>
      <div>
        <div>
          <div className={classes.row}>
            <div className="col-24">
              <div>
                <div className={classes.offerContent}>
                  <div className={classes.offerRows}>
                    {primary.field_value.map((row, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            changeCategory(
                              index,
                              row.field_value.title.field_value
                            )
                          }
                          className={
                            index === fieldCategory
                              ? classes.offerRowsCurrent
                              : classes.offerRowsField
                          }
                        >
                          <Text className={classes.offerTab}>
                            {row.field_value.title.field_value}
                          </Text>
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className={classes.offerContentFields}
                    style={{ minHeight: "450px" }}
                  >
                    <div className="containerFields">
                      {primary.field_value.map((item, index) => {
                        if (index === fieldCategory) {
                          return (
                            <div className={classes.containerContent}>
                              <div className={classes.textContainer}>
                                <Text className={classes.titleItem}>
                                  {item.field_value.title_item.field_value}
                                </Text>
                                <Box>
                                  <Hidden mdUp>
                                    <FluidImage
                                      innerClassName={clsx(
                                        classes.img,
                                        classes.imgInner
                                      )}
                                      {...item?.field_value?.tab_image_mobile
                                        ?.field_value}
                                    ></FluidImage>
                                  </Hidden>
                                </Box>
                                <List className={classes.info}>
                                  {item.field_value.primary.field_value.map(
                                    (point, index) => (
                                      <ListItem
                                        key={index}
                                        className={classes.infoItem}
                                      >
                                        <ListItemIcon>
                                          <Icon
                                            className={classes.bulletIcon}
                                            name="bullet"
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          className={classes.infoText}
                                          primary={
                                            point?.field_value?.title
                                              ?.field_value
                                          }
                                        />
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </div>
                              <Box
                                className={clsx(
                                  classes.sizeImgContainer,
                                  classes.sizeWide
                                )}
                              >
                                <Box className={classes.parallaxWrapper}>
                                  <Parallax
                                    className={classes.parallax}
                                    amount={0.35}
                                  >
                                    <div
                                      style={{
                                        height: parallaxHeight,
                                        width: "100%",
                                      }}
                                    ></div>
                                  </Parallax>
                                </Box>
                                <Hidden smDown>
                                  <ParallaxItem
                                    disabled={isMobile}
                                    y={["8", "-2"]}
                                  >
                                    <FluidImage
                                      innerClassName={clsx(
                                        classes.img,
                                        classes.imgInner
                                      )}
                                      {...item?.field_value?.tab_image
                                        ?.field_value}
                                    ></FluidImage>
                                  </ParallaxItem>
                                </Hidden>
                              </Box>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Module>
  )
}

export default DoubleTabSpecificationsModule
