import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@src/components/Button';
import Text from '@src/components/Text';
import FooterModule from '@src/modules/FooterModule'
import { useTranslation } from "react-i18next"
import { snake } from '@src/snake/core';
import Image from '@src/components/Image';

const useStyles = makeStyles((theme) => ({
    space: {
        backgroundImage: 'url(/img/errors/pexels@2x.png)',
        position: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'black',
        width: '100%',
        height: '100%'
    },
    spaceInner: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    logo: {
        height: 56,
        marginTop: '15px',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.only('xs')]: {
            height: 45,
        },
    },
    container: {
        position: 'absolute',
        top: '50%',
        width: '100%',
    },
    aligner: {
        display: 'block',
        textAlign: 'center',
        width: '70%',
        maxWidth: '700px',
        margin: '0 auto',
        transform: 'translateY(-35%)'
    },
    image: {
        width: '100%',
        maxWidth: '600px',
    },
    text: {
        color: 'white'
    },
    above: {
        marginBottom: '15px'
    },
    below: {
        marginTop: '20px'
    },
    note: {
        fontSize: '1.5em',
        marginTop: '10px'
    },
    footerWrap: {
        position: 'absolute',
        width: '100%',
        top: '100%',
        marginTop: -theme.spacing(4)
    },
    homeButton: {
        marginTop: '15px',
        //textTransform: 'uppercase'
    }
}));

export default function Error({ code }) {
    const classes = useStyles();
    const [display, setDisplay] = useState({
        message: "",
        notes: ""
    })

    const { t, i18n } = useTranslation()
    const [footerData, setFooterData] = useState({});

    const handleClick = () => {
        window.location.assign('/');
    }

    useEffect(() => {
        setDisplay({
            message: t(`page${code}.message`),
            notes: t(`page${code}.notes`)
        });
        async function fetchData() {
            let site = t('site');
            const result = await snake.getSectionByType('footer', site);
            setFooterData(result.data.content);
        }
        fetchData();
    }, []);

    return (
        <>
            <div className={classes.space}></div>
            <div className={classes.spaceInner}>
                <a href="/">
                    <Image className={classes.logo} name='logo_white' />
                </a>
                <div className={classes.container}>
                    <div className={classes.aligner}>
                        <Text className={`${classes.text} ${classes.above}`} variant='titleL' weight='bold' align='center'>Error</Text>
                        <img className={classes.image} src={`/img/errors/${code}@3x.png`} alt="" />
                        <Text className={`${classes.text} ${classes.below}`} variant='titleXXL' weight='bold' align='center'>{display.message}</Text>
                        <Text className={`${classes.text} ${classes.note}`} variant='bodyM' align='center'>{display.notes}</Text>
                        <Button onClick={handleClick} className={classes.homeButton} size="large">{t(`home`)}</Button>
                    </div>
                </div>
                <div className={classes.footerWrap}>
                    <FooterModule {...footerData} /> 
                </div>
            </div>
        </>
    );
}