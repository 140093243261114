import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Module from '../components/Module';
import CtaButton from '../components/CtaButton';

const useStyles = makeStyles((theme) => ({
    cta: {},
    ctaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonShortcut: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: theme.spacing(0, 2),
        },
    }
}));


const CtaShortcutModule = ({ cta, ...others }) => {
    const classes = useStyles();
    return (
        <Module className={classes.cta}>
            <Box className={classes.ctaWrapper}>
                {cta && <CtaButton
                        className={classes.buttonShortcut}
                        next
                        size='large'
                        {...cta}
                        trackingObj={others.repo}
                        trackingAction="click_link"
                        trackingLabel={"CtaShortcutModule - "+(cta?.label || cta?.title || cta?.href)}
                    >
                        {cta.text || cta.label}
                    </CtaButton>
                }
            </Box>
        </Module >
    );
};

export default CtaShortcutModule;
