import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  Fragment,
} from "react"
import clsx from "clsx"
import { Container, Box, Hidden, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { showGuides } from "@src/utils/debug"
import shortid from "shortid"
import { pubsub } from "@src/utils/pubsub"
import Module from "../components/Module"
import Parallax from "../components/Parallax"
import Text from "../components/Text"
import Button from "../components/Button"
import CtaButton from "../components/CtaButton"
import Slider from "../components/Slider"
import SliderHero from "@src/components/SliderHeroBanner.js"
import FadeInContainer from "../animations/FadeInContainer"
import FluidImage from "../components/FluidImage"
import Stamp from "../components/Stamp"
import PromoFooter from "../components/PromoFooter"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import Chip from "@material-ui/core/Chip"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const parallaxHeight = 642

const useStyles = makeStyles(theme => ({
  heroBanner: {
    background: "#1d1d1d",
    width: "100%",
    height: 642,
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  hidden: {
    display: "none",
  },
  parallax: {
    position: "absolute",
    zIndex: -1,
    height: parallaxHeight,
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  fadeInContainer: {
    height: "100%",
    width: "100%",
  },

  slider: {
    height: "100%",
    "& .slick-slide": {
      overflow: "hidden",
    },
  },
  sliderArrows: {
    display: "flex",
    justifyContent: "flex-end",
    width: "fit-content",
    right: 1,
    marginTop: -126,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
      top: "40%",
      justifyContent: "space-between",
    },
  },
  sliderBack: {
    height: 60,
    width: 60,
    //border: `4px solid transparent`,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  sliderNext: {
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  heroBannerContainer: {
    height: parallaxHeight,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(3),
      display: "block",
    },
  },
  contentContainer: {
    border: showGuides("2px solid yellow"), // FIX ** guides
    position: "absolute",
    width: "40%",
    zIndex: 99,
    left: theme.spacing(4), // FIX ** apply padding internally
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: 0,
      padding: theme.spacing(2), // FIX ** apply padding internally
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "center",
    },
  },
  logo: {
    width: 350,
    maxHeight: 200,
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "85%", // FIX ** move logo and sizing
      maxHeight: 150, // FIX ** move logo and sizing
      marginTop: 60, // FIX ** move logo and sizing
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center",
      maxWidth: 280,
      //padding: theme.spacing(1, 4, 1, 4) // FIX ** maybe not needed
    },
  },
  logo_narrow_desktop: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxHeight: "15%",
      maxWidth: 250,
    },
  },
  logo_narrow_mobile: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxHeight: "15%",
      maxWidth: 250,
    },
  },
  position_center_logo: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cta: {
    backgroundColor: "#000",
    color: "#fff",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: "initial",
    fontWeight: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      whiteSpace: "nowrap",
    },
  },
  ctaContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    width: 400,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  picture: {
    border: showGuides("3px solid green"), // FIX ** guides
    maxHeight: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      //top: 100,
      bottom: 0,
      left: "50%",
      transform: "translate3d(-50%, 0, 0)",
      width: "230%", // FIX ** to give more space @ fluid images to fill the container
      maxWidth: "930px", // FIX ** to give more space @ fluid images to fill the container
      maxHeight: "100%",
      //height: '100%',
      right: 0,
      //left: 0,
      margin: 0,
      zIndex: 0,
    },
  },
  bannerCtaButton: {
    width: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stamp: {
    left: 0,
    bottom: "100px",
    maxWidth: "120px",
    //padding: 30,
    [theme.breakpoints.down("sm")]: {
      left: "unset",
      right: "3%",
      //top: '0%',
      bottom: "10vh",
    },
  },
  stampTitle: {
    width: "80%",
    margin: "0 auto",
    //paddingBottom: 5,
    ...theme.fonts.faces.bodyXS,
    ...theme.fonts.weights.bold,
    [theme.breakpoints.down("sm")]: {
      ...theme.fonts.weights.bold,
    },
  },
  stamphigHightedText: {
    ...theme.fonts.faces.titleXXXL,
    ...theme.fonts.weights.bold,
    [theme.breakpoints.down("sm")]: {
      ...theme.fonts.faces.titleXXXL,
      ...theme.fonts.weights.bold,
    },
  },
  licenseLogo: {
    position: "absolute",
    height: 57,
    width: 140,
    right: theme.spacing(4),
    bottom: 150,
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(1),
      right: "unset",
      bottom: 10,
      width: "fit-content",
    },
  },
  licenseLogoSingle: {
    bottom: 50,
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(2),
      bottom: 10,
    },
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  fadeInText: {
    animation: "$fadeIn 1.5s",
  },
  parallaxItemWrapper: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "flex-end",
    height: "100%",
    width: "60%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: "120vh",
      maxWidth: "800px",
      height: "auto",
      transform: "translate3d(-50%,0,0)",
    },
  },
  chip: {
    marginBottom: "4px",
  },

  // Mobile styles why not
  heroBannerMobile: { padding: 0 },
  sliderMobile: { height: "100%" },
  sliderArrowsMobile: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "auto",
    gap: theme.spacing(1),
    position: "absolute",
    transform: "translateY(-100%)",
    right: 0,
    paddingBottom: 50,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
      transform: "translateY(0)",
      right: "auto",
      justifyContent: "flex-end",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "10vh",
      width: "100%",
      marginTop: 0,
      top: "100%",
    },
  },
  sliderBackMobile: {
    height: "12vw",
    width: "12vw",
    display: "flex",
    //border: `4px solid transparent`,
    justifyContent: "center",
    alignItems: "center",
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    // marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      width: "60px",
      height: "60px",
      margin: 0,
    },
  },
  sliderNextMobile: {
    height: "12vw",
    width: "12vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    // marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      width: "60px",
      height: "60px",
      margin: 0,
    },
  },

  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  textContentMobile: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 2,
    // justifyContent: "space-between",
  },
  logoMobile: {
    maxHeight: "15%",
    // paddingTop: "3.8%",
    width: "85%",
    maxWidth: "280px",
    // height: "100%",
    // maxHeight: 150,
    position: "relative",
    zIndex: 2,
    "& img": {
      objectFit: "contain",
      width: "100%",
      height: "100%",
      objectPosition: "left",
    },
  },
  cardTitleMobile: {
    fontFamily: "Druk Wide Web",
    fontSize: "3.141361256544503vh",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    paddingTop: "2%",
    [theme.breakpoints.up("sm")]: {},
  },
  cardSubtitleMobile: {
    fontFamily: "Spezia",
    fontSize: "2.094240837696335vh",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    paddingTop: "1.5%",
    // height: "10%",
    [theme.breakpoints.up("sm")]: {},
  },
  pictureMobile: {
    // maxHeight: "46%",
    height: "100%",
    overflow: "hidden",
    "& img": {
      height: "100%",
      width: "100%",
      objectPosition: "bottom",
      objectFit: "contain",
      // paddingBottom: "10%",
    },
  },
  ctaContainer: {
    display: "flex",
    width: "100%",
    // marginTop: theme.spacing(4),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      maxWidth: 240,
      width: "100%",
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4),
    },
  },
  ctaMobile: {
    backgroundColor: "#000",
    color: "#fff",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: "initial",
    fontWeight: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      whiteSpace: "nowrap",
    },
  },
  bannerCtaButtonMobile: {
    display: "flex",
    width: "100%",
    // marginTop: theme.spacing(4),
    marginTop: theme.spacing(0),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      maxWidth: 240,
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4),
    },
  },
  fadeInTextMobile: {
    animation: "$fadeIn 1.5s",
  },
  marginBottomSliderMobile: {
    marginBottom: "10vh",
  },
}))

const HeroStamp = ({ text, highlightedText }) => {
  const classes = useStyles()
  return text && highlightedText ? (
    <Stamp className={classes.stamp}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <Text className={classes.stampTitle}>{text}</Text>
        <Text className={classes.stamphigHightedText}>{highlightedText}</Text>
      </Box>
    </Stamp>
  ) : (
    ""
  )
}

const SingleSlide = ({
  slide,
  i,
  count,
  onReady,
  classes,
  cta,
  pageFamily,
  tracking,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const topic = pubsub.oneoff()
  const handleOnLoad = () => {
    pubsub.publish(topic, true)
  }

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    slide.link &&
    slide.link.href &&
    slide.link.href.includes("/subscribe") &&
    ((slide.link.href.includes("checkout.fanhome.com/") &&
      !slide.link.href.includes("testVersion")) ||
      slide.link.href.includes("p1597070c3tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <>
      <Parallax
        key={`hero_slide_${i}`}
        bgImage={slide.backgroundImage}
        bgMobileImage={slide.mobileBackgroundImage}
        strength={250}
        className={classes.parallax}
        parallaxStyle={{
          position: "absolute",
          zIndex: -1,
          height: parallaxHeight,
        }}
      />
      <Container
        className={classes.heroBannerContainer}
        style={{ position: "relative" }}
      >
        <Hidden smUp>
          <FluidImage
            avoidExtraAnimation
            className={classes.picture}
            alt=""
            {...slide.mobileForegroundImage}
          ></FluidImage>
        </Hidden>
        <Box className={classes.contentContainer}>
          <img
            src={slide?.logo?.src}
            alt={slide?.logo?.alt}
            title={slide?.logo?.title}
            className={clsx(
              classes.logo,
              slide.narrow_logo_desktop && classes.logo_narrow_desktop,
              slide.position_center_logo && classes.position_center_logo
            )}
          />
          <Box className={classes.fadeInText}>
            <Hidden mdUp>
              <Box style={{ position: "relative" }}>
                {slide.stamp && <HeroStamp {...slide.stamp} />}
                {slide.licenseLogo && (
                  <FluidImage
                    className={classes.licenseLogo}
                    {...slide.licenseLogo}
                  />
                )}
              </Box>
            </Hidden>
            {slide.chipText && (
              <Chip className={classes.chip} label={slide.chipText} />
            )}
            <Text
              variant="specialTitleXXL"
              component={pageFamily === "home" ? "h2" : i === 0 ? "h1" : "h2"}
              color="textTertiary"
              className={classes.title}
            >
              {slide.title}
            </Text>
            <Text
              variant="bodyM"
              color="textTertiary"
              className={classes.subtitle}
            >
              {slide.subtitle}
            </Text>
            {!slide.link && cta && cta.productCode && (
              <Box className={classes.ctaContainer}>
                <CtaButton
                  className={classes.bannerCtaButton}
                  {...cta}
                  next
                  size="large"
                  trackingObj={tracking}
                  trackingAction="click_link"
                  trackingLabel={"HeroBannerModule - " + cta.productCode}
                >
                  {cta.text}
                </CtaButton>
              </Box>
            )}
            {/*HOTFIX 26-07*/}
            {slide.link && TEMPORARY_FIX && (
              <Box className={classes.ctaContainer}>
                <CtaButton
                  className={classes.bannerCtaButton}
                  //HOTFIX 26-07
                  forceOverrideLinkTEMPORARY={slide.link.href}
                  next
                  size="large"
                  {...cta}
                  trackingObj={tracking}
                  trackingAction="click_link"
                  trackingLabel={
                    "HeroBannerModule - " + (cta?.label || cta?.title)
                  }
                >
                  {slide.link.title}
                </CtaButton>
              </Box>
            )}
            {/*HOTFIX 26-07*/}
            {slide.link && !TEMPORARY_FIX && (
              <Button
                className={classes.bannerCtaButton}
                next
                size="large"
                {...slide.link}
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={
                  "HeroBannerModule - " +
                  (slide.link?.label || slide.link?.title)
                }
              >
                {slide.link.title}
              </Button>
            )}
          </Box>
        </Box>
        <Hidden xsDown>
          <ParallaxItem
            disabled={isMobile}
            className={classes.parallaxItemWrapper}
            y={["10", "-10"]}
          >
              <Hidden smDown>
                {slide.stamp && <HeroStamp {...slide.stamp} />}
              </Hidden>
              <FluidImage
                avoidExtraAnimation
                className={classes.picture}
                alt=""
                onLoad={handleOnLoad}
                {...slide.foregroundImage}
              ></FluidImage>
          </ParallaxItem>
          {slide.licenseLogo && (
            <FluidImage
              className={clsx(classes.licenseLogo, {
                [classes.licenseLogoSingle]: count === 1,
              })}
              {...slide.licenseLogo}
            />
          )}
        </Hidden>
      </Container>
    </>
  )
}

const SingleSlideMobile = props => {
  const classes = useStyles()
  const { slide, pageFamily, i, tracking, cta, count } = props
  const { headerInfo } = useContext(GlobalStateContext)
  const [dispatchH, setDispatchH] = useState(0)
  const [navSize, setNavSize] = useState(0)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const topic = pubsub.oneoff()
  const handleOnLoad = () => {
    pubsub.publish(topic, true)
  }

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    slide.link &&
    slide.link.href &&
    slide.link.href.includes("/subscribe") &&
    ((slide.link.href.includes("checkout.fanhome.com/") &&
      !slide.link.href.includes("testVersion")) ||
      slide.link.href.includes("p1597070c3tst-store.occa.ocs.oraclecloud.com/"))

  useEffect(() => {
    if (headerInfo) {
      setDispatchH(
        headerInfo.total + headerInfo.differentCountry + headerInfo.topbar
      )
      setNavSize(headerInfo.total)
    }
  }, [headerInfo])

  return (
    <Fragment>
      <Parallax
        bgImage={slide.backgroundImageMobile}
        bgMobileImage={slide.mobileBackgroundImage}
      >
        <Box
          className={classes.textContentMobile}
          px={2}
          pb={2}
          style={{
            paddingTop: `${navSize - 110}px`,
          }}
        >
          <Box
            className={clsx(
              classes.logoMobile,
              slide.narrow_logo_mobile && classes.logo_narrow_mobile,
              slide.position_center_logo && classes.position_center_logo
            )}
          >
            <img
              src={slide?.logo?.src}
              alt={slide?.logo?.alt}
              title={slide?.logo?.title}
            />
          </Box>
          <Box>
            <Text
              component={pageFamily === "home" ? "h2" : i === 0 ? "h1" : "h2"}
              color="textTertiary"
              className={classes.cardTitleMobile}
            >
              {slide?.title}
            </Text>
            <Text
              id="cardSubtitle"
              color="textTertiary"
              className={classes.cardSubtitleMobile}
              component="p"
            >
              {slide.subtitle}
            </Text>
          </Box>
            
          <FluidImage
            avoidExtraAnimation
            className={classes.pictureMobile}
            alt=""
            {...slide.mobileForegroundImage}
          ></FluidImage>
          {!slide.link && cta && cta.productCode && (
            <Box
              className={classes.ctaContainerMobile}
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              mb={2}
              px={2}
            >
              <CtaButton
                className={classes.bannerCtaButtonMobile}
                {...cta}
                next
                size="large"
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={"HeroBannerModule - " + cta.productCode}
              >
                {cta.text}
              </CtaButton>
            </Box>
          )}
          {/*HOTFIX 26-07*/}
          {slide.link && TEMPORARY_FIX && (
            <Box
              className={classes.ctaContainerMobile}
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              mb={2}
              px={2}
            >
              <CtaButton
                className={classes.bannerCtaButtonMobile}
                //HOTFIX 26-07
                forceOverrideLinkTEMPORARY={slide.link.href}
                next
                size="large"
                {...cta}
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={
                  "HeroBannerModule - " + (cta?.label || cta?.title)
                }
              >
                {slide.link.title}
              </CtaButton>
            </Box>
          )}
          {/*HOTFIX 26-07*/}
          {slide.link && !TEMPORARY_FIX && (
            <Box
              className={classes.ctaContainerMobile}
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              mb={2}
              px={2}
            >
              <Button
                className={classes.bannerCtaButtonMobile}
                next
                size="large"
                {...slide.link}
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={
                  "HeroBannerModule - " +
                  (slide.link?.label || slide.link?.title)
                }
              >
                {slide.link.title}
              </Button>
            </Box>
          )}
        </Box>
      </Parallax>
    </Fragment>
  )
}

const HeroBannerModule = props => {
  const { slides, cta, footer, repo } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [dispatchH, setDispatchH] = useState(0)
  const { headerInfo } = useContext(GlobalStateContext)
  const pageFamily = useMemo(() => {
    return repo.tracking.dataLayer[0].pageFamily
  }, [])

  const classes = useStyles()

  useEffect(() => {
    if (headerInfo) {
      setDispatchH(headerInfo.differentCountry + headerInfo.topbar)
    }
  }, [headerInfo])

  return (
    <Fragment>
      {/* Desktop */}
      <Module
        alwaysVisible
        className={!isMobile ? classes.heroBanner : classes.hidden}
        variant="full"
        marginBottomOnly
      >
        {slides && (
          <Slider
            className={classes.slider}
            classes={{
              arrows: classes.sliderArrows,
              back: classes.sliderBack,
              next: classes.sliderNext,
            }}
            arrows={(slides || []).length > 1}
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            dark
            tracking={repo}
          >
            {(slides || []).map((slide, i) => (
              <SingleSlide
                key={shortid.generate()}
                slide={slide}
                i={i}
                classes={classes}
                cta={cta}
                pageFamily={pageFamily}
                tracking={repo}
              />
            ))}
          </Slider>
        )}

        {footer && cta && (cta.productCode || cta.href) && (
          <PromoFooter {...footer} cta={cta} tracking={repo} />
        )}
      </Module>

      {/* Mobile */}
      <Module
        alwaysVisible
        className={isMobile ? classes.heroBannerMobile : classes.hidden}
        variant="full"
        marginBottomOnly
      >
        <div
          className={clsx(
            (slides || []).length > 1 ? classes.marginBottomSliderMobile : ""
          )}
          style={{
            minHeight: `calc(${
              isMobile
                ? (slides || []).length > 1
                  ? 90 + "vh"
                  : 100 + "vh"
                : parallaxHeight + "px"
            } - ${isMobile ? dispatchH : 0}px)`,
            height: `calc(${
              isMobile
                ? (slides || []).length > 1
                  ? 90 + "vh"
                  : 100 + "vh"
                : "auto"
            } - ${isMobile ? dispatchH : null}px)`,
          }}
        >
          {slides && (
            <SliderHero
              className={classes.sliderMobile}
              classes={{
                arrows: classes.sliderArrowsMobile,
                back: classes.sliderBackMobile,
                next: classes.sliderNextMobile,
              }}
              arrows={(slides || []).length > 1}
              infinite={false}
              slidesToShow={1}
              slidesToScroll={1}
              tracking={repo}
            >
              {slides.map((slide, index) => {
                return (
                  <SingleSlideMobile
                    key={shortid.generate()}
                    pageFamily={pageFamily}
                    slide={slide}
                    i={index}
                    cta={cta}
                    tracking={repo}
                  ></SingleSlideMobile>
                )
              })}
            </SliderHero>
          )}
        </div>

        {footer && cta && (cta.productCode || cta.href) && (
          <PromoFooter {...footer} cta={cta} tracking={repo} />
        )}
      </Module>

    </Fragment>
  )
}

export default HeroBannerModule
