import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Module from "../components/Module"
import Text from "../components/Text"
import Picture from "../components/Picture"

const useStyles = makeStyles(theme => ({
  howItWorksV2: {},
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  grid: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  },
  element: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  elementIcon: {
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px",
    },
  },
  elementTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold !important",
  },
  elementTextcontainer: {
    padding: theme.spacing(0, 1),
  },
  bottomElementBackGround: {
    backgroundColor: theme.customPalette.greyClear,
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0),
      flexDirection: "column",
    },
  },
  bottomContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bottomIcon: {
    width: "100%",
    maxWidth: "38px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "48px",
      marginRight: 0,
    },
  },
}))

const HowItWorksV2Module = props => {
  const classes = useStyles()
  const { title, items, bottom_icon, bottom_title } = props

  return (
    <Module className={classes.howItWorksV2}>
      <Text
        className={classes.title}
        align="center"
        component="h2"
        variant="titleXXXL"
        weight="bold"
        overline
      >
        {title || ""}
      </Text>
      <Grid container className={classes.grid}>
        {(items || []).map((el, i) => (
          <Grid key={i} item xs={12} md={true} className={classes.element}>
            <Grid container>
              <Grid container xs={3} justifyContent="center">
                <Picture {...el.icon} imgClassName={classes.elementIcon} />
              </Grid>
              <Grid item xs={9} className={classes.elementTextcontainer}>
                <Text
                  variant="titleXL"
                  component="h3"
                  color="primary"
                  weight="bold"
                  className={classes.elementTitle}
                >
                  {el.title || ""}
                </Text>
                <Text variant="bodyM" className={classes.description}>
                  {el.description || ""}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container className={classes.bottomElementBackGround}>
        <Grid container xs={12} className={classes.bottomContainer}>
          <Picture {...bottom_icon} imgClassName={classes.bottomIcon} />
          <Text
            variant="titleXL"
            component="h5"
            color="primary"
            weight="bold"
            className={classes.elementTitle}
          >
            {bottom_title || ""}
          </Text>
        </Grid>
      </Grid>
    </Module>
  )
}

const defaultProps = {
  title: "",
  items: [],
  bottom_icon: {},
  bottom_title: "",
}

HowItWorksV2Module.defaultProps = defaultProps

export default HowItWorksV2Module
