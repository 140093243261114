import React from 'react';
import Error from './Error';
import Page404 from '@src/pages/404'

const Error404 = () => {

  return (
    <Error code="404" head="Not Found" message="">
      <Page404 />
    </Error>
  );
};

export default Error404;
