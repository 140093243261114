import React from 'react';
import DefaultTemplate from "@src/site/templates/default";
import Error from '@src/modules/common/Error';
import { detectAlpha2Country } from '@src/utils/country';

export default function Error404() {
    const country = detectAlpha2Country();
    const page = {
        content: null,
        seo: {},
        tracking: {
            dataLayer: [
                {
                    country: country,
                    pageFamily: "home",
                    pageName: "error",
                    userId: ""
                },
            ]
        }
    }

    return (
        <DefaultTemplate page={page}>
            <Error code="404" />
        </DefaultTemplate>
    );
}