import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useInView } from "react-intersection-observer"
import Module from "../components/Module"
import Text from "../components/Text"

const useStyles = makeStyles(theme => ({
  howItWorks: {},
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  number: {
    marginBottom: theme.spacing(3),
    fontSize: 33,
  },
  elementTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold !important",
  },
  grid: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  "@keyframes slideIn15": {
    "0%": { opacity: 0, transform: "translateY(15%)" },
    "100%": { opacity: 1, transform: "translateY(0%)" },
  },
  gridElement: {
    opacity: 0,
    borderBottom: `3px solid ${theme.customPalette.darkishPurple}`,
    padding: theme.spacing(5, 7),
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: theme.customPalette.greyClear,

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      border: 0,
      margin: theme.spacing(2, 0),
      backgroundColor: "#FFF",
    },
  },
  description: {
    lineHeight: 1.5,
  },
}))

const HowItWorksModule = ({ title, items }) => {
  const classes = useStyles()
  const { ref, inView, entry } = useInView()
  const [animate, setAnimate] = React.useState(true)

  useEffect(() => {
    if (animate && inView && ref) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: i * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, i * 50 + 500)
      })
    }
  }, [ref, inView, entry])

  return (
    <Module className={classes.howItWorks} alwaysVisible>
      <Text
        className={classes.title}
        align="center"
        component="h2"
        variant="titleXXXL"
        weight="bold"
        overline
      >
        {title}
      </Text>
      <Grid ref={ref} container className={classes.grid}>
        {(items || []).map((el, i) => (
          <Grid key={i} item xs={12} md={true} className={classes.gridElement}>
            <Grid container>
              <Grid item xs={3} md={12}>
                <Text
                  className={classes.number}
                  component="h3"
                  variant="specialTitleXL"
                  color="textSecondary"
                >
                  {el.number}
                </Text>
              </Grid>

              <Grid item xs={9} md={12}>
                <Text
                  variant="titleXL"
                  component="h3"
                  color="primary"
                  weight="bold"
                  className={classes.elementTitle}
                >
                  {el.title}
                </Text>
                <Text variant="bodyM" className={classes.description}>
                  {el.description}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Module>
  )
}

const defaultProps = {
  title: "",
  items: [],
}

HowItWorksModule.defaultProps = defaultProps

export default HowItWorksModule
