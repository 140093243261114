import React from "react"
import txt from "@src/utils/txt"

import Box from "@material-ui/core/Box"
import { Grid, Hidden, Chip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Slider from "../components/Slider"
import Text from "../components/Text"
import FluidImage from "../components/FluidImage"
import CtaButton from "../components/CtaButton"
import Button from "../components/Button"
import Module from "../components/Module"
import { EqualHeight, EqualHeightElement } from "../components/EqualHeight"
import Icon from "../components/Icon"
import Stamp from "../components/Stamp"

const useStyles = makeStyles(theme => ({
  package: {},
  moduleTitle: {
    marginBottom: 130,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  packageItemContainer: {
    position: "relative",
    background: theme.customPalette.greyClear,
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(2),
      borderLeft: `5px solid ${theme.customPalette.darkishPurple}`,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      paddingTop: 90,
      background: "white",
      borderBottom: `5px solid ${theme.customPalette.darkishPurple}`,
      height: "100%",
    },
    marginTop: 130,
    marginBottom: 60,
  },
  packageItem: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingLeft: 0,
      paddingRight: 0,
      boxShadow: "0 2px 9px 0 rgba(0, 0, 0, 0.08)",
      border: "solid 1px #e7e7e7",
      backgroundColor: "#ffffff",
      height: "100%",
    },
  },
  textContent: {
    textAlign: "left",
    position: "relative",
    paddingTop: 30,
  },
  title: {
    // fontSize: 'min(max(28px, 2.5vw), 36px)', // FIX ** no font override
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  figureContent: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: -130,
  },
  figurePromo: {
    color: "#fff",
    backgroundColor: theme.customPalette.darkishPurple,
    ...theme.fonts.faces.bodyM,
    width: 68,
    height: 68,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1,
    fontSize: 14,
    bottom: "13%",
    left: "10%",
    position: "absolute",
  },
  specialOfferChip: {
    background: theme.customPalette.darkishPurple,
    transform: "rotate3d(0,0,1,-45deg)",
    position: "absolute",
    textAlign: "center",
    top: 26,
    left: -35,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      color: "#fff",
      backgroundColor: theme.customPalette.darkishPurple,
      ...theme.fonts.faces.bodyM,
      width: 80,
      height: 80,
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      fontSize: 14,
      top: "auto",
      bottom: "-12%",
      left: "-5%",
      position: "absolute",
      zIndex: 1,
      transform: "none",
    },
  },
  specialOfferText: {
    lineHeight: "25px !important",
  },
  originalPrice: {
    color: theme.customPalette.darkishPurple,
    textDecoration: "line-through",
    marginRight: theme.spacing(1),
  },
  figurePictureContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      minWidth: "10em",
      padding: "0.25em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  figure: {
    width: "100%",
    height: "200px",
    [theme.breakpoints.down("sm")]: {
      height: "152px",
      marginTop: -40,
    },

    "& img": {
      objectFit: "contain",
      objectPosition: "top",
      width: "100%",
      height: "100%",
    },
  },
  chipImage: {
    width: 20,
    height: 20,
  },
  chipImageWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: 14,
  },
  chipText: {
    fontSize: 16,
    fontWeight: 500,
  },
  stamp: {
    width: 136,
    height: 136,
    //fontSize: 18,
    top: 0,
    ...theme.fonts.faces.bodyS,
    ...theme.fonts.weights.bold,
    lineHeight: "1rem !important",
    [theme.breakpoints.down("sm")]: {
      width: 84,
      height: 84,
      left: -20,
      top: 70,
      lineHeight: "1rem !important",
    },
  },
  stampWrapper: {
    position: "absolute",
    width: "100%",
    height: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      maxHeight: 360,
    },
  },
  chipMobileContainer: {
    display: "flex",
    justifyContent: "center",
  },
  chip: {
    display: "flex",
    alignItems: "center",
    minHeight: "30px",
    padding: "4px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      marginBottom: 20,
    },
  },
  bigStampTitle: {
    fontSize: 49,
    lineHeight: "49px",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 29,
      lineHeight: "29px",
    },
  },
  preBigStampTitle: {
    fontSize: 24,
    lineHeight: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "14px",
    },
  },
  postBigStampTitle: {
    fontSize: 24,
    alignSelf: "flex-end",
    marginBottom: 6,
    lineHeight: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginBottom: 5,
      lineHeight: "14px",
    },
  },
  figureTitle: {
    lineHeight: "normal !important",
  },
  figureContainer: {
    marginTop: -110,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.customPalette.greyClear,
      display: "flex",
      justifyContent: "center",
      height: 150,
    },
  },
  figureListContainer: {
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #e7e7e7",
      "&:last-child": {
        borderBottom: 0,
      },
    },
  },
  sliderContainer: {
    "& .slick-slide": {
      marginRight: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
  ctaButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ctaButton: {
    textTransform: "none",
  },
}))

const PackageModule = ({ moduleTitle, items, cta, ...others }) => {
  const classes = useStyles()

  const RIBBON = "ribbon"
  const BULLET = "bullet"

  const stampContent = content => {
    if (!content) return ""
    const array = content.split(/(%)/g);
    if (array.length === 1) return content

    const array2 = array[0].split(/(-?[0-9]+)$/g);
    return (
      <>
        { array2?.length === 3 ?
        <>
          {array2[0]}
          <div className={classes.bigStampTitle}>
            { array2[1].match(/-/g) && ( <div className={classes.preBigStampTitle}>-</div>) }
            {array2[1].replace('-','')}
            <div className={classes.postBigStampTitle}>{array[1]}</div>
          </div>
          <div>{array[2]}</div>
        </>
        :
        <>
          <div>{array[0]}</div>
          <div className={classes.postBigStampTitle}>{array[1]}</div>
          <div>{array[2]}</div>
        </>
        }        
      </>
    )
  }

  const settings = {
    tracking: others.repo,
    arrows: true,
    equalHeight: true,
    variableWidth: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    className: classes.sliderContainer,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    cta &&
    cta.href &&
    cta.href.includes("/subscribe") &&
    ((cta.href.includes("checkout.fanhome.com/") &&
      !cta.href.includes("testVersion")) ||
      cta.href.includes("p1597070c1tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <Module className={classes.package}>
      <Box textAlign="center">
        <Text
          className={classes.moduleTitle}
          variant="titleXXXL"
          align="center"
          overline
          weight="bold"
        >
          {moduleTitle}
        </Text>

        <Hidden smDown>
          {(items || []).map((el, i) => (
            <Box style={{ position: "relative" }} key={`package_${i}`}>
              {el.isSpecialOffer &&
                el.specialOfferText &&
                (!el.renderType || el.renderType.toLowerCase() === RIBBON) && (
                  <div
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      height: "200px",
                      width: "150px",
                      background: "transparent",
                      zIndex: "1",
                    }}
                  >
                    <Box className={classes.specialOfferChip}>
                      <Text
                        className={classes.specialOfferText}
                        variant="bodyXXS"
                        color="textTertiary"
                        weight="bold"
                        align="center"
                        verticalAlign="middle"
                      >
                        {el.specialOfferText}
                      </Text>
                    </Box>
                  </div>
                )}
              <Box className={classes.packageItemContainer}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(5, 1fr)`,
                  }}
                  className={classes.packageItem}
                >
                  <div
                    style={
                      el.isSpecialOffer &&
                      el.specialOfferText &&
                      el.renderType &&
                      el.renderType.toLowerCase() === BULLET
                        ? { alignSelf: "baseline", paddingTop: "10px" }
                        : {}
                    }
                    className={`${classes.textContent}`}
                  >
                    {el.freeShippingText && el.isFreeShipping && (
                      <Box mt={3} display="flex" alignItems="center">
                        <Chip
                          className={classes.chip}
                          icon={
                            <div className={classes.chipImageWrapper}>
                              <Icon
                                className={classes.chipImage}
                                name="shipping"
                              />
                            </div>
                          }
                          label={
                            <div className={classes.chipText}>
                              {el.freeShippingText}
                            </div>
                          }
                        />
                      </Box>
                    )}
                    <Box py={2}>
                      <Text
                        className={classes.title}
                        component="p"
                        variant="titleXXL"
                        weight="bold"
                      >
                        {el.title}
                      </Text>
                    </Box>
                    {!(el.freeShippingText && el.isFreeShipping) && (
                      <div style={{ paddingBottom: 30 }}>
                        <Text variant="bodyM" weight="medium">
                          {el.subtitle}
                        </Text>
                      </div>
                    )}
                    {el.isSpecialOffer &&
                      el.specialOfferText &&
                      el.renderType &&
                      el.renderType.toLowerCase() === BULLET && (
                        <div className={classes.stampWrapper}>
                          <Stamp className={classes.stamp}>
                            {stampContent(el.specialOfferText)}
                          </Stamp>
                        </div>
                      )}
                  </div>
                  {el.figures &&
                    el.figures.length < 5 &&
                    [...Array(Math.ceil((4 - el.figures.length) / 2))].map(
                      (x, i) => <div key={`padding_${i}`}></div>
                    )}
                  {(el.figures || []).map((figure, i) => (
                    <Box
                      key={`figure_${i}`}
                      className={classes.figureContainer}
                      position="relative"
                    >
                      <Box px={3}>
                        <FluidImage
                          {...figure.picture}
                          className={classes.figure}
                        />
                      </Box>
                      <Box px={1}>
                        <Text variant="bodyM" weight="medium">
                          {txt.newLineBeforeParentheses(figure.title)}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {figure.originalPrice && (
                          <Text
                            variant="bodyXS"
                            className={classes.originalPrice}
                          >
                            {figure.originalPrice}
                          </Text>
                        )}

                        <Text variant="bodyM" weight="bold">
                          {figure.price}
                        </Text>
                      </Box>
                      {figure.offerText && (
                        <Box className={classes.figurePromo}>
                          {figure.offerText}
                        </Box>
                      )}
                    </Box>
                  ))}
                </div>
              </Box>
            </Box>
          ))}
        </Hidden>
        {/* MOBILE - FIX ** EqualHeight uses document. object to make his job and this break SSE build process */}
        <Hidden mdUp>
          <EqualHeight>
            <Slider {...settings}>
              {(items || []).map((el, i) => (
                <Box
                  key={`package_mobile_${i}`}
                  className={classes.packageItemContainer}
                >
                  <Box className={classes.packageItem}>
                    <Box className={classes.figureContainer} display="flex">
                      <Box
                        key={`figure`}
                        position="relative"
                        className={classes.figurePictureContainer}
                      >
                        {/*(el.isSpecialOffer) && (
                          <Box className={classes.specialOfferChip}><Text varinat='bodyXS'>{el.specialOfferText}</Text></Box>
                        )*/}
                        <FluidImage
                          {...el.mobileImage}
                          className={classes.figure}
                        />
                        {el.isSpecialOffer && el.specialOfferText && (
                          <div className={classes.stampWrapper}>
                            <Stamp className={classes.stamp}>
                              {stampContent(el.specialOfferText)}
                            </Stamp>
                          </div>
                        )}
                      </Box>
                    </Box>
                    <Box textAlign="left" px={2}>
                      <EqualHeightElement name="header">
                        <Box pt={3} pb={2}>
                          <Text
                            className={classes.title}
                            variant="titleXXL"
                            align="center"
                            weight="bold"
                            component="p"
                          >
                            {el.title}
                          </Text>
                        </Box>
                        <Box>
                          {el.isFreeShipping && el.freeShippingText ? (
                            <div className={classes.chipMobileContainer}>
                              <Chip
                                className={classes.chip}
                                icon={
                                  <div className={classes.chipImageWrapper}>
                                    <Icon
                                      className={classes.chipImage}
                                      name="shipping"
                                    />
                                  </div>
                                }
                                label={
                                  <div className={classes.chipText}>
                                    {el.freeShippingText}
                                  </div>
                                }
                              />
                            </div>
                          ) : (
                            <Text
                              className={classes.subtitle}
                              variant="bodyS"
                              align="center"
                            >
                              {el.subtitle}
                            </Text>
                          )}
                        </Box>
                      </EqualHeightElement>
                    </Box>
                    <Box px={2}>
                      {(el.figures || []).map((figure, i) => (
                        <Grid
                          container
                          alignItems="center"
                          key={`figure_list_${i}`}
                          className={classes.figureListContainer}
                        >
                          <Grid xs={8} sm={9} item>
                            <Text
                              className={classes.figureTitle}
                              noWrap
                              align="left"
                              variant="bodyS"
                              weight="bold"
                            >
                              {txt.brakeBeforeParentheses(figure.title)}
                            </Text>
                          </Grid>
                          <Grid xs={4} sm={3} item>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              width="100%"
                            >
                              {figure.offerText && (
                                <Box width="100%" textAlign="right">
                                  <Text
                                    variant="bodyXS"
                                    weight="bold"
                                    color="textSecondary"
                                  >
                                    {figure.offerText}
                                  </Text>{" "}
                                </Box>
                              )}
                              <Box
                                display="flex"
                                py={1}
                                textAlign="right"
                                alignItems="center"
                              >
                                {figure.originalPrice && (
                                  <Text
                                    noWrap
                                    variant="bodyXS"
                                    className={classes.originalPrice}
                                  >
                                    {figure.originalPrice}
                                  </Text>
                                )}
                                <Text noWrap variant="bodyM" weight="medium">
                                  {figure.price}
                                </Text>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </EqualHeight>
        </Hidden>
      </Box>
      {cta && cta.productCode && (
        <Box className={classes.ctaButtonWrapper}>
          <CtaButton
            className={classes.ctaButton}
            next
            size="large"
            {...cta}
            trackingObj={others.repo}
            trackingAction="click_link"
            trackingLabel={"PackageModule - " + cta.productCode}
          >
            {cta.text}
          </CtaButton>
        </Box>
      )}
      {/*HOTFIX 26-07*/}
      {cta && cta.label && cta.href && TEMPORARY_FIX && (
        <Box className={classes.ctaButtonWrapper}>
          <CtaButton
            className={classes.ctaButton}
            next
            size="large"
            forceOverrideLinkTEMPORARY={cta.href}
            {...cta}
            trackingObj={others.repo}
            trackingAction="click_link"
            trackingLabel={"PackageModule - " + (cta?.text || "")}
          >
            {cta.text}
          </CtaButton>
        </Box>
      )}
      {/*HOTFIX 26-07*/}
      {cta && cta.label && cta.href && !TEMPORARY_FIX && (
        <Box className={classes.ctaButtonWrapper}>
          <Button
            className={classes.ctaButton}
            next
            size="large"
            {...cta}
            trackingObj={others.repo}
            trackingAction="click_link"
            trackingLabel={
              "PackageModule - " + (cta?.label || cta?.title || "")
            }
          >
            {cta.label}
          </Button>
        </Box>
      )}
    </Module>
  )
}

const defaultProps = {
  moduleTitle: "",
  items: [],
}

PackageModule.defaultProps = defaultProps

export default PackageModule
