import React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import { Container, Grid, Chip, Hidden, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import txt from "@src/utils/txt"

import Module from "../components/Module"
import Text from "../components/Text"
import Button from "../components/Button"
import Parallax from "../components/Parallax"
import FluidImage from "../components/FluidImage"
import Stamp from "../components/Stamp"

const useStyles = makeStyles(theme => ({
  collectionList: {
    padding: 0,
    // "& $collectionWrapper:first-child ": {
    //   marginTop: 0,
    // },
  },
  title: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  elementContainer: {
    height: "100%",
    position: "relative",
    minHeight: 450,
  },
  /* BACKGROUND */
  background: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  backgroundLayer0: {
    position: "absolute !important",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      "& .parallax-inner > div": {
        margin: "-10%",
      },
    },
  },
  backgroundLayer1: {
    position: "absolute",
    top: "50%",
    right: 0,
    height: "124%",
    width: "74%",
    maxHeight: 620,
    transform: "translate(0, -50%)",
    [theme.breakpoints.down("md")]: {
      "& .parallax-inner": {
        justifyContent: "center",
      },
    },
    [theme.breakpoints.down("sm")]: {
      right: "unset",
      left: "50%",
      width: "100%",
      transform: "translate(-50%, -50%)",
      "& img": {
        maxWidth: 400,
      },
    },
  },
  item: {
    maxHeight: 620,
  },
  /* FOREGROUND */
  foreground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    maxWidth: "55%",
    height: "100%",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      padding: theme.spacing(2, 3),
    },
  },
  foreground1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    maxWidth: "55%",
    height: "100%",
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      padding: theme.spacing(2, 3),
    },
  },
  logo: {
    maxWidth: "95%",
    maxHeight: 110,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "56%",
      marginBottom: "30%",
    },
  },
  chipContainer: {
    marginBottom: theme.spacing(1),
  },
  chip: {},
  textContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    padding: "14px 40px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stamp: {
    position: "absolute",
    fontFamily: "SpeziaExtended",
    fontSize: 18,
    lineHeight: 1,
    fontWeight: "bold",
    bottom: theme.spacing(12),
    transform: "translate(-50%, -50%)",
    right: 0,
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      bottom: "45%",
      right: 0,
      left: "70px",
    },
  },
  stampSmall: {
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      transform: "translate(-10%, -50%)",
    },
  },
  big: {
    "& $backgroundLayer1": {
      [theme.breakpoints.up("md")]: {
        width: "70%",
      },
    },
    "& $foreground": {
      [theme.breakpoints.up("md")]: {
        maxWidth: "33%",
      },
    },
    "& $logo": {
      maxHeight: 147,
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    "& $button": {
      [theme.breakpoints.up("md")]: {
        width: "100%",
        maxWidth: "500px",
        height: 56,
      },
    },
  },
  small: {
    "& $backgroundLayer1": {
      [theme.breakpoints.up("md")]: {
        width: "88%",
      },
    },
    "& $logo": {
      maxHeight: 110,
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    "& $item": {
      alignItems: "initial",
    },
  },
  collectionWrapper: {
    margin: theme.spacing(5, 1 / 2),
    [theme.breakpoints.down("sm")]: {
      margin: "10px 4px",
      // marginBottom: "40px !important",
      // "&:not(:last-child)": {
      //   margin: theme.spacing(0, 1 / 2, 17, 1 / 2),
      //   marginBottom: "134px !important",
      // },
    },
  },
  position_center_logo: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  narrow_logo_desktop: {
    [theme.breakpoints.up("sm")]: {
      maxHeight: 125,
    },
  },
  narrow_logo_mobile: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "56%",
      marginBottom: "30%",
      maxHeight: "125px",
    },
  },
}))

const CollectionListElement = ({
  collection,
  variant = "normal",
  singleEl,
  moduleTitle,
  tracking,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div
      className={clsx(classes.elementContainer, {
        [classes[variant]]: variant !== "normal",
      })}
    >
      <Box className={classes.background}>
        <Parallax
          className={classes.backgroundLayer0}
          bgImage={collection.backgroundImage}
          bgMobileImage={collection.mobileBackgroundImage}
        ></Parallax>
        <ParallaxItem
          disabled={isMobile}
          className={classes.backgroundLayer1}
          styleInner={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
          y={["8", "-8"]}
        >
          <Hidden mdUp>
            <FluidImage
              className={classes.item}
              {...collection.mobileForegroundImage}
            />
          </Hidden>
          <Hidden smDown>
            <FluidImage
              className={classes.item}
              {...collection.foregroundImage}
            />
          </Hidden>
          {collection.stampText && (
            <Stamp
              className={
                variant === "small"
                  ? `${classes.stamp} ${classes.stampSmall}`
                  : classes.stamp
              }
              reduceTextSpace
            >
              {txt.lastWord(collection.stampText, 1.5)}
            </Stamp>
          )}
        </ParallaxItem>
      </Box>
      <Box className={classes.foreground}>
        {collection.logo && (
          <FluidImage
            className={clsx(
              classes.logo,
              collection.position_center_logo && classes.position_center_logo,
              collection.narrow_logo_desktop && classes.narrow_logo_desktop,
              collection.narrow_logo_mobile && classes.narrow_logo_mobile
            )}
            {...collection.logo}
          />
        )}
        <Box width="100%">
          {collection.chipText && (
            <Box className={classes.chipContainer}>
              <Chip className={classes.chip} label={collection.chipText} />
            </Box>
          )}
          <Box className={classes.textContainer}>
            <a href={collection.link?.href}>
              <Text className="specialTitleXL" color="textTertiary">
                {collection.title}
              </Text>
            </a>

            {/* <Typography>{collection.description}</Typography> */}
          </Box>
          {collection.link && collection.link.title && (
            <Box>
              <Button
                className={classes.button}
                {...collection.link}
                variant="contained"
                next
                size="large"
                trackingObj={tracking}
                trackingAction={moduleTitle}
                trackingLabel={
                  "CollectionListModule - " +
                  (collection.link?.label ||
                    collection.link?.title ||
                    collection.link?.href)
                }
              >
                {collection.link.title}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

const CollectionListModule = ({ title, collections, ...others }) => {
  const classes = useStyles()

  var variant = "medium"
  if (collections.length === 1) {
    variant = "big"
  } else if (collections.length > 2) {
    variant = "small"
  }

  return (
    <Module className={classes.collectionList}>
      {title && (
        <Text
          className={classes.title}
          align="center"
          variant="titleXXXL"
          weight="bold"
          overline
        >
          {title}
        </Text>
      )}
      <Grid container>
        {(collections || []).map((collection, i) => (
          <Grid
            key={`collection_${i}`}
            className={classes.collectionWrapper}
            item
            xs={12}
            md={true}
          >
            <CollectionListElement
              variant={variant}
              collection={collection}
              singleEl={collections.length === 1}
              moduleTitle={title || ""}
              tracking={others.repo || null}
            />
          </Grid>
        ))}
      </Grid>
    </Module>
  )
}

const defaultProps = {
  collections: [],
}

CollectionListModule.defaultProps = defaultProps

export default CollectionListModule
