import React from 'react';
import cookie from '@src/utils/cookie';
import { window } from 'browser-monads';

const cookieNamePrefix = 'first_visit_flag';

const FirstVisitModule = ({ url }) => {

    const path = window.location.pathname;
    // allow to use this module on multiple pages.
    const cookieName = `${cookieNamePrefix}_${path}`;

    // check if the user has been here before
    if (!cookie.isPresent(cookieName)) {
        window.location.replace(url + window.location.search);
        cookie.createCookie(cookieName);
    }

    return null;
};

export default FirstVisitModule;
