import React, { useState, useRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import withStyles from '@material-ui/core/styles/withStyles';

import { IconButton, Modal } from '@material-ui/core';

import Icon from './Icon';
import PlayButton from './PlayButton';

import { GTMEvent } from "../utils/gtm";

export const styles = (theme) => ({
    video: {
    },
    image: {
        backgroundSize: 'cover',
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: '100%',
        height: '100%',
    },
    foreground: {
        /* zIndex: 99  --> why is needed here? */
    },
    playerWrapper: {
        position: "relative",
    },
    player: {
        position: "absolute",
        top: "0",
        left: "0"
    },
    playWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    fullscreenWrapper: {
        display: "grid",
        objectPosition: "center",
        gridTemplate: 'auto 1fr / auto',
        width: "100%",
        height: "100%",
        //overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    fullscreenVideoWrapper: {
        position: 'relative',
        backgroundColor: 'black',
        width: "100%",
        height: "100%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "60vw"
        }
    },
    grow: {
        flexGrow: 1,
    },
    fullscreenBar: {
        position: 'relative',
        display: 'flex',
        height: 30,
        padding: "0 8px 0 8px !important",
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            height: 80,
            backgroundColor: 'transparent',
        }
    },
    playButtonFullscreenWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    closeButton: {
        height: 'fit-content',
        margin: 'auto'
    },
    closeIcon: {
        height: 24,
        width: 24
    },
});


const FullscreenBar = ({ classes, onClose }) => {
    return (
        <div className={classes.fullscreenBar}>
            <div className={classes.grow} />
            <IconButton className={classes.closeButton} edge="start" size="small" color="inherit" aria-label="close" disableRipple onClick={onClose} >
                <Icon className={classes.closeIcon} name="close_white" />
            </IconButton>
        </div>
    );
}

const Video = React.forwardRef(function Video(props, ref) {

    const {
        classes,
        className,
        url,
        preview,
        config = {
            youtube: {
                playerVars: {
                    rel: 0,
                    modestbranding: 1,
                    showinfo: 0
                }
            }
        },
        hidePlayButton = false,
        playButton,
        showPlayButtonText = false,
        fullscreen = true,
        tracking
    } = props;


    const player = useRef(null);
    const [playing, setPlaying] = useState(false);

    const handlePlay = () => {
        setPlaying(true);
        GTMEvent(tracking, "play_video", (url || ""));
    };

    const handleStop = () => {
        setPlaying(false);
        player.current.showPreview();
    };

    useImperativeHandle(ref, () => ({
        play: () => {
            handlePlay();
        },
        stop: () => {
            handleStop();
        }
    }));

    const playButtonComponent = playButton ? playButton : <PlayButton onClick={handlePlay} showPlayButtonText={showPlayButtonText} classes={{ root: classes.playWrapper }} />;
    const previewComponent = <div className={clsx(classes.image, 'lazyload')} style={{ backgroundImage: `url(${preview ? preview.src : ''})` }}></div>;

    if (!url) {
        return (
            <div ref={ref} className={clsx(classes.playerWrapper, className)}>
                {previewComponent}
            </div>
        );
    }

    return (
        <div ref={ref} className={clsx(classes.playerWrapper, className, { [classes.foreground]: playing })}>
            {fullscreen ?
                <>
                    {/* Video placeholder */}
                    {previewComponent}
                    {/* if the player is playing, do not show the play button. This is not needed*/}
                    {(!playing && !hidePlayButton) ?
                        <div className={classes.playButtonFullscreenWrapper}>
                            {playButtonComponent}
                        </div> : null
                    }
                    {
                        /**
                         * FIX: video modal under the page content.
                         * Modals provide a first-class way to render children into a DOM node
                         * that exists outside the DOM hierarchy of the parent component.
                         * In addition, it disables the scrollbar.
                         */
                    }
                    <Modal open={playing}>
                        <div className={classes.fullscreenWrapper}>
                            <FullscreenBar classes={classes} onClose={handleStop}></FullscreenBar>
                            <div ref={ref} className={classes.fullscreenVideoWrapper}>
                                <ReactPlayer
                                    ref={player}
                                    controls
                                    playsinline
                                    className={classes.player}
                                    url={url}
                                    config={config}
                                    playing={playing}
                                    width='100%'
                                    height='100%'
                                    onError={(e) => console.log('Video Error: ', e)}
                                    onEnded={handleStop}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
                :
                <div ref={ref}>
                    <ReactPlayer
                        ref={player}
                        controls
                        className={classes.player}
                        url={url}
                        light={(preview && preview.src) || ''}
                        config={config}
                        playing={playing}
                        playIcon={!hidePlayButton ? playButtonComponent : null}
                        width='100%'
                        height='100%'
                        onError={(e) => console.log('Video Error:', e)}
                        onEnded={handleStop}
                    />
                </div>
            }
        </div>
    );

});

export default withStyles(styles, { name: 'FHVideo' })(Video);