import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',    
    borderRadius: '10px',
    border: '4px solid #d0d0d0',
    backgroundColor: '#f3f3f3',
    padding: theme.spacing(2),
    margin: '10px auto'
  },
}));

const DevelopLazy = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography align="center" variant="h6">
        {message}...
      </Typography>
    </div>
  );
};

export default DevelopLazy;
