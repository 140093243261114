import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Box, Container } from '@material-ui/core';

import Module from '../components/Module';
import Text from '../components/Text';
import FluidImage from '../components/FluidImage';


const useStyles = makeStyles((theme) => ({
    rowContent: {
    },
    container: {
        display: 'grid',
        gridGap: theme.spacing(9),
        gridTemplate: 'auto / 1fr auto',
        [theme.breakpoints.down('sm')]: {
            gridGap: theme.spacing(2),
            gridTemplate: 'auto / auto',
        }

    },
    borderBottom: {
        clear: 'both',
        display: 'block',
        width: '100%',
        position: 'relative',
        borderBottom: '1px solid #c0c0c0',
        top: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(4),
        }
    },
    imageLeft: {
        gridTemplate: 'auto / auto 1fr',
        [theme.breakpoints.down('sm')]: {
            gridTemplate: 'auto / auto',
        }
    },
    col: {

    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '40vw',
        maxHeight: 250,
        //marginBottom: 64,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            width: 'unset',
            maxWidth: 'unset',
        }
    },
    title: {
        fontFamily: 'SpeziaExtended',
        fontWeight: 'bold',
        fontSize: 40,
        lineHeight: '1.06',
        letterSpacing: -0.5,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
        },
    },
    text: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.25,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    img: {
        zIndex: 1,
    }
}));

const TextContent = ({ title, text }) => {
    const classes = useStyles();
    return (
        <Box>
            <Text className={classes.title} color='textSecondary' variant='titleExtXXXL' weight='bold'>{title}</Text>
            <Text className={classes.text} variant='bodyM'>{text}</Text>
        </Box>);
};

const ImageContentContent = ({ foregroundImage, mobileForegroundImage }) => {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <div className={classes.imageWrapper}>
                    <FluidImage className={classes.img} {...foregroundImage}></FluidImage>
                </div>
            </Hidden>
            <Hidden mdUp>
                <FluidImage className={classes.img} {...mobileForegroundImage}></FluidImage>
            </Hidden>
        </>);
};


const RowContentModule = ({ title, text, foregroundImage, mobileForegroundImage, alignImageLeft, borderBottom }) => {
    const classes = useStyles();

    const Content = ({ printImage }) => (printImage ?
        <ImageContentContent
            foregroundImage={foregroundImage}
            mobileForegroundImage={mobileForegroundImage}
        /> :
        <TextContent
            title={title}
            text={text}
        />);

    return (
        <Module className={classes.rowContent} variant="wrap">
            <Box className={clsx(classes.container, { [classes.imageLeft]: alignImageLeft })}>
                <Box className={classes.col}>
                    <Hidden smDown>
                        <Content printImage={alignImageLeft} />
                    </Hidden>
                    <Hidden mdUp>
                        <Content printImage={false} />
                    </Hidden>
                </Box>
                <Box className={classes.col}>
                    <Hidden smDown>
                        <Content printImage={!alignImageLeft} />
                    </Hidden>
                    <Hidden mdUp>
                        <Content printImage={true} />
                    </Hidden>
                </Box>
            </Box>
            {borderBottom && <div className={classes.borderBottom}></div>}
        </Module >
    );
};

export default RowContentModule;
