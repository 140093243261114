import React, { useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Hidden } from "@material-ui/core"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Module from "../components/Module"
import Text from "../components/Text"
import ReactPlayer from "react-player"
import PlayButton from "../components/PlayButton"
import { GTMEvent } from "../utils/gtm"
import FluidImage from "../components/FluidImage"
import Icon from "../components/Icon"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import screenfull from "screenfull"

const useStyles = makeStyles(theme => ({
  gallery: {
    textAlign: "center",
    overflow: "hidden",
    "& .icon": {
      width: 32,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        width: 24,
        height: 24,
      },
    },
    "& .keen-slider": {
      marginTop: 30,
    },
    "& .video-gallery-select-container": {
      width: "100%",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      "& .video-gallery-select": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 335,
        height: 40,
        padding: "20px 16px 16px",
        backgroundColor: "#fbfbfb",
        color: "#808080",
        fontSize: 16,
        cursor: "pointer",
        "& .video-gallery-select-arrow": {
          border: "1px solid #ef901c",
          borderWidth: "0 2px 2px 0",
          display: "inline-block",
          padding: 5,
          transform: "rotate(45deg)",
        },
      },
      "& .video-gallery-select-options": {
        marginTop: 15,
        width: 335,
        backgroundColor: "#fbfbfb",
        border: "1px solid #ef901c",
        cursor: "pointer",
        "& .video-gallery-select-opt": {
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          color: "#808080",
          height: 25,
        },
        "& .video-gallery-select-opt:hover": {
          color: "#ef901c",
        },
      },
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  uptitle: {
    paddingBottom: theme.spacing(4),
  },
  textContainer: {
    textAlign: "left",
    position: "absolute",
    left: 20,
    top: 20,
    right: 20,
    zIndex: 8,
    color: "#fff",
  },
  videoFullscreen: {
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 9,
    cursor: "pointer",
  },
  videoControlsTime: {
    position: "absolute",
    left: 20,
    bottom: 65,
    right: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9,
    "& .icon_forward": {
      width: 25,
      height: 24,
      cursor: "pointer",
    },
    "& .icon_play, & .icon_pause": {
      width: 35,
      height: 35,
      margin: "0 10px",
      cursor: "pointer",
    },
  },
  videoControlsMute: {
    position: "absolute",
    right: 20,
    cursor: "pointer",
  },
  videoControlsProgressBar: {
    position: "absolute",
    left: 20,
    bottom: 35,
    right: 20,
    color: "#fff",
    fontSize: "10px",
    zIndex: 9,
    "@media screen and (-webkit-min-device-pixel-ratio: 0)": {
      '& input[type="range"]': {
        width: "calc(100% - 40px)",
        height: 3,
        margin: "0 auto",
        overflow: "hidden",
        WebkitAppearance: "none",
        backgroundColor: "#fff",
        cursor: "pointer",
      },
      '& input[type="range"]::-webkit-slider-runnable-track': {
        height: "100%",
        WebkitAppearance: "none",
        color: "#fff",
      },
      '& input[type="range"]::-webkit-slider-thumb': {
        width: 1,
        WebkitAppearance: "none",
        height: "100%",
        background: "#fff",
        boxShadow: "-10000px 0 0 10000px #ef901c",
      },
    },
    '& input[type="range"]::-moz-range-progress': {
      backgroundColor: "#ef901c",
    },
    '& input[type="range"]::-moz-range-track': {
      backgroundColor: "#fff",
    },
    '& input[type="range"]::-ms-fill-lower': {
      backgroundColor: "#ffbd08",
    },
    '& input[type="range"]::-ms-fill-upper': {
      backgroundColor: "#fff",
    },
    "& .times": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "calc(100% - 40px)",
      margin: "0 auto 0 auto",
    },
  },
  cardWrapper: {
    position: "relative",
    width: "100%  !important",
    height: "420px !important",
    minHeight: 420,
    padding: 0,
    overflow: "hidden",
  },
  playerWrapper: {
    position: "relative",
    width: "100%  !important",
    height: "420px !important",
    minHeight: 420,
    padding: 0,
    overflow: "hidden",
  },
  playerHidden: {
    zIndex: -1,
    opacity: 0,
  },
  preview: {
    backgroundColor: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "& > div": {
      height: 420,
    },
    "& picuter, & img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  playerButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  slideArrows: {
    display: "block",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#000",
    zIndex: 4,
    position: "relative",
    padding: "0px 25px",
    top: "-12px",
    "& .video-gallery-change-slide-icon": {
      width: 25,
      height: 25,
      cursor: "pointer",
    },
    "& .video-gallery-padding-left": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
      },
    },
    "& .video-gallery-slide:last-child": {
      "& .video-gallery-videoFrame": {
        [theme.breakpoints.down("sm")]: {
          paddingRight: 5,
        },
      },
    },
  },
}))

const VideoGalleryModule = props => {
  const classes = useStyles()
  const { title, primary } = props
  const refContainer = React.useRef(null)
  const [elRefs, setElRefs] = React.useState([])
  const [openSelect, setOpenSelect] = React.useState(false)

  const [selectIndexValue, setSelectIndexValue] = React.useState(0)
  const [showPreview, setShowPreview] = React.useState(true)
  const [isMuted, setIsMuted] = React.useState(true)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [duration, setDuration] = React.useState(-1)
  const [playedSeconds, setPlayedSeconds] = React.useState(-1)

  const { t, i18n } = useTranslation()

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    mode: "free",
    drag: true,
    slides: { origin: "center", perView: 2.1, spacing: 16 },
    breakpoints: {
      "(max-width: 900px)": {
        slides: {
          origin: "center",
          perView: 1.4,
          spacing: 16,
        },
      },
      "(max-width: 600px)": {
        loop: false,
        slides: {
          origin: "auto",
          perView: 1.05,
          spacing: 0,
        },
      },
    },
    slideChanged(slider) {
      stopVideo()
      setSelectIndexValue(slider.track.details.rel)
    },
  })

  /**useEffect per la creazione dei ref */
  React.useEffect(() => {
    if (primary?.field_value) {
      setElRefs(elRefs =>
        Array(primary?.field_value?.length)
          .fill()
          .map((_, i) => elRefs[i] || React.createRef())
      )
    }
  }, [primary])

  /* stopVideo */
  const stopVideo = () => {
    setShowPreview(true)
    setIsPlaying(false)
    setIsMuted(true)
    setDuration(-1)
    setPlayedSeconds(-1)
    setOpenSelect(false)
  }

  /**Funzione per passare in full screen */
  const toFullScreen = () => {
    if (screenfull.isEnabled) {
      // eslint-disable-next-line react/no-find-dom-node
      const myElement = ReactDOM.findDOMNode(elRefs[selectIndexValue]?.current)
      screenfull.request(myElement)
    }
  }
  if (screenfull.isEnabled) {
    screenfull.on("change", () => {
      if (!screenfull.isFullscreen && refContainer && refContainer.current) {
        refContainer.current.scrollIntoView()
      }
    })
  }

  /**Funzione per il click sulla progress bar (NON FUNZIONA CON reactPlayer di questa versione) */
  const handleSeekChange = event => {
    setPlayedSeconds(Math.round(event.target.value))
    elRefs[selectIndexValue]?.current?.seekTo(
      Math.round(event.target.value),
      "seconds"
    )
  }
  /**Funzione per rewind e skip 10 secondi */
  const moveTenSecondsVideo = index => {
    if (index === -1) {
      if (playedSeconds < 10) {
        elRefs[selectIndexValue]?.current?.seekTo(0, "seconds")
      } else {
        elRefs[selectIndexValue]?.current?.seekTo(
          Math.round(playedSeconds - 10),
          "seconds"
        )
      }
    } else if (index === 1) {
      if (playedSeconds > duration - 10) {
        elRefs[selectIndexValue]?.current?.seekTo(0, "seconds")
      } else {
        elRefs[selectIndexValue]?.current?.seekTo(playedSeconds + 10, "seconds")
      }
    }
  }

  /**Funzione per cambio slide con le arrow */
  const changeSlideWithArrow = index => {
    if (index === -1) {
      slider.current?.moveToIdx(selectIndexValue - 1)
    } else if (index === 1) {
      slider.current?.moveToIdx(selectIndexValue + 1)
    }
  }

  return (
    <Module className={classes.gallery} variant="full">
      <Text
        className={classes.title}
        variant="titleXXXL"
        component="h3"
        align="center"
        weight="bold"
        overline
      >
        {title?.field_value}
      </Text>

      <div className="video-gallery-select-container">
        <div
          className="video-gallery-select"
          onClick={() => setOpenSelect(!openSelect)}
          style={openSelect ? { border: "1px solid #ffbd08" } : {}}
        >
          <span>{t("seeAllVideos")}</span>
          <span
            className="video-gallery-select-arrow"
            style={
              openSelect
                ? { transform: "rotate(225deg)" }
                : { position: "relative", top: "-6px" }
            }
          ></span>
        </div>
        {openSelect && (
          <div className="video-gallery-select-options">
            {primary?.field_value?.map((el, i) => (
              <div
                className="video-gallery-select-opt"
                key={i}
                onClick={() => slider?.current?.moveToIdx(i)}
              >
                {el?.field_value?.title?.field_value
                  ? el.field_value.title.field_value
                  : t("seeVideo") + " " + parseInt(i + 1)}
              </div>
            ))}
          </div>
        )}
      </div>

      <Box className={classes.sliderContainer} ref={refContainer}>
        <div
          ref={sliderRef}
          className="keen-slider video-gallery-carousel-container"
        >
          {(primary?.field_value || []).map((video, index) => (
            <div key={index} className="keen-slider__slide video-gallery-slide">
              <div className={classes.cardWrapper}>
                <div className={classes.textContainer}>
                  {video?.field_value?.title?.field_value ? (
                    <Text className="uptitle" variant="bodyXS" weight="bold">
                      {video?.field_value?.subtitle?.field_value}
                    </Text>
                  ) : null}
                  <div className="title">
                    <Text variant="bodyM" weight="bold">
                      {video?.field_value?.title?.field_value.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      )}
                    </Text>
                  </div>
                </div>

                {selectIndexValue === index && !showPreview && (
                  <>
                    <div
                      className={classes.videoFullscreen}
                      onClick={toFullScreen}
                    >
                      <Icon className="icon" name="expand_video_tangerine" />
                    </div>
                    <div className={classes.videoControlsTime}>
                      <div
                        className={classes.videoControlsMute}
                        onClick={() => setIsMuted(!isMuted)}
                      >
                        {isMuted ? (
                          <Icon className="icon" name="mute" />
                        ) : (
                          <Icon className="icon" name="unmute" />
                        )}
                      </div>
                      <div onClick={() => moveTenSecondsVideo(-1)}>
                        <Icon
                          className="icon_forward"
                          name="arrow_forward_left_tangerine"
                        ></Icon>
                      </div>
                      <div onClick={() => setIsPlaying(!isPlaying)}>
                        {isPlaying ? (
                          <Icon
                            className="icon_pause"
                            name="pause_tangerine"
                          ></Icon>
                        ) : (
                          <Icon
                            className="icon_play"
                            name="play_tangerine"
                          ></Icon>
                        )}
                      </div>
                      <div onClick={() => moveTenSecondsVideo(1)}>
                        <Icon
                          className="icon_forward"
                          name="arrow_forward_right_tangerine"
                        ></Icon>
                      </div>
                    </div>
                    <div className={classes.videoControlsProgressBar}>
                      <input
                        type="range"
                        min={0}
                        max={duration}
                        value={playedSeconds}
                        step="any"
                        className="video-gallery-progressbar"
                        onChange={event => handleSeekChange(event)}
                        onSeek
                      />
                      <div className="times">
                        <div>
                          {new Date(playedSeconds * 1000)
                            .toISOString()
                            .slice(14, 19)}
                        </div>
                        <div>
                          {new Date(duration * 1000)
                            .toISOString()
                            .slice(14, 19)}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className={clsx(classes.playerWrapper)}>
                  <ReactPlayer
                    ref={elRefs[index]}
                    controls={false}
                    playsinline={false}
                    url={video?.field_value?.link_video?.field_value}
                    light={
                      video?.field_value?.image_video_placeholder?.field_value
                        ?.src
                    }
                    config={{
                      youtube: {
                        playerVars: {
                          modestbranding: 1,
                          rel: 0,
                          controls: 0,
                          loop: 1,
                          showinfo: 0,
                          disablekb: 1,
                          iv_load_policy: 3,
                        },
                      },
                    }}
                    playing={index === selectIndexValue && isPlaying}
                    muted={isMuted}
                    playIcon={
                      <PlayButton
                        className={classes.playerButton}
                        showPlayButtonText={true}
                        onClick={() => {
                          if (index !== selectIndexValue) {
                            // eslint-disable-next-line no-unused-expressions
                            slider?.current?.moveToIdx(index)
                          }
                          setShowPreview(false)
                          setIsPlaying(true)
                          GTMEvent(
                            "VideoGalleryModule",
                            "play_video",
                            video?.field_value?.link_video?.field_value || ""
                          )
                        }}
                      />
                    }
                    width="100%"
                    height="100%"
                    onError={e => console.log("Video Error:", e)}
                    onDuration={dur => setDuration(dur)}
                    onProgress={res => setPlayedSeconds(res.playedSeconds)}
                    onPause={() => {
                      setIsPlaying(false)
                    }}
                    onPlay={() => {
                      //setShowPreview(false)
                      setIsPlaying(true)
                    }}
                    onEnded={() => {
                      stopVideo()
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={classes.slideArrows}>
          <div
            className="video-gallery-change-slide-icon"
            onClick={() => changeSlideWithArrow(-1)}
          >
            <Icon className="icon" name="slider_left_tangerine" />
          </div>
          <div
            className="video-gallery-change-slide-icon"
            onClick={() => changeSlideWithArrow(1)}
          >
            <Icon className="icon" name="slider_right_tangerine" />
          </div>
        </div>
      </Box>
    </Module>
  )
}

VideoGalleryModule.propTypes = {
  title: PropTypes.string,
  primary: PropTypes.array,
}

export default VideoGalleryModule
