import React, { useEffect } from "react"
import axios from "axios"

const KlarnaPlacement = props => {
  const { repo, moduleName } = props

  useEffect(async () => {
    const data = { productCode: repo?.product_info?.product_id }

    await axios
      .post(process.env.KLARNA_WEB_MESSAGING, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        if (res.data.data.isFullKit)
          document.querySelector(
            "." + moduleName + " #klarnaPlacement"
          ).innerHTML = `<klarna-placement id="klarnaMessage" data-key="credit-promotion-auto-size" data-locale=${res.data.data.locale} data-purchase-amount=${res.data.data.amount}></klarna-placement>`
      })
      .catch(error => {
        console.log("Klarna Error: ", error)
      })
  }, [])

  return <div id="klarnaPlacement"></div>
}
export default KlarnaPlacement
