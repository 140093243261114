import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import { useInView } from "react-intersection-observer"
import { Typography, Grid, Hidden } from "@material-ui/core"
import ArticleCard from "../components/ArticleCard"

import Module from "../components/Module"
import Text from "../components/Text"
import { window } from "browser-monads"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Lazy } from "swiper"

const useStyles = makeStyles(theme => ({
  highlights: {
    overflow: 'hidden',
    '& .swiper-container': {
      width: '100%',      
      listStyle: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      zIndex: 1,
      padding:0,
    },
    '& .swiper-wrapper': {
      boxSizing: 'border-box',
      display: 'flex',
      height: '100%',
      position: 'relative',
      transitionProperty: '-webkit-transform',
      transitionProperty: 'transform',
      transitionProperty: 'transform,-webkit-transform',
      width: '100%',
      zIndex: 1,      
    },
    '& .swiper-slide': {
      flexShrink: 0,
      height: 'auto',
      position: 'relative',
      transitionProperty: '-webkit-transform',
      transitionProperty: 'transform',
      transitionProperty: 'transform,-webkit-transform',
      width: '100%',
    }    
    
  },
  gridElement: {
    opacity: 0,
    padding: theme.spacing(1, 1 / 2),
  },
  gridElementNoAnimation: {
    opacity: 1,
    padding: theme.spacing(1, 1 / 2),
  },
  article: {
    height: "100%",
  },
}))

const HighlightsModule = ({ title, subtitle, highlights, ...others }) => {
  const classes = useStyles()
  const { ref, inView, entry } = useInView()
  const [animate, setAnimate] = React.useState(true)

  useEffect(() => {
    if (animate && inView && ref) {
      setAnimate(false)
      Array.from(entry.target.children).forEach((sub, i) => {
        sub.animate(
          [
            { opacity: 0, transform: `translateY(25%)` },
            { opacity: 1, transform: "translateY(0%)" },
          ],
          {
            // timing options
            duration: 500,
            delay: i * 50,
          }
        )
        setTimeout(() => {
          sub.style.opacity = 1
        }, i * 50 + 500)
      })
    }
  }, [ref, inView, entry])

  const IsObserverSupported = () => {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      return true
    } else {
      return false
    }
  }

  const sliderConfiguration = {
    loop: false,
    allowTouchMove: true,
    loopFillGroupWithBlank: false,
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 8
      },              
      600: {
        slidesPerView: 1.5,
        spaceBetween: 8
      },
      960: {
        slidesPerView: 2.5,
        spaceBetween: 8
      },
    }
  }

  return (
    <Module className={classes.highlights}>
      <Text
        align="center"
        variant="titleXXXL"
        component="h2"
        weight="bold"
        overline
        gutterBottom
      >
        {title}
      </Text>
      <Text
        align="center"
        component="h3"
        variant="bodyM"
        weight="medium"
        gutterBottom
      >
        {subtitle}
      </Text>
      <Hidden smDown>
        {highlights &&
          (highlights.length > 3 ? (
            <Swiper {...sliderConfiguration}>
              {highlights.map((el, i) => (
                <SwiperSlide key={i}>
                  <Box height="100%">
                    <ArticleCard
                      titleSeoComponent="h4"
                      className={classes.article}
                      {...el}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : IsObserverSupported() ? (
            <Grid ref={ref} container className={classes.grid}>
              {(highlights || []).map((el, i) => (
                <Grid key={i} item xs={true} className={classes.gridElement}>
                  <ArticleCard
                    titleSeoComponent="h4"
                    className={classes.article}
                    {...el}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container className={classes.grid}>
              {(highlights || []).map((el, i) => (
                <Grid
                  key={i}
                  item
                  xs={true}
                  className={classes.gridElementNoAnimation}
                >
                  <ArticleCard
                    titleSeoComponent="h4"
                    className={classes.article}
                    {...el}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
      </Hidden>
      <Hidden mdUp>
        <Swiper {...sliderConfiguration}>
          {(highlights || []).map((el, i) => (
            <SwiperSlide key={i}>
              <Box height="100%">
                <ArticleCard
                  titleSeoComponent="h4"
                  className={classes.article}
                  {...el}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Hidden>
    </Module>
  )
}

export default HighlightsModule
