import React from "react"
import PropTypes from "prop-types"
import Icon from "../components/Icon"
import Text from "../components/Text"
import Module from "../components/Module"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { Container } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TrustBox from "../components/TrustBox"
import VisibilitySensor from "../animations/VisibilitySensor"

const useStyles = makeStyles(theme => ({
  TrustPilot: {},
}))

const TrustpilotWidget = props => {
  const { url } = props
  const classes = useStyles()

  return (
    <VisibilitySensor>
      <Module className={classes.TrustPilot}>
        <Container maxWidth="md">
          <Box textAlign="center">
            <TrustBox type="horizontal" country="uk"></TrustBox>
          </Box>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

TrustpilotWidget.propTypes = {
  url: PropTypes.string,
}

export default TrustpilotWidget
