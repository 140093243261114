import React, { useRef, useEffect, useState } from "react"
import clsx from "clsx"
import Portal from "@material-ui/core/Portal"
import withStyles from "@material-ui/core/styles/withStyles"
import SlickSlider from "react-slick"
import Icon from "./Icon"
import { GTMEvent } from "../utils/gtm"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const styles = theme => ({
  root: {
    position: "relative",
    "& .slick-disabled": {
      border: `4px solid transparent`,
      opacity: 0.25,
    },
    overflow: "hidden",
  },
  slider: {
    "& .slick-track": {
      alignItems: "center",
    },
    "& .slick-prev": {
      left: "0px",
      zIndex: "1",
    },
    "& .slick-next": {
      right: "10px",
      zIndex: "1",
    },
  },
  slider2: {
    "& .slick-track": { padding: "0 20% 0 0" },
  },
  equalHeight: {
    "& .slick-track": { display: "flex !important", alignItems: "stretch" },
    "& .slick-slide": { height: "auto !important", display: "flex" },
    "& .slick-slide > div": { height: "100%" },
  },
  arrows: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  icon: {
    display: "block",
    height: 32,
    width: 32,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "calc(50% - 16px)",
  },
  arrow: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  back: {
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
  },
  next: {
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
  },
})

const NextArrow = ({
  className,
  classes,
  dark,
  onClick,
  container,
  arrowBorder,
}) => {
  const filteredClasses = className.replace("slick-arrow slick-next", "")
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.next)}
      onClick={onClick}
      style={{ borderWidth: arrowBorder }}
    >
      <Icon className={classes.icon} name={dark ? "right_white" : "right"} />
    </div>
  )

  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  )
}

const PrevArrow = ({
  className,
  classes,
  dark,
  onClick,
  container,
  arrowBorder,
}) => {
  const filteredClasses = className.replace("slick-arrow slick-prev", "")
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.back)}
      onClick={onClick}
      style={{ borderWidth: arrowBorder }}
    >
      <Icon className={classes.icon} name={dark ? "left_white" : "left"} />
    </div>
  )
  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  )
}

const Slider = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    classes,
    showExtraSlides,
    prevIcon = "left",
    nextIcon = "right",
    disableEqualHeight = false,
    dark = false,
    initialPos,
    arrowBorder,
    tracking,
    indexSlider,
    ...settings
  } = props

  const slider = useRef(null)
  const arrowContainer = useRef(null)
  const [sliderSettings, setSliderSettings] = useState(settings)

  // set initial position
  useEffect(() => {
    const firstSlide = initialPos || 0
    if (slider && slider.current) {
      slider.current.slickGoTo(firstSlide, true)
    }
  }, [initialPos, slider.current])

  useEffect(() => {
    if (slider && slider.current) {
      slider.current.slickGoTo(indexSlider, true)
      //console.log("sliderxxxx", indexSlider, slider?.current)
    }
  }, [indexSlider])

  return (
    <div className={classes.root} ref={ref}>
      <SlickSlider
        ref={slider}
        className={clsx(
          showExtraSlides ? classes.slider2 : classes.slider,
          className,
          { [classes.equalHeight]: !disableEqualHeight }
        )}
        {...sliderSettings}
      >
        {children}
      </SlickSlider>
      <div ref={arrowContainer} className={classes.arrows}></div>
    </div>
  )
})

export default withStyles(styles, { name: "FHSlider" })(Slider)
