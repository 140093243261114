import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from './Icon';
import Text from './Text';

export const styles = (theme) => ({
    /* Styles applied to the root element. */
    linkButton: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            textDecoration: 'none',
        }
    },
    label: {
        textTransform: 'uppercase',
        whiteSpase: 'nowrap',
        borderBottom: `3px solid ${theme.customPalette.tangerine}`,
        paddingBottom: 2,
        marginRight: 4
    },
    icon: {
        width: 21,
        height: 21
    }
});

const LinkButton = React.forwardRef(function LinkButton(props, ref) {
    const {
        classes,
        className,
        textProps,
        icon,
        iconName,
        title = '',
        href = '',
        hrefLang = '',
        rel = '',
        target = '',
        onClick
    } = props;

    return (
        <a
            className={clsx(
                classes.linkButton,
                className,
            )}
            ref={ref}
            onClick={onClick}
            href={href}
            hrefLang={hrefLang}
            rel={rel}
            target={target}
            title={title}
        >
            <Text className={classes.label} variant='bodyXS' weight="bold" {...textProps}>{title}</Text>
            {
                (icon && (icon)) || (iconName && (<Icon className={classes.icon} name={iconName} />))
            }
        </a>
    );
});

LinkButton.propTypes = {
    /**
     * the link text
     */
    text: PropTypes.string,
    /**
     * The icon of the button.
     */
    iconName: PropTypes.string,
    /**
     * The icon of the button.
     */
    icon: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The text props
     */
    textProps: PropTypes.object
};

export default withStyles(styles, { name: 'FHLinkButton' })(LinkButton);