import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Paper, Avatar, Grid } from "@material-ui/core"

import Module from "../components/Module"
import Text from "../components/Text"
import Slider from "../components/Slider"
import Video from "../components/Video"

const useStyles = makeStyles(theme => ({
  influencer: {
    overflow: "hidden",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(8, 1, 1, 1),
    },
  },
  preTitle: {
    fontSize: 12,
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  slider: {
    "& .slick-list": {
      overflow: "visible",
      height: "100% !important",
      maxWidth: 375,
      ["@media (min-device-width: 400px) and (max-device-width: 425px)"]: {
        maxWidth: 425,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 800,
      },
    },
    "& .slick-track": {
      alignItems: "flex-end",
    },
  },
  sliderXAuto: {
    "& .slick-list": {
      overflow: "visible",
      maxWidth: 375,
      margin: "0 auto",
      ["@media (min-device-width: 400px) and (max-device-width: 425px)"]: {
        maxWidth: 425,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 800,
      },
    },
    "& .cardContainer": {
      paddingRight: 0,
    },
  },

  sliderItem: {
    marginBottom: theme.spacing(1),
  },
  cardContainerNoPaddingRight: {
    position: "relative",
    padding: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: 0,
    textAlign: "left",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(1),
    },
  },
  cardContainer: {
    position: "relative",
    padding: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: 0,
    textAlign: "left",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(1),
    },
  },
  card: {
    display: "flex",
    height: "100%",
    padding: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: `4px solid ${theme.customPalette.darkishPurple}`,
    borderRadius: 0,
    background: "transparent",
    position: "relative",
    fontFamily: "Spezia",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  cardBackground: {
    background: theme.customPalette.greyClear,
    position: "absolute",
    width: "100%",
    height: `calc(100% - 60px)`,
    bottom: 0,
    left: 0,
    zIndex: -100,
    [theme.breakpoints.up("md")]: {
      height: `calc(100% - 56px)`,
    },
    [theme.breakpoints.up("lg")]: {
      height: `calc(100% - 80px)`,
    },
  },
  cardContent: {
    paddingRight: theme.spacing(4),
    wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      display: "inline-flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  picture: {
    height: "96px",
    width: "96px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  cardVideo: {
    position: "relative",
    backgroundColor: "#EDEDED",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minHeight: theme.spacing(18),
    },
  },
  video: {
    width: "100%",
    height: "100%",
  },
  header: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  name: {
    fontFamily: "SpeziaExtended",
    fontSize: "22px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px !important",
    },
  },
  subtitle: {},
  cardSubtitle: {
    fontSize: "16px !important",
    fontFamily: "SpeziaExtended",
    marginTop: theme.spacing(1),
    marginBottom: "0",
    [theme.breakpoints.up("md")]: {
      marginBottom: "14px",
    },
  },
  description: {
    fontSize: "16px !important",
    fontWeight: "normal",
    [theme.breakpoints.down("md")]: {
      textAlign: "justify",
      textJustify: "inter-word",
    },
  },
  textContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}))

const InfluencerBox = ({
  firstName,
  lastName,
  subtitle,
  description,
  video,
  picture,
  tracking,
  lenght,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={
        lenght > 1 ? classes.cardContainer : classes.cardContainerNoPaddingRight
      }
    >
      <Paper elevation={0} className={classes.card}>
        <Box className={classes.cardBackground}></Box>
        <Grid container className={classes.textContainer}>
          <Grid item xs={12} lg={4} className={classes.cardContent}>
            <Box className={classes.header}>
              <Avatar
                className={classes.picture}
                src={picture?.src}
                alt={picture?.alt}
                title={picture?.title}
              ></Avatar>
              <Box>
                <Text className={classes.name} variant="titleXL" weight="bold">
                  {firstName}
                </Text>
                <Text className={classes.name} variant="titleXL" weight="bold">
                  {lastName}
                </Text>
                <Text
                  className={classes.cardSubtitle}
                  variant="bodyS"
                  weight="medium"
                >
                  {subtitle}
                </Text>
              </Box>
            </Box>
            <Text className={classes.description}>{description}</Text>
          </Grid>
          <Grid item xs={12} lg={8} className={classes.cardVideo}>
            <Video
              className={classes.video}
              fullscreen={false}
              showPlayButtonText
              {...video}
              tracking={tracking}
            ></Video>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

const InfluencerModule = props => {
  const { title, subtitle, items, repo } = props
  const classes = useStyles()

  const sliderSettings = {
    tracking: repo,
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    className: items.length > 1 ? classes.slider : classes.sliderXAuto,
    arrowBorder: "3px",
    centerPadding: 0,
    centerMode: true,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Module className={classes.influencer} variant="medium">
      <Text
        className={classes.title}
        variant="titleXXXL"
        align="center"
        overline
        weight="bold"
      >
        {title}
      </Text>
      <Text className={classes.subtitle} variant="bodyM" weight="medium">
        {subtitle}
      </Text>

      <Box className={classes.sliderContainer}>
        <Slider {...sliderSettings}>
          {(items || []).map((item, index) => (
            <InfluencerBox
              lenght={items.lenght}
              key={`gift_${index}`}
              number={index + 1}
              {...item}
              tracking={repo}
            />
          ))}
        </Slider>
      </Box>
    </Module>
  )
}

InfluencerModule.defaultProps = {
  title: "",
  subtitle: "",
  items: [],
}

export default InfluencerModule
