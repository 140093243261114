import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import Module from "../components/Module"
import FluidImage from "../components/FluidImage"
import Text from "../components/Text"
import Button from "../components/Button"

import { Box } from "@material-ui/core"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Lazy } from "swiper"

// install Swiper modules
SwiperCore.use([Lazy])

const useStyles = makeStyles(theme => ({
  benefits: {
    overflow: "hidden",
    "& .swiper-container": {
      width: "100%",
      listStyle: "none",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      zIndex: 1,
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
      },
    },
    "& .swiper-wrapper": {
      boxSizing: "border-box",
      display: "flex",
      height: "100%",
      position: "relative",
      transitionProperty: "-webkit-transform",
      transitionProperty: "transform",
      transitionProperty: "transform,-webkit-transform",
      width: "100%",
      zIndex: 1,
    },
    "& .swiper-slide": {
      flexShrink: 0,
      height: "auto",
      position: "relative",
      transitionProperty: "-webkit-transform",
      transitionProperty: "transform",
      transitionProperty: "transform,-webkit-transform",
      width: "100%",
    },
  },
  sectionLogo: {
    maxWidth: "200px",
    margin: "20px auto",
  },
  title: {
    color: "#00828c",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  slider: {
    "& .slick-list": {
      overflow: "hidden",
    },
  },
  card: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "275px",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  logo: {
    height: "70px",
    "& img": {
      height: "100%",
      marginLeft: "0%",
      marginRight: "100%",
    },
  },
  text: {
    fontSize: "20px !important",
    maxWidth: "50%",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    maxWidth: "65%",
    width: "100%",
    justifyContent: "space-between !important",
    [theme.breakpoints.down("sm")]: { maxWidth: "100%" },
    backgroundColor: "#ff3658",
    "&:hover": {
      backgroundImage:
        "linear-gradient(246.99deg, #ff3658 -.44%, #ae1277 101.69%)",
    },
  },
}))

const CrossSellingModule = ({
  background_color,
  section_logo,
  section_title,
  collections,
  ...others
}) => {
  const classes = useStyles()
  const CollectionListElement = ({ cta, description, image, logo }) => {
    const classes = useStyles()
    let ctaHref = cta?.href || ""
    let ctaSplit = ctaHref.split("/")
    const trakingCollection = ctaSplit[ctaSplit.length - 1]
    return (
      <Box
        className={classes.card}
        style={{ backgroundImage: `url("${image.src}")` }}
      >
        <FluidImage {...logo} className={classes.logo} />
        <Box>
          <Text
            align="inherit"
            variant="specialTitleL"
            weight="bold"
            color="textTertiary"
            gutterBottom
            className={classes.text}
          >
            {description.field_value}
          </Text>
          {cta.href && cta.title && (
            <Box>
              <Button
                className={classes.button}
                {...cta}
                variant="contained"
                next
                size="large"
                trackingObj="home"
                trackingAction="crossselling"
                trackingLabel={trakingCollection}
              >
                {cta.title}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Module
      className={classes.benefits}
      style={{ backgroundColor: background_color.field_value }}
    >
      <FluidImage
        className={classes.sectionLogo}
        {...section_logo.field_value}
      />
      <Text
        className={classes.title}
        align="center"
        variant="titleXXXL"
        component="h2"
        weight="bold"
        gutterBottom
      >
        {section_title.field_value}
      </Text>
      <Swiper
        loop={false}
        allowTouchMove
        loopFillGroupWithBlank={false}
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 8,
          },
          1023: {
            slidesPerView: 1.9,
            spaceBetween: 8,
          },
          1398: {
            slidesPerView: 2.9,
            spaceBetween: 8,
          },
          1599: {
            slidesPerView: 2.9,
            spaceBetween: 8,
          },
          1899: {
            slidesPerView: 2.9,
            spaceBetween: 8,
          },
          3000: {
            slidesPerView: 2.9,
            spaceBetween: 8,
          },
        }}
      >
        {(collections || []).map((collection, index) => {
          return (
            <SwiperSlide key={index}>
              <Box height="100%">
                <CollectionListElement {...collection} />
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Module>
  )
}

const defaultProps = {
  title: "",
  items: [],
}

CrossSellingModule.defaultProps = defaultProps

export default CrossSellingModule
