import React from "react"
import Text from "../components/Text"
import CtaButton from "../components/CtaButton"
import { Container, useMediaQuery, Box, Chip } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import "../style/HeroBannerV4Module.css"
import Module from "../components/Module"
import Slider from "../components/Slider"
import slide_outline from "../assets/img/icons/slide-outline/slide-outline.png"
import Parallax from "../components/Parallax"
import { GTMEvent } from "../utils/gtm"
import { useTranslation } from "react-i18next"

const parallaxHeight = 680

const useStyles = makeStyles(theme => ({
  HeroBannerV4FormMobile: {
    position: "relative",
    zIndex: "20",
    height: "642px",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    backgroundPosition: "center center !important",
  },
  parallax: {
    position: "absolute",
    zIndex: -1,
    height: parallaxHeight,
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  slider: {
    height: "100%",
    "& .slick-slide": {
      overflow: "hidden",
      position: "relative",
    },
  },
  sliderArrows: {
    display: "none",
    justifyContent: "flex-end",
    width: "fit-content",
    right: 1,
    marginTop: -126,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
      top: "40%",
      justifyContent: "space-between",
    },
  },
  sliderBack: {
    display: "none",
    height: 60,
    width: 60,
    //border: `4px solid transparent`,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  sliderNext: {
    display: "none",
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginBottom: "23px",
  },
  bannerCtaButton: {
    width: 400,
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentContainer: {
    //border: showGuides("2px solid yellow"), // FIX ** guides
    position: "absolute",
    width: "40%",
    zIndex: 99,
    left: theme.spacing(4), // FIX ** apply padding internally
    [theme.breakpoints.down("sm")]: {
      top: "10px",
      left: 0,
      padding: theme.spacing(2), // FIX ** apply padding internally
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "center",
    },
  },
  heroBannerContainer: {
    paddingTop: "10% !important",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(3),
      display: "block",
    },
  },
  fadeInText: {
    textAlign: "center",
  },
}))
const HeroBannerV4Module = props => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  const [masterSliderIndex, setMasterSliderIndex] = React.useState(0)
  const [touchStart, setTouchStart] = React.useState(null)
  const [touchEnd, setTouchEnd] = React.useState(null)
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })
  const { i18n, t } = useTranslation()
  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  React.useEffect(() => {
    // Handler to call on window resize
    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && windowSize.width < 900) {
      changeSlide(1)
    } else if (isRightSwipe && windowSize.width < 900) {
      changeSlide(-1)
    }
  }

  const changeSlide = index => {
    if (masterSliderIndex + index < 0) {
      setMasterSliderIndex(primary.field_value.length - 1)
    } else if (masterSliderIndex + index > primary.field_value.length - 1) {
      setMasterSliderIndex(0)
    } else {
      setMasterSliderIndex(masterSliderIndex + index)
    }
  }

  React.useEffect(() => {
    if (primary.field_value.length > 1) {
      const sliderAutoplay = setInterval(() => {
        changeSlide(1)
      }, 8000)
      return () => {
        clearInterval(sliderAutoplay)
      }
    }
  }, [masterSliderIndex])

  const { pageFamily, tracking, primary, repo } = props
  return isMobile ? (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Slider
        className={classes.slider}
        classes={{
          arrows: classes.sliderArrows,
          back: classes.sliderBack,
          next: classes.sliderNext,
        }}
        arrows={(primary.field_value || []).length > 1}
        infinite={false}
        slidesToShow={1}
        slidesToScroll={1}
        dark
        tracking={repo}
        indexSlider={masterSliderIndex}
        swipe={false}
      >
        {" "}
        {(primary.field_value || []).map((slide, i) => (
          <>
            <Module
              className={classes.HeroBannerV4FormMobile}
              style={{
                background:
                  "url(" +
                  slide.field_value?.single_image_mobile?.field_value?.src +
                  ")",
              }}
            >
              <Parallax
                key={`hero_slide_${i}`}
                bgMobileImage={slide.field_value?.single_image_mobile}
                strength={250}
                className={classes.parallax}
                parallaxStyle={{
                  position: "absolute",
                  zIndex: -1,
                  height: parallaxHeight,
                }}
              />
              <Container
                className={classes.heroBannerContainer}
                style={{ position: "relative" }}
              >
                <Box className={classes.contentContainer}>
                  <Box className={classes.fadeInText}>
                    {slide?.cta?.isPreorder ? (
                      <Chip
                        className={classes.chip}
                        label={t("pre_order_label")}
                      />
                    ) : (
                      slide.chipText && (
                        <Chip className={classes.chip} label={slide.chipText} />
                      )
                    )}
                    <Text
                      variant="specialTitleXXL"
                      component={
                        pageFamily === "home" ? "h2" : i === 0 ? "h1" : "h2"
                      }
                      color="textTertiary"
                      className={classes.title}
                    >
                      {slide.field_value?.title?.field_value}
                    </Text>
                    <Text
                      variant="bodyM"
                      color="textTertiary"
                      className={classes.subtitle}
                    >
                      {slide.field_value?.subtitle?.field_value}
                    </Text>
                    <Box className={classes.ctaContainer}>
                      <a href={slide.field_value?.cta?.field_value?.href}>
                        <CtaButton
                          className="bannerCtaButton"
                          {...slide.field_value.cta}
                          next
                          size="large"
                          trackingObj={tracking}
                          trackingAction="click_link"
                          trackingLabel={
                            "HeroBannerModule - " +
                            slide.field_value.cta?.productCode
                          }
                        >
                          {slide.field_value.cta?.field_value?.title}
                        </CtaButton>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Module>
          </>
        ))}
      </Slider>
      {primary.field_value.length > 1 && (
        <div className="dots-container">
          <div className="dots-container-inner">
            <div className="inner-button-arrows">
              <button
                onClick={() => {
                  changeSlide(-1)
                  GTMEvent("home", "slider-figures", "swipe-slider")
                }}
                className="arrow-button"
              >
                <img src={slide_outline} />
              </button>
              {primary.field_value.map((_k, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setMasterSliderIndex(i)
                    GTMEvent("home", "slider-figures", "swipe-slider")
                  }}
                  className="dot-rectangle"
                >
                  <div className="dot-bg">
                    <div
                      className={`dot-fill ${
                        i === masterSliderIndex ? "dot-animation" : ""
                      } ${i < masterSliderIndex ? "bg-yellow01" : "bg-grey01"}`}
                    />
                  </div>
                </div>
              ))}
              <button
                onClick={() => {
                  changeSlide(1)
                  GTMEvent("home", "slider-figures", "swipe-slider")
                }}
                className="arrow-button mb-5"
                style={{ transform: "rotate(180deg)" }}
              >
                <img src={slide_outline} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <section className="HeroBannerV4Module">
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Slider
          className={classes.slider}
          classes={{
            arrows: classes.sliderArrows,
            back: classes.sliderBack,
            next: classes.sliderNext,
          }}
          arrows={(primary.field_value || []).length > 1}
          infinite={false}
          slidesToShow={1}
          slidesToScroll={1}
          dark
          tracking={repo}
          indexSlider={masterSliderIndex}
          swipe={false}
        >
          {(primary.field_value || []).map((slide, i) => (
            <div className="hero-banner-v4-module-container">
              <div className="h-100 w-100">
                <div className="h-100 w-100">
                  <div className="h-100 w-100">
                    <div className="h-100 w-100">
                      <div className="slide-container-v4">
                        <div className="slide-description-container-v4">
                          <div class="slide-logo-v4">
                            <img
                              src={
                                slide.field_value.logo_image?.field_value?.src
                              }
                              alt={
                                slide.field_value.logo_image?.field_value?.alt
                              }
                              title={
                                slide.field_value.logo_image?.field_value?.title
                              }
                              className="slide-foreground slide-left-v4"
                            />
                          </div>
                          <a
                            href={slide.field_value.cta?.field_value?.href}
                            className="btn-mobile"
                          >
                            {slide?.field_value?.cta?.field_value
                              ?.isPreorder ? (
                              <Chip
                                className={classes.chip}
                                label={t("pre_order_label")}
                              />
                            ) : (
                              slide.field_value?.new_arrivals_2_layer
                                ?.field_value && (
                                <Chip
                                  className="chip"
                                  label={
                                    slide.field_value.new_arrivals_2_layer
                                      ?.field_value
                                  }
                                />
                              )
                            )}
                            <Text
                              variant="specialTitleXXL"
                              component="h2"
                              color="textTertiary"
                              className="slide-title-v4"
                            >
                              {slide.field_value.title?.field_value}
                            </Text>
                            <Text
                              variant="bodyM"
                              color="textTertiary"
                              className="slide-subtitle-v4"
                            >
                              {slide.field_value.subtitle?.field_value}
                            </Text>
                            <CtaButton
                              className="bannerCtaButton"
                              {...slide.field_value.cta}
                              next
                              size="large"
                              trackingObj={tracking}
                              trackingAction="click_link"
                              trackingLabel={
                                "HeroBannerModule - " +
                                slide.field_value.cta?.productCode
                              }
                            >
                              {slide.field_value.cta?.field_value?.title}
                            </CtaButton>
                          </a>
                        </div>
                        <div className="carousel-img-container-v4">
                          <img
                            src={
                              slide.field_value.background_image?.field_value
                                ?.src
                            }
                            alt={
                              slide.field_value.background_image?.field_value
                                ?.alt
                            }
                            title={
                              slide.field_value.background_image?.field_value
                                ?.title
                            }
                            className="slide-background-v4"
                          />
                        </div>
                        <div className="slide-foreground-container-v4">
                          <img
                            style={{ maxWidth: "100%" }}
                            src={
                              slide.field_value.product_image?.field_value?.src
                            }
                            alt={
                              slide.field_value.product_image?.field_value?.alt
                            }
                            title={
                              slide.field_value.product_image?.field_value
                                ?.title
                            }
                            className="slide-foreground slide-left-v4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {primary.field_value.length > 1 && (
          <div className="dots-container">
            <div className="dots-container-inner">
              <div className="inner-button-arrows">
                <button
                  onClick={() => {
                    changeSlide(-1)
                    GTMEvent("home", "slider-figures", "swipe-slider")
                  }}
                  className="arrow-button"
                >
                  <img src={slide_outline} />
                </button>
                {primary.field_value.map((_k, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setMasterSliderIndex(i)
                      GTMEvent("home", "slider-figures", "swipe-slider")
                    }}
                    className="dot-rectangle"
                  >
                    <div className="dot-bg">
                      <div
                        className={`dot-fill ${
                          i === masterSliderIndex ? "dot-animation" : ""
                        } ${
                          i < masterSliderIndex ? "bg-yellow01" : "bg-grey01"
                        }`}
                      />
                    </div>
                  </div>
                ))}
                <button
                  onClick={() => {
                    changeSlide(1)
                    GTMEvent("home", "slider-figures", "swipe-slider")
                  }}
                  className="arrow-button mb-5"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <img src={slide_outline} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroBannerV4Module
