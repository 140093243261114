import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { webpTry } from '@src/utils/webp';

import Text from './Text';
import Ribbon from './Ribbon';


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.customPalette.greyClear,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    '&.isBig': {
      display: 'flex',
      height: '100%',
    },
  },
  imageContainer: {
    height: 230,
    position: 'relative',
    '&.isBig': {
      width: '50%',
      flexShrink: 0,
      height: '100%',
    },
  },
  bodyContainer: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    '&.isBig': {
      padding: theme.spacing(6, 4),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  body: {
    lineHeight: 1.5,
    flexGrow: 1,
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&.isBig': {
      justifyContent: 'flex-start',
    },
  },
  ctaBox: {
    paddingBottom: theme.spacing(1 / 2),
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    '&.isBig': {
      justifyContent: 'flex-start',
    },
  },
  badgeBox: {
    position: 'absolute',
    left: theme.spacing(3),
    bottom: 0,
    padding: theme.spacing(1 / 4, 1 / 2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&.isBig': {
      position: 'relative',
      left: 'initial',
      width: 'fit-content',
      marginBottom: theme.spacing(2),
    },
  },
  ribbonRoot: {
  },
  ribbon: {
    top: 42,
    left: -32,
    width: 170
  }
}));

const ArticleCard = ({ titleSeoComponent, className, title, text, picture, pictureBig, badgeText, ribbonText, link, isBig }) => {
  const classes = useStyles();

  const pic = isBig ? (pictureBig || '') : (picture || '');
  const image = webpTry(pic);

  const imageStyle = {
    backgroundSize: 'cover',
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Box className={clsx(className, classes.container, isBig && 'isBig')}>
      <Box className={clsx(classes.imageContainer, isBig && 'isBig', 'lazyload')} data-bg={image} style={imageStyle}>
        {!isBig && badgeText && (
          <Text className={classes.badgeBox} variant='bodyXS' weight='medium'>{badgeText}</Text>
        )}
        {ribbonText &&
          <Ribbon classes={{ root: classes.ribbonRoot, ribbon: classes.ribbon }}>
            {ribbonText}
          </Ribbon>
        }
      </Box>
      <Box className={clsx(classes.bodyContainer, isBig && 'isBig')}>
        {isBig && badgeText && (
          <Text className={clsx(classes.badgeBox, isBig && 'isBig')} variant='bodyXS' weight='medium'>
            {badgeText}
          </Text>
        )}
        <Text
          className={clsx(classes.title)}
          variant={isBig ? 'titleXL' : 'titleL'}
          weight='medium'
          component={titleSeoComponent}
        >
          {title}
        </Text>
        <Text className={classes.body} variant='bodyM'>{text}</Text>
        {link && (
          <Box className={clsx(classes.ctaContainer, isBig && 'isBig')}>
            <Text className={classes.ctaBox} variant='bodyXS' weight='medium'>{link.title}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ArticleCard;
