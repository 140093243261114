import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import Text from './Text';
import Video from './Video';

const useStyles = makeStyles((theme) => ({
    VideoDescription: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gridGap: theme.spacing(12),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gridGap: theme.spacing(4),
        }
    },
    editorialContent: {
        paddingRight: theme.spacing(15),
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(0, 1, 0, 1)
        }
    },
    title: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        '&:before': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
    },
    text: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            lineHeight: '1.57 !important',
            textAlign: 'center',
            fontSize: '0.9rem !important'
        },
    },
    videoWrapper: {
        position: 'relative',
        height: 430,
        [theme.breakpoints.down('md')]: {
            paddingBottom: '56.25%', /* 16:9 */
            height: 0
        }
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%'
    },
}));


const VideoDescription = ({ title, text, video, hideDescOnMobile = false, tracking }) => {
    const classes = useStyles();

    return (
        <div className={classes.VideoDescription}>
            <div className={classes.editorialContent}>
                {title &&
                    <Text className={classes.title} variant='titleXXXL' overline weight='bold'>
                        {title}
                    </Text>}
                {text && <>
                    <Hidden mdDown>
                        <Text className={classes.text} variant='bodyXL' weight='medium'>
                            {text}
                        </Text>
                    </Hidden>
                    <Hidden lgUp>
                        {!hideDescOnMobile && <Text className={classes.text} variant='bodyS'>
                            {text}
                        </Text>}
                    </Hidden>
                </>
                }
            </div>
            {video &&
                <div className={classes.videoWrapper}>
                    <Video className={classes.video} fullscreen={false} showPlayButtonText {...video} tracking={tracking} />
                </div>
            }
        </div>
    );
};

export default VideoDescription;
