import React, { useState, useEffect, useMemo, Fragment } from "react"
import clsx from "clsx"
import { Container, Box, Hidden, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { showGuides } from "@src/utils/debug"
import shortid from "shortid"
import { pubsub } from "@src/utils/pubsub"
import Module from "../components/Module"
import Parallax from "../components/Parallax"
import Text from "../components/Text"
import Button from "../components/Button"
import CtaButton from "../components/CtaButton"
import Slider from "../components/Slider"
import FadeInContainer from "../animations/FadeInContainer"
import FluidImage from "../components/FluidImage"
import Picture from "../components/Picture"
import Stamp from "../components/Stamp"
import PromoFooter from "../components/PromoFooter"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import Chip from "@material-ui/core/Chip"
import slide_outline from "../assets/img/icons/slide-outline/slide-outline.png"
import { GTMEvent } from "../utils/gtm"

import TrustBox from "../components/TrustBox"
import "../style/heroBannerModule.css"
import { useTranslation } from "react-i18next"

const parallaxHeight = 680

const useStyles = makeStyles(theme => ({
  heroBanner: {
    background: "#1d1d1d",
    width: "100%",
    height: parallaxHeight,
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },

    "& .slick-next": {
      display: "none !important",
    },
  },
  parallax: {
    position: "absolute",
    zIndex: -1,
    height: parallaxHeight,
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
    },
  },
  fadeInContainer: {
    height: "100%",
    width: "100%",
  },

  slider: {
    height: "100%",
    "& .slick-slide": {
      overflow: "hidden",
      position: "relative",
    },
  },
  sliderArrows: {
    display: "none",
    justifyContent: "flex-end",
    width: "fit-content",
    right: 1,
    marginTop: -126,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
      top: "40%",
      justifyContent: "space-between",
    },
  },
  sliderBack: {
    display: "none",
    height: 60,
    width: 60,
    //border: `4px solid transparent`,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  sliderNext: {
    display: "none",
    height: 60,
    width: 60,
    border: `4px solid ${theme.customPalette.tangerine}`,
    borderRadius: "50%",
    marginLeft: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      borderColor: "transparent",
      margin: 0,
    },
  },
  heroBannerContainer: {
    height: parallaxHeight,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh", // FIX ** full extension for mobile
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(3),
      display: "block",
    },
  },
  contentContainer: {
    border: showGuides("2px solid yellow"), // FIX ** guides
    position: "absolute",
    width: "40%",
    zIndex: 99,
    left: theme.spacing(4), // FIX ** apply padding internally
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: 0,
      padding: theme.spacing(2), // FIX ** apply padding internally
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "center",
    },
  },
  logo: {
    width: 350,
    maxHeight: 200,
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-start",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "85%", // FIX ** move logo and sizing
      maxHeight: 150, // FIX ** move logo and sizing
      marginTop: 60, // FIX ** move logo and sizing
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center",
      maxWidth: 280,
      //padding: theme.spacing(1, 4, 1, 4) // FIX ** maybe not needed
    },
  },
  logo_narrow_desktop: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxHeight: "15%",
      maxWidth: 250,
    },
  },
  logo_narrow_mobile: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxHeight: "15%",
      maxWidth: 250,
    },
  },
  position_center_logo: {
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cta: {
    backgroundColor: "#000",
    color: "#fff",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: "initial",
    fontWeight: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      whiteSpace: "nowrap",
    },
  },
  ctaContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    width: 400,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  picture: {
    border: showGuides("1px solid green"), // FIX ** guides
    maxHeight: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      right: "0",
      width: "100%",
      top: "50%",
      margin: "0",
      zIndex: "0",
      position: "absolute",
      maxWidth: "930px",
      transform: "translate3d(-50%, -50%, 0)",
      maxHeight: "100%",
    },
  },
  bannerCtaButton: {
    width: 400,
    marginTop: theme.spacing(4),
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stamp: {
    left: 0,
    bottom: "100px",
    maxWidth: "120px",
    //padding: 30,
    [theme.breakpoints.down("sm")]: {
      left: "unset",
      right: "3%",
      //top: '0%',
      bottom: "10vh",
    },
  },
  stampTitle: {
    width: "80%",
    margin: "0 auto",
    //paddingBottom: 5,
    ...theme.fonts.faces.bodyXS,
    ...theme.fonts.weights.bold,
    [theme.breakpoints.down("sm")]: {
      ...theme.fonts.weights.bold,
    },
  },
  stamphigHightedText: {
    ...theme.fonts.faces.titleXXXL,
    ...theme.fonts.weights.bold,
    [theme.breakpoints.down("sm")]: {
      ...theme.fonts.faces.titleXXXL,
      ...theme.fonts.weights.bold,
    },
  },
  licenseLogo: {
    position: "absolute",
    height: 57,
    width: 140,
    right: theme.spacing(4),
    bottom: 150,
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(1),
      right: "unset",
      bottom: 10,
      width: "fit-content",
    },
  },
  licenseLogoSingle: {
    bottom: 50,
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(2),
      bottom: 10,
    },
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  fadeInText: {
    animation: "$fadeIn 1.5s",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "-50px",
    },
  },
  parallaxItemWrapper: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "flex-end",
    height: "100%",
    width: "60%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: "120vh",
      maxWidth: "800px",
      height: "auto",
      transform: "translate3d(-50%,0,0)",
    },
  },
  chip: {
    marginBottom: "4px",
  },
  titleXXL: {
    fontSize: "2.5rem !important",
    fontStyle: "normal",
    fontFamily:
      "Druk Wide Web, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontWeight: "bold",
    lineHeight: "2.4rem !important",
    fontStretch: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem !important",
      lineHeight: "1.7rem !important",
    },
  },
}))

const HeroStamp = ({ text, highlightedText }) => {
  const classes = useStyles()
  return text && highlightedText ? (
    <Stamp className={classes.stamp}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <Text className={classes.stampTitle}>{text}</Text>
        <Text className={classes.stamphigHightedText}>{highlightedText}</Text>
      </Box>
    </Stamp>
  ) : (
    ""
  )
}

const SingleSlide = ({
  slide,
  i,
  count,
  onReady,
  classes,
  cta,
  pageFamily,
  tracking,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const topic = pubsub.oneoff()
  const handleOnLoad = () => {
    pubsub.publish(topic, true)
  }
  const { i18n, t } = useTranslation()

  // TODO questa logica DEVE arrivare da CMS
  const TEMPORARY_FIX =
    slide.link &&
    slide.link.href &&
    slide.link.href.includes("/subscribe") &&
    ((slide.link.href.includes("checkout.fanhome.com/") &&
      !slide.link.href.includes("testVersion")) ||
      slide.link.href.includes("p1597070c3tst-store.occa.ocs.oraclecloud.com/"))

  return (
    <>
      <Parallax
        key={`hero_slide_${i}`}
        bgImage={slide.backgroundImage}
        bgMobileImage={slide.mobileBackgroundImage}
        strength={250}
        className={classes.parallax}
        parallaxStyle={{
          position: "absolute",
          zIndex: -1,
          height: parallaxHeight,
        }}
      />
      <Container
        className={classes.heroBannerContainer}
        style={{ position: "relative" }}
      >
        <Hidden smUp>
          <Picture
            className={classes.picture}
            {...slide.mobileForegroundImage}
          />
        </Hidden>
        <Box className={classes.contentContainer}>
          <img
            src={slide?.logo?.src}
            alt={slide?.logo?.alt}
            title={slide?.logo?.title}
            className={clsx(
              classes.logo,
              slide.narrow_logo_desktop && classes.logo_narrow_desktop,
              slide.narrow_logo_mobile && classes.logo_narrow_mobile,
              slide.position_center_logo && classes.position_center_logo
            )}
          />
          <Box className={classes.fadeInText}>
            <Hidden mdUp>
              <Box style={{ position: "relative" }}>
                {slide.stamp && <HeroStamp {...slide.stamp} />}
                {slide.licenseLogo && (
                  <FluidImage
                    className={classes.licenseLogo}
                    {...slide.licenseLogo}
                  />
                )}
              </Box>
            </Hidden>
            {slide?.cta?.isPreorder ? (
              <Chip className={classes.chip} label={t("pre_order_label")} />
            ) : (
              slide.chipText && (
                <Chip className={classes.chip} label={slide.chipText} />
              )
            )}
            <a href={cta?.href || slide?.link?.href}>
              <Text
                variant="specialTitleXL"
                color="textTertiary"
                className={clsx(classes.title, classes.titleXXL)}
              >
                {slide.title}
              </Text>
              <Text
                variant="bodyM"
                color="textTertiary"
                className={classes.subtitle}
              >
                {slide.subtitle}
              </Text>
            </a>

            {!slide.link && cta && cta.productCode && (
              <Box className={classes.ctaContainer}>
                <CtaButton
                  className={classes.bannerCtaButton}
                  {...cta}
                  next
                  size="large"
                  trackingObj={tracking}
                  trackingAction="click_link"
                  trackingLabel={"HeroBannerModule - " + cta.productCode}
                >
                  {cta.text}
                </CtaButton>
              </Box>
            )}
            {/*HOTFIX 26-07*/}
            {slide.link && TEMPORARY_FIX && (
              <Box className={classes.ctaContainer}>
                <CtaButton
                  className={classes.bannerCtaButton}
                  //HOTFIX 26-07
                  forceOverrideLinkTEMPORARY={slide.link.href}
                  next
                  size="large"
                  {...cta}
                  trackingObj={tracking}
                  trackingAction="click_link"
                  trackingLabel={
                    "HeroBannerModule - " + (cta?.label || cta?.title)
                  }
                >
                  {slide.link.title}
                </CtaButton>
              </Box>
            )}
            {/*HOTFIX 26-07*/}
            {slide.link && !TEMPORARY_FIX && (
              <Button
                className={classes.bannerCtaButton}
                next
                size="large"
                {...slide.link}
                trackingObj={tracking}
                trackingAction="click_link"
                trackingLabel={
                  "HeroBannerModule - " +
                  (slide.link?.label || slide.link?.title)
                }
              >
                {slide.link.title}
              </Button>
            )}
          </Box>
        </Box>
        <Hidden xsDown>
          <ParallaxItem
            disabled={isMobile}
            className={classes.parallaxItemWrapper}
            y={["10", "-10"]}
          >
            <Hidden smDown>
              {slide.stamp && <HeroStamp {...slide.stamp} />}
            </Hidden>
            <Picture className={classes.picture} {...slide.foregroundImage} />
          </ParallaxItem>
          {slide.licenseLogo && (
            <FluidImage
              className={clsx(classes.licenseLogo, {
                [classes.licenseLogoSingle]: count === 1,
              })}
              {...slide.licenseLogo}
            />
          )}
        </Hidden>
      </Container>
    </>
  )
}

const HeroBannerModule = props => {
  const { slides, cta, footer, repo, templateLabel } = props
  //   console.log("+++++++templateLabel", templateLabel)
  const pageFamily = useMemo(() => {
    return repo.tracking.dataLayer[0].pageFamily
  }, [])

  const classes = useStyles()

  const [masterSliderIndex, setMasterSliderIndex] = React.useState(0)
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })
  const [touchStart, setTouchStart] = React.useState(null)
  const [touchEnd, setTouchEnd] = React.useState(null)

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  React.useEffect(() => {
    if (slides.length > 1 && templateLabel !== "PDP") {
      const sliderAutoplay = setInterval(() => {
        changeSlide(1)
      }, 8000)
      return () => {
        clearInterval(sliderAutoplay)
      }
    }
  }, [masterSliderIndex])

  React.useEffect(() => {
    // Handler to call on window resize
    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && windowSize.width < 900) {
      changeSlide(1)
    } else if (isRightSwipe && windowSize.width < 900) {
      changeSlide(-1)
    }
  }

  const changeSlide = index => {
    if (masterSliderIndex + index < 0) {
      setMasterSliderIndex(slides.length - 1)
    } else if (masterSliderIndex + index > slides.length - 1) {
      setMasterSliderIndex(0)
    } else {
      setMasterSliderIndex(masterSliderIndex + index)
    }
  }

  return (
    <section className="HeroBannerModule">
      <Module
        alwaysVisible
        className={classes.heroBanner}
        variant="full"
        marginBottomOnly
      >
        {slides && (
          <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <Slider
              className={classes.slider}
              classes={{
                arrows: classes.sliderArrows,
                back: classes.sliderBack,
                next: classes.sliderNext,
              }}
              arrows={(slides || []).length > 1}
              infinite={false}
              slidesToShow={1}
              slidesToScroll={1}
              dark
              tracking={repo}
              indexSlider={masterSliderIndex}
              swipe={false}
            >
              {(slides || []).map((slide, i) => (
                <SingleSlide
                  key={shortid.generate()}
                  slide={slide}
                  i={i}
                  classes={classes}
                  cta={cta}
                  pageFamily={pageFamily}
                  tracking={repo}
                />
              ))}
            </Slider>
            {slides.length > 1 && (
              <div className="dots-container">
                <div className="dots-container-inner">
                  <div
                    className={`inner-button-arrows ${
                      templateLabel === "PDP" ? "inner-button-arrows-PDP" : ""
                    }`}
                  >
                    <button
                      onClick={() => {
                        changeSlide(-1)
                        GTMEvent("home", "slider-figures", "swipe-slider")
                      }}
                      className="arrow-button"
                    >
                      <img src={slide_outline} />
                    </button>

                    {slides.map((_k, i) =>
                      templateLabel !== "PDP" ? (
                        <div
                          key={i}
                          onClick={() => {
                            setMasterSliderIndex(i)
                            GTMEvent("home", "slider-figures", "swipe-slider")
                          }}
                          className="dot-rectangle"
                        >
                          <div className="dot-bg">
                            <div
                              className={`dot-fill ${
                                i === masterSliderIndex ? "dot-animation" : ""
                              } ${
                                i < masterSliderIndex
                                  ? "bg-yellow01"
                                  : "bg-grey01"
                              }`}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="dot-bg">
                          <div
                            className={`dot-fill ${
                              i === masterSliderIndex ? "dot-animation" : ""
                            } ${
                              i < masterSliderIndex
                                ? "bg-yellow01"
                                : "bg-grey01"
                            }`}
                          />
                        </div>
                      )
                    )}
                    <button
                      onClick={() => {
                        changeSlide(1)
                        GTMEvent("home", "slider-figures", "swipe-slider")
                      }}
                      className="arrow-button"
                      style={{
                        transform: "rotate(180deg)",
                        marginBottom: "5px",
                      }}
                    >
                      <img src={slide_outline} />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {footer && cta && (cta.productCode || cta.href) && (
          <PromoFooter {...footer} cta={cta} tracking={repo} />
        )}
      </Module>

      {!footer?.trustpilotHide && (
        <TrustBox type="horizontal" country={repo?.seo?.country}></TrustBox>
      )}
    </section>
  )
}

export default HeroBannerModule
