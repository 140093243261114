import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';

import Module from '../components/Module';
import Text from '../components/Text';
import GridGallery from '../components/GridGallery';


const useStyles = makeStyles((theme) => ({
    columnContent: {
        background: theme.customPalette.greyClear,
        color: theme.customPalette.darkishPurple,
    },
    container: {
        display: 'grid',
        //gridGap: theme.spacing(3),
        gridTemplate: 'auto / auto',
        padding: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        }
    },
    dark: {
        backgroundColor: theme.customPalette.darkishPurple,
        backgroundImage:
            "linear-gradient(to bottom, rgba(107, 27, 123, 0.06), #390b45 98%)",
        color: 'white'
    },
    headerWrapper: {},
    galleryWrapper: {},
    gallery: {
        [theme.breakpoints.down('sm')]: {
            gridRowGap: 0,
        },
    },
    kicker: {
        lineHeight: 3.13,
        letterSpacing: -0.03,
        [theme.breakpoints.down('sm')]: {
            lineHeight: 3.13,
        },
    },
    title: {
        lineHeight: 1.17,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem !important',
            lineHeight: 1.09
        },
    },
    smaller: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem !important',
        },
    },
    text: {
        fontSize: 20,
        lineHeight: 1.35,
        letterSpacing: -0.04,
        padding: theme.spacing(3, 0, 3, 0),
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            lineHeight: 1.33,
            letterSpacing: 0,
        },
    },
}));


const ColumnContentModule = ({ kicker, title, text, images, dark }) => {
    const classes = useStyles();

    return (
        <Module className={clsx(classes.columnContent, { [classes.dark]: dark })} classes={{ container: classes.container }} variant="wrap">
            <Box className={classes.headerWrapper}>
                <Text className={classes.kicker} variant='bodyExtM' color='secondary' weight='bold'>{kicker}</Text>
                <Text className={clsx(classes.title, { [classes.smaller]: dark })} variant='bodyExtXXL' weight={!dark ? 'bold' : 'medium'}>{title}</Text>
            </Box>
            {text && <Text className={classes.text} variant='bodyM'>{text}</Text>}
            <Box className={classes.galleryWrapper}>
                <GridGallery className={classes.gallery} photos={images} cols={2} />
            </Box>
        </Module>
    );
};

export default ColumnContentModule;
