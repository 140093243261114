import React, { useState, Suspense } from "react"
import shortid from "shortid"

import { snake } from "@src/snake/core"

import DevelopLazy from "@src/snake/components/DevelopLazy"
import DevelopLazyError from "@src/snake/components/DevelopLazyError"
import Error404 from "@src/snake/components/Error404"
import Error500 from "@src/snake/components/Error500"
import DefaultTemplate from "@src/site/templates/default"

export default function SnakeRender({ pageContext: { preview, datasource } }) {
  const [page, setPage] = useState()
  const read = () => {
    if (page) return
    if (preview && noope(datasource)) {
      //console.log("[Render] Render is on hold, waiting for page data...", window.location.pathname);

      snake
        .getPageByUriWithData(window.location.pathname)
        .then(response => {
          //console.log("[Render] Datasource ok, start rendering...", response);
          datasource = response ? response.data : false
          render()
        })
        .catch(e => {
          console.error("API error", e)
          setPage({
            seo: null,
            tracking: null,
            content: <Error500 key={shortid.generate()} message={e.message} />,
            social: null,
            product_info: null,
            qa_exception: null,
          })
        })

      setPage({
        seo: null,
        tracking: null,
        content: <DevelopLazy message="Generating Preview" />,
        social: null,
        product_info: null,
        qa_exception: null,
      })
      return
    }
    setPage({
      seo: datasource.seo,
      tracking: datasource.tracking,
      content: render(),
      social: datasource.social,
      product_info: datasource.product_info,
      qa_exception: datasource.qa_exception,
    })
  }

  const render = () => {
    /* if (preview) {
      console.warn('[Render] %c PREVIEW MODE IS %c ON ', 'font-weight: 800;', 'font-weight: 800; color: white; background: green;');
      console.warn('[Render] %c This is a development functionality and performance could be not optimized for production. ', 'font-weight: 800;');
    } */
    // Validation first
    var failsOn404 = noope(datasource, null)
    if (failsOn404) {
      if (preview) {
        // Show generic 404
        setPage({
          seo: null,
          tracking: null,
          content: <Error404 key={shortid.generate()} />,
          social: null,
          product_info: null,
          qa_exception: null,
        })
        return
      } else {
        // Abort build process
        console.error('Not able to build uri: ' + datasource.uri);
        throw new Error("Impossibile to build: page not found!")
      }
    }
    var failsOn500 =
      noope(datasource, "modules") ||
      !Array.isArray(datasource.modules) ||
      datasource.modules.length === 0
    if (failsOn500) {
      if (preview) {
        // Show generic 500
        setPage({
          seo: null,
          tracking: null,
          content: (
            <Error500 key={shortid.generate()} message="No modules in page." />
          ),
          social: null,
          product_info: null,
          qa_exception: null,
        })
        return
      } else {
        // Abort build process
        console.log('URI: ' + datasource.uri);
        /*
        throw new Error(
          "Impossibile to build: page found, but modules is empty!"
        )
        */
      }
    }
    // Rendering modes start
    if (preview) {
      lazy()
      return true
    } else {
      return sync()
    }
  }

  const noope = (data, key) => {
    let check1 = typeof data === "undefined" || data === null || !data
    if (check1) return check1
    let check2 =
      key != null &&
      (typeof data[key] === "undefined" || data[key] === null || !data[key])
    return check2
  }

  const sync = () => {
    return pack(collect(true))
  }

  const lazy = () => {
    collect()
  }

  const collect = inline => {
    let queue = datasource.modules
    var modules = []
    push(queue, modules, 0, queue.length, inline)
    return modules
  }

  const push = (queue, modules, index, size, inline) => {
    if (queue.length === 0) {
      return
    }
    var shift = queue.shift()
    var slug = `${shift.type}Module`

    var id = shift.id
    if (typeof id === "string") {
      id = id.match(/(\d+)/)[0]
    }
    var identifier = `${shift.type}-${id}`

    const data = {
      ...shift.content,
      css: shift.css,
      repo: datasource,
      identifier: identifier,
    }

    // Processing
    if (inline) {
      // Build mode: inline import
      try {
        const module = require(`@src/modules/${slug}.js`)
        const ModuleElement = module.default
        const ReactTag = (
          <div id={data.identifier} key={shortid.generate()}>
            <ModuleElement {...data} />
          </div>
        )
        pull(index, ReactTag, modules, size, inline)
      } catch (e) {
        console.error("[Build] Error", e)
        console.error("[Build] Error on Module", slug, data)
        console.error("[Build] Error on Page", datasource.uri)
        throw new Error(
          "[Build] Impossible to render page due to module issue @ " +
            slug +
            " (ref error:" +
            e.message +
            ")"
        )
      }
    } else {
      //console.log("Lazy", slug);
      // Preview mode: lazy loading
      const ModuleElement = React.lazy(() => import(`@src/modules/${slug}`))
      const ReactTag = (
        <DevelopLazyError key={shortid.generate()}>
          <Suspense fallback={<DevelopLazy message={`Generating ${slug}`} />}>
            <ModuleElement {...data} />
          </Suspense>
        </DevelopLazyError>
      )
      pull(index, ReactTag, modules, size, inline)
    }
    // Enqueue next
    push(queue, modules, index + 1, size, inline)
  }

  const pull = (index, tag, modules, size, inline) => {
    modules.push({ sort: index, component: tag })
    if (!inline) {
      if (modules.length === size) {
        setPage({
          seo: datasource.seo,
          tracking: datasource.tracking,
          content: pack(modules),
          social: datasource.social,
          product_info: datasource.product_info,
          qa_exception: datasource.qa_exception,
        })
      }
    }
  }

  const pack = modules => {
    var render = []
    // Sort by index
    modules.forEach(function (key) {
      render[key.sort] = key.component
    })
    return render
  }

  // Read page data
  read()

  return <DefaultTemplate preview={preview} page={page} />
}
