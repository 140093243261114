import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Slider from 'react-slick';

import clsx from 'clsx';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { GTMEvent } from "../utils/gtm"

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  sliderButton: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderNextButton: {
    borderRadius: '100%',
    border: `3px solid ${theme.customPalette.tangerine}`,
  },
  slider: {
    '& .slick-list': {
      overflow: 'visible',
    },
    marginBottom: theme.spacing(3),
  },
}));

const MobileSlider = ({ className, slideToShow, slideToScroll, tracking, children }) => {
  const classes = useStyles();

  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideToShow ? slideToShow : 1,
    slidesToScroll: slideToScroll ? slideToScroll : 1,
    className: clsx(classes.slider, className),
    tracking: tracking
  };

  const next = () => {
    if(tracking){
      GTMEvent(tracking, "slider", "swiper-slider")
    }

    sliderRef.current.slickNext();
  };
  const previous = () => {
    if(tracking){
      GTMEvent(tracking, "slider", "swiper-slider")
    }

    sliderRef.current.slickPrev();
  };

  return (
    <Box className={clsx(classes.container)}>
      <Slider ref={sliderRef} {...sliderSettings}>
        {children}
      </Slider>
      <Box display="flex" justifyContent="flex-end" paddingRight={2}>
        <Box className={classes.sliderButton} onClick={previous}>
          <ArrowBackIcon />
        </Box>
        <Box className={clsx(classes.sliderButton, classes.sliderNextButton)} onClick={next}>
          <ArrowForwardIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileSlider;
