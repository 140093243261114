import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';

export const styles = (theme) => ({
    /* Styles applied to the root element. */
    gallery: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
        gridTemplateRows: 'repeat( auto-fit, minmax(150px, 372px) )',
        gridTemplateAreas: '"a0 a0 a1 a1"\n"a0 a0 a2 a3"\n"aOthers aOthers aOthers aOthers"',
        gridGap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            gridTemplateAreas: '"a0 a0"\n"a0 a0"\n"a1 a1"\n"a2 a3"\n"aOthers aOthers"\n"aOthers aOthers"',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
            gridTemplateRows: 'repeat( auto-fit, minmax(50px, 372px) )',
        },
    },
    background: {
        height: '100%',
        width: "100%",
        minHeight: 372,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        [theme.breakpoints.down('md')]: {
            minHeight: 350,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 200,
        },
    }
});

const Gallery = React.forwardRef(function Gallery(props, ref) {
    const {
        classes,
        className,
        photos,
        onClick
    } = props;

    return (
        <div
            className={clsx(
                classes.gallery,
                className,
            )}
            ref={ref}
            onClick={onClick}
        >
            {(photos || []).map((p, i) => (
                <div key={`photo_${i}`} style={{ gridArea: `a${i < 4 ? i : 'Others'}` }}>
                    <div className={classes.background} style={{ backgroundImage: `url(${p ? p.src : ''})` }}></div>
                </div>
            ))}
        </div>
    );
});

Gallery.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: 'FHGallery' })(Gallery);