import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { Box } from "@material-ui/core"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Module from '../components/Module';
import Text from '../components/Text';
import Icon from '../components/Icon';

import { GTMEvent } from "../utils/gtm"

const useStyles = makeStyles((theme) => ({
    faqs: {
        paddingBottom:38
    },
    infoText: {
        padding: theme.spacing(0, 2, 0, 0),
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(0, 2, 0, 2),
        },        
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2, 0, 2),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        '&:before': {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            }
        }
    },
    description: {
        marginBottom: theme.spacing(4)
    },
    expandIcon: {
        width: 32,
        height: 32
    },
    faq: {
        background: theme.customPalette.greyClear,
        borderRadius: 0,
        marginBottom: theme.spacing(2),
        '&:first-child': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        },
        '&:last-child': {
            marginBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
        [theme.breakpoints.down('sm')]: {
            borderRight: `${theme.spacing(2)}px solid white`,
            borderLeft: `${theme.spacing(2)}px solid white`,
        }
    },
    faqLastVisible: {
        '& > div:before': {
            content:"''",
            position:'absolute',
            top:0,
            left:0,
            bottom:0,
            right:0,
            opacity:0.8,
            backgroundColor:'#fff',
            zIndex:9
        },
    },
    lastVisibileFaqOpenIcon: {
        position:'absolute',
        left:0,
        right:0,
        bottom:-10,
        textAlign:'center',
        backgroundColor:'#fff',
        zIndex:11,
        '&:before': {
            content:"''",
            position:'absolute',
            top:0,
            left:0,
            right:0,
            opacity:0.3,
        }        
    },
    faqRoot: {
        '&:before': {
            display: 'none'
        }
    },
    faqHeading: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight: theme.typography.fontWeightBold,
    },
    answer: {
        paddingBottom: theme.spacing(2)
    },
    grid: {
        position:'relative'
    },
    overlayWrapper: {    
        cursor: 'pointer',
        position: 'absolute',
        left: 0,
        right: 0,
        textAlign: 'center',
        '& .overlayTrans': {
            width: '100%',
            backgroundColor: '#fff',
            opacity: 0.8,
        },             
        '& .overlayWhite': {
            width: '100%',
            backgroundColor: '#fff',
            textAlign: 'center',
        },                     
    },
    bgOpenLastFaq: {
        position: 'absolute',
    }
}));

const FaqsModule = ({ title, description, faqs, ...others }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(new Array((faqs || []).length).fill(false));
    const [faqInitialShow, setFaqInitialShow] = useState(4);

    const handleToggle = (index, faq) => {
        if ( ( index < 3 ) || ( expanded.length < faqInitialShow ) ) {
            const newExpanded = [...expanded];
            newExpanded[index] = !newExpanded[index];
            setExpanded(newExpanded);
            GTMEvent(others.repo, "faqs", (faq?.faq || "").replace(/<\/?[^>]+(>|$)/g, ""));
        } else {
            setFaqInitialShow(faqInitialShow > 4 ? 4 : 999);
        }
    };

    return (
        <Module className={classes.faqs}>
            <Grid container>
                <Grid xs={12} md={4} item>
                    <Box className={classes.infoText}>
                        <Text className={classes.title} variant="titleXXXL" overline weight="bold" component="p">{title}</Text>
                        <Text className={classes.description} variant="bodyM">{description}</Text>
                    </Box>
                </Grid>
                <Grid className={classes.grid} xs={12} md={8} item>
                    {
                        (faqs || []).map((f, index) => {
                            if ( index < faqInitialShow ) {

                                const lastVisibileFaq = index == 3 && expanded.length > faqInitialShow ? [classes.faq, classes.faqLastVisible] : [classes.faq];

                                return (
                                <Accordion elevation={0} expanded={expanded[index]} key={`faq_${index}`} className={lastVisibileFaq.join(' ')} onChange={() => { handleToggle(index, f) }} classes={{ root: classes.faqRoot, rounded: classes.faqRounded }}>
                                    <AccordionSummary
                                        expandIcon={!expanded[index] ?
                                            <Icon className={clsx(classes.expandIcon)} name='plus_tangerine' />
                                            : <Icon className={clsx(classes.expandIcon)} name='minus_tangerine' />
                                        }
                                    >
                                        <Text className={classes.faqHeading} variant="bodyM" weight='bold'>{f.faq}</Text>
                                        { index == 3 && expanded.length > faqInitialShow ? 
                                        <div className={classes.lastVisibileFaqOpenIcon}><Icon className={clsx(classes.expandIcon)} name='open_down' /></div> : null
                                        }
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Text className={classes.answer} variant='bodyM' align='left'>
                                            {f.answer}
                                        </Text>
                                    </AccordionDetails>
                                </Accordion>);
                            }
                        })                        
                    }
                </Grid>
            </Grid>
        </Module>
    );
}


FaqsModule.defaultProps = {
    title: '',
    subtitle: '',
    description: '',
    faqs: []
}


export default FaqsModule;